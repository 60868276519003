import React, { useEffect, useState } from "react";
import Header from "../Header";
import "../sidebar.css";
import { Link, useNavigate } from "react-router-dom";
import Sidebarr from "../Sidebar";
import toast, { Toaster } from "react-hot-toast";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";

const Editproduct = () => {
  const [sale_pricee, setsale_pricee] = useState("");
  const [discount_pricee, setdiscount_pricee] = useState();
  const [sale_price, setsale_price] = useState();
  const [discountData, setDiscountData] = useState([
    { discount_type: "", discount_value: "" },
  ]);

  const handleDiscountChange = (index, field, updatedValue) => {
    const updatedDiscountData = [...discountData];
    updatedDiscountData[index][field] = updatedValue[field];
    setDiscountData(updatedDiscountData);
  };

  const [productdata, setproductdata] = useState();

  let token = secureLocalStorage.getItem("vendortoken");
  let venderIdd = secureLocalStorage.getItem("vendorid");
  let productid = secureLocalStorage.getItem("productid");

  const Navigate = useNavigate();

  useEffect(() => {
    Products_details();
  }, [0]);

  const Products_details = () => {
    const options = {
      headers: {
        token: token,
      },
    };
    const data = {
      productId: productid,
      venderId: venderIdd,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/productDetails`,
        data,
        options
      )
      .then((res) => {
        setproductdata(res?.data?.data);
        setsale_pricee(res?.data?.data[0]?.unit_price);
        setdiscount_pricee(res?.data?.data[0]?.discount);
      })
      .catch((error) => {});
  };

  const updateproduct = (e) => {
    e.preventDefault();

    const formData = {
      venderId: venderIdd,
      productId: productid,
      unit_price: sale_price ? sale_price : sale_pricee,
      discount: discountData?.some((d) => d?.discount_type || d?.discount_value)
        ? discountData
        : discount_pricee,
    };

    let options = {
      headers: {
        token: token,
      },
    };

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/productUpdate`,
        formData,
        options
      )
      .then((res) => {
        Products_details();
        toast.success(res.data.message);
        setTimeout(() => {
          Navigate("/Products List");
        }, 3000);
      })
      .catch((error) => {});
  };

  return (
    <div className="container">
      <Toaster />
      {/* <Header /> */}
      <div
        className="container row"
        style={{ paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px" }}
      >
        <div className="col-lg-2 col-md-3" style={{ paddingLeft: "0px" }}>
          {/* <Sidebarr /> */}
        </div>

        <div
          className="col-lg-10 col-md-9"
          style={{ paddingLeft: "30px", marginTop: "60px" }}
        >
          <div className="content container-fluid">
            <br />
            <br />
            <div className="d-flex flex-wrap gap-2 align-items-center mb-3">
              <h2 className="h1 mb-0 d-flex align-items-center gap-2">
                <img
                  width={20}
                  src="https://6valley.6amtech.com/public/assets/back-end/img/products.png"
                  alt=""
                />
                Product Edit
              </h2>
            </div>
            {productdata?.map((data) => {
              return (
                <form
                  className="product-form text-start"
                  onSubmit={updateproduct}
                >
                  <input type="hidden" name="_token" />{" "}
                  <div className="card mt-3 rest-part">
                    <div className="card-header">
                      <div className="d-flex gap-2">
                        <i class="fa fa-user" aria-hidden="true"></i>
                        <h4 className="mb-0">Pricing &amp; others</h4>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="row ">
                        <div className="col-md-6 col-lg-6 col-xl-6">
                          <div className="form-group">
                            <div className="d-flex gap-2 mb-2">
                              <label className="title-color mb-0">
                                Unit price ($)
                              </label>
                              <span
                                className="input-label-secondary cursor-pointer"
                                data-toggle="tooltip"
                                title="Set the selling price for each unit of this product. This Unit Price section won’t be applied if you set a variation wise price."
                              >
                                <img
                                  src="https://6valley.6amtech.com/public/assets/back-end/img/info-circle.svg"
                                  alt
                                />
                              </span>
                            </div>
                            <input
                              value={sale_price}
                              type="number"
                              min={0}
                              step="0.01"
                              placeholder={data?.unit_price}
                              name="unit_price"
                              onChange={(e) => {
                                setsale_price(e.target.value);
                              }}
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="col-md-6 col-lg-6 col-xl-6">
                          {discountData?.map((discount, index) => (
                            <div key={index} className="row">
                              <div className="col-md-6 col-lg-6 col-xl-6">
                                <label className="title-color">
                                  Discount type
                                </label>
                                <select
                                  className="form-control"
                                  value={discount.discount_type}
                                  onChange={(e) =>
                                    handleDiscountChange(
                                      index,
                                      "discount_type",
                                      {
                                        discount_type: e.target.value,
                                      }
                                    )
                                  }
                                >
                                  <option value="" disabled>
                                    {data?.discount[0]?.discount_type}
                                  </option>
                                  <option value="Unit">Unit</option>
                                  <option value="Percent">Percent</option>
                                </select>
                              </div>

                              <div className="col-md-6 col-lg-6 col-xl-6">
                                <label className="title-color">
                                  Discount Amount
                                </label>
                                <input
                                  
                                  type="number"
                                  value={discount.discount_value}
                                  onChange={(e) =>
                                    handleDiscountChange(
                                      index,
                                      "discount_value",
                                      {
                                        discount_value: parseInt(
                                          e.target.value,
                                          10
                                        ),
                                      }
                                    )
                                  }
                                  className="js-select2-custom form-control"
                                  placeholder={
                                    data?.discount[0]?.discount_value
                                  }
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                        
                      </div>
                      <div className="row justify-content-end gap-3 mt-3 mx-1 mb-4">
                    <button type="submit" className="btn btn--primary px-5">
                      Update
                    </button>
                  </div>
                    </div>
                  </div>
                 
                </form>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Editproduct;

