import axios from "axios";
import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Navigate, json, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

const Updatestaff = () => {
  const [fname, setfname] = useState();
  const [phone, setphone] = useState();
  const [lanme, setlanme] = useState();
  const [dob, setdob] = useState();
  const [position, setposition] = useState();

  const [email, setemail] = useState();
  const [staff_image, setstaff_image] = useState();
  const [password, setpassword] = useState();
  const [confirmpassword, setconfirmpassword] = useState();
  const Navigate = useNavigate();
const [staffimage, setstaffimage] = useState();
  const [checkedValues, setCheckedValues] = useState([]);
  const [restriction, setRestriction] = useState({});

  const getCurrentDate = () => {
    const today = new Date();
    const yyyy = (today.getFullYear() -15);
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  useEffect(() => {
    setfname(secureLocalStorage.getItem("fname"));
    setphone(secureLocalStorage.getItem("phone"));
    setlanme(secureLocalStorage.getItem("lanme"));
    setdob(secureLocalStorage.getItem("dob"));
    setposition(secureLocalStorage.getItem("position"));
    setemail(secureLocalStorage.getItem("email"));
    setstaffimage(secureLocalStorage.getItem("img"));
    
  }, [0]);

  useEffect(() => {
    const apiResponse = secureLocalStorage.getItem("restrictions");

    if (apiResponse) {
      setRestriction(apiResponse);
      setCheckedValues(getCheckedValuesFromRestrictions(apiResponse));
    }
  }, []);

  useEffect(() => {
    const apiResponse = secureLocalStorage.getItem("restr");

    if (apiResponse) {
      setRestriction(apiResponse);
      setCheckedValues(getCheckedValuesFromRestrictions(apiResponse));
    }
  }, []);

  const getCheckedValuesFromRestrictions = (restrictions) => {
    let checked = [];
    Object.keys(restrictions).forEach((key) => {
      if (restrictions[key].length > 0) {
        checked.push(key.toUpperCase().replace(/_/g, " "));
      }
    });
    return checked;
  };

  const handleCheckboxChange = (value) => {
    if (checkedValues.includes(value)) {
      setCheckedValues(checkedValues.filter((item) => item !== value));
    } else {
      setCheckedValues([...checkedValues, value]);
    }
  };

  const generateRestrictionsObject = (checkedValues) => {
    const restrictions = {};

    const restrictionsMapping = {
      ORDERS: [
        "/All Orders",
        "/Confirm Orders",
        "/Pending Orders",
        "/Shipped",
        "/Delivered Orders",
        "/Not Delivered",
        "/Canceled Orders",
        "/Rejected Orders",
        "/Returned Orders",
      ],
      COUPONS: ["/Coupon"],
      OFFERS: ["/Offer"],
      REFUNDS: [
        "/Pending Refund",
        "/Approved Refund",
        "/Refunded",
        "/Rejected Refund",
      ],

      "QR CODES": ["/QR Codes"],
      REVIEWS: ["/Reviews"],
      CHATS: ["/Customers", "/Deliverymen"],
      WALLET: ["/Withdraw"],
      "BANK AND MOBILE MONEY": ["/My Bank info"],
      "SUBSCRIPTION PLANS": ["/subscription_plans"],
    };

    checkedValues.forEach((key) => {
      const formattedKey = key.toLowerCase().replace(/ /g, "_");
      if (restrictionsMapping[key]) {
        restrictions[formattedKey] = restrictionsMapping[key];
      }
    });

    return restrictions;
  };

  let staffidd = secureLocalStorage.getItem("staffid");

  const staffdata = (e) => {
    e.preventDefault();
    const updatedRestrictions = generateRestrictionsObject(checkedValues);

    if (password != confirmpassword) {
      toast.error("Password & Confirm Password Not Matched");
      return;
    }
    const formData = new FormData();
    formData.append("staffId", staffidd);
    formData.append("fname", fname);
    formData.append("phone", phone);
    formData.append("lname", lanme);
    formData.append("dob", dob);
    formData.append("position", position);

    formData.append("email", email);
    formData.append("staff_image", staff_image);
    formData.append("restrictions", JSON.stringify(updatedRestrictions));

    axios
      .post(`${process.env.REACT_APP_API_KEY}vender/api/updateStaff`, formData)
      .then((res) => {
        toast.success(res.data.message);
        setTimeout(() => {
          Navigate("/Staffs");
        }, 3000);
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data.message);
        } else {
          toast.error("An error occurred. Please try again later.");
        }
      });
  };

  const checkboxOptions = [
    { label: "ORDERS", value: "ORDERS" },
    { label: "CHATS", value: "CHATS" },
    // { label: "Products", value: "Products" },
    { label: "REVIEWS", value: "REVIEWS" },
    { label: "COUPONS", value: "COUPONS" },
    { label: "WALLET", value: "WALLET" },
    { label: "BANK AND MOBILE MONEY", value: "BANK AND MOBILE MONEY" },
    { label: "REFUNDS", value: "REFUNDS" },
    { label: "QR CODES", value: "QR CODES" },
    { label: "OFFERS", value: "OFFERS" },
    { label: "SUBSCRIPTION PLANS", value: "SUBSCRIPTION PLANS" },
  ];

  return (
    <div className="container">
      <Toaster />
      {/* <Header /> */}
      <div
        className="container row"
        style={{ paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px" }}
      >
        <div className="col-lg-2 col-md-3" style={{ paddingLeft: "0px" }}>
          {/* <Sidebarr /> */}
        </div>

        <div
          className="col-lg-10 col-md-9"
          style={{ paddingLeft: "30px", marginTop: "60px" }}
        >
          <div className=" mt-3">
            <div className="d-flex justify-content-between align-items-center gap-3 mb-3">
              <h2 className="h1 mb-1 text-capitalize d-flex align-items-center gap-2 mt-3 pl-3">
                <img
                  src="https://6valley.6amtech.com/public/assets/back-end/img/add-new-seller.png"
                  alt=""
                />{" "}
                Staff Update
              </h2>
            </div>

            <div className="col-md-12">
              <form onSubmit={staffdata}>
                <div class="card" style={{borderColor:'rgb(188, 189, 190)'}}>
                  <div class="card-body">
                    <h5 class="mb-0 page-header-title text-capitalize d-flex align-items-center gap-2 border-bottom pb-3 mb-3">
                      <i class="fa fa-user" aria-hidden="true"></i>
                      Personal information
                    </h5>
                    <div class="row">
                      <div class="col-md-6 col-lg-6 col-sm-12">
                        <div class="form-group">
                          <label for="name" class="title-color">
                            First name
                          </label>
                          <input
                            value={fname}
                            onChange={(e) => {
                              setfname(e.target.value);
                            }}
                            type="text"
                            name="name"
                            class="form-control"
                            id="name"
                            placeholder="First Name"
                            required
                          />
                        </div>
                        <div class="form-group">
                          <label for="name" class="title-color">
                            Last name
                          </label>
                          <input
                            type="text"
                            name="name"
                            class="form-control"
                            id="name"
                            placeholder="Last Name"
                            value={lanme}
                            onChange={(e) => {
                              setlanme(e.target.value);
                            }}
                          />
                        </div>
                        <div class="form-group">
                          <label for="phone" class="title-color">
                            Phone
                          </label>
                          <div class="mb-3">
                            <div class="">
                              <input
                                minLength={10}
                                maxLength={10}
                                required
                                type="text"
                                class="form-control"
                                value={phone}
                                onChange={(e) => {
                                  setphone(e.target.value);
                                }}
                                name="phone"
                                placeholder="Enter Phone number"
                              />
                            </div>
                          </div>
                        </div>

                        <div class="form-group">
                          <label for="phone" class="title-color">
                            Date of birth
                          </label> 
                          <div class="mb-3">
                            <div class="">
                              <input
                                required
                                type="date"
                                class="form-control"
                                value={dob}
                                onChange={(e) => {
                                  setdob(e.target.value);
                                }}
                                max={getCurrentDate()}
                                name="date"
                                placeholder="Enter Phone number"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <label for="role_id" class="title-color">
                            Position
                          </label>
                          <input
                            value={position}
                            onChange={(e) => {
                              setposition(e.target.value);
                            }}
                            class="form-control"
                            name="role_id"
                            id="role_id"
                          >
                           
                          </input>
                        </div>
                        <div class="form-group">
                          <div class="text-center mb-3">
                            {staff_image ? (
                              <img
                                class="upload-img-view"
                                id="viewer"
                                src={URL.createObjectURL(staff_image)}
                                alt=""
                              />
                              
                            ) : (
                              <img
                                class="upload-img-view"
                                id="viewer"
                                src={`${process.env.REACT_APP_API_KEY}uploads/${staffimage}`}
                                alt=""
                              />
                            )}
                          </div>
                          <label for="customFileUpload" class="title-color">
                            Staff image
                          </label>
                          
                          <div class="form-group">
                            <div class="custom-file text-left">
                              <input
                                onChange={(e) => {
                                  setstaff_image(e.target.files[0]);
                                }}
                                type="file"
                                name="image"
                                id="custom-file-upload"
                                class="custom-file-input image-input"
                                data-image-id="viewer"
                                accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                              />
                              <label
                                class="custom-file-label"
                                for="custom-file-upload"
                              >
                                Choose file
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 col-lg-6 col-sm-12">
                        <label className="title-color">
                          Restrictions: {checkedValues.join(", ")}
                        </label>
                        <div className="form-group mb-0">
                          {checkboxOptions.map((option, index) => (
                            <div
                              key={index}
                              className="border rounded px-2 py-3 d-flex justify-content-between mb-1"
                            >
                              <h5 className="mb-1 d-flex gap-1 c1">
                                {option.label}
                              </h5>
                              <div className="position-relative">
                                <label className="switcher">
                                  <input
                                    type="checkbox"
                                    className="switcher_input toggle-switch-message"
                                    name={option.value}
                                    checked={checkedValues.includes(
                                      option.label
                                    )}
                                    onChange={() =>
                                      handleCheckboxChange(option.label)
                                    }
                                  />
                                  <span className="switcher_control"></span>
                                </label>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card mt-3 mb-3" style={{borderColor:'rgb(188, 189, 190)'}}>
                  <div className="card-body" >
                    <h5 className="mb-0 page-header-title d-flex align-items-center gap-2 border-bottom pb-3 mb-3">
                      <i class="fa fa-user" aria-hidden="true"></i>
                      Account Information
                    </h5>
                    <div className="row">
                      <div className="col-md-6 col-xxl-6 col-lg-4 col-xl-4 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="email" className="title-color">
                            Email
                          </label>
                          <input
                            type="email"
                            name="email"
                            value={email}
                            onChange={(e) => {
                              setemail(e.target.value);
                            }}
                            className="form-control"
                            id="email"
                            placeholder="Enter Email"
                            required
                          />
                        </div>
                      </div>
                      {/* <div className="col-md-6 col-xxl-6 col-lg-4 col-xl-4 col-sm-12">
                        <div className="form-group">
                          <label
                            htmlFor="user_password"
                            className="title-color d-flex align-items-center"
                          >
                            Password<span className="text-danger">*</span>
                          </label>
                          <div className="input-group input-group-merge">
                            <input
                              minLength={8}
                              value={password}
                              onChange={(e) => {
                                setpassword(e.target.value);
                              }}
                              type="password"
                              className="js-toggle-password form-control password-check"
                              name="password"
                              required
                              id="user_password"
                              placeholder="Password minimum 8 characters"
                            />
                            <div
                              id="changePassTarget"
                              className="input-group-append"
                            >
                              <a
                                className="input-group-text"
                                href="javascript:"
                              >
                                <i
                                  id="changePassIcon"
                                  className="tio-hidden-outlined"
                                />
                              </a>
                            </div>
                          </div>
                          <span className="text-danger mx-1 password-error" />
                        </div>
                      </div>
                      <div className="col-md-6 col-xxl-6 col-lg-4 col-xl-4 col-sm-12">
                        <div className="form-group">
                          <label
                            htmlFor="confirm_password"
                            className="title-color"
                          >
                            Confirm password
                            <span className="text-danger">*</span>
                          </label>
                          <div className="input-group input-group-merge">
                            <input
                              value={confirmpassword}
                              onChange={(e) => {
                                setconfirmpassword(e.target.value);
                              }}
                              type="password"
                              className="js-toggle-password form-control"
                              name="confirm_password"
                              required
                              id="confirm_password"
                              placeholder="Confirm password"
                            />
                            <div
                              id="changeConfirmPassTarget"
                              className="input-group-append"
                            >
                              <a
                                className="input-group-text"
                                href="javascript:"
                              >
                                <i
                                  id="changeConfirmPassIcon"
                                  className="tio-hidden-outlined"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                    <div className="d-flex justify-content-end gap-3">
                      <button type="submit" className="btn btn--primary px-4">
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Updatestaff;
