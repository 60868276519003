import React, { useEffect, useState } from "react";
import Header from "../Header";
import "../sidebar.css";
import { Link } from "react-router-dom";
import Sidebarr from "../Sidebar";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import toast, { Toaster } from "react-hot-toast";
import './Productdetails.css'
import QRCode from "react-qr-code";
const Productqrcode = () => {
  const [productdata, setproductdata] = useState();
  const [isOpen, setIsOpen] = useState(true);
  let productidd = secureLocalStorage.getItem("productid");
  let token = secureLocalStorage.getItem("vendortoken");
  let venderIdd = secureLocalStorage.getItem("vendorid");
  useEffect(() => {
    Products_details();
  }, [0]);

  const Products_details = () => {
    const options = {
      headers: {
        token: token,
      },
    };
    const data = {
      productId: productidd,
      venderId: venderIdd,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/productDetails`,
        data,
        options
      )
      .then((res) => {

        setproductdata(res.data.data);
      })
      .catch((error) => {});
  };

  return (
    <div className="container">
      <Toaster />
      {/* <Header /> */}
      <div
        className="container row"
        style={{ paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px" }}
      >
        <div className="col-lg-2 col-md-3" style={{ paddingLeft: "0px" }}>
          {/* <Sidebarr /> */}
        </div>

        <div
          className="col-lg-10 col-md-9"
          style={{ paddingLeft: "30px", marginTop: "60px" }}
        >
          <div div className="mt-3 mb-4">
            <h2 className="h1 mb-0 text-capitalize d-flex align-items-center gap-2">
              <img
                width={20}
                src="https://6valley.6amtech.com/public/assets/back-end/img/products.png"
                alt
              />
              Product detail
            </h2>
          </div>

          <div className="row mt-30 mb-3">
            <div className="col-md-6 col-lg-6 col-sm-12 ml-5">
              {productdata?.map((data) => {
                return (
                  <div>
                    {" "}
                    <Link className="align-items-center">
                      {" "}
                      <QRCode
                        size={'200'}
                        bgColor="white"
                        fgColor="black"
                        value={data?.product_code}
                      />
                    </Link>
                  </div>
                );
              })}
            </div>

            <div className="col-md-4 col-lg-4 col-sm-12 mt-5">
              {productdata?.map((data) => {
                return (
                  <div>
                   
                    <h3 className="align-items-center">
                     
                    {data?.product_name}
                      
                    </h3>
                    <h3 className="align-items-center mt-3">
                    <i class="fa fa-download btn btn-outline--primary mr-3" aria-hidden="true">
                        <br/>
                    <a href="#" title="Save" download=""> Save</a></i>
                    <i class="fa fa-share btn btn-outline--primary ml-3" aria-hidden="true">
                        <br/>
                    <a href="#"  title="Share" download="">Share</a></i>
                      
                    </h3>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Productqrcode;
