import React, { useEffect, useState } from "react";
import Header from "../Header";
import "../sidebar.css";
import { Link } from "react-router-dom";
import Sidebarr from "../Sidebar";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import toast, { Toaster } from "react-hot-toast";
import swal from "sweetalert";
import Pagination from "react-js-pagination";
import QRCode from "react-qr-code";
const Products = () => {
  const [productlist, setproductlist] = useState([]);
  const [count, setcount] = useState();
  const [ProductListfilter, setProductListfilter] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const itemsPerPage = 10;
  let token = secureLocalStorage.getItem("vendortoken");
  let venderIdd = secureLocalStorage.getItem("vendorid");

  useEffect(() => {
    getproductlist();
  }, [0]);
  let getproductlist = () => {
    const data = {
      venderId: venderIdd,
    };
    const options = {
      headers: {
        token: token,
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/productList`,
        data,
        options
      )
      .then((res) => {

        setcount(res?.data?.data?.length);
        setproductlist(res?.data?.data);
      })
      .catch((error) => {});
  };
  useEffect(() => {
    setProductListfilter(productlist);
  }, [productlist]);
  const handleFilter = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    const result = productlist.filter((item) =>
      item.product_name.toLowerCase().includes(searchTerm)
    );
    setProductListfilter(result);
    setActivePage(1);
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const activedeactive = (item) => {
    const Data = {
      productId: item,
    };

    let options = {
      hraders: {
        token: token,
      },
    };

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/productActive_statusChange`,
        Data,
        options
      )
      .then((res) => {
        toast.success(res.data.message);
        getproductlist();
      })
      .catch((error) => {});
  };

  let deleteproduct = (item) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Product!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let deletebannerimage = () => {
          let bannerdata = {
            venderId: venderIdd,
            productId: item,
          };

          let options = {
            headers: {
              token: token,
            },
          };
          axios
            .post(
              `${process.env.REACT_APP_API_KEY}vender/api/deleteProduct`,
              bannerdata,
              options
            )
            .then((res) => {
              getproductlist();
            })
            .catch((error) => {});
        };
        deletebannerimage();
        swal("Poof! Your product has been deleted!", {
          icon: "success",
        });
      } else {
        swal("Your Product is safe!");
      }
    });
  };

  const [CategoryList, setCategoryList] = useState();
  const [serviceName, setServiceName] = useState("");
  let serviceTypeId = secureLocalStorage.getItem("serviceTypeeee");

  useEffect(() => {
    getCategories();
  }, [0]);

  const getCategories = () => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}vender/api/maincategory_list`)
      .then((res) => {
        setCategoryList(res.data.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (CategoryList) {
      const matchedCategory = CategoryList?.find(
        (category) => category?._id === serviceTypeId
      );
      if (matchedCategory) {
        setServiceName(matchedCategory?.maincategory_englishName);
      }
    }
  }, [CategoryList, serviceTypeId]);

  const renderProductData = (data, index) => {
    const adjustedIndex = (activePage - 1) * itemsPerPage + index + 1;
    return (
      <tr key={index}>
        <th scope="row">{adjustedIndex}</th>
        <td className="text-capitalize"> 
          <Link
            onClick={() => {
              secureLocalStorage.setItem("productid", data?.productId);
            }}
            to="/productdetails"
            className="media align-items-center gap-2 w-max-content"
          >
            <img
              src={
                data?.image
                  ? `${process.env.REACT_APP_API_KEY}uploads/${data.image}`
                  : "https://6valley.6amtech.com/storage/app/public/product/thumbnail/2023-06-13-64883db39dcbb.png"
              }
              className="avatar border"
              alt
            />
            <span className="media-body title-color hover-c1">
              {data?.product_name && data?.product_name.length > 20
                ? data?.product_name?.slice(0, 20) + "..."
                : data?.product_name}
            </span>
          </Link>
        </td>
        <td className="text-capitalize text-center">
          
            
            <span className="media-body title-color hover-c1">
              {data?.frnz_product_name
 && data?.frnz_product_name
 .length > 20
                ? data?.frnz_product_name
                ?.slice(0, 20) + "..."
                : data?.frnz_product_name
              }
            </span>
          
        </td>

        {data?.status != 0 ? (
          <td>
            <Link
              onClick={() => {
                secureLocalStorage.setItem("productid", data?.productId);
              }}
              to="/productqrcode"
              className="media align-items-center gap-2 w-max-content"
            >
              {" "}
              <QRCode
                size={50}
                bgColor="white"
                fgColor="black"
                value={data?.product_code}
              />
            </Link>
          </td>
        ) : (
          <td>
            <label className="badge badge-soft-danger">Pending</label>
          </td>
        )}

        <td className="text-capitalize text-center">{data?.productType}</td>

        <td className="text-center">{data?.sale_price}CFA</td>
        {data?.status != 0 ? (
          <td className="text-center">
            <label className="badge badge-soft-success">Approved</label>
          </td>
        ) : (
          <td>
            <label className="badge badge-soft-danger">Not-approved</label>
          </td>
        )}

        {data?.product_status !== 0 ? (
          <td >
            <form className=" banner_status_form">
              <input type="hidden" />
              <input type="hidden" name="id" />
              <label className="switcher mx-auto">
                <input
                  id="coupon_status9"
                  name="status"
                  defaultValue={1}
                  defaultChecked
                  type="checkbox"
                  className="switcher_input"
                  onChange={() => activedeactive(data?.productId)}
                />
                <span className="switcher_control" />
              </label>
            </form>
          </td>
        ) : (
          <td>
            <form className="banner_status_form">
              <input type="hidden" />
              <input type="hidden" name="id" />
              <label className="switcher mx-auto" >
                <input
                  type="checkbox"
                  className="switcher_input"
                  name="status"
                  onChange={() => activedeactive(data?.productId)}
                />
                <span className="switcher_control" />
              </label>
            </form>
          </td>
        )}
        <td>
          <div className="d-flex gap-10">
            <Link
              onClick={() => {
                secureLocalStorage.setItem("productid", data?.productId);
              }}
              className="btn btn-outline-info btn-sm square-btn"
              title="View"
              to="/productdetails"
            >
              <i class="fa fa-share" aria-hidden="true"></i>
            </Link>
            <Link
              onClick={() => {
                secureLocalStorage.setItem("productid", data?.productId);
              }}
              className="btn btn-outline-info btn-sm square-btn"
              title="View"
              to="/productdetails"
            >
              <i className="fa fa-eye" aria-hidden="true" />
            </Link>
            <Link
              onClick={() => {
                secureLocalStorage.setItem("productid", data?.productId);
              }}
              className="btn btn-outline-primary btn-sm square-btn"
              title="Edit"
              to="/editproduct"
            >
              <i class="fa fa-pencil" aria-hidden="true"></i>
            </Link>
            <a
              onClick={() => {
                deleteproduct(data?.productId);
              }}
              className="btn btn-outline-danger btn-sm square-btn"
              href="javascript:"
              title="Delete"
              onclick="form_alert('product-54','Want to delete this item ?')"
            >
              <i className="fa fa-trash-o" aria-hidden="true" />
            </a>
          </div>
          <form
            action="https://6valley.6amtech.com/seller/product/delete/54"
            method="post"
            id="product-54"
          >
            <input
              type="hidden"
              name="_token"
              defaultValue="ogOCpmdAJJ38pWdY4o8txAvCPH58PO03n5rkZpRx"
            />{" "}
            <input type="hidden" name="_method" defaultValue="delete" />{" "}
          </form>
        </td>
      </tr>
    );
  };
  return (
    <div className="container">
      <Toaster />
      {/* <Header /> */}
      <div
        className="container row"
        style={{ paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px" }}
      >
        <div className="col-lg-2 col-md-3" style={{ paddingLeft: "0px" }}>
          {/* <Sidebarr /> */}
        </div>

        <div
          className="col-lg-10 col-md-9"
          style={{ paddingLeft: "30px", marginTop: "60px" }}
        >
          <div div className="mt-3 mb-4">
            <h2 className="h1 mb-0 text-capitalize d-flex align-items-center gap-2">
              <img
                width={20}
                src="https://6valley.6amtech.com/public/assets/back-end/img/products.png"
                alt
              />
              Products
              <span className="badge badge-soft-dark radius-50 fz-14 ml-1">
                {count}
              </span>
            </h2>
          </div>
          {/* <div className="card">
            <div className="card-body">
              <form
                action="https://6valley.6amtech.com/seller/product/list"
                method="GET"
              >
                <input type="hidden" defaultValue name="status" />
                <div className="row gx-2">
                  <div className="col-12">
                    <h4 className="mb-3">Filter Products</h4>
                  </div>
                  <div className="col-sm-6 col-lg-4 col-xl-3">
                    <div className="form-group">
                      <label className="title-color" htmlFor="store">
                        Brand
                      </label>
                      <select
                        name="brand_id"
                        className="js-select2-custom form-control text-capitalize"
                      >
                        <option value selected>
                          All Brands
                        </option>
                        <option value={1}>i Bird</option>
                        <option value={4}>Agron</option>
                        <option value={5}>Triangle</option>
                        <option value={6}>Estro</option>
                        <option value={7}>Ohoenix</option>
                        <option value={8}>Waltro</option>
                        <option value={9}>JK</option>
                        <option value={12}>Fashion</option>
                        <option value={13}>S.Cube</option>
                        <option value={14}>Estha dot</option>
                        <option value={17}>Digital Product</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-4 col-xl-3">
                    <div className="form-group">
                      <label htmlFor="name" className="title-color">
                        Category
                      </label>
                      <select
                        className="js-select2-custom form-control"
                        name="category_id"
                        onchange="getRequest('https://6valley.6amtech.com/seller/product/get-categories?parent_id='+this.value,'sub-category-select','select')"
                      >
                        <option value selected disabled>
                          Select category
                        </option>
                        <option value={11}>Home Improvement &amp; Tools</option>
                        <option value={12}>Toys , Kids &amp; Babies</option>
                        <option value={13}>Men's fashion</option>
                        <option value={14}>Outdoor Fun &amp; Sports</option>
                        <option value={15}>Women's fashion</option>
                        <option value={16}>ebook</option>
                        <option value={24}>Jewelry &amp; Watches</option>
                        <option value={25}>Beauty, Health &amp; Hair</option>
                        <option value={26}>Mobile Accessories</option>
                        <option value={27}>
                          Computer, Office &amp; Security
                        </option>
                        <option value={28}>Phones &amp; Telecom</option>
                        <option value={34}>Home, Pet &amp; Appliances</option>
                        <option value={39}>Bags &amp; Shoes</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-4 col-xl-3">
                    <div className="form-group">
                      <label htmlFor="name" className="title-color">
                        Sub Category
                      </label>
                      <select
                        className="js-select2-custom form-control"
                        name="sub_category_id"
                        id="sub-category-select"
                        onchange="getRequest('https://6valley.6amtech.com/seller/product/get-categories?parent_id='+this.value,'sub-sub-category-select','select')"
                      >
                        <option value selected disabled>
                          Select Sub Category
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-4 col-xl-3">
                    <div className="form-group">
                      <label htmlFor="name" className="title-color">
                        Sub Sub Category
                      </label>
                      <select
                        className="js-select2-custom form-control"
                        name="sub_sub_category_id"
                        id="sub-sub-category-select"
                      >
                        <option value selected disabled>
                          Select Sub Sub Category
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="d-flex gap-3 justify-content-end">
                      <a href="#" className="btn btn-secondary px-5">
                        Reset
                      </a>
                      <Link
                        to="/filterproduct"
                        className="btn btn--primary px-5"
                      >
                        Show data
                      </Link>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div> */}
          <div className="row mt-20 mb-3">
            <div className="col-md-12">
              <div className="card">
                <div className="px-3 py-4">
                  <div className="row align-items-center">
                    <div className="col-lg-4">
                      <form>
                        <div className="input-group input-group-merge input-group-custom">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i class="fa fa-search" aria-hidden="true"></i>
                            </div>
                          </div>
                          <input
                            onChange={handleFilter}
                            type="search"
                            name="search"
                            className="form-control"
                            placeholder="Search by Product Name"
                          />
                          <button type="submit" className="btn btn--primary">
                            Search
                          </button>
                        </div>
                      </form>
                    </div>
                    {/* <div className="col-lg-8 mt-3 mt-lg-0 d-flex flex-wrap gap-3 justify-content-lg-end">
                      {serviceName === "Innt" ? (
                        <Link to="/addnewproducts" className="btn btn--primary">
                          <i class="fa fa-plus-circle" aria-hidden="true"></i>{" "}
                          <span className="text">Add new product</span>
                        </Link>
                      ) : (
                        <Link to="/addnewproduct" className="btn btn--primary">
                          <i class="fa fa-plus-circle" aria-hidden="true"></i>{" "}
                          <span className="text">Add new product</span>
                        </Link>
                      )}
                    </div> */}
                  </div>
                </div>

                <div className="table-responsive">
                  {ProductListfilter?.length > 0 ? (
                    <table
                      style={{ textAlign: "left" }}
                      className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100"
                    >
                      <thead className="thead-light thead-50 text-capitalize">
                        <tr>
                          <th>SL</th>
                          <th>Product Name(EN)</th>
                          <th>Product Name(FR)</th>
                          <th>QR Code</th>
                          <th>Product Type</th>

                          <th>Selling price</th>
                          <th>Verify status</th>
                          <th>Product visible online</th>

                          <th className="text-center __w-5px">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ProductListfilter.slice(
                          (activePage - 1) * itemsPerPage,
                          activePage * itemsPerPage
                        ).map((data, index) => renderProductData(data, index))}
                      </tbody>
                    </table>
                  ) : (
                    <div class="text-center p-4">
                      <img
                        class="mb-3 w-160"
                        src="https://6valley.6amtech.com/public/assets/back-end/img/empty-state-icon/default.png"
                        alt="Image Description"
                      />
                      <p class="mb-0 order-stats__subtitle">No product found</p>
                    </div>
                  )}
                  <div className="d-flex justify-content-center mt-4">
                    {ProductListfilter.length > itemsPerPage && (
                      <Pagination
                        activePage={activePage}
                        itemsCountPerPage={itemsPerPage}
                        totalItemsCount={ProductListfilter.length}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                      />
                    )}
                  </div>
                </div>

                <div className="table-responsive mt-4">
                  <div className="px-4 d-flex justify-content-lg-end"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
