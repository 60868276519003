import React, { useEffect, useState } from "react";
import "./sidebar.css";
import { json, Link, useNavigate } from "react-router-dom";
import "./manubar.css";
import "./helloside.css";
import { BiCategory } from "react-icons/bi";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { BsQrCode } from "react-icons/bs";
import { IoIosArrowDown } from "react-icons/io";
import {
  MdOutlineDescription,
  MdPolicy,
  MdProductionQuantityLimits,
} from "react-icons/md";
import { MdDiscount } from "react-icons/md";
import { GrTransaction } from "react-icons/gr";
import { MdPayments } from "react-icons/md";
import { MdSubscriptions } from "react-icons/md";
import { MdOutlineFreeCancellation } from "react-icons/md";

import { MdReviews } from "react-icons/md";
import { IoMdNotifications } from "react-icons/io";
import { FaRocketchat, FaUser } from "react-icons/fa";
import { BiMoneyWithdraw } from "react-icons/bi";
import { CiBank } from "react-icons/ci";
import { FaShoppingBag } from "react-icons/fa";
import { TbBrandProducthunt } from "react-icons/tb";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import { RiRefundLine } from "react-icons/ri";

const Sidebarr = (props) => {
  const [data, setData] = useState({});

  const [CategoryList, setCategoryList] = useState();
  const [serviceName, setServiceName] = useState("");
  useEffect(() => {
    setData(secureLocalStorage.getItem("roue"));
  }, []);

  let navigate = useNavigate();

  let [opcount, setopcount] = useState();

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    updateScreenWidth();

    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, [screenWidth]);

  useEffect(() => {
    window.addEventListener("resize", updateScreenWidth);

    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, [screenWidth]);

  let serviceTypeId = secureLocalStorage.getItem("serviceTypeeee");

  useEffect(() => {
    getCategories();
  }, [0]);

  const getCategories = () => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}vender/api/maincategory_list`)
      .then((res) => {
        setCategoryList(res.data.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (CategoryList) {
      const matchedCategory = CategoryList?.find(
        (category) => category?._id === serviceTypeId
      );
      if (matchedCategory) {
        setServiceName(matchedCategory?.maincategory_englishName);
      }
    }
  }, [CategoryList, serviceTypeId]);

  const [sidebarStatus, setSidebarStatus] = useState(() => {
    return localStorage.getItem("setstatus");
  });

  useEffect(() => {
    const handleStorageChange = () => {
      setSidebarStatus(localStorage.getItem("setstatus"));
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);


 //  let sunmenuiconss =  localStorage.getItem("icons");

  // const iconNames = JSON.parse(localStorage.getItem("icons"));
//const icons = iconNames.map(iconName => iconMap[iconName]);



  // const sunmenuiconss = [

  //   TbBrandProducthunt,

  //   FaShoppingBag,
  //   BiMoneyWithdraw,

  //   TbBrandProducthunt,
  //   TbBrandProducthunt,
  //   TbBrandProducthunt,

  //   FaUser,
  //   MdOutlineFreeCancellation,
  //   MdPolicy,
  //   RiRefundLine,
  //   MdPolicy,
  //   MdPolicy,

  //   MdReviews,
  //   IoMdNotifications,
  //   FaRocketchat,
  //   BiMoneyWithdraw,
  //   CiBank,
  //   MdPayments,

  // ];


  const iconMapping = {
    TbBrandProducthunt:TbBrandProducthunt,

    FaShoppingBag:FaShoppingBag,
    BiMoneyWithdraw:BiMoneyWithdraw,

    TbBrandProducthunt:TbBrandProducthunt,
    TbBrandProducthunt:TbBrandProducthunt,
    TbBrandProducthunt:TbBrandProducthunt,

    FaUser:FaUser,
    MdOutlineFreeCancellation:MdOutlineFreeCancellation,
    MdPolicy:MdPolicy,
    RiRefundLine:RiRefundLine,
    MdPolicy:MdPolicy,
    MdPolicy:MdPolicy,

    MdReviews:MdReviews,
    IoMdNotifications:IoMdNotifications,
    FaRocketchat:FaRocketchat,
    BiMoneyWithdraw:BiMoneyWithdraw,
    CiBank:CiBank,
    MdPayments:MdPayments,
  };
  const storedIconNames = secureLocalStorage?.getItem("iconss") || [];
  const sunmenuiconss = storedIconNames?.map((name) => iconMapping[name]);



  const renderMenuItems = (items, basePath) => {
    return items.map((item) => (
      <MenuItem
        onClick={() => {
          const currentStatus = localStorage.getItem("setstatus");
          const newStatus = currentStatus === "true" ? "false" : "true";
          localStorage.setItem("setstatus", newStatus);
          window.dispatchEvent(new Event("storage"));
        }}
        key={item}
        component={<Link to={`${basePath}${item}`} />}
        icon={
          <i
            className="fa fa-circle"
            style={{ color: "white", fontSize: "5px" }}
          />
        }
        style={{
          paddingLeft: "20px",
          backgroundColor: "#073b74",
          color: "white",
          height: "32px",
        }}
      >
        {item.replace("/", "").replace(/_/g, " ")}
      </MenuItem>
    ));
  };

  // const renderDynamicSubMenus = (routes) => {
  //   return Object?.keys(routes).map((key, index) => (
  //     <SubMenu
  //       key={key}
  //       label={key.toUpperCase()}
  //       style={{
  //         paddingLeft: "5px",
  //         backgroundColor: "#073b74",
  //         color: "white",
  //         height: "39px",
  //       }}
  //       icon={React?.createElement(
  //         sunmenuiconss[index % sunmenuiconss?.length],
  //         { style: { fontSize: "20px" } }
  //       )}
  //     >
  //       {renderMenuItems(routes[key], "")}
  //     </SubMenu>
  //   ));
  // };

  // const renderDynamicSubMenus = (routes) => {
  //   return Object?.keys(routes).map((key, index) => (
  //     <SubMenu
  //       key={key}
  //       label={key.toUpperCase()}
  //       style={{
  //         paddingLeft: "5px",
  //         backgroundColor: "#073b74",
  //         color: "white",
  //         height: "39px",
  //       }}
  //       icon={React?.createElement(
  //         sunmenuiconss[index % sunmenuiconss?.length],
  //         { style: { fontSize: "20px" } }
  //       )}
  //     >
  //       {renderMenuItems(routes[key], "")}
  //     </SubMenu>
  //   ));
  // };

  // const renderDynamicSubMenus = (routes) => {
  //   return Object.keys(routes).map((key) => (
  //     <SubMenu
  //       className="ps-submenu-expand-icon css-1cuxlhl "
  //       key={key}
  //       label={key.toUpperCase()}
  //       style={{
  //         paddingLeft: "3px",
  //         backgroundColor: "#073b74",
  //         color: "white",
  //         height: "39px",
  //       }}
  //       icon={<BiCategory style={{ fontSize: "20px" }} />}
  //     >
  //       {renderMenuItems(routes[key], "")}
  //     </SubMenu>
  //   ));
  // };

  const renderDynamicSubMenus = (routes) => {
    return Object?.keys(routes).map((key, index) => (
      <SubMenu
        key={key}
        label={key.toUpperCase()}
        style={{
          paddingLeft: "5px",
          backgroundColor: "#073b74",
          color: "white",
          height: "39px",
        }}
        icon={React?.createElement(
          sunmenuiconss[index % sunmenuiconss?.length],
          { style: { fontSize: "20px" } }
        )}
      >
        {renderMenuItems(routes[key], "")}
      </SubMenu>
    ));
  };
  return (
    <div>
      <div>
        {screenWidth >= 767 ? (
          <aside
            className="sidenav"
            style={{ textAlign: "left", paddingLeft: "0px", marginTop: "60px" }}
          >
            <div
              className="navbar-vertical-container "
              style={{ width: "100%" }}
            >
              <div className="navbar-vertical-footer-offset">
                <div className="navbar-vertical-content">
                  <ul
                    style={{
                      overflow: "scroll",
                      height: "100%",
                      scrollbarWidth: "none",
                      borderBottom: "none",
                    }}
                    className="navbar-nav navbar-nav-lg nav-tabs pb-0"
                  >
                    <li
                      className="navbar-vertical-aside-has-menu "
                      style={{ marginBottom: "100px" }}
                    >
                      <Sidebar className="bg-info example">
                        <Menu style={{ width: "90%" }}>
                          <MenuItem
                            component={<Link to="/home" />}
                            icon={
                              <i
                                class="fa fa-home"
                                style={{ color: "white", fontSize: "20px" }}
                                aria-hidden="true"
                              ></i>
                            }
                            style={{
                              paddingLeft: "3px",
                              backgroundColor: "#073b74",
                              color: "white",
                            }}
                          >
                            DASHBOARD
                          </MenuItem>

                          {renderDynamicSubMenus(data)}
                        </Menu>
                      </Sidebar>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </aside>
        ) : null}
        {sidebarStatus == "true" ? (
          <aside
            className="sidenav1"
            style={{
              textAlign: "left",
              paddingLeft: "0px",
              width: "250px",
              marginTop: "60px",
              opacity: `${opcount}`,
            }}
          >
            <div
              className="navbar-vertical-container "
              style={{ width: "100%" }}
            >
              <div className="navbar-vertical-footer-offset pb-0">
                <div className="">
                  <ul
                    style={{
                      overflow: "scroll",
                      height: "500px",
                      borderBottom: "none",
                    }}
                    className="navbar-nav navbar-nav-lg nav-tabs"
                  >
                    <li className="navbar-vertical-aside-has-menu ">
                      <Sidebar className="bg-info example">
                        <Menu style={{ width: "100%" }}>
                          <MenuItem
                            onClick={() => {
                              const currentStatus =
                                localStorage.getItem("setstatus");
                              const newStatus =
                                currentStatus === "true" ? "false" : "true";
                              localStorage.setItem("setstatus", newStatus);
                              window.dispatchEvent(new Event("storage"));
                            }}
                            component={<Link to="/home" />}
                            icon={
                              <i
                                class="fa fa-home"
                                style={{ color: "white", fontSize: "20px" }}
                                aria-hidden="true"
                              ></i>
                            }
                            style={{
                              paddingLeft: "4px",
                              backgroundColor: "#073b74",
                              color: "white",
                            }}
                          >
                            DASHBOARD
                          </MenuItem>

                          {renderDynamicSubMenus(data)}
                        </Menu>
                      </Sidebar>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </aside>
        ) : null}
      </div>
    </div>
  );
};

export default Sidebarr;
