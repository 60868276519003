import axios from "axios";
import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Link, Navigate, json, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

const Staffdetails = () => {
  const [fname, setfname] = useState();
  const [phone, setphone] = useState();
  const [lanme, setlanme] = useState();
  const [dob, setdob] = useState();
  const [position, setposition] = useState();

  const [email, setemail] = useState();
  const [staff_image, setstaff_image] = useState();
  const [password, setpassword] = useState();
  const [confirmpassword, setconfirmpassword] = useState();
  const Navigate = useNavigate();

  const [checkedValues, setCheckedValues] = useState([]);

  const [checkboxOptions] = useState([
    { label: "Orders", value: "Orders" },
    { label: "Chats", value: "Chats" },
    { label: "Products", value: "Products" },
    { label: "Reviews", value: "Reviews" },
    { label: "Coupons", value: "Coupons" },
    { label: "Wallet", value: "Wallet" },
    { label: "Bank and mobile money", value: "Bank and mobile money" },
    { label: "Refunds", value: "Refunds" },
    { label: "QR Codes", value: "QR Codes" },
    { label: "Offers", value: "Offers" },
    { label: "Subscription plans", value: "Subscription plans" },
  ]);

  const handleCheckboxChange = (value) => {
    if (checkedValues.includes(value)) {
      setCheckedValues(checkedValues.filter((item) => item !== value));
    } else {
      setCheckedValues([...checkedValues, value]);
    }
  };


  const selectedValuesString = checkedValues.join(", ");

  let staffidd = secureLocalStorage.getItem("staffid");

  const staffdata = (e) => {
    e.preventDefault();
    if (password != confirmpassword) {
      toast.error("Password & Confirm Password Not Matched");
      return;
    }
    const formData = new FormData();
    formData.append("staffid", staffidd);
    formData.append("fname", fname);
    formData.append("phone", phone);
    formData.append("lanme", lanme);
    formData.append("dob", dob);
    formData.append("position", position);
    formData.append("password", password);

    formData.append("email", email);
    formData.append("staff_image", staff_image);
    formData.append("restrictons", checkedValues);

    axios
      .post(`${process.env.REACT_APP_API_KEY}vender/api/createStaff`, formData)
      .then((res) => {
        toast.success(res.data.message);
        setTimeout(() => {
          Navigate("/staffs");
        }, 3000);
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data.message);
        } else {
          toast.error("An error occurred. Please try again later.");
        }
      });
  };
  return (
    <div className="container">
      <Toaster />
      {/* <Header /> */}
      <div
        className="container row"
        style={{ paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px" }}
      >
        <div className="col-lg-2 col-md-3" style={{ paddingLeft: "0px" }}>
          {/* <Sidebarr /> */}
        </div>

        <div
          className="col-lg-10 col-md-9"
          style={{ paddingLeft: "30px", marginTop: "60px" }}
        >
          <div className=" mt-3">
            <div className="d-flex justify-content-between align-items-center gap-3 mb-3">
              <h2 className="h1 mb-1 text-capitalize d-flex align-items-center gap-2 mt-3 pl-3">
                <img
                  src="https://6valley.6amtech.com/public/assets/back-end/img/add-new-seller.png"
                  alt=""
                />{" "}
                Staff Details
              </h2>
            </div>

            <div className="col-md-12">
            <div class="card mt-3">
<div class="card-body">
<h3 class="mb-3 text-primary">#Staff 4</h3>
<div class="row g-2">
<div class="col-lg-7 col-xl-8">
<div class="media align-items-center flex-wrap flex-sm-nowrap gap-3">
<img width="250" class="rounded" src="https://6valley.6amtech.com/storage/app/public/admin/2022-04-21-6260e15d0ecc5.png" alt="Image Description"/>
<div class="media-body">
<div class="text-capitalize mb-4">
<h4 class="mb-2">Staff</h4>
<p>product manager</p>
</div>
<ul class="d-flex flex-column gap-3 px-0">
<li class="d-flex gap-2 align-items-center">
<i class="fa fa-phone" aria-hidden="true"></i>
<a href="tel:011111111111" class="text-dark">0**********</a>
</li>
<li class="d-flex gap-2 align-items-center">
<i class="fa fa-envelope" aria-hidden="true"></i>
<a href="mailto:employee123@gmail.com" class="text-dark">e**********@gmail.com</a>
</li>
</ul>
</div>
</div>
</div>
<div class="col-lg-5 col-xl-4">
<div class="bg-primary-light rounded p-3">
<div class="bg-white rounded p-3">
<div class="d-flex gap-2 align-items-center">
<i class="fa fa-calendar" aria-hidden="true"></i>
<span class="text-dark">Join : 21/04/2022</span>
</div>
</div>
<div class="bg-white rounded p-3 mt-3">
<div class="d-flex justify-content-between gap-3">
<div class="d-flex flex-column gap-4">
<div class="d-flex gap-2 align-items-center">
<i class="fa fa-user-circle-o" aria-hidden="true"></i>
<h6 class="text-dark mb-0 text-capitalize">Access available: </h6>
</div>
<div class="tags d-flex gap-2 flex-wrap">
<span class="badge bg-primary-light text-capitalize">order management</span>
<span class="badge bg-primary-light text-capitalize">product management</span>
<span class="badge bg-primary-light text-capitalize">marketing section</span>
</div>
</div>
<Link to='/updatestaff'>
<i class="fa fa-pencil btn-outline--primary" aria-hidden="true"></i>
</Link>
</div>
</div>
</div>
</div>
<div class="col-12">
<div class="d-flex justify-content-end">
<Link to='/updatestaff'  class="btn btn--primary px-5">
<i class="fa fa-pencil " aria-hidden="true"></i> Edit
</Link>
</div>
</div>
</div>
</div>
</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Staffdetails;




