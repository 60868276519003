import React from 'react'
import '../helloside.css'

const Helloside = () => {
    return (
        <div>
            <ul className="">
                <li>
                    <a href="#" className="menu">
                        <h2 className="menu-title">animals</h2>
                        <ul className="menu-dropdown">
                            <li style={{ color: "black" }}>cat</li>
                            <li style={{ color: "black" }}>dog</li>
                            <li style={{ color: "black" }}>horse</li>
                            <li style={{ color: "black" }}>cow</li>
                            <li style={{ color: "black" }}>pig</li>
                        </ul>
                    </a>
                </li>

            </ul>

        </div>
    )
}

export default Helloside
