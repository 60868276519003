import React, { useEffect, useState } from "react";
import Header from "../Header";

import "../sidebar.css";
import { Link, useNavigate } from "react-router-dom";
import Sidebarr from "../Sidebar";
import toast, { Toaster } from "react-hot-toast";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";

const Productedit = () => {
  const [subcategorylist, setsubcategorylist] = useState();
  const [subcategorylistt, setsubcategorylistt] = useState();

  const [subcategoryId, setsubcategoryId] = useState();

  const [subcategoryId1, setsubcategoryId1] = useState();

  const [product_name, setproduct_name] = useState();
  const [description, setdescription] = useState();
  const [product_details, setproduct_details] = useState();
  const [product_code, setproduct_code] = useState();
  const [brand_name, setbrand_name] = useState();
  const [mrp_price, setmrp_price] = useState();
  const [sale_price, setsale_price] = useState();
  const [image1, setimage1] = useState();
  const [image2, setimage2] = useState();
  const [image3, setimage3] = useState();

  const [dataa, setdataa] = useState();
  const [productdata, setproductdata] = useState();

  let token = secureLocalStorage.getItem("vendortoken");
  let venderIdd = secureLocalStorage.getItem("vendorid");
  let productid = secureLocalStorage.getItem("productid");

  const Navigate = useNavigate();

  useEffect(() => {
    Products_details();
  }, [0]);

  const Products_details = () => {
    const options = {
      headers: {
        token: token,
      },
    };
    const data = {
      productId: productid,
      venderId: venderIdd,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/productDetails`,
        data,
        options
      )
      .then((res) => {
        setproductdata(res.data.data);
      })
      .catch((error) => {});
  };
  useEffect(() => {
    getscategory();
  }, [0]);
  let getscategory = () => {
    const data = {
      venderId: venderIdd,
    };
    let options = {
      headers: {
        token: token,
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/venderCategory_list`,
        data,
        options
      )
      .then((res) => {
        setsubcategorylistt(res.data.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getsubcategoryy();
  });
  let getsubcategoryy = () => {
    const data = {
      categoryId: subcategoryId,
    };

    let options = {
      headers: {
        token: token,
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/venderSubcategory_list`,
        data,
        options
      )
      .then((res) => {
        setsubcategorylist(res.data.data);
      })
      .catch((error) => {});
  };

  const addproduct = (e) => {
    e.preventDefault();

    let dataToSend;

    if (image1) {
      const formData = new FormData();
      formData.append("venderId", venderIdd);
      formData.append("productId", productid);
      formData.append(
        "categoryId",
        subcategoryId ? subcategoryId : productdata[0]?.categoryId
      );
      formData.append(
        "subcategoryId",
        subcategoryId1 ? subcategoryId1 : productdata[0]?.subcategoryId
      );
      formData.append(
        "product_name",
        product_name ? product_name : productdata[0]?.product_name
      );
      formData.append(
        "description",
        description ? description : productdata[0]?.description
      );
      formData.append(
        "product_details",
        product_details ? product_details : productdata[0]?.product_details
      );
      formData.append(
        "product_code",
        product_code ? product_code : productdata[0]?.product_code
      );
      formData.append(
        "brand_name",
        brand_name ? brand_name : productdata[0]?.brand_name
      );
      formData.append(
        "mrp_price",
        mrp_price ? mrp_price : productdata[0]?.mrp_price
      );
      formData.append(
        "sale_price",
        sale_price ? sale_price : productdata[0]?.sale_price
      );
      formData.append("image1", image1);
      formData.append("image2", image2);
      formData.append("image3", image3);
      dataToSend = formData;
    } else {
      const dataWithoutImages = {
        venderId: venderIdd,
        productId: productid,
        categoryId: subcategoryId ? subcategoryId : productdata[0]?.categoryId,
        subcategoryId: subcategoryId1
          ? subcategoryId1
          : productdata[0]?.subcategoryId,
        product_name: product_name
          ? product_name
          : productdata[0]?.product_name,
        description: description ? description : productdata[0]?.description,
        product_details: product_details
          ? product_details
          : productdata[0]?.product_details,
        product_code: product_code
          ? product_code
          : productdata[0]?.product_code,
        brand_name: brand_name ? brand_name : productdata[0]?.brand_name,
        mrp_price: mrp_price ? mrp_price : productdata[0]?.mrp_price,
        sale_price: sale_price ? sale_price : productdata[0]?.sale_price,
      };
      dataToSend = dataWithoutImages;
    }

    let options = {
      headers: {
        token: token,
      },
    };

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/productUpdate`,
        dataToSend,
        options
      )
      .then((res) => {
        Products_details();
        toast.success(res.data.message);
        setTimeout(() => {
          Navigate("/products");
        }, 3000);
      })
      .catch((error) => {});
  };

  return (
    <div className="container">
      <Toaster />
      {/* <Header /> */}
      <div
        className="container row"
        style={{ paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px" }}
      >
        <div className="col-lg-2 col-md-3" style={{ paddingLeft: "0px" }}>
          {/* <Sidebarr /> */}
        </div>

        <div
          className="col-lg-10 col-md-9"
          style={{ paddingLeft: "30px", marginTop: "60px" }}
        >
          <div className="content container-fluid">
            <br />
            <br />
            <div className="d-flex flex-wrap gap-2 align-items-center mb-3">
              <h2 className="h1 mb-0 d-flex align-items-center gap-2">
                <img
                  width={20}
                  src="https://6valley.6amtech.com/public/assets/back-end/img/products.png"
                  alt=""
                />
                Product Edit
              </h2>
            </div>
            {productdata?.map((data) => {
              return (
                <form className="product-form text-start" onSubmit={addproduct}>
                  <input type="hidden" name="_token" />{" "}
                  <div className="card">
                    <div className="card-body">
                      <div className=" lang_form" id="en-form">
                        <div className="row">
                          <div className="col-md-6 col-lg-6 col-sm-6">
                            <div className="form-group">
                              <label className="title-color" htmlFor="en_name">
                                Product name (EN)
                              </label>

                              <input
                                type="text"
                                value={product_name}
                                onChange={(e) => {
                                  setproduct_name(e.target.value);
                                }}
                                className="form-control"
                                placeholder={data?.product_name}
                              />
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-6 col-sm-6">
                            <div className="form-group">
                              <label className="title-color" htmlFor="en_name">
                                Product name (FR)
                              </label>

                              <input
                                type="text"
                                name="name[]"
                                id="en_name"
                                className="form-control"
                                placeholder="New Product"
                              />
                            </div>
                          </div>
                        </div>
                        <input type="hidden" name="lang[]" defaultValue="en" />
                      </div>
                    </div>
                  </div>
                  <div className="card mt-3 rest-part">
                    <div className="card-header">
                      <div className="d-flex gap-2">
                        <i class="fa fa-user" aria-hidden="true"></i>
                        <h4 className="mb-0">General setup</h4>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6 col-lg-4 col-xl-3">
                          <div className="form-group">
                            <label htmlFor="name" className="title-color">
                              Category
                            </label>

                            <select
                              className="js-select2-custom form-control"
                              name="sub_category_id"
                              value={subcategoryId}
                              onChange={(e) => {
                                const selectedCategory = subcategorylistt.find(
                                  (item) => item.categoryId === e.target.value
                                );
                                setsubcategoryId(e.target.value);
                                setdataa(
                                  selectedCategory?.category_englishName || ""
                                );
                              }}
                            >
                              <option value="" disabled selected>
                                Select Category
                              </option>
                              {subcategorylistt?.map((typee) => (
                                <option
                                  key={typee?.categoryId}
                                  value={typee?.categoryId}
                                >
                                  {typee?.category_englishName}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3">
                          <div className="form-group">
                            <label htmlFor="name" className="title-color">
                              Sub Category
                            </label>
                            <select
                              className="js-select2-custom form-control"
                              name="sub_category_id"
                              value={subcategoryId1}
                              onChange={(e) => {
                                setsubcategoryId1(e.target.value);
                              }}
                            >
                              <option value="" selected disabled>
                                Select Sub Category
                              </option>

                              {subcategorylist?.map((type) => (
                                <option
                                  key={type?.subcategoryId}
                                  value={type?.subcategoryId}
                                >
                                  {type?.english_subcategory_name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="col-md-6 col-lg-4 col-xl-3">
                          <div className="form-group">
                            <label className="title-color">Description</label>
                            <textarea
                              value={description}
                              onChange={(e) => {
                                setdescription(e.target.value);
                              }}
                              className="js-select2-custom form-control"
                              name="brand_id"
                              placeholder={data?.description}
                            ></textarea>
                          </div>
                        </div>

                        <div className="col-md-6 col-lg-4 col-xl-3">
                          <div className="form-group">
                            <label className="title-color">Product Code</label>
                            <input
                              placeholder={data?.product_code}
                              type="text"
                              value={product_code}
                              onChange={(e) => {
                                setproduct_code(e.target.value);
                              }}
                              className="js-select2-custom form-control"
                            ></input>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3">
                          <div className="form-group">
                            <label className="title-color">Brand</label>
                            <input
                              type="text"
                              value={brand_name}
                              onChange={(e) => {
                                setbrand_name(e.target.value);
                              }}
                              className="js-select2-custom form-control"
                              placeholder={data?.brand_name}
                            ></input>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3">
                          <div className="form-group">
                            <label className="title-color">
                              Product Details
                            </label>
                            <textarea
                              value={product_details}
                              onChange={(e) => {
                                setproduct_details(e.target.value);
                              }}
                              className="js-select2-custom form-control"
                              placeholder={data?.product_details}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card mt-3 rest-part">
                    <div className="card-header">
                      <div className="d-flex gap-2">
                        <i class="fa fa-user" aria-hidden="true"></i>
                        <h4 className="mb-0">Pricing &amp; others</h4>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="row align-items-end">
                        <div className="col-md-6 col-lg-4 col-xl-3">
                          <div className="form-group">
                            <div className="d-flex gap-2 mb-2">
                              <label className="title-color mb-0">
                                MRP price ($)
                              </label>
                              <span
                                className="input-label-secondary cursor-pointer"
                                data-toggle="tooltip"
                                title="Add the purchase price for this product."
                              >
                                <img
                                  src="https://6valley.6amtech.com/public/assets/back-end/img/info-circle.svg"
                                  alt
                                />
                              </span>
                            </div>
                            <input
                              type="number"
                              min={0}
                              step="0.01"
                              placeholder={data?.mrp_price}
                              value={mrp_price}
                              onChange={(e) => {
                                setmrp_price(e.target.value);
                              }}
                              name="purchase_price"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3">
                          <div className="form-group">
                            <div className="d-flex gap-2 mb-2">
                              <label className="title-color mb-0">
                                Sale price ($)
                              </label>
                              <span
                                className="input-label-secondary cursor-pointer"
                                data-toggle="tooltip"
                                title="Set the selling price for each unit of this product. This Unit Price section won’t be applied if you set a variation wise price."
                              >
                                <img
                                  src="https://6valley.6amtech.com/public/assets/back-end/img/info-circle.svg"
                                  alt
                                />
                              </span>
                            </div>
                            <input
                              value={sale_price}
                              type="number"
                              min={0}
                              step="0.01"
                              placeholder={data?.sale_price}
                              name="unit_price"
                              onChange={(e) => {
                                setsale_price(e.target.value);
                              }}
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 rest-part">
                    <div className="row g-2">
                      <div className="col-md-4">
                        <div className="card h-100">
                          <div className="card-body">
                            <div className="form-group">
                              <div className="d-flex align-items-center gap-2 mb-3">
                                <label
                                  htmlFor="name"
                                  className="title-color text-capitalize font-weight-bold mb-0"
                                >
                                  Product Image
                                </label>
                              </div>
                              <div>
                                <div>
                                  <div className="custom_upload_input">
                                    <input
                                      onChange={(e) => {
                                        setimage1(e.target.files[0]);
                                      }}
                                      type="file"
                                      name="image"
                                      className="custom-upload-input-file"
                                      id
                                      data-imgpreview="pre_img_viewer"
                                      accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                                    />
                                    <span
                                      className="delete_file_input btn btn-outline-danger btn-sm square-btn"
                                      style={{ display: "none" }}
                                    >
                                      <i className="tio-delete" />
                                    </span>
                                    <div className="img_area_with_preview position-absolute z-index-2">
                                      <img
                                        id="pre_img_viewer"
                                        className="h-auto aspect-1 bg-white d-none"
                                        src="img"
                                        onerror="this.classList.add('d-none')"
                                      />
                                    </div>
                                    <div className="position-absolute h-100 top-0 w-100 d-flex align-content-center justify-content-center">
                                      <div className="d-flex flex-column justify-content-center align-items-center">
                                        {image1 ? (
                                          <img
                                            src={URL.createObjectURL(image1)}
                                            className="w-50"
                                          />
                                        ) : (
                                          <>
                                            <img
                                              src={
                                                `${process.env.REACT_APP_API_KEY}uploads/` +
                                                data?.image1
                                              }
                                              className="w-50"
                                            />
                                            {/* <h3 className="text-muted">
                                          Upload Image
                                        </h3> */}
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <p className="text-muted mt-2">
                                    Image format : Jpg, png, jpeg <br />
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="card h-100">
                          <div className="card-body">
                            <div className="form-group">
                              <div className="d-flex align-items-center gap-2 mb-3">
                                <label
                                  htmlFor="name"
                                  className="title-color text-capitalize font-weight-bold mb-0"
                                >
                                  Product Image
                                </label>
                              </div>
                              <div>
                                <div>
                                  <div className="custom_upload_input">
                                    <input
                                      type="file"
                                      name="image"
                                      className="custom-upload-input-file"
                                      id
                                      data-imgpreview="pre_img_viewer"
                                      accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                                      onChange={(e) => {
                                        setimage2(e.target.files[0]);
                                      }}
                                    />
                                    <span
                                      className="delete_file_input btn btn-outline-danger btn-sm square-btn"
                                      style={{ display: "none" }}
                                    >
                                      <i className="tio-delete" />
                                    </span>
                                    <div className="img_area_with_preview position-absolute z-index-2">
                                      <img
                                        id="pre_img_viewer"
                                        className="h-auto aspect-1 bg-white d-none"
                                        src="img"
                                        onerror="this.classList.add('d-none')"
                                      />
                                    </div>
                                    <div className="position-absolute h-100 top-0 w-100 d-flex align-content-center justify-content-center">
                                      <div className="d-flex flex-column justify-content-center align-items-center">
                                        {image2 ? (
                                          <img
                                            src={URL.createObjectURL(image2)}
                                            className="w-50"
                                          />
                                        ) : (
                                          <>
                                            <img
                                              src={
                                                `${process.env.REACT_APP_API_KEY}uploads/` +
                                                data?.image2
                                              }
                                              className="w-50"
                                            />
                                            {/* <h3 className="text-muted">
                                          Upload Image
                                        </h3> */}
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <p className="text-muted mt-2">
                                    Image format : Jpg, png, jpeg <br />
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="card h-100">
                          <div className="card-body">
                            <div className="form-group">
                              <div className="d-flex align-items-center gap-2 mb-3">
                                <label
                                  htmlFor="name"
                                  className="title-color text-capitalize font-weight-bold mb-0"
                                >
                                  Product Image
                                </label>
                              </div>
                              <div>
                                <div>
                                  <div className="custom_upload_input">
                                    <input
                                      type="file"
                                      name="image"
                                      className="custom-upload-input-file"
                                      id
                                      data-imgpreview="pre_img_viewer"
                                      accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                                      onChange={(e) => {
                                        setimage3(e.target.files[0]);
                                      }}
                                    />
                                    <span
                                      className="delete_file_input btn btn-outline-danger btn-sm square-btn"
                                      style={{ display: "none" }}
                                    >
                                      <i className="tio-delete" />
                                    </span>
                                    <div className="img_area_with_preview position-absolute z-index-2">
                                      <img
                                        id="pre_img_viewer"
                                        className="h-auto aspect-1 bg-white d-none"
                                        src="img"
                                        onerror="this.classList.add('d-none')"
                                      />
                                    </div>
                                    <div className="position-absolute h-100 top-0 w-100 d-flex align-content-center justify-content-center">
                                      <div className="d-flex flex-column justify-content-center align-items-center">
                                        {image3 ? (
                                          <img
                                            src={URL.createObjectURL(image3)}
                                            className="w-50"
                                          />
                                        ) : (
                                          <>
                                            <img
                                              src={
                                                `${process.env.REACT_APP_API_KEY}uploads/` +
                                                data?.image3
                                              }
                                              className="w-50"
                                            />
                                            {/* <h3 className="text-muted">
                                          Upload Image
                                        </h3> */}
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <p className="text-muted mt-2">
                                    Image format : Jpg, png, jpeg <br />
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-end gap-3 mt-3 mx-1 mb-8">
                    <button type="submit" className="btn btn--primary px-5">
                      Submit
                    </button>
                  </div>
                </form>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Productedit;
