import React, { useEffect, useState } from "react";

import Header from "./Header";
import "./sidebar.css";
import { Link, useNavigate } from "react-router-dom";
import Sidebarr from "./Sidebar";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import ChartComponent from "./page/ChartComponent";

const Home = () => {
  let navigate = useNavigate();

  let token = secureLocalStorage.getItem("vendortoken");
  let shopid = secureLocalStorage.getItem("shopidofvendor");
  let venderid = secureLocalStorage.getItem("vendorid");

  let addtoproducts = () => {
    navigate("/addnewproducts");
  };

  let addtoproduct = () => {
    navigate("/addnewproduct");
  };
  const [phonenumber, setphonenumber] = useState();
  const [bankaccountnumber, setbankaccountnumber] = useState();
  const [bankaccountname, setbankaccountname] = useState();
  const [swiftcode, setswiftcode] = useState();
  const [amount, setamount] = useState();
  const [CategoryList, setCategoryList] = useState();
  const [serviceName, setServiceName] = useState("");
  const [ordercount, setordercount] = useState();
  const [counting, setcounting] = useState();
  const [highlight, sethighlight] = useState();
  const [topsell, settopsell] = useState();
  const [withdrawtype, setwithdrawtype] = useState();
  const [typeofpayment, settypeofpayment] = useState();
  const [mobile_number, setmobile_number] = useState();
  const [statusone, setstatusone] = useState();
  const [otpp, setotpp] = useState();
  let serviceTypeId = secureLocalStorage.getItem("serviceTypeeee");

  useEffect(() => {
    getCategories();
  }, [0]);

  const getCategories = () => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}vender/api/maincategory_list`)
      .then((res) => {
        setCategoryList(res.data.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (CategoryList) {
      const matchedCategory = CategoryList?.find(
        (category) => category?._id === serviceTypeId
      );
      if (matchedCategory) {
        setServiceName(matchedCategory?.maincategory_englishName);
      }
    }
  }, [CategoryList, serviceTypeId]);

  useEffect(() => {
    getorderlist();
  }, [0]);

  let getorderlist = () => {
    const data = {
      shopId: shopid,
      status: counting ? counting : "1",
    };

    const options = {
      headers: {
        token: token,
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/shopOrder_count`,
        data,
        options
      )
      .then((res) => {
        setordercount(res?.data?.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    shopHighlight();
  }, [0]);

  const shopHighlight = () => {
    const sellerdata = {
      shopId: shopid,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/shopHigh_light`,
        sellerdata
      )
      .then((res) => {
        sethighlight(res.data.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    topsellproduct();
  }, [0]);

  const topsellproduct = () => {
    const sellerdata = {
      shopId: shopid,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/topSale_productList`,
        sellerdata
      )
      .then((res) => {
        settopsell(res.data.data);
      })
      .catch((error) => {});
  };
  const [typeofbank, settypeofbank] = useState();

  const paymentrequesttype = (e) => {
    e.preventDefault();
    settypeofpayment(withdrawtype);
    if (withdrawtype === "Bank transfer") {
      paymentrequest();
    }
  };

  const sendrequest = (e) => {
    e.preventDefault();
    paymentrequest();
    setstatusone(true);
    // settypeofpayment("");
  };

  const paymentrequest = () => {
    // e.preventDefault();
    const data = {
      venderId: venderid,
      amount: amount,
      withdrawalType: withdrawtype,
      bankAcc_number: bankaccountnumber,
      bankAcc_name: bankaccountname,
      swiftCode: swiftcode,
      mobile_money_number: phonenumber,
    };

    axios
      .post(`${process.env.REACT_APP_API_KEY}vender/api/withdrawRequest`, data)
      .then((res) => {
        settypeofbank(true);
        settypeofpayment("");
        setmobile_number(res.data.mobile_number);
        setotpp(res.data.otp);
        //toast.success(res.data.message);
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Invalid Data Entered by you.");
        }
      });
  };

  const [genrateotp, setgenrateotp] = useState("");

  const verifyotp = (e) => {
    e.preventDefault();

    const userdata = {
      venderId: venderid,
      amount: amount,
      withdrawalType: withdrawtype,
      otp: genrateotp,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/withdrawRequest_verify`,
        userdata
      )
      .then((response) => {
        // setTimeout(() => {
        //   navigate("/Withdraw");
        // },1000);
        toast.success(response.data.message);
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data.message);
        }
      });
  };

  const [walletdata, setwalletdata] = useState();

  useEffect(() => {
    getwallett();
  }, [0]);

  let getwallett = () => {
    let data = {
      vendorId: venderid,
    };
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/withdrawable_blance`,
        data
      )
      .then((res) => {
        setwalletdata(res.data.data);
      })
      .catch((error) => {});
  };
  return (
    <div className="container">
      {/* <Header /> */}
      <Toaster />
      <div
        className="container row"
        style={{ paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px" }}
      >
        <div className="col-lg-2 col-md-3" style={{ paddingLeft: "0px" }}>
          {/* <Sidebarr /> */}
        </div>

        <div
          className="col-lg-10 col-md-9"
          style={{ paddingLeft: "30px", marginTop: "60px" }}
        >
          <div className="">
            <div className="page-header pb-0 border-0 mb-3 mt-3">
              <div className="flex-between row align-items-center mx-1 row">
                <div>
                  <h1 className="page-header-title">Dashboard</h1>
                  <div className="text-capitalize">
                    Hello {secureLocalStorage.getItem("firstnamee")}
                  </div>
                </div>

                {/* <div>
                  {serviceName === "Innt" ? (
                    <button
                      onClick={addtoproducts}
                      className="btn btn--primary"
                    >
                      Add Products
                    </button>
                  ) : (
                    <button onClick={addtoproduct} className="btn btn--primary">
                      Add Products
                    </button>
                  )}
                </div> */}
              </div>
            </div>

            <div className="card mb-3 remove-card-shadow">
              <div className="card-body">
                <div className="row justify-content-between align-items-center g-2 mb-3">
                  <div className="col-sm-6">
                    <h4 className="d-flex align-items-center text-capitalize gap-10 mb-0">
                      <img
                        src="https://6valley.6amtech.com/public/assets/back-end/img/business_analytics.png"
                        alt
                      />
                      Ongoing orders
                    </h4>
                  </div>
                  <div className="col-sm-6 d-flex justify-content-sm-end">
                    <select
                      className="custom-select w-auto"
                      name="statistics_type"
                      value={counting}
                      onChange={(e) => {
                        setcounting(e.target.value);
                        getorderlist();
                      }}
                    >
                      <option value="1">All</option>
                      <option value="2">Today</option>
                      <option value="3">This Week</option>
                      <option value="4">This Month</option>
                      <option value="5">This Year</option>
                    </select>
                  </div>
                </div>
                <div className="row g-2" id="order_stats">
                  <div className="col-sm-6 col-lg-3">
                    <Link
                      className="order-stats order-stats_confirmed"
                      to="/Confirm Orders"
                    >
                      <div className="order-stats__content">
                        <img
                          width={20}
                          src="https://6valley.6amtech.com/public/assets/back-end/img/confirmed.png"
                          alt
                        />
                        <h6 className="order-stats__subtitle">Confirmed</h6>
                      </div>
                      <span className="order-stats__title">
                        {ordercount?.confirm}
                      </span>
                    </Link>
                  </div>
                  <div className="col-sm-6 col-lg-3">
                    <Link
                      className="order-stats order-stats_packaging"
                      to="/Rejected Orders"
                    >
                      <div className="order-stats__content">
                        <img
                          width={20}
                          src="https://6valley.6amtech.com/public/assets/back-end/img/packaging.png"
                          alt
                        />
                        <h6 className="order-stats__subtitle">Rejected</h6>
                      </div>
                      <span className="order-stats__title">
                        {ordercount?.packing}
                      </span>
                    </Link>
                  </div>
                  <div className="col-sm-6 col-lg-3">
                    <Link
                      className="order-stats order-stats_pending"
                      to="/Pending Orders"
                    >
                      <div className="order-stats__content">
                        <img
                          width={20}
                          src="https://6valley.6amtech.com/public/assets/back-end/img/pending.png"
                          alt
                        />
                        <h6 className="order-stats__subtitle">Pending</h6>
                      </div>
                      <span className="order-stats__title">
                        {ordercount?.pending}
                      </span>
                    </Link>
                  </div>

                  <div className="ol-sm-6 col-lg-3">
                    <Link
                      className="order-stats order-stats_delivered"
                      to="/Shipped"
                    >
                      <div className="order-stats__content">
                        <img
                          width={20}
                          src="https://6valley.6amtech.com/public/assets/back-end/img/delivered.png"
                          alt
                        />
                        <h6 className="order-stats__subtitle">Shipped</h6>
                      </div>
                      <span className="order-stats__title">
                        {ordercount?.shipped}
                      </span>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="card-body">
                <div className="row justify-content-between align-items-center g-2 mb-3">
                  <div className="col-sm-6">
                    <h4 className="d-flex align-items-center text-capitalize gap-10 mb-0">
                      <img
                        src="https://6valley.6amtech.com/public/assets/back-end/img/business_analytics.png"
                        alt
                      />
                      Complete orders
                    </h4>
                  </div>
                </div>
                <div className="row g-2" id="order_stats">
                  <div className="ol-sm-6 col-lg-3">
                    <Link
                      className="order-stats order-stats_delivered"
                      to="/Delivered Orders"
                    >
                      <div className="order-stats__content">
                        <img
                          width={20}
                          src="https://6valley.6amtech.com/public/assets/back-end/img/delivered.png"
                          alt
                        />
                        <h6 className="order-stats__subtitle">Delivered</h6>
                      </div>
                      <span className="order-stats__title">
                        {ordercount?.delivered}
                      </span>
                    </Link>
                  </div>
                  <div className="ol-sm-6 col-lg-3">
                    <Link
                      className="order-stats order-stats_failed"
                      to="/Not Delivered"
                    >
                      <div className="order-stats__content">
                        <img
                          width={20}
                          src="https://6valley.6amtech.com/public/assets/back-end/img/canceled.png"
                          alt
                        />
                        <h6 className="order-stats__subtitle">Not Deliverd</h6>
                      </div>
                      <span className="order-stats__title">
                        {ordercount?.not_delivered}
                      </span>
                    </Link>
                  </div>
                  <div className="ol-sm-6 col-lg-3">
                    <Link
                      className="order-stats order-stats_canceled"
                      to="/Canceled Orders"
                    >
                      <div className="order-stats__content">
                        <img
                          width={20}
                          src="https://6valley.6amtech.com/public/assets/back-end/img/failed-to-deliver.png"
                          alt
                        />
                        <h6 className="order-stats__subtitle">Cancelled</h6>
                      </div>
                      <span className="order-stats__title">
                        {ordercount?.cancel}
                      </span>
                    </Link>
                  </div>
                  <div className="ol-sm-6 col-lg-3">
                    <Link
                      className="order-stats order-stats_returned"
                      to="/Returned Orders"
                    >
                      <div className="order-stats__content">
                        <img
                          width={20}
                          src="https://6valley.6amtech.com/public/assets/back-end/img/returned.png"
                          alt
                        />
                        <h6 className="order-stats__subtitle">Returned</h6>
                      </div>
                      <span className="order-stats__title">
                        {ordercount?.return}
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="row g-2">
              <div className="col-lg-12">
                <div className="card h-100 remove-card-shadow">
                  <div className="card-body">
                    <div className="row g-2 align-items-center">
                      <div class="col-lg-12 col-sm-12 col-md-12 col-xl-12">
                        <div class="card h-100 d-flex justify-content-center align-items-center">
                          <div class="card-body d-flex flex-column gap-10 align-items-center justify-content-center">
                            <img
                              width="48"
                              class="mb-2"
                              src="https://6valley.6amtech.com/public/assets/back-end/img/withdraw.png"
                            />
                            <h3 class="for-card-count mb-0 fz-24">
                              {walletdata?.withdrawable_amount}CFA
                            </h3>
                            <div class="font-weight-bold text-capitalize mb-30">
                              Withdrawable balance
                            </div>
                            <a
                              href="javascript:"
                              class="btn btn--primary px-4"
                              data-toggle="modal"
                              data-target="#balance-modal"
                            >
                              Withdraw
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-12 col-sm-12 col-md-12 col-xl-12">
                        <ChartComponent />
                      </div>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mb-3 remove-card-shadow"></div>
            <div
              className="modal fade"
              id="balance-modal"
              tabIndex={-1}
              role="dialog"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content" style={{ textAlign: "left" }}>
                  <div className="modal-header">
                    <h5 className="modal-title " id="exampleModalLabel">
                      Withdrawal request
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>

                  {typeofpayment === "Cash" ? (
                    <form encType="multipart/form-data" onSubmit={sendrequest}>
                      <div className="modal-body">
                        <input
                          type="hidden"
                          name="_token"
                          defaultValue="ogOCpmdAJJ38pWdY4o8txAvCPH58PO03n5rkZpRx"
                        />{" "}
                        <div className>
                          <h5 className="text-center">
                            Send withdrawal request of {amount} CFA in cash?
                          </h5>
                        </div>
                      </div>
                      <div className="mb-3" style={{ textAlign: "center" }}>
                        <button type="submit" className="btn btn--primary">
                          Send
                        </button>
                      </div>
                    </form>
                  ) : typeofpayment === "Mobile money" ? (
                    <form encType="multipart/form-data" onSubmit={sendrequest}>
                      <div className="modal-body">
                        <input
                          type="hidden"
                          name="_token"
                          defaultValue="ogOCpmdAJJ38pWdY4o8txAvCPH58PO03n5rkZpRx"
                        />{" "}
                        <div className>
                          <h5 className="text-center">
                            Send withdrawal request of {amount} CFA to{" "}
                            {phonenumber}?
                          </h5>
                        </div>
                      </div>
                      <div className="mb-3" style={{ textAlign: "center" }}>
                        <button type="submit" className="btn btn--primary">
                          Send
                        </button>
                      </div>
                    </form>
                  ) : typeofbank === true ? (
                    <form encType="multipart/form-data" onSubmit={verifyotp}>
                      <div className="modal-body">
                        <div className="js-form-message form-group">
                          <div
                            className="input-group input-group-merge iconinput mb-3"
                            style={{ justifyContent: "center" }}
                          >
                            <h3>Verify your phone number</h3>
                          </div>
                          <div
                            className="input-group input-group-merge iconinput mb-3"
                            style={{ justifyContent: "center" }}
                          >
                            <h4>Please enter the code sent to {otpp}</h4>
                          </div>
                          <div
                            className="input-group input-group-merge iconinput mb-3"
                            style={{ justifyContent: "center" }}
                          >
                            <h3>{mobile_number}</h3>
                          </div>
                          <div className="input-group input-group-merge iconinput">
                            <input
                              value={genrateotp}
                              onChange={(e) => {
                                setgenrateotp(e.target.value);
                              }}
                              placeholder="Enter otp here"
                              type="number"
                              className="form-control"
                            />
                          </div>
                          <br />
                          <div>
                            <button
                              onClick={() => {
                                paymentrequest();
                              }}
                              className="btn btn-lg btn-block btn--info"
                            >
                              Resend code
                            </button>
                          </div>

                          <br />
                          <button
                            type="submit"
                            className="btn btn-lg btn-block btn--primary"
                          >
                            Verify
                          </button>
                          <br />
                          <Link
                            onClick={() => {
                              settypeofbank("");
                              settypeofpayment("");
                              setstatusone(false);
                              setwithdrawtype("");
                              setamount("");
                              setbankaccountname("");
                              setbankaccountnumber("");
                              setswiftcode("");
                              setphonenumber("");
                            }}
                            data-dismiss="modal"
                            type="submit"
                            className=" btn btn-lg btn-block btn--info"
                          >
                            Back
                          </Link>
                        </div>
                      </div>
                    </form>
                  ) : statusone === true ? (
                    <form onSubmit={verifyotp}>
                      <div className="modal-body">
                        <div className="js-form-message form-group">
                          <div
                            className="input-group input-group-merge iconinput mb-3"
                            style={{ justifyContent: "center" }}
                          >
                            <h3>Verify your phone number</h3>
                          </div>
                          <div
                            className="input-group input-group-merge iconinput mb-3"
                            style={{ justifyContent: "center" }}
                          >
                            <h4>Please enter the code sent to {otpp}</h4>
                          </div>
                          <div
                            className="input-group input-group-merge iconinput mb-3"
                            style={{ justifyContent: "center" }}
                          >
                            <h3>{mobile_number}</h3>
                          </div>
                          <div className="input-group input-group-merge iconinput">
                            <input
                              value={genrateotp}
                              onChange={(e) => {
                                setgenrateotp(e.target.value);
                              }}
                              placeholder="Enter otp here"
                              type="number"
                              className="form-control"
                            />
                          </div>
                          <br />
                          <div>
                            <button
                              onClick={() => {
                                paymentrequest();
                              }}
                              className="btn btn-lg btn-block btn--info"
                            >
                              Resend code
                            </button>
                          </div>

                          <br />
                          <button
                            type="submit"
                            className="btn btn-lg btn-block btn--primary"
                          >
                            Verify
                          </button>
                          <br />
                          <Link
                            onClick={() => {
                              settypeofbank("");
                              settypeofpayment("");
                              setstatusone(false);
                              setwithdrawtype("");
                              setamount("");
                              setbankaccountname("");
                              setbankaccountnumber("");
                              setswiftcode("");
                              setphonenumber("");
                            }}
                            data-dismiss="modal"
                            type="submit"
                            className=" btn btn-lg btn-block btn--info"
                          >
                            Back
                          </Link>
                        </div>
                      </div>
                    </form>
                  ) : (
                    <form
                      encType="multipart/form-data"
                      onSubmit={paymentrequesttype}
                    >
                      <div className="modal-body">
                        <input
                          type="hidden"
                          name="_token"
                          defaultValue="ogOCpmdAJJ38pWdY4o8txAvCPH58PO03n5rkZpRx"
                        />{" "}
                        {withdrawtype == "Mobile money" ? (
                          <div>
                            <div className>
                              <label
                                htmlFor="recipient-name"
                                className="col-form-label fz-16"
                              >
                                Select withdrawal type
                              </label>
                              <select
                                value={withdrawtype}
                                onChange={(e) => {
                                  setwithdrawtype(e.target.value);
                                }}
                                className="form-control"
                                id="withdraw_method"
                                name="withdraw_method"
                                required
                              >
                                <option value="" selected>
                                  Select
                                </option>
                                <option value="Cash">Cash</option>
                                <option value="Mobile money">
                                  Mobile money
                                </option>
                                <option value="Bank transfer">
                                  Bank transfer
                                </option>
                              </select>
                            </div>
                            <div className="mt-1">
                              <label
                                htmlFor="recipient-name"
                                className="col-form-label fz-16"
                              >
                                Enter phone number
                              </label>
                              <input
                                required
                                value={phonenumber}
                                onChange={(e) => {
                                  setphonenumber(e.target.value);
                                }}
                                type="number"
                                placeholder="Enter phone number"
                                className="form-control"
                                id
                              />
                            </div>
                            <div className="mt-1">
                              <label
                                htmlFor="recipient-name"
                                className="col-form-label fz-16"
                              >
                                Enter amount
                              </label>
                              <input
                                required
                                value={amount}
                                onChange={(e) => {
                                  setamount(e.target.value);
                                }}
                                type="number"
                                name="amount"
                                step=".01"
                                placeholder={walletdata?.withdrawable_amount}
                                className="form-control"
                                id
                              />
                            </div>
                            <div
                              className="mt-3 mb-3"
                              style={{ textAlign: "center" }}
                            >
                              <button
                                type="submit"
                                className="btn btn--primary"
                              >
                                Continue
                              </button>
                            </div>
                          </div>
                        ) : withdrawtype == "Bank transfer" ? (
                          <div>
                            <div className>
                              <label
                                htmlFor="recipient-name"
                                className="col-form-label fz-16"
                              >
                                Select withdrawal type
                              </label>
                              <select
                                value={withdrawtype}
                                onChange={(e) => {
                                  setwithdrawtype(e.target.value);
                                }}
                                className="form-control"
                                id="withdraw_method"
                                name="withdraw_method"
                                required
                              >
                                <option value="" selected>
                                  Select
                                </option>
                                <option value="Cash">Cash</option>
                                <option value="Mobile money">
                                  Mobile money
                                </option>
                                <option value="Bank transfer">
                                  Bank transfer
                                </option>
                              </select>
                            </div>
                            <div className="mt-1">
                              <label
                                htmlFor="recipient-name"
                                className="col-form-label fz-16"
                              >
                                Bank account number
                              </label>
                              <input
                                required
                                value={bankaccountnumber}
                                onChange={(e) => {
                                  setbankaccountnumber(e.target.value);
                                }}
                                type="number"
                                placeholder="Bank account number"
                                className="form-control"
                                id
                              />
                            </div>
                            <div className="mt-1">
                              <label
                                htmlFor="recipient-name"
                                className="col-form-label fz-16"
                              >
                                Bank account name
                              </label>
                              <input
                                required
                                value={bankaccountname}
                                onChange={(e) => {
                                  setbankaccountname(e.target.value);
                                }}
                                type="text"
                                name="amount"
                                step=".01"
                                placeholder="Bank account name"
                                className="form-control"
                                id
                              />
                            </div>
                            <div className="mt-1">
                              <label
                                htmlFor="recipient-name"
                                className="col-form-label fz-16"
                              >
                                SWIFT code
                              </label>
                              <input
                                required
                                value={swiftcode}
                                onChange={(e) => {
                                  setswiftcode(e.target.value);
                                }}
                                type="text"
                                placeholder="Enter SWIFT code"
                                className="form-control"
                                id
                              />
                            </div>
                            <div className="mt-1">
                              <label
                                htmlFor="recipient-name"
                                className="col-form-label fz-16"
                              >
                                Enter Phone number
                              </label>
                              <input
                                required
                                value={phonenumber}
                                onChange={(e) => {
                                  setphonenumber(e.target.value);
                                }}
                                type="number"
                                name="amount"
                                step=".01"
                                placeholder="Enter phone number"
                                className="form-control"
                                id
                              />
                            </div>
                            <div className="mt-1">
                              <label
                                htmlFor="recipient-name"
                                className="col-form-label fz-16"
                              >
                                Enter amount
                              </label>
                              <input
                                required
                                value={amount}
                                onChange={(e) => {
                                  setamount(e.target.value);
                                }}
                                type="number"
                                name="amount"
                                step=".01"
                                placeholder={walletdata?.withdrawable_amount}
                                className="form-control"
                                id
                              />
                            </div>
                            <div
                              className="mt-3 mb-3"
                              style={{ textAlign: "center" }}
                            >
                              <button
                                type="submit"
                                className="btn btn--primary"
                              >
                                Continue
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div className>
                              <label
                                htmlFor="recipient-name"
                                className="col-form-label fz-16"
                              >
                                Select withdrawal type
                              </label>
                              <select
                                value={withdrawtype}
                                onChange={(e) => {
                                  setwithdrawtype(e.target.value);
                                }}
                                className="form-control"
                                id="withdraw_method"
                                name="withdraw_method"
                                required
                              >
                                <option value="" selected>
                                  Select
                                </option>
                                <option value="Cash">Cash</option>
                                <option value="Mobile money">
                                  Mobile money
                                </option>
                                <option value="Bank transfer">
                                  Bank transfer
                                </option>
                              </select>
                            </div>
                            <div className="mt-1">
                              <label
                                htmlFor="recipient-name"
                                className="col-form-label fz-16"
                              >
                                Enter amount
                              </label>
                              <input
                                required
                                value={amount}
                                onChange={(e) => {
                                  setamount(e.target.value);
                                }}
                                type="number"
                                name="amount"
                                step=".01"
                                placeholder={walletdata?.withdrawable_amount}
                                className="form-control"
                                id
                              />
                            </div>{" "}
                            <div
                              className="mt-3 mb-3"
                              style={{ textAlign: "center" }}
                            >
                              <button
                                type="submit"
                                className="btn btn--primary"
                              >
                                Continue
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                      {/* <div className="mb-3" style={{ textAlign: "center" }}>
                        <button type="submit" className="btn btn--primary">
                          Continue
                        </button>
                      </div> */}
                    </form>
                  )}
                </div>
              </div>
            </div>
            <div className="row g-2">
              <div className="col-lg-6 col-sm-12 col-md-6">
                <div className="card h-100 remove-card-shadow">
                  <div className="card-header">
                    <h5 className="d-flex align-items-center text-capitalize gap-10 mb-0">
                      {/* <img width={20} src="https://6valley.6amtech.com/public/assets/back-end/img/top-selling-product.png" alt /> */}
                      Top selling products
                    </h5>
                    <div
                      onClick={() => {
                        navigate("/topsellingproducts");
                      }}
                      className="orders-count py-2 px- justify-content-sm-end  cursor-pointer"
                    >
                      View all
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="grid-item-wrap">
                      {topsell?.slice(0, 5)?.map((top) => {
                        return (
                          <div
                            className="cursor-pointer"
                            onClick={() => {
                              secureLocalStorage.setItem(
                                "productid",
                                top?.productId
                              );
                              navigate("/productdetails");
                            }}
                          >
                            <div className="grid-item bg-transparent basic-box-shadow">
                              <div className="d-flex align-items-center gap-10">
                                <img
                                  className="avatar avatar-lg rounded avatar-bordered"
                                  src={
                                    top?.image
                                      ? `${process.env.REACT_APP_API_KEY}uploads/` +
                                        top?.image
                                      : "https://6valley.6amtech.com/storage/app/public/product/thumbnail/2022-10-12-6346670e3427e.png"
                                  }
                                  alt="women's shoes image"
                                />
                                <span className="title-color text-capitalize">
                                  {top?.product_name}{" "}
                                </span>
                              </div>
                              <div className="orders-count py-2 px-3 d-flex gap-1">
                                <div>Sold :</div>
                                <div className="sold-count">{top?.sold}</div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-sm-12 col-md-6">
                <div className="card h-100 remove-card-shadow">
                  <div className="card-header">
                    <h4 className="d-flex align-items-center text-capitalize gap-10 mb-0">
                      <img
                        width={20}
                        src="https://6valley.6amtech.com/public/assets/back-end/img/most-popular-product.png"
                        alt
                      />
                      Shop highlights
                    </h4>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <div className="grid-card-wrap">
                          <div className="cursor-pointer grid-card basic-box-shadow rounded avatar-bordered">
                            <div className="fz-12 title-color text-center line--limit-1">
                              Rating
                            </div>

                            <div className="d-flex align-items-center gap-1 fz-10 mt-3">
                              <span className="rating-color d-flex align-items-center font-weight-bold">
                                <i className="fa fa-star" />
                                <i className="fa fa-star" />
                                <i className="fa fa-star" />
                                <i className="fa fa-star" />
                                <i className="fa fa-star" />
                              </span>
                            </div>
                            <div className="fz-12 title-color text-center line--limit-1 mt-2">
                              <span className="d-flex align-items-center gap-10">
                                {highlight?.raters_count}
                              </span>
                            </div>
                          </div>

                          <Link to="/Products List">
                            <div className="cursor-pointer grid-card basic-box-shadow rounded avatar-bordered">
                              <div className="fz-12 title-color text-center line--limit-1">
                                Products
                              </div>

                              <div className="d-flex align-items-center gap-1 fz-10 mt-0">
                                <img
                                  width="30"
                                  src="https://6valley.6amtech.com/public/assets/back-end/img/canceled.png"
                                />
                              </div>
                              <div className="fz-12 title-color text-center line--limit-1 mt-2">
                                <span className="d-flex align-items-center gap-10">
                                  {highlight?.products_count}
                                </span>
                              </div>
                            </div>
                          </Link>

                          <div className="cursor-pointer grid-card basic-box-shadow rounded avatar-bordered">
                            <div className="fz-12 title-color text-center line--limit-1">
                              Sales
                            </div>

                            <div className="d-flex align-items-center gap-1 fz-10 mt-0">
                              <img
                                width="30"
                                src="https://6valley.6amtech.com/public/assets/back-end/img/tcg.png"
                              />
                            </div>
                            <div className="fz-12 title-color text-center line--limit-1 mt-2">
                              <span className="d-flex align-items-center gap-10">
                                {highlight?.sales_count}
                              </span>
                            </div>
                          </div>

                          <div className="cursor-pointer grid-card basic-box-shadow rounded avatar-bordered">
                            <div className="fz-12 title-color text-center line--limit-1">
                              Page views
                            </div>

                            <div className="d-flex align-items-center gap-1 fz-10 mt-0">
                              <img
                                width="30"
                                src="https://6valley.6amtech.com/public/assets/back-end/img/cc.png"
                              />
                            </div>
                            <div className="fz-12 title-color text-center line--limit-1 mt-2">
                              <span className="d-flex align-items-center gap-10">
                                {highlight?.view_count}
                              </span>
                            </div>
                          </div>

                          <div className="cursor-pointer grid-card basic-box-shadow rounded avatar-bordered">
                            <div className="fz-12 title-color text-center line--limit-1">
                              Liked items
                            </div>

                            <div className="d-flex align-items-center gap-1 fz-10 mt-0">
                              <img
                                width="30"
                                src="https://6valley.6amtech.com/public/assets/back-end/img/top-selling-product-icon.png"
                              />
                            </div>
                            <div className="fz-12 title-color text-center line--limit-1 mt-2">
                              <span className="d-flex align-items-center gap-10">
                                {highlight?.like_count}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-4">
                <div className="card h-100 remove-card-shadow">
                  <div className="card-header">
                    <h4 className="d-flex align-items-center text-capitalize gap-10 mb-0">
                      <img
                        src="https://6valley.6amtech.com/public/assets/back-end/img/top-customers.png"
                        alt
                      />
                      Top Delivery Man
                    </h4>
                  </div>
                  <div className="card-body">
                    <div className="grid-card-wrap">
                      <div
                        className="cursor-pointer"
                        onclick="location.href='https://6valley.6amtech.com/seller/delivery-man/earning-statement/6'"
                      >
                        <div className="grid-card basic-box-shadow">
                          <div className="text-center">
                            <img
                              className="avatar rounded-circle avatar-lg"
                              onclick="location.href='https://6valley.6amtech.com/seller/delivery-man/earning-statement/6'"
                              onerror="this.src='https://6valley.6amtech.com/public/assets/back-end/img/160x160/img1.jpg'"
                              src="https://6valley.6amtech.com/storage/app/public/delivery-man/2022-10-12-634667a5d9df0.png"
                            />
                          </div>
                          <h5 className="mb-0">Delivery</h5>
                          <div className="orders-count d-flex gap-1">
                            <div>Delivered : </div>
                            <div>0</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
