// firebase.js
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import secureLocalStorage from "react-secure-storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBF_pIUNxQv9UXmv0e7RC5Uiy3_jr_ML8U",
  authDomain: "innt-6700e.firebaseapp.com",
  projectId: "innt-6700e",
  storageBucket: "innt-6700e.appspot.com",
  messagingSenderId: "321243157659",
  appId: "1:321243157659:web:8e2415f5233192918f223e",
};

const app = initializeApp(firebaseConfig);

const messaging = getMessaging(app);

getToken(messaging, {
  vapidKey:
    "BNMe_IES-o1YwOchEW9VdzppfFf2hOPvuKwAPGrkriE-oC8MjUWk6Lz2XZO6REs9C1r0QiBgxFLYO8TeVcJpbUU",
})
  .then((currentToken) => {
    if (currentToken) {

      secureLocalStorage.setItem("currentToken",currentToken)
    } else {
     
    }
  })
  .catch((err) => {
    
  });

export { messaging, onMessage };
