import axios from "axios";
import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Navigate, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

const Addnewqr = () => {
  let shopid = secureLocalStorage.getItem("shopidofvendor");
  let venderIdd = secureLocalStorage.getItem("vendorid");
  const [appliesType, setappliesType] = useState();
  const [qrcode_name, setqrcode_name] = useState();
  const [selectedProducts, setSelectedProducts] = useState();
  const [productname, setproductname] = useState();
  const Navigate = useNavigate();
  const handleProductClickk = (categoryId) => {
    setSelectedProducts(categoryId);
  };

  const addqrcode = (e) => {
    e.preventDefault();
    if (appliesType == "Specific product" && selectedProducts == null) {
      toast.error("Please select product for QR Codes");
      return;
    }
    const formData = {
      shopId: shopid,
      qrcode_name: qrcode_name,
      type: appliesType,
      productId: selectedProducts ? selectedProducts : null,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/createQrcodes`,
        formData
      )
      .then((res) => {
        setTimeout(() => {
          Navigate("/QR Codes");
        }, 3000);
        toast.success("QR code successfully created !");
      })
      .catch((error) => {});
  };

  const [productlist, setproductlist] = useState();
  const [productFilter, setProductFilter] = useState("");
  const filterProducts = (products, filter) => {
    return products?.filter((product) =>
      product?.product_name?.toLowerCase().includes(filter.toLowerCase())
    );
  };
  useEffect(() => {
    getproductlist();
  }, [0]);
  let getproductlist = () => {
    const data = {
      venderId: venderIdd,
    };

    axios
      .post(`${process.env.REACT_APP_API_KEY}vender/api/productList`, data)
      .then((res) => {
        setproductlist(res?.data?.data);
      })
      .catch((error) => {});
  };
  return (
    <div className="container">
      <Toaster />

      <div
        className="container row"
        style={{ paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px" }}
      >
        <div className="col-lg-2 col-md-3" style={{ paddingLeft: "0px" }}></div>

        <div
          className="col-lg-10 col-md-9"
          style={{ paddingLeft: "30px", marginTop: "60px" }}
        >
          <div className="mt-3">
            <div className="mb-3">
              <h2 className="h1 mb-0 text-capitalize d-flex align-items-center gap-2">
                <img
                  src="https://6valley.6amtech.com/public/assets/back-end/img/coupon_setup.png"
                  alt=""
                />
                Create QR Code
              </h2>
            </div>
            <div className="row">
              <div className="col-sm-12 col-lg-12 mb-3 mb-lg-2">
                <div className="card" style={{borderColor:'rgb(188, 189, 190)'}}>
                  <div className="card-body">
                    <form onSubmit={addqrcode}>
                      <input
                        type="hidden"
                        name="_token"
                        defaultValue="YFPrIbFYlUsgcs6Ht4EEyCysWGBq8lmpLaBUW0q6"
                      />
                      <div className="row">
                        <div className="col-md-6 col-lg-4 form-group">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="title-color font-weight-medium d-flex"
                          >
                            QR code name<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            required
                            value={qrcode_name}
                            onChange={(e) => {
                              setqrcode_name(e.target.value);
                            }}
                            className="form-control"
                            placeholder="QR Code Name"
                          />
                        </div>
                        <div className="col-md-6 col-lg-4 form-group">
                          <label
                            htmlFor="name"
                            className="title-color font-weight-medium d-flex"
                          >
                            Select directory
                            <span className="text-danger">*</span>
                            <span style={{ color: "rgb(53, 149, 246)" }}>
                              {" "}
                            </span>
                          </label>
                          <select
                            onChange={(e) => {
                              setappliesType(e.target.value);
                            }}
                            className="form-control"
                            name="coupon_type"
                            required
                          >
                            <option disabled selected value="">
                              Select
                            </option>
                            <option value="Store front">Store front</option>
                            <option value="Specific product">
                              Specific product
                            </option>
                          </select>
                        </div>

                        {appliesType === "Specific product" ? (
                          <div className="col-md-6  col-lg-4 form-group">
                            <label
                              htmlFor="name"
                              className="title-color font-weight-medium d-flex"
                            >
                              Choose product
                              <span className="text-danger">*</span>&nbsp;
                              <span className="text-danger">{productname}</span>
                              <span style={{ color: "rgb(53, 149, 246)" }}>
                                {" "}
                              </span>
                            </label>
                            <p
                              onChange={(e) => {
                                setappliesType(e.target.value);
                              }}
                              className="form-control"
                              name="coupon_type"
                            >
                              <option disabled selected value="">
                                {appliesType}
                              </option>
                            </p>

                            {appliesType === "Specific product" ? (
                              <>
                                <div
                                  style={{
                                    overflow: "scroll",
                                    height: "220px",
                                    scrollbarWidth: "none",

                                    background: "#fffefd",
                                  }}
                                >
                                  <input
                                    placeholder="Search in products"
                                    type="text"
                                    id="categoryFilter"
                                    className="form-control"
                                    value={productFilter}
                                    onChange={(e) =>
                                      setProductFilter(e.target.value)
                                    }
                                  />

                                  {filterProducts(productlist, productFilter)
                                    .length === 0 ? (
                                    <p className="btn btn-secondary">
                                      No product found
                                    </p>
                                  ) : (
                                    filterProducts(
                                      productlist,
                                      productFilter
                                    ).map((product) => (
                                      <p
                                        onClick={() => {
                                          handleProductClickk(
                                            product?.productId
                                          );
                                          setproductname(product?.product_name);
                                        }}
                                        style={{ textAlign: "justify" }}
                                        className="btn btn-secondary"
                                      >
                                        <font>{product?.product_name}</font>
                                        <span className="caret"></span>
                                      </p>
                                    ))
                                  )}
                                </div>
                              </>
                            ) : null}
                          </div>
                        ) : null}
                      </div>
                      <div className="d-flex align-items-center justify-content-end flex-wrap gap-10">
                        <button type="submit" className="btn btn--primary px-4">
                          Create
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Addnewqr;
