import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Notifications = () => {
  let navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const fcmPayload = {
      message: {
        token: "f96U4qSZSS6MNFKrzJRk_P:APA91bEQ0kH7GgkjTdmDRORE9U6fjbgd_jSkY4YTAX8gRkX2OsfW4DgT5Kmak1PhTiO43koQM7vC0It7N4Ek6x_-UgOWQ7S97xc5x6m6LkpnzZmIlk4WSm58fQlOS9cqvL7N2KwvWSgS",
        notification: {
          title: title,
          body: description,
        },
      },
    };

    const config = {
      headers: {
        Authorization: "Bearer ya29.c.c0AY_VpZhUEcmg7buyy2fMPn5yvLZWJCKLb4PCcbZVYmmL-Kax4h0avZxw_daG1L4lMTqHVe9kbN4JGVoyvtRcPdArDzD0MQcfb4MsdYZc7OnlvdX8pwzDyrZbtEWbxjLC3jJIfgZsxuN8Owomwa2TcbZDS4JnOINwHWBLWQcern3TXtE4LVm9cDJXoBdZrfR0fMOjDi9r_1aWmL6uoCjooxTBhhasMJX7CuYvt3z2kClhawOK5UfJ_BZAGkgNTuAWpQEYdJt4gqecp_I6-n9QexcxjsV_Sxa2eAaonhU1H2O8Td-wbTP70IqERPH2XEx8ZFUb077vSiZpt6F3VaNiZzmGQ4dxnejviuF0xVlyWFFVRGGewfxyneIuH385ClFRfyVuyIYp7IO9pm6lqjWb0g-y3jndvr8M00gj42SWn1prO9sU0fZqX07wVrbd7yXuuyVBxfdWl0QXV-tbcUs8n7uMrQyU5rF_s66riQYbsmSh5V59sUWV0Sz19aiqfRauqZR2wwIB8hZ2hmX795pquWQqcZX8-c9pl5V89MV899oY_07bsa1pQ6RhxUOOpF6ey33-wXzQ41W_Mjujh0Jqas6q4sJycbSf-hw7pglR4F00veW82BnxygqXoXvgzQgzZzctUq02WF5Fy56skIgYvBa_79sXvZkxXrWuJSiR9Fopjl3qVYqtv_XF2XvBF2-yIIV-oUaFBpYp_l1j_fclXntI84qQjpJ_JBZlialipIaOkIl03pdev2pd0OxXpi7SZBMBjswZd3gzWyQpi4MvtBn73soiSiMrbr54dJec48bqRJRW-nci9wZ0MvsvOw_dM_yd7sxsopr70UJp5kch0g3dRdM5fc1fJpoczldzh7Z8YxVXkrhxRmr_hXuvd6c47MaXdV7qI4kyV2ngWaJxjb-qv4hvg4uz0Yw3to5smWsSBX8j88-maw7IwXn5yV0uepY-I725fkso4F-r7pdj3MqOqv-ib_ItI0hx-taJUJV3dfs04x9s8Wx",
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await axios.post(
        "https://fcm.googleapis.com/v1/projects/innt-6700e/messages:send",
        fcmPayload,
        config
      );
      console.log("Notification sent successfully:", response.data);
    } catch (error) {
      console.error("Error sending notification:", error.message);
    }
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  return (
    <div className="container">
      {/* <Header /> */}
      <div
        className="container row"
        style={{ paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px" }}
      >
        <div className="col-lg-2 col-md-3" style={{ paddingLeft: "0px" }}>
          {/* <Sidebarr /> */}
        </div>

        <div
          className="col-lg-10 col-md-9"
          style={{ paddingLeft: "30px", marginTop: "60px" }}
        >
          <div className=" mt-3">
            <div className="mb-3">
              <h2 className="h1 mb-0 text-capitalize d-flex align-items-center gap-2">
                <img
                  width={20}
                  src="https://6valley.6amtech.com/public/assets/back-end/img/push_notification.png"
                  alt="Send Notification"
                />
                Send notification
              </h2>
            </div>
            <div className="row gx-2 gx-lg-3">
              <div className="col-sm-12 col-lg-12 mb3 mb-lg-2">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      <div className="row g-3">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label
                              className="title-color text-capitalize"
                              htmlFor="title"
                            >
                              Title
                            </label>
                            <input
                              type="text"
                              name="title"
                              className="form-control"
                              placeholder="New notification"
                              required
                              value={title}
                              onChange={(e) => setTitle(e.target.value)}
                            />
                          </div>
                          <div className="form-group">
                            <label
                              className="title-color text-capitalize"
                              htmlFor="description"
                            >
                              Description
                            </label>
                            <textarea
                              name="description"
                              className="form-control"
                              required
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <center>
                              <img
                                className="upload-img-view mb-4"
                                id="viewer"
                                onError={(e) => {
                                  e.target.src =
                                    "https://6valley.6amtech.com/public/assets/front-end/img/image-place-holder.png";
                                }}
                                src="img/earning.png"
                                alt="image"
                              />
                            </center>
                            <label className="title-color text-capitalize">
                              Image
                            </label>
                            <span className="text-info">(Ratio 1:1)</span>
                            <div className="custom-file text-left">
                              <input
                                type="file"
                                name="image"
                                id="customFileEg1"
                                className="custom-file-input"
                                accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                                onChange={handleImageChange}
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="customFileEg1"
                              >
                                Choose File
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end gap-3">
                        <button type="reset" className="btn btn-secondary">
                          Reset
                        </button>
                        <button type="submit" className="btn btn--primary">
                          Send Notification
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            
              <div className="col-sm-12 col-lg-12 mb-3 mb-lg-2">
                <div className="card">
                  <div className="px-3 py-4">
                    <div className="row align-items-center">
                      <div className="col-sm-4 col-md-6 col-lg-8 mb-2 mb-sm-0">
                        <h5 className="mb-0 text-capitalize d-flex align-items-center gap-2">
                          Push notification table
                          <span className="badge badge-soft-dark radius-50 fz-12 ml-1">2</span>
                        </h5>
                      </div>
                      <div className="col-sm-8 col-md-6 col-lg-4">
                        <form action="" method="GET">
                          <div className="input-group input-group-merge input-group-custom">
                            <div className="input-group-prepend">
                              <div className="input-group-text">
                              <i class="fa fa-search" aria-hidden="true"></i>
                              </div>
                            </div>
                            <input id="datatableSearch_" type="search" name="search" className="form-control" placeholder="Search by title" aria-label="Search orders" defaultValue required />
                            <button type="submit" className="btn btn--primary">Search</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive datatable-custom">
                    <table style={{textAlign: 'left'}} className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100">
                      <thead className="thead-light thead-50 text-capitalize">
                        <tr>
                          <th>SL </th>
                          <th>Title </th>
                          <th>Description </th>
                          <th>Image </th>
                          <th>Notification count </th>
                          <th>Status </th>
                          <th>Resend </th>
                          <th className="text-center">Action </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>
                            <span className="d-block">
                              Coupon
                            </span>
                          </td>
                          <td>
                            From October, Use Code "pcuw655ytg" to g...
                          </td>
                          <td>
                            <img className="min-w-75" width={75} height={75} onerror="this.src='https://6valley.6amtech.com/public/assets/back-end/img/160x160/img2.jpg'" src="https://6valley.6amtech.com/storage/app/public/notification/2022-04-20-625f9cc7e2f84.png" />
                          </td>
                          <td id="count-3">1</td>
                          <td>
                            <form action="https://6valley.6amtech.com/admin/notification/status" method="post" id="notification_status3_form" className="notification_status_form">
                              <input type="hidden" name="_token" defaultValue="M5Ms1Z4GEx6hqRPEuULXw4EVAXN9PGreUwLBXESa" /> <input type="hidden" name="id" defaultValue={3} />
                              <label className="switcher mx-auto">
                                <input type="checkbox" className="switcher_input" id="notification_status3" name="status" defaultValue={1} defaultChecked onclick="toogleStatusModal(event,'notification_status3','notification-on.png','notification-off.png','Want to Turn ON Notification Status','Want to Turn OFF Notification Status',`<p>If enabled customers will receive notifications on their devices</p>`,`<p>If disabled customers will not receive notifications on their devices</p>`)" />
                                <span className="switcher_control" />
                              </label>
                            </form>
                          </td>
                          <td>
                            <span className="btn btn-outline-success square-btn btn-sm" onclick="resendNotification(this)" data-id={3}>
                              <i className="fa fa-refresh" aria-hidden="true" />
                            </span>
                          </td>
                          <td className="text-center">
                            <div className="d-flex justify-content-center gap-2">
                              <span onClick="eidtnotification" className="btn btn-outline--primary btn-sm edit square-btn" title="Edit">
                                <i className="fa fa-pencil-square-o" aria-hidden="true" />
                              </span>
                              <a className="btn btn-outline-danger btn-sm delete" title="Delete" href="javascript:" id="3')">
                                <i className="fa fa-trash-o" aria-hidden="true" />
                              </a>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>
                            <span className="d-block">
                              Buy 2 get 1
                            </span>
                          </td>
                          <td>
                            Buy any 2 products then get any product.
                          </td>
                          <td>
                            <img className="min-w-75" width={75} height={75} onerror="this.src='https://6valley.6amtech.com/public/assets/back-end/img/160x160/img2.jpg'" src="https://6valley.6amtech.com/storage/app/public/notification/2022-04-16-625a6446ee10d.png" />
                          </td>
                          <td id="count-2">2</td>
                          <td>
                            <form action="https://6valley.6amtech.com/admin/notification/status" method="post" id="notification_status2_form" className="notification_status_form">
                              <input type="hidden" name="_token" defaultValue="M5Ms1Z4GEx6hqRPEuULXw4EVAXN9PGreUwLBXESa" /> <input type="hidden" name="id" defaultValue={2} />
                              <label className="switcher mx-auto">
                                <input type="checkbox" className="switcher_input" id="notification_status2" name="status" defaultValue={1} defaultChecked onclick="toogleStatusModal(event,'notification_status2','notification-on.png','notification-off.png','Want to Turn ON Notification Status','Want to Turn OFF Notification Status',`<p>If enabled customers will receive notifications on their devices</p>`,`<p>If disabled customers will not receive notifications on their devices</p>`)" />
                                <span className="switcher_control" />
                              </label>
                            </form>
                          </td>
                          <td>
                            <span className="btn btn-outline-success square-btn btn-sm" onclick="resendNotification(this)" data-id={2}>
                              <i className="fa fa-refresh" aria-hidden="true" />
                            </span>
                          </td>
                          <td className="text-center">
                            <div className="d-flex justify-content-center gap-2">
                              <span onClick="eidtnotification" className="btn btn-outline--primary btn-sm edit square-btn" title="Edit">
                                <i className="fa fa-pencil-square-o" aria-hidden="true" />
                              </span>
                              <a className="btn btn-outline-danger btn-sm delete" title="Delete" href="javascript:" id="2')">
                                <i className="fa fa-trash-o" aria-hidden="true" />
                              </a>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table className="mt-4">
                      <tfoot>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
