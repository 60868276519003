import React, { useEffect, useState } from "react";
import Header from "../Header";

import { Link, useNavigate } from "react-router-dom";
import Sidebarr from "../Sidebar";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import toast, { Toaster } from "react-hot-toast";

const Myshop = () => {
  const [details, setdetails] = useState();
  const navigate = useNavigate();
  const [amount, setamount] = useState();
  const [startdate, setstartdate] = useState();
  const [enddate, setenddate] = useState();
  const [startdate1, setstartdate1] = useState();
  const [enddate1, setenddate1] = useState();
  let token = secureLocalStorage.getItem("vendortoken");
  let venderid = secureLocalStorage.getItem("vendorid");

  useEffect(() => {
    shopdetails();
  }, [0]);

  const shopdetails = () => {
    const sellerdata = {
      venderId: venderid,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/shopDetails`,
        sellerdata
      )
      .then((res) => {
        setdetails(res.data.data);
      })
      .catch((error) => {});
  };

  const minimumdata = (e) => {
    e.preventDefault();
    if (!amount) {
      toast.error("Please enter amount");
      return;
    }
    const data = {
      vendorId: venderid,
      amount: amount,
    };
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/updateMinimum_orderAmount`,
        data
      )
      .then((res) => {
        toast.success(res.data.message);
      })
      .catch((error) => {});
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    const checked = event.target.checked;
    setIsChecked(checked);

    if (!startdate || !enddate) {
      toast.error("Please select both start and end dates");
      return;
    }

    if (checked) {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    const data = {
      vendorId: venderid,
      start_date: startdate,
      end_date: enddate,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/updatedTemporaryClose_date`,
        data
      )
      .then((res) => {
        toast.success(res.data.message);
      })
      .catch((error) => {
        toast.error("An error occurred. Please try again later.");
      });
  };

  const [isChecked1, setIsChecked1] = useState(false);

  const handleCheckboxChange1 = (event) => {
    const checked1 = event.target.checked;
    setIsChecked1(checked1);

    if (!startdate1 || !enddate1) {
      toast.error("Please select both start and end dates");
      return;
    }

    if (checked1) {
      handleSubmit1();
    }
  };

  const handleSubmit1 = () => {
    const data = {
      vendorId: venderid,
      start_date: startdate1,
      end_date: enddate1,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/updatedVacationMode_date`,
        data
      )
      .then((res) => {
        toast.success(res.data.message);
      })
      .catch((error) => {
        toast.error("An error occurred. Please try again later.");
      });
  };

  return (
    <div className="container">
      <Toaster />
      {/* <Header /> */}
      <div
        className="container row"
        style={{ paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px" }}
      >
        <div className="col-lg-2 col-md-3" style={{ paddingLeft: "0px" }}>
          {/* <Sidebarr /> */}
        </div>

        <div
          className="col-lg-10 col-md-9"
          style={{ paddingLeft: "30px", marginTop: "60px" }}
        >
          <div className="mt-3">
            <div className="mb-3">
              <h2 className="h1 mb-0 text-capitalize d-flex align-items-center gap-2">
                <img
                  width={20}
                  src="https://6valley.6amtech.com/public/assets/back-end/img/shop-info.png"
                  alt
                />
                My shop
              </h2>
            </div>

            <div className="row">
              <div className="col-md-12 mb-7">
                <div className="card ">
                  <div className="card-header mb-3">
                    <div style={{ visibility: "hidden" }}>
                      <h4 className="mb-0">My shop info </h4>
                    </div>
                    <div className="d-inline-flex gap-2">
                      <Link
                        className="btn btn--primary __inline-70 px-4 text-white"
                        to="/editmyshop"
                      >
                        Edit <i class="fa fa-pencil"></i>
                      </Link>
                    </div>
                  </div>
                  <div className="card-body card-header mb-3">
                    <div className="d-flex align-items-center flex-wrap gap-5">
                      <div className="text-left">
                        {details?.sellerImage ? (
                          <img
                            src={
                              `${process.env.REACT_APP_API_KEY}uploads/` +
                              details?.sellerImage
                            }
                            className="rounded-circle border"
                            height={200}
                            width={200}
                            alt
                          />
                        ) : (
                          <img
                            src="https://6valley.6amtech.com/storage/app/public/shop/2022-04-21-6260f6e190f4c.png"
                            className="rounded-circle border"
                            height={200}
                            width={200}
                            alt
                          />
                        )}
                      </div>
                      <div className>
                        <div className="flex-start">
                          <h4>Name : </h4>
                          {details?.shopName ? (
                            <h4 className="mx-1 text-capitalize">
                              {details?.shopName}{" "}
                            </h4>
                          ) : (
                            <h4 className="mx-1">No data found</h4>
                          )}
                        </div>
                        <div className="flex-start">
                          <h6>Phone : </h6>
                          {details?.sellerMobile ? (
                            <h6 className="mx-1">{details?.sellerMobile}</h6>
                          ) : (
                            <h6 className="mx-1">0000000000</h6>
                          )}
                        </div>
                        <div className="flex-start">
                          <h6>Address : </h6>
                          {details?.shopAddress ? (
                            <h6 className="mx-1 text-capitalize">
                              {details?.shopAddress}
                            </h6>
                          ) : (
                            <h6 className="mx-1">
                              House-09, Road-02, Section-15, Block-D, Mirpur-13
                            </h6>
                          )}
                        </div>
                      </div>
                      <div className />
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="row g-2" id="order_stats">
                      <div className="col-sm-6 col-lg-3">
                        <Link
                          className="order-stats order-stats_delivered"
                          to="/Products List"
                        >
                          <div className="order-stats__content">
                            <img
                              width={20}
                              src="https://6valley.6amtech.com/public/assets/back-end/img/delivered.png"
                              alt
                            />
                            <h6 className="order-stats__subtitle">Products</h6>
                          </div>
                          <span className="order-stats__title">
                            {details?.totalProduct}
                          </span>
                        </Link>
                      </div>
                      <div className="col-sm-6 col-lg-3">
                        <Link className="order-stats order-stats_failed" to="#">
                          <div className="order-stats__content">
                            <img
                              width={20}
                              src="https://6valley.6amtech.com/public/assets/back-end/img/canceled.png"
                              alt
                            />
                            <h6 className="order-stats__subtitle">Ratings</h6>
                          </div>
                          <span className="order-stats__title">
                            {details?.shop_rating}
                          </span>
                        </Link>
                      </div>
                      <div className="col-sm-6 col-lg-3">
                        <Link
                          className="order-stats order-stats_canceled"
                          to="/Reviews"
                        >
                          <div className="order-stats__content">
                            <img
                              width={20}
                              src="https://6valley.6amtech.com/public/assets/back-end/img/failed-to-deliver.png"
                              alt
                            />
                            <h6 className="order-stats__subtitle">Reviews</h6>
                          </div>
                          <span className="order-stats__title">
                            {details?.totalReviews}
                          </span>
                        </Link>
                      </div>
                      <div className="col-sm-6 col-lg-3">
                        <Link
                          className="order-stats order-stats_returned"
                          to="#"
                        >
                          <div className="order-stats__content">
                            <img
                              width={20}
                              src="https://6valley.6amtech.com/public/assets/back-end/img/returned.png"
                              alt
                            />
                            <h6 className="order-stats__subtitle">Sales</h6>
                          </div>
                          <span className="order-stats__title">
                            {details?.totalSales}
                          </span>
                        </Link>
                      </div>

                      <div className="col-sm-6 col-lg-3">
                        <Link
                          className="order-stats order-stats_returned"
                          to="#"
                        >
                          <div className="order-stats__content">
                            <img
                              width={20}
                              src="https://6valley.6amtech.com/public/assets/back-end/img/returned.png"
                              alt
                            />
                            <h6 className="order-stats__subtitle">
                              Page views
                            </h6>
                          </div>
                          <span className="order-stats__title">
                            {details?.pageViews}
                          </span>
                        </Link>
                      </div>

                      <div className="col-sm-6 col-lg-3">
                        <Link
                          className="order-stats order-stats_returned"
                          to="#"
                        >
                          <div className="order-stats__content">
                            <img
                              width={20}
                              src="https://6valley.6amtech.com/public/assets/back-end/img/returned.png"
                              alt
                            />
                            <h6 className="order-stats__subtitle">
                              Liked items
                            </h6>
                          </div>
                          <span className="order-stats__title">
                            {details?.totalLiked_items}
                          </span>
                        </Link>
                      </div>

                      <div className="col-sm-12 col-lg-4 col-md-4">
                        <label className="mt">Minimum order amount</label>
                        <input
                          placeholder="Amount"
                          onChange={(e) => {
                            setamount(e.target.value);
                          }}
                          type="text"
                          value={amount}
                          required
                          class="form-control"
                        ></input>
                      </div>
                      <div className="col-sm-12 col-lg-2 col-md-4">
                        <div class="d-inline-flex gap-2 mt-4">
                          <button
                            onClick={minimumdata}
                            class="btn btn--primary  px-4 text-white"
                            href=""
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-3 card-body">
                      <div className="card mb-3  col-sm-12 col-lg-6 col-md-4">
                        <div className="card-body">
                          <input
                            type="hidden"
                            name="_token"
                            defaultValue="ZzkfkLNbAUt4VTq5h8M6WE4WDu8lkTMJLYAhZQBe"
                          />{" "}
                          <div className="border rounded border-color-c1 px-4 py-3 d-flex justify-content-between mb-1">
                            <h5 className="mb-0 d-flex gap-1 c1">
                              Temporary close
                            </h5>
                            <input type="hidden" name="id" defaultValue={2} />
                            <div className="position-relative">
                              <label className="switcher">
                                <input
                                  type="checkbox"
                                  className="switcher_input"
                                  name="status"
                                  checked={isChecked}
                                  id="temporary_close"
                                  onChange={handleCheckboxChange}
                                />
                                <span className="switcher_control" />
                              </label>
                            </div>
                          </div>
                          <div className="row">
                            <div className=" col-sm-12 col-lg-6 col-md-6 mt-2 ">
                              From{" "}
                              <input
                                required
                                onChange={(e) => {
                                  setstartdate(e.target.value);
                                }}
                                placeholder="Select Date"
                                type="date"
                                style={{
                                  border: ".0625rem solid #e7eaf3",
                                  height: "35px",
                                  borderColor: "#d0dbe9",
                                  borderRadius: ".3125rem",
                                }}
                              />
                            </div>
                            <div className=" col-sm-12 col-lg-6 col-md-6 mt-2 ">
                              - to{" "}
                              <input
                                required
                                onChange={(e) => {
                                  setenddate(e.target.value);
                                }}
                                type="date"
                                style={{
                                  border: ".0625rem solid #e7eaf3",
                                  height: "35px",
                                  borderColor: "#d0dbe9",
                                  borderRadius: ".3125rem",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card mb-3 col-sm-12 col-lg-6 col-md-4">
                        <div className="card-body">
                          <input
                            type="hidden"
                            name="_token"
                            defaultValue="ZzkfkLNbAUt4VTq5h8M6WE4WDu8lkTMJLYAhZQBe"
                          />{" "}
                          <div className="border rounded border-color-c1 px-4 py-3 d-flex justify-content-between mb-1">
                            <h5 className="mb-0 d-flex gap-1 c1">
                              Vacation mode
                            </h5>
                            <input type="hidden" name="id" defaultValue={2} />
                            <div className="position-relative">
                              <label className="switcher">
                                <input
                                  type="checkbox"
                                  className="switcher_input"
                                  name="status"
                                  checked={isChecked1}
                                  id="temporary_close"
                                  onChange={handleCheckboxChange1}
                                />
                                <span className="switcher_control" />
                              </label>
                            </div>
                          </div>
                          <div className="row">
                            <div className=" col-sm-12 col-lg-6 col-md-6 mt-2 ">
                              From{" "}
                              <input
                                required
                                onChange={(e) => {
                                  setstartdate1(e.target.value);
                                }}
                                type="date"
                                name="id"
                                style={{
                                  border: ".0625rem solid #e7eaf3",
                                  height: "35px",
                                  borderColor: "#d0dbe9",
                                  borderRadius: ".3125rem",
                                }}
                              />
                            </div>
                            <div className=" col-sm-12 col-lg-6 col-md-6 mt-2 ">
                              - to{" "}
                              <input
                                type="date"
                                name="id"
                                style={{
                                  border: ".0625rem solid #e7eaf3",
                                  height: "35px",
                                  borderColor: "#d0dbe9",
                                  borderRadius: ".3125rem",
                                }}
                                required
                                onChange={(e) => {
                                  setenddate1(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="balance-modal"
              tabIndex={-1}
              style={{ display: "none" }}
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content" style={{ textAlign: "left" }}>
                  <form>
                    <div className="modal-header border-bottom pb-2">
                      <div>
                        <h5 className="modal-title" id="exampleModalLabel">
                          Vacation Mode
                        </h5>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label className="switcher">
                            <input
                              type="checkbox"
                              name="vacation_status"
                              className="switcher_input"
                              id="vacation_close"
                            />
                            <span className="switcher_control" />
                          </label>
                        </div>
                        <div className="col-md-6">
                          <button
                            type="button"
                            className="close pt-0"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">×</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="modal-body">
                      <div className="mb-5">
                        *Set vacation mode for shop means you will be not
                        available receive order and provider products for placed
                        order at that time
                      </div>
                      <input
                        type="hidden"
                        name="_token"
                        defaultValue="ZzkfkLNbAUt4VTq5h8M6WE4WDu8lkTMJLYAhZQBe"
                      />{" "}
                      <div className="row">
                        <div className="col-md-6">
                          <label>Vacation Start</label>
                          <input
                            type="date"
                            name="vacation_start_date"
                            defaultValue
                            id="vacation_start_date"
                            className="form-control"
                            required
                          />
                        </div>
                      </div>
                      <div className="text-end gap-5 mt-2">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                        >
                          Close
                        </button>
                        <button type="submit" className="btn btn--primary">
                          Update
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Myshop;
