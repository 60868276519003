import React from 'react'

import Header from '../Header'
import { Link } from 'react-router-dom'
import Sidebarr from '../Sidebar'

const Transacation_report = () => {
    return (
        <div className='container'>
            {/* <Header /> */}
            <div className='container row' style={{ paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px" }}>
                <div className="col-lg-2 col-md-3" style={{ paddingLeft: "0px" }}>
                    {/* <Sidebarr /> */}
                </div>

                <div className="col-lg-10 col-md-9" style={{ paddingLeft: "30px", marginTop: "60px" }}>
                    <div className="mt-3">
                        <div className="mb-4  ">
                            <h2 className="h1 mb-0 text-capitalize d-flex align-items-center gap-2">
                                <img width={20} src="https://6valley.6amtech.com/public/assets/back-end/img/order_report.png" alt />
                                Transaction report
                            </h2>
                        </div>
                        <div class="inline-page-menu my-4 ml-3">
                            <ul class="list-unstyled">
                                <li ><Link to="/transactionreport" class="text-info">Order Transactions</Link></li>
                                <li class=""><Link to="/transactionexpenselist">Expense Transactions</Link></li>
                            </ul>
                        </div>
                        <div className="card mb-2">
                            <div className="card-body">
                                <h4 className="mb-3">Filter Data</h4>
                                <form action="#" id="form-data" method="GET" className="w-100">
                                    <div className="row  gx-2 gy-3 align-items-center text-left">
                                        <div className="col-sm-6 col-md-3">
                                            <div className>
                                                <select className="form-control __form-control" name="status">
                                                    <option className="text-center" value={0} disabled>
                                                        ---Select status---
                                                    </option>
                                                    <option className="text-capitalize" value="all" selected>All </option>
                                                    <option className="text-capitalize" value="disburse">Disburse </option>
                                                    <option className="text-capitalize" value="hold">Hold</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-3">
                                            <div className>
                                                <select className="js-select2-custom form-control __form-control select2-hidden-accessible" name="customer_id" data-select2-id={1} tabIndex={-1} aria-hidden="true">
                                                    <option className="text-center" value="all" selected data-select2-id={3}>
                                                        All customer
                                                    </option>
                                                    <option className="text-left text-capitalize" value={2} data-select2-id={4}>
                                                        fatema subarna
                                                    </option>
                                                    <option className="text-left text-capitalize" value={4} data-select2-id={5}>
                                                        Md.Safayet Hossain
                                                    </option>
                                                    <option className="text-left text-capitalize" value={5} data-select2-id={6}>
                                                        Jocky Lop
                                                    </option>
                                                    <option className="text-left text-capitalize" value={6} data-select2-id={7}>
                                                        Demo user
                                                    </option>
                                                    <option className="text-left text-capitalize" value={7} data-select2-id={8}>
                                                        Bsgsh Nsbdv
                                                    </option>
                                                    <option className="text-left text-capitalize" value={8} data-select2-id={9}>
                                                        Joy Joy
                                                    </option>
                                                    <option className="text-left text-capitalize" value={9} data-select2-id={10}>
                                                        Devid Jack
                                                    </option>
                                                    <option className="text-left text-capitalize" value={10} data-select2-id={11}>
                                                        test test
                                                    </option>
                                                    <option className="text-left text-capitalize" value={11} data-select2-id={12}>
                                                        Hi-fi Cinemas
                                                    </option>
                                                </select><span className="select2 select2-container select2-container--default" dir="ltr" data-select2-id={2} style={{ width: '100%' }}><span className="selection"><span className="select2-selection custom-select" role="combobox" aria-haspopup="true" aria-expanded="false" tabIndex={0} aria-disabled="false" aria-labelledby="select2-customer_id-pv-container"><span className="select2-selection__rendered" id="select2-customer_id-pv-container" role="textbox" aria-readonly="true" title="
All customer
"><span>
                                                        All customer
                                                    </span></span><span className="select2-selection__arrow" role="presentation"><b role="presentation" /></span></span></span><span className="dropdown-wrapper" aria-hidden="true" /></span>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-3">
                                            <select className="form-control __form-control" name="date_type" id="date_type">
                                                <option value="this_year" selected>This Year</option>
                                                <option value="this_month">This Month</option>
                                                <option value="this_week">This Week</option>
                                                <option value="custom_date">Custom Date</option>
                                            </select>
                                        </div>
                                        <div className="col-sm-6 col-md-3" id="from_div">
                                            <div className="form-floating">
                                                <input type="date" name="from" defaultValue id="from_date" className="form-control __form-control" />
                                                <label>Start date</label>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-3" id="to_div">
                                            <div className="form-floating">
                                                <input type="date" defaultValue name="to" id="to_date" className="form-control __form-control" />
                                                <label>End date</label>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-2 d-flex gap-2">
                                            <button type="submit" className="btn btn--primary px-4 min-w-120 __h-45px" onclick="formUrlChange(this)" data-action="https://6valley.6amtech.com/seller/transaction/order-list">
                                                Filter
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="store-report-content mb-2">
                            <div className="left-content">
                                <div className="left-content-card">
                                    <img src="https://6valley.6amtech.com/public/assets/back-end/img/cart.svg" alt />
                                    <div className="info">
                                        <h4 className="subtitle">11</h4>
                                        <h6 className="subtext">Total Products</h6>
                                    </div>
                                </div>
                                <div className="left-content-card">
                                    <img src="https://6valley.6amtech.com/public/assets/back-end/img/products.svg" alt />
                                    <div className="info">
                                        <h4 className="subtitle">10</h4>
                                        <h6 className="subtext">Active Products</h6>
                                    </div>
                                </div>
                                <div className="left-content-card">
                                    <img src="https://6valley.6amtech.com/public/assets/back-end/img/inactive-product.svg" alt />
                                    <div className="info">
                                        <h4 className="subtitle">1</h4>
                                        <h6 className="subtext">Inactive Products</h6>
                                    </div>
                                </div>
                                <div className="left-content-card">
                                    <img src="https://6valley.6amtech.com/public/assets/back-end/img/pending_products.svg" alt />
                                    <div className="info">
                                        <h4 className="subtitle">0</h4>
                                        <h6 className="subtext">Pending Products</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="center-chart-area">
                                <div className="center-chart-header">
                                    <h3 className="title">Order Statistics</h3>
                                </div>
                                <canvas id="updatingData" className="store-center-chart" data-hs-chartjs-options="{
        &quot;type&quot;: &quot;bar&quot;,
        &quot;data&quot;: {
          &quot;labels&quot;: [&quot;January-2023&quot;,&quot;February-2023&quot;,&quot;March-2023&quot;,&quot;April-2023&quot;,&quot;May-2023&quot;,&quot;June-2023&quot;,&quot;July-2023&quot;,&quot;August-2023&quot;,&quot;September-2023&quot;,&quot;October-2023&quot;,&quot;November-2023&quot;,&quot;December-2023&quot;],
          &quot;datasets&quot;: [{
            &quot;label&quot;: &quot;Total order amount&quot;,
            &quot;data&quot;: [&quot;0&quot;,&quot;0&quot;,&quot;0&quot;,&quot;0&quot;,&quot;0&quot;,&quot;0&quot;,&quot;0&quot;,&quot;0&quot;,&quot;0&quot;,&quot;77181.8&quot;,&quot;0&quot;,&quot;0&quot;],
            &quot;backgroundColor&quot;: &quot;#a2ceee&quot;,
            &quot;hoverBackgroundColor&quot;: &quot;#0177cd&quot;,
            &quot;borderColor&quot;: &quot;#a2ceee&quot;
          }]
        },
        &quot;options&quot;: {
          &quot;scales&quot;: {
            &quot;yAxes&quot;: [{
              &quot;gridLines&quot;: {
                &quot;color&quot;: &quot;#e7eaf3&quot;,
                &quot;drawBorder&quot;: false,
                &quot;zeroLineColor&quot;: &quot;#e7eaf3&quot;
              },
              &quot;ticks&quot;: {
                &quot;beginAtZero&quot;: true,
                &quot;fontSize&quot;: 12,
                &quot;fontColor&quot;: &quot;#97a4af&quot;,
                &quot;fontFamily&quot;: &quot;Open Sans, sans-serif&quot;,
                &quot;padding&quot;: 5,
                &quot;postfix&quot;: &quot; $&quot;
              }
            }],
            &quot;xAxes&quot;: [{
              &quot;gridLines&quot;: {
                &quot;display&quot;: false,
                &quot;drawBorder&quot;: false
              },
              &quot;ticks&quot;: {
                &quot;fontSize&quot;: 12,
                &quot;fontColor&quot;: &quot;#97a4af&quot;,
                &quot;fontFamily&quot;: &quot;Open Sans, sans-serif&quot;,
                &quot;padding&quot;: 5
              },
              &quot;categoryPercentage&quot;: 0.3,
              &quot;maxBarThickness&quot;: &quot;10&quot;
            }]
          },
          &quot;cornerRadius&quot;: 5,
          &quot;tooltips&quot;: {
            &quot;prefix&quot;: &quot; &quot;,
            &quot;hasIndicator&quot;: true,
            &quot;mode&quot;: &quot;index&quot;,
            &quot;intersect&quot;: false
          },
          &quot;hover&quot;: {
            &quot;mode&quot;: &quot;nearest&quot;,
            &quot;intersect&quot;: true
          }
        }
      }">
                                </canvas>
                            </div>

                        </div>
                        <div className="card">
                            <div className="px-3 py-4">
                                <div className="d-flex flex-wrap gap-3 align-items-center">
                                    <h4 className="mb-0 mr-auto">
                                        Total Transactions
                                        <span className="badge badge-soft-dark radius-50 fz-12">2</span>
                                    </h4>
                                    <form action="https://6valley.6amtech.com/seller/transaction/order-list" method="GET" className="mb-0">
                                        <div className="input-group input-group-merge input-group-custom">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">
                                                    <i className="fa fa-search" aria-hidden="true" />
                                                </div>
                                            </div>
                                            <input id="datatableSearch_" type="search" name="search" className="form-control" placeholder="Search by orders id or transaction ID" />
                                            <button type="submit" className="btn btn--primary">Search</button>
                                        </div>
                                    </form>
                                    <div>
                                        <a href="#" className="btn btn-outline--primary text-nowrap btn-block">
                                            Download PDF
                                        </a>
                                    </div>
                                    <div>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table id="datatable" style={{ textAlign: 'left' }} className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100 __table">
                                    <thead className="thead-light thead-50 text-capitalize">
                                        <tr>
                                            <th>SL</th>
                                            <th>Order id</th>
                                            <th>Customer name</th>
                                            <th>Total product amount</th>
                                            <th>Product discount</th>
                                            <th>Coupon discount</th>
                                            <th>Discounted amount</th>
                                            <th>VAT/TAX</th>
                                            <th>Shipping charge</th>
                                            <th>Order amount</th>
                                            <th>Delivered by</th>
                                            <th>Admin discount</th>
                                            <th>Seller discount</th>
                                            <th>Admin commission</th>
                                            <th>Seller net income</th>
                                            <th>Payment method</th>
                                            <th>Payment Status</th>
                                            <th className="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>
                                                <a className="title-color hover-c1" href="#">100181</a>
                                            </td>
                                            <td>
                                                Jocky Lop
                                            </td>
                                            <td>$400.00</td>
                                            <td>$40.00</td>
                                            <td>$0.00</td>
                                            <td>$360.00</td>
                                            <td>$20.00</td>
                                            <td>$0.00</td>
                                            <td>$380.00</td>
                                            <td>admin</td>
                                            <td>
                                                $0.00
                                            </td>
                                            <td>
                                                $0.00
                                            </td>
                                            <td>$54.00</td>
                                            <td>
                                                $326.00
                                            </td>
                                            <td>pay by wallet</td>
                                            <td>
                                                <div className="text-center">
                                                    <span className="badge badge-soft-success">
                                                        Disburse
                                                    </span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="d-flex justify-content-center">
                                                    <a href="#" className="btn btn-outline-success square-btn btn-sm">
                                                        <i className="fa fa-download" aria-hidden="true" />
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                        
                                    </tbody>
                                </table>
                            </div>
                            <div className="table-responsive mt-4">
                                <div className="px-4 d-flex justify-content-lg-end">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>



        </div>
    )
}

export default Transacation_report
