import React, { useEffect, useRef, useState } from "react";
import Header from "../Header";

import "../sidebar.css";
import { Link } from "react-router-dom";
import Sidebarr from "../Sidebar";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import toast, { Toaster } from "react-hot-toast";
import ReactPlayer from "react-player";
import ReactAudioPlayer from "react-audio-player";

const Chatdeliveryman = () => {
  const [customerchatlist, setcustomerchatlist] = useState();

  const [vendorchat, setvendorchat] = useState();

  const [details, setdetails] = useState();
  const [text, settext] = useState();
  const [fileimage, setfileimage] = useState();
  const [customerid, setcustomerid] = useState();

  let token = secureLocalStorage.getItem("vendortoken");
  let venderIdd = secureLocalStorage.getItem("vendorid");
  let productid = secureLocalStorage.getItem("productid");
  let idcheck = (customerids) => {
    setcustomerid(customerids);
  };

  useEffect(() => {
    getcustomerlist();
  }, [0]);
  const getcustomerlist = () => {
    let options = {
      hraders: {
        token: token,
      },
    };
    const data = {
      productId: productid,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/reviewsDetails`,
        data,
        options
      )
      .then((res) => {
        setcustomerchatlist(res?.data?.data[0]);
      })
      .catch((error) => {});
  };

  const getvendormessage = (item) => {
    let options = {
      hraders: {
        token: token,
      },
    };
    const data = {
      ratingId: item ? item : customerid,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/singleReview_detail`,
        data,
        options
      )
      .then((res) => {

        setvendorchat(res.data.data);
      })
      .catch((error) => {});
  };

  const sendmessage = (e) => {
    e.preventDefault();
    if (!fileimage && !text) {
      toast.error("Please give message");
      return null;
    }
    let formData;

    if (fileimage) {
      formData = new FormData();
      formData.append("shopId", venderIdd);
      formData.append("ratingId", customerid);

      formData.append("message", fileimage);
    } else {
      formData = {
        shopId: venderIdd,
        ratingId: customerid,

        message: text,
      };
    }

    let options = {
      hraders: {
        token: token,
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/reviewReplay`,
        formData,
        options
      )
      .then((res) => {
        getvendormessage();
      })
      .catch((error) => {
        if (error.response.status === 404) {
          toast.error(error.response.data.message);
        }
      });
    settext("");
    setfileimage("");
  };

  useEffect(() => {
    sellerdetails();
  }, [0]);

  const sellerdetails = () => {
    const sellerdata = {
      venderId: venderIdd,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/getVenderProfile`,
        sellerdata
      )
      .then((res) => {
        setdetails(res.data.data);
      })
      .catch((error) => {});
  };

  const Deletevendormessage = (item) => {
    let options = {
      hraders: {
        token: token,
      },
    };
    const data = {
      venderId: venderIdd,
      messageIds: [item],
    };

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/removeVender_Message`,
        data,
        options
      )
      .then((res) => {
        getvendormessage();
      })
      .catch((error) => {});
  };

  const chatContainerRef = useRef(null);

  useEffect(() => {
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  }, [vendorchat]);

  const [FilteredCustomerList, setFilteredCustomerList] = useState([]);

  useEffect(() => {
    setFilteredCustomerList(customerchatlist);
  }, [customerchatlist]);

  const report = (item) => {
    let options = {
      hraders: {
        token: token,
      },
    };
    const data = {
      shopId: venderIdd,
      ratingId: item,
      message: "The is bad content",
    };

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/reviewReport`,
        data,
        options
      )
      .then((res) => {
        toast.success(res.data.message);
      })
      .catch((error) => {});
  };
  return (
    <div className="container">
      <Toaster />
      {/* <Header/> */}
      <div
        className="container row"
        style={{ paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px" }}
      >
        <div className="col-lg-2 col-md-3" style={{ paddingLeft: "0px" }}>
          {/* <Sidebarr/> */}
        </div>

        <div
          className="col-lg-10 col-md-9"
          style={{ paddingLeft: "30px", marginTop: "60px" }}
        >
          <div className="mt-3">
            <div className="mb-3">
              <h2 className="h1 mb-0 text-capitalize d-flex align-items-center gap-2">
                <img
                  src="https://6valley.6amtech.com/public/assets/back-end/img/support-ticket.png"
                  alt=""
                />
                Reviews
              </h2>
            </div>
            <div className="row">
              <div className="col-xl-5 col-lg-5 chatSel mb-2">
                <div className="card card-body px-0 h-100 px-2 py-0 border rounded">
                  <div className="media align-items-center px-3 gap-3 mt-3 mb-4 px-2 py-0 border rounded">
                    <div className="avatar avatar-lg avatar-circle">
                      <img
                        height={250}
                        className="avatar-img"
                        src={
                          details?.vender_profile
                            ? `${process.env.REACT_APP_API_KEY}uploads/${details?.vender_profile}`
                            : `https://6valley.6amtech.com/storage/app/public/seller/2022-10-12-63467dae3ef83.png`
                        }
                        alt="Image Description"
                      />
                      <span className="avatar-status avatar-sm-status avatar-status-success" />
                    </div>
                    <div className="media-body mt-3">
                      <h5 className="profile-name mb-0 text-capitalize">
                        {secureLocalStorage.getItem("firstnamee")}
                      </h5>

                      <span class="d-flex align-items-center gap-1 text-warning  fz-14 mb-2">
                        <font style={{ fontSize: "28px", marginRight: "30px" }}>
                          {customerchatlist?.rating}
                        </font>

                        {customerchatlist?.rating?.toFixed() === "1" ? (
                          <div>
                            <i class="fa fa-star " aria-hidden="true"></i>
                            <i
                              class="fa fa-star text-secondary"
                              aria-hidden="true"
                            ></i>
                            <i
                              class="fa fa-star text-secondary"
                              aria-hidden="true"
                            ></i>
                            <i
                              class="fa fa-star text-secondary"
                              aria-hidden="true"
                            ></i>
                            <i
                              class="fa fa-star text-secondary"
                              aria-hidden="true"
                            ></i>
                          </div>
                        ) : customerchatlist?.rating?.toFixed() === "2" ? (
                          <div>
                            <i class="fa fa-star " aria-hidden="true"></i>
                            <i class="fa fa-star " aria-hidden="true"></i>
                            <i
                              class="fa fa-star text-secondary"
                              aria-hidden="true"
                            ></i>
                            <i
                              class="fa fa-star text-secondary"
                              aria-hidden="true"
                            ></i>
                            <i
                              class="fa fa-star text-secondary"
                              aria-hidden="true"
                            ></i>
                          </div>
                        ) : customerchatlist?.rating?.toFixed() === "3" ? (
                          <div>
                            <i class="fa fa-star " aria-hidden="true"></i>
                            <i class="fa fa-star " aria-hidden="true"></i>
                            <i class="fa fa-star " aria-hidden="true"></i>
                            <i
                              class="fa fa-star text-secondary"
                              aria-hidden="true"
                            ></i>
                            <i
                              class="fa fa-star text-secondary"
                              aria-hidden="true"
                            ></i>
                          </div>
                        ) : customerchatlist?.rating?.toFixed() === "4" ? (
                          <div>
                            <i class="fa fa-star " aria-hidden="true"></i>
                            <i class="fa fa-star " aria-hidden="true"></i>
                            <i class="fa fa-star " aria-hidden="true"></i>
                            <i class="fa fa-star " aria-hidden="true"></i>
                            <i
                              class="fa fa-star text-secondary"
                              aria-hidden="true"
                            ></i>
                          </div>
                        ) : customerchatlist?.rating?.toFixed() === "5" ? (
                          <div>
                            <i class="fa fa-star " aria-hidden="true"></i>
                            <i class="fa fa-star " aria-hidden="true"></i>
                            <i class="fa fa-star " aria-hidden="true"></i>
                            <i class="fa fa-star " aria-hidden="true"></i>
                            <i class="fa fa-star " aria-hidden="true"></i>
                          </div>
                        ) : null}
                        {/* <i class="fa fa-star " aria-hidden="true"></i>
                        <i class="fa fa-star " aria-hidden="true"></i>
                        <i class="fa fa-star " aria-hidden="true"></i>
                        <i class="fa fa-star " aria-hidden="true"></i>
                        <i class="fa fa-star " aria-hidden="true"></i> */}
                      </span>

                      <span className="profile-name fz-12">
                        <h5>Out of 5</h5>
                      </span>
                    </div>
                  </div>
                  <div className="inbox_people px-2 py-0  mb-2">
                    <div
                      className="inbox_chat d-flex flex-column mt-1"
                      style={{
                        overflow: "scroll",
                        height: "450px",
                        scrollbarWidth: "none",
                      }}
                    >
                      {customerchatlist?.ratingsDetails?.map((data) => {
                        return (
                          <div className="list_filter border rounded mb-2 mt-2">
                            <div
                              className="chat_list p-3 d-flex gap-2 user_9 seller-list   "
                              id={9}
                              data-name="Devid Jack"
                              data-phone={8801623456678}
                              data-image="https://6valley.6amtech.com/storage/app/public/profile/2022-10-12-63464cd299fc3.png"
                              onclick="message_view('9')"
                            >
                              <div
                                className="chat_people media gap-10"
                                id="chat_people"
                              >
                                <div className="chat_img avatar avatar-sm avatar-circle">
                                  <img
                                    className="avatar-img avatar-circle"
                                    id="profile_image"
                                    src={
                                      data?.userDetails?.user_profile.trim() !==
                                      ""
                                        ? `${process.env.REACT_APP_API_KEY}uploads/${data?.userDetails?.user_profile}`
                                        : "https://6valley.6amtech.com/storage/app/public/profile/2022-04-20-625fa7d513aa5.png"
                                    }
                                    alt="Image Description"
                                  />

                                  {/* <img
                                    src="https://6valley.6amtech.com/storage/app/public/profile/2022-04-20-625fa7d513aa5.png"
                                    id={2}
                                    className="avatar-img avatar-circle"
                                  /> */}
                                  <span className="avatar-satatus avatar-sm-status avatar-status-success" />
                                </div>
                                <div className="chat_ib media-body">
                                  <h5 class="text-capitalize active-text mb-1 mt-2 d-flex align-items-center gap-5 fz-14 mb-2">
                                    <font>
                                      {data?.userDetails?.first_name?.slice(
                                        0,
                                        5
                                      )}{" "}
                                      {data?.userDetails?.last_name?.slice(
                                        0,
                                        5
                                      )}
                                    </font>

                                    <div
                                      style={{ alignItems: "end" }}
                                      class="review-hover position-relative cursor-pointer d-flex gap-5 align-items-center"
                                    >
                                      <i
                                        class="fa fa-ellipsis-h"
                                        aria-hidden="true"
                                      ></i>
                                      <div
                                        class="review-details-popup "
                                        style={{ width: "100px" }}
                                      >
                                        <h6 class="mb-2"></h6>
                                        <div>
                                          <ul class="list-unstyled list-unstyled-py-2  mb-0">
                                            <li
                                              onClick={() => {
                                                idcheck(data?._id);
                                                getvendormessage(data?._id);
                                              }}
                                              class="d-flex align-items-center font-size-sm"
                                            >
                                              <span class="mr-3">Reply</span>
                                              <span class="ml-2">
                                                <i
                                                  class="fa fa-share"
                                                  aria-hidden="true"
                                                ></i>
                                              </span>
                                            </li>
                                            <li
                                              onClick={() => {
                                                report(data?._id);
                                              }}
                                              class="d-flex align-items-start font-size-sm"
                                            >
                                              <span class="mr-3">Report</span>
                                              <span class="ml-2">
                                                <i
                                                  class="fa fa-trash"
                                                  aria-hidden="true"
                                                ></i>
                                              </span>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </h5>
                                </div>
                              </div>
                            </div>
                            <div className="ml-3">
                              <span class="d-flex align-items-center text-warning  fz-14 mb-2">
                                {data?.rating === "1" ? (
                                  <div>
                                    <i
                                      class="fa fa-star "
                                      aria-hidden="true"
                                    ></i>
                                    <i
                                      class="fa fa-star text-secondary"
                                      aria-hidden="true"
                                    ></i>
                                    <i
                                      class="fa fa-star text-secondary"
                                      aria-hidden="true"
                                    ></i>
                                    <i
                                      class="fa fa-star text-secondary"
                                      aria-hidden="true"
                                    ></i>
                                    <i
                                      class="fa fa-star text-secondary"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                ) : data?.rating === "2" ? (
                                  <div>
                                    <i
                                      class="fa fa-star "
                                      aria-hidden="true"
                                    ></i>
                                    <i
                                      class="fa fa-star "
                                      aria-hidden="true"
                                    ></i>
                                    <i
                                      class="fa fa-star text-secondary"
                                      aria-hidden="true"
                                    ></i>
                                    <i
                                      class="fa fa-star text-secondary"
                                      aria-hidden="true"
                                    ></i>
                                    <i
                                      class="fa fa-star text-secondary"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                ) : data?.rating === "3" ? (
                                  <div>
                                    <i
                                      class="fa fa-star "
                                      aria-hidden="true"
                                    ></i>
                                    <i
                                      class="fa fa-star "
                                      aria-hidden="true"
                                    ></i>
                                    <i
                                      class="fa fa-star "
                                      aria-hidden="true"
                                    ></i>
                                    <i
                                      class="fa fa-star text-secondary"
                                      aria-hidden="true"
                                    ></i>
                                    <i
                                      class="fa fa-star text-secondary"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                ) : data?.rating === "4" ? (
                                  <div>
                                    <i
                                      class="fa fa-star "
                                      aria-hidden="true"
                                    ></i>
                                    <i
                                      class="fa fa-star "
                                      aria-hidden="true"
                                    ></i>
                                    <i
                                      class="fa fa-star "
                                      aria-hidden="true"
                                    ></i>
                                    <i
                                      class="fa fa-star "
                                      aria-hidden="true"
                                    ></i>
                                    <i
                                      class="fa fa-star text-secondary"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                ) : data?.rating === "5" ? (
                                  <div>
                                    <i
                                      class="fa fa-star "
                                      aria-hidden="true"
                                    ></i>
                                    <i
                                      class="fa fa-star "
                                      aria-hidden="true"
                                    ></i>
                                    <i
                                      class="fa fa-star "
                                      aria-hidden="true"
                                    ></i>
                                    <i
                                      class="fa fa-star "
                                      aria-hidden="true"
                                    ></i>
                                    <i
                                      class="fa fa-star "
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                ) : null}

                                {/* <i class="fa fa-star " aria-hidden="true"></i>
                                <i class="fa fa-star " aria-hidden="true"></i>
                                <i class="fa fa-star " aria-hidden="true"></i>
                                <i class="fa fa-star " aria-hidden="true"></i>
                                <i class="fa fa-star " aria-hidden="true"></i> */}
                              </span>
                              <h5 className="text-capitalize"
                                style={{
                                  textAlign: "justify",
                                  marginRight: "12px",
                                }}
                              >
                                {data?.comment}
                              </h5>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <section className="col-xl-7 col-lg-7 mt-4 mt-lg-0">
                <div className="card card-body card-chat justify-content-between Chat">
                  <div className="inbox_msg_header d-flex flex-wrap gap-3 justify-content-between align-items-center border px-3 py-2 rounded mb-4">
                    <div className="media align-items-center gap-3">
                      <div className="avatar avatar-sm avatar-circle">
                        {vendorchat?.userImage.trim() !== " " ? (
                          <img
                            className="avatar-img"
                            id="profile_image"
                            src={
                              "https://6valley.6amtech.com/storage/app/public/profile/2022-04-20-625fa7d513aa5.png"
                            }
                            alt="Image Description"
                          />
                        ) : (
                          <img
                            className="avatar-img"
                            id="profile_image"
                            src={`${process.env.REACT_APP_API_KEY}uploads/${vendorchat?.userImage}`}
                            alt="Image Description"
                          />
                        )}
                        <span className="avatar-status avatar-sm-status avatar-status-success" />
                      </div>
                      <div className="chat_ib media-body">
                        <h5
                          className="profile-name mb-1 text-capitalize"
                          id="profile_name"
                        >
                          {vendorchat && vendorchat.userFirst_name ? (
                            <>
                              {vendorchat?.userFirst_name}{" "}
                              {vendorchat?.userLast_name}
                            </>
                          ) : (
                            <>Innt Reviews</>
                          )}
                        </h5>
                        {/* <span className="fz-12" id="profile_phone">
                          {vendorchat && vendorchat  ? (
                            <>{vendorchat?.userFirst_name}</>
                          ) : (
                            <>XXX XXX XXX </>
                          )}
                        </span> */}

                        <span class="d-flex align-items-center text-warning  fz-14 mb-2">
                          {vendorchat?.rating === "1" ? (
                            <div>
                              <i class="fa fa-star " aria-hidden="true"></i>
                              <i
                                class="fa fa-star text-secondary"
                                aria-hidden="true"
                              ></i>
                              <i
                                class="fa fa-star text-secondary"
                                aria-hidden="true"
                              ></i>
                              <i
                                class="fa fa-star text-secondary"
                                aria-hidden="true"
                              ></i>
                              <i
                                class="fa fa-star text-secondary"
                                aria-hidden="true"
                              ></i>
                            </div>
                          ) : vendorchat?.rating === "2" ? (
                            <div>
                              <i class="fa fa-star " aria-hidden="true"></i>
                              <i class="fa fa-star " aria-hidden="true"></i>
                              <i
                                class="fa fa-star text-secondary"
                                aria-hidden="true"
                              ></i>
                              <i
                                class="fa fa-star text-secondary"
                                aria-hidden="true"
                              ></i>
                              <i
                                class="fa fa-star text-secondary"
                                aria-hidden="true"
                              ></i>
                            </div>
                          ) : vendorchat?.rating === "3" ? (
                            <div>
                              <i class="fa fa-star " aria-hidden="true"></i>
                              <i class="fa fa-star " aria-hidden="true"></i>
                              <i class="fa fa-star " aria-hidden="true"></i>
                              <i
                                class="fa fa-star text-secondary"
                                aria-hidden="true"
                              ></i>
                              <i
                                class="fa fa-star text-secondary"
                                aria-hidden="true"
                              ></i>
                            </div>
                          ) : vendorchat?.rating === "4" ? (
                            <div>
                              <i class="fa fa-star " aria-hidden="true"></i>
                              <i class="fa fa-star " aria-hidden="true"></i>
                              <i class="fa fa-star " aria-hidden="true"></i>
                              <i class="fa fa-star " aria-hidden="true"></i>
                              <i
                                class="fa fa-star text-secondary"
                                aria-hidden="true"
                              ></i>
                            </div>
                          ) : vendorchat?.rating === "5" ? (
                            <div>
                              <i class="fa fa-star " aria-hidden="true"></i>
                              <i class="fa fa-star " aria-hidden="true"></i>
                              <i class="fa fa-star " aria-hidden="true"></i>
                              <i class="fa fa-star " aria-hidden="true"></i>
                              <i class="fa fa-star " aria-hidden="true"></i>
                            </div>
                          ) : null}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="messaging">
                    <div className="inbox_msg">
                      <div className="mesgs">
                        <div
                          className="msg_history pr-2 "
                          id="show_msg "
                          ref={chatContainerRef}
                        >
                          <>
                            <div className="incoming_msg d-flex align-items-end gap-1">
                              {/* <div class="">
                                      <img
                                        class="avatar-img user-avatar-image border inbox-user-avatar-25"
                                        style={{
                                          borderRadius: "100%",
                                          height: "32px",
                                          width: "32px",
                                        }}
                                        src={
                                          vendorchat &&
                                          vendorchat.length > 0 &&
                                          vendorchat[0]?.userId?.user_profile.trim() !==
                                            ""
                                            ? `${process.env.REACT_APP_API_KEY}uploads/${vendorchat[0]?.userId?.user_profile}`
                                            : "https://6valley.6amtech.com/storage/app/public/profile/2022-10-12-63464cd299fc3.png"
                                        }
                                        alt="Image Description"
                                      />
                                    </div> */}
                              <div className="received_msg">
                                <div className="received_withd_msg">
                                  <div className="d-flex justify-content-start">
                                    {vendorchat &&
                                      vendorchat?.comment &&
                                      vendorchat?.comment &&
                                      (vendorchat?.comment?.endsWith(".jpg") ||
                                      vendorchat?.comment?.endsWith(".png") ||
                                      vendorchat?.comment?.endsWith(".jpeg") ||
                                      vendorchat?.comment?.endsWith(".bmp") ||
                                      vendorchat?.comment?.endsWith(".webp") ? (
                                        <img
                                          className="rounded"
                                          src={`${process.env.REACT_APP_API_KEY}uploads/${vendorchat?.comment}`}
                                          width={120}
                                          alt="Image"
                                        />
                                      ) : vendorchat?.comment?.endsWith(
                                          ".wmv"
                                        ) ||
                                        vendorchat?.comment?.endsWith(".mp4") ||
                                        vendorchat?.text?.endsWith(".mov") ||
                                        vendorchat?.comment?.endsWith(".avi") ||
                                        vendorchat?.comment?.endsWith(".flv") ||
                                        vendorchat?.comment?.endsWith(".mkv") ||
                                        vendorchat?.comment?.endsWith(
                                          ".mts"
                                        ) ? (
                                        <video
                                          className="rounded"
                                          width={200}
                                          height={180}
                                          controls
                                        >
                                          <source
                                            src={`${process.env.REACT_APP_API_KEY}uploads/${vendorchat?.comment}`}
                                            type="video/mp4"
                                          />
                                          Your browser does not support the
                                          video tag.
                                        </video>
                                      ) : vendorchat?.comment?.endsWith(
                                          ".wmv"
                                        ) ||
                                        vendorchat?.comment?.endsWith(
                                          ".mp3"
                                        ) ? (
                                        <ReactAudioPlayer
                                          className=" text-white rounded px-3 py-2 mb-1"
                                          width={200}
                                          height={180}
                                          src={`${process.env.REACT_APP_API_KEY}uploads/${vendorchat?.comment}`}
                                          controls
                                        />
                                      ) : (
                                        <p
                                          style={{
                                            backgroundColor: "rgb(40 123 132)",
                                            color: "#fff",
                                          }}
                                          className="bg-chat rounded px-3 py-2 mb-1"
                                        >
                                          {vendorchat?.comment}
                                        </p>
                                      ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {vendorchat?.ratingsDetails?.map((data) => {
                              return (
                                <div className="outgoing_msg">
                                  <div className="sent_msg p-1">
                                    <div className="d-flex justify-content-end gap-1">
                                      {data &&
                                        data?.message &&
                                        data?.message &&
                                        (data?.message?.endsWith(".jpg") ||
                                        data?.message?.endsWith(".png") ||
                                        data?.message?.endsWith(".jpeg") ||
                                        data?.message?.endsWith(".bmp") ||
                                        data?.message?.endsWith(".webp") ? (
                                          <img
                                            className="rounded"
                                            src={`${process.env.REACT_APP_API_KEY}uploads/${data?.message}`}
                                            width={120}
                                            alt="Image"
                                          />
                                        ) : data?.message?.endsWith(".wmv") ||
                                          data?.message?.endsWith(".mp4") ||
                                          data?.message?.endsWith(".mov") ||
                                          data?.message?.endsWith(".avi") ||
                                          data?.message?.endsWith(".flv") ||
                                          data?.message?.endsWith(".mkv") ||
                                          data?.message?.endsWith(".mts") ? (
                                          <video
                                            className="rounded bg-c1 text-white rounded px-3 py-2 mb-1"
                                            width={200}
                                            height={180}
                                            controls
                                          >
                                            <source
                                              src={`${process.env.REACT_APP_API_KEY}uploads/${vendorchat?.data?.message}`}
                                              type="video/mp4"
                                            />
                                            Your browser does not support the
                                            video tag.
                                          </video>
                                        ) : data?.message?.endsWith(".wmv") ||
                                          data?.message?.endsWith(".mp3") ? (
                                          <ReactAudioPlayer
                                            className="bg-c1 text-white rounded px-3 py-2 mb-1"
                                            width={200}
                                            height={180}
                                            src={`${process.env.REACT_APP_API_KEY}uploads/${data?.message}`}
                                            controls
                                          />
                                        ) : (
                                          <p className="bg-c1 text-white rounded px-3 py-2 mb-1">
                                            {data?.message}
                                          </p>
                                        ))}

                                      {/* <div class="">
                                          <img
                                            class="avatar-img user-avatar-image border inbox-user-avatar-25"
                                            style={{
                                              borderRadius: "100%",
                                              height: "32px",
                                              width: "32px",
                                            }}
                                            src={
                                              details?.vender_profile
                                                ? `${process.env.REACT_APP_API_KEY}uploads/${details?.vender_profile}`
                                                : `https://6valley.6amtech.com/storage/app/public/seller/2022-10-12-63467dae3ef83.png`
                                            }
                                            alt="Image Description"
                                          />

                                         
                                        </div> */}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        </div>
                        {fileimage ? (
                          fileimage.type.startsWith("image/") ? (
                            <img
                              width={150}
                              height={150}
                              src={URL.createObjectURL(fileimage)}
                              alt="Image"
                            />
                          ) : (
                            <ReactPlayer
                              width={150}
                              height={150}
                              controls
                              url={URL.createObjectURL(fileimage)}
                            />
                          )
                        ) : null}

                        <div class="type_msg">
                          <div class="input_msg_write">
                            <form
                              onSubmit={sendmessage}
                              class="mt-4 chatting-messages-ajax-form"
                              enctype="multipart/form-data"
                            >
                              <input
                                type="hidden"
                                name="_token"
                                value="R2a4JPykxlbGP7QVKC69wpePkrNlz7T8J4fqJX1c"
                                autocomplete="off"
                              />{" "}
                              <input
                                type="hidden"
                                id="current-user-hidden-id"
                                value="9"
                                name="user_id"
                              />
                              <div class="position-relative d-flex">
                                <div>
                                  <label class="py-0 px-3 d-flex align-items-center m-0 cursor-pointer position-absolute top-3">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="22"
                                      height="22"
                                      viewBox="0 0 22 22"
                                      fill="none"
                                    >
                                      <path
                                        d="M18.1029 1.83203H3.89453C2.75786 1.83203 1.83203 2.75786 1.83203 3.89453V18.1029C1.83203 19.2395 2.75786 20.1654 3.89453 20.1654H18.1029C19.2395 20.1654 20.1654 19.2395 20.1654 18.1029V3.89453C20.1654 2.75786 19.2395 1.83203 18.1029 1.83203ZM3.89453 3.20703H18.1029C18.4814 3.20703 18.7904 3.51595 18.7904 3.89453V12.7642L15.2539 9.2277C15.1255 9.09936 14.9514 9.02603 14.768 9.02603H14.7653C14.5819 9.02603 14.405 9.09936 14.2776 9.23136L10.3204 13.25L8.65845 11.5945C8.53011 11.4662 8.35595 11.3929 8.17261 11.3929C7.9957 11.3654 7.81053 11.4662 7.6822 11.6009L3.20703 16.1705V3.89453C3.20703 3.51595 3.51595 3.20703 3.89453 3.20703ZM3.21253 18.1304L8.17903 13.0575L13.9375 18.7904H3.89453C3.52603 18.7904 3.22811 18.4952 3.21253 18.1304ZM18.1029 18.7904H15.8845L11.2948 14.2189L14.7708 10.6898L18.7904 14.7084V18.1029C18.7904 18.4814 18.4814 18.7904 18.1029 18.7904Z"
                                        fill="#1455AC"
                                      ></path>
                                      <path
                                        d="M8.12834 9.03012C8.909 9.03012 9.54184 8.39728 9.54184 7.61662C9.54184 6.83597 8.909 6.20312 8.12834 6.20312C7.34769 6.20312 6.71484 6.83597 6.71484 7.61662C6.71484 8.39728 7.34769 9.03012 8.12834 9.03012Z"
                                        fill="#1455AC"
                                      ></path>
                                    </svg>

                                    <input
                                      onChange={(e) => {
                                        setfileimage(e.target.files[0]);
                                      }}
                                      type="file"
                                      id="msgfilesValue"
                                      className="h-100 position-absolute w-100 "
                                      hidden
                                      //multiple
                                      accept=".mp4, .mts, .mkv, .mov, .flv, .avi, .jpg, .png, .jpeg, .bmp, .tif, .tiff|image/*"
                                    />
                                  </label>
                                </div>

                                <div className="">
                                  <label class="ml-5 py-0 px-3 d-flex align-items-center m-0 cursor-pointer position-absolute top-3">
                                    <i class="fa fa-microphone mt-1"></i>
                                    <input
                                      onChange={(e) => {
                                        setfileimage(e.target.files[0]);
                                      }}
                                      type="file"
                                      className="h-100 position-absolute w-100 "
                                      hidden
                                      accept=".mp3, .mp4 audio/*"
                                    />
                                  </label>
                                </div>

                                <input
                                  value={text}
                                  onChange={(e) => {
                                    settext(e.target.value);
                                  }}
                                  class="form-control w-0 pl-9 mt-1"
                                  id="msgInputValue"
                                  name="message"
                                  type="text"
                                  placeholder="Send a message"
                                  aria-label="Search"
                                ></input>
                                <div class="d-flex align-items-center justify-content-center bg-F1F7FF radius-right-button">
                                  <button
                                    class="aSend bg-transparent outline-0 border-0 shadow-0"
                                    type="submit"
                                    id="msgSendBtn"
                                  >
                                    <img
                                      src="https://6valley.6amtech.com/public/assets/back-end/img/send-icon.png"
                                      alt=""
                                    />
                                  </button>
                                </div>
                              </div>
                              <div class="mt-3 d-flex justify-content-between">
                                <div class="overflow-x-auto pb-2 pt-3 w-100">
                                  <div class="d-flex gap-3 filearray"></div>
                                  <div id="selected-files-container"></div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chatdeliveryman;
