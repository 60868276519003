import React, { useEffect, useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";

const Producsreviews = () => {
  const [productlist, setproductlist] = useState([]);

  const [count, setcount] = useState();
  const [open, setopen] = useState(true);
  const [ProductListfilter, setProductListfilter] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [selectedRatings, setSelectedRatings] = useState([]);
  const itemsPerPage = 10;
  let token = secureLocalStorage.getItem("vendortoken");
  let venderIdd = secureLocalStorage.getItem("vendorid");
  useEffect(() => {
    setProductListfilter(productlist);
  }, [productlist]);
  const handleFilter = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    const result = productlist.filter((item) =>
      item?.product_name?.toLowerCase().includes(searchTerm)
    );
    setProductListfilter(result);
    setActivePage(1);
  };

  const handleRatingFilter = (rating) => {
    let filteredList = productlist;
    if (selectedRatings?.includes(rating)) {
      filteredList = filteredList?.filter((item) => item?.rating !== rating);
      setSelectedRatings(selectedRatings?.filter((item) => item !== rating));
    } else {
      filteredList = filteredList?.filter((item) => item?.rating === rating);
      setSelectedRatings([...selectedRatings, rating]);
    }
    setProductListfilter(filteredList);
    setActivePage(1);
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  useEffect(() => {
    getproductlist();
  }, [0]);
  let getproductlist = () => {
    const data = {
      venderId: venderIdd,
    };
    const options = {
      headers: {
        token: token,
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/productReviews_list`,
        data,
        options
      )
      .then((res) => {
     
        setcount(res?.data?.data?.length);
        setproductlist(res?.data?.data);
      })
      .catch((error) => {});
  };

  const handleclick = () => {
    setopen(!open);
  };

  const renderProductData = (data, index) => {
    const adjustedIndex = (activePage - 1) * itemsPerPage + index + 1;
    return (
      <tr>
        <td>{adjustedIndex}</td>
       
       {data?.raters > 0 ? <td className="text-capitalize">
          {" "}
          <Link
            onClick={() => {
              secureLocalStorage.setItem("productid", data?.productId);
            }}
            to="/Chatdeliveryman"
            className="media align-items-center gap-2 w-max-content"
          >
            <img
              src={
                data?.image
                  ? `${process.env.REACT_APP_API_KEY}uploads/${data?.image}`
                  : "https://6valley.6amtech.com/storage/app/public/product/thumbnail/2023-06-13-64883db39dcbb.png"
              }
              className="avatar border"
              alt
            />

            {data?.product_name && data?.product_name.length > 20
              ? data?.product_name?.slice(0, 20) + "..."
              : data?.product_name}
          </Link>
        </td> : <td className="text-capitalize">
          {" "}
          <Link
            onClick={() => {
              secureLocalStorage.setItem("productid", data?.productId);
            }}
            to="/Reviews"
            className="media align-items-center gap-2 w-max-content"
          >
            <img
              src={
                data?.image
                  ? `${process.env.REACT_APP_API_KEY}uploads/${data?.image}`
                  : "https://6valley.6amtech.com/storage/app/public/product/thumbnail/2023-06-13-64883db39dcbb.png"
              }
              className="avatar border"
              alt
            />

            {data?.product_name && data?.product_name.length > 20
              ? data?.product_name?.slice(0, 20) + "..."
              : data?.product_name}
          </Link>
        </td>}
        
        <td className="text-capitalize">{data?.productType}</td>
        <td>
          <label className="badge badge-soft-info mb-0">
            <span className="fz-12 d-flex align-items-center gap-1">
              {data?.rating} <i class="fa fa-star " aria-hidden="true"></i>
            </span>
          </label>
        </td>
        <td>{data?.raters} reviews</td>
      </tr>
    );
  };
  return (
    <div className="container">
      {/* <Header /> */}
      <div
        className="container row"
        style={{ paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px" }}
      >
        <div className="col-lg-2 col-md-3" style={{ paddingLeft: "0px" }}>
          {/* <Sidebar /> */}
        </div>

        <div
          className="col-lg-10 col-md-9"
          style={{ paddingLeft: "30px", marginTop: "60px" }}
        >
          <div className="mt-3">
            <div className="mb-3" >
              <h2 className="h1 mb-0 text-capitalize">
                <img
                  width={20}
                  src="https://6valley.6amtech.com/public/assets/back-end/img/product-review.png"
                  className="mb-1 mr-1"
                  alt
                />
                Reviews
              </h2>
            </div>
            <div className="card card-body">
              <div className="row border-bottom pb-3 align-items-center mb-20" >
                <div  className="col-sm-4 col-md-6 col-lg-8 mb-2 mb-sm-0">
                  <h5 className="text-capitalize mb-0 d-flex gap-1">
                    Reviews
                    <span className="badge badge-soft-dark radius-50 fz-12">
                      {count}
                    </span>
                  </h5>
                </div>
              </div>
              <form > 
                <div className="row" >
                  <div className="col-sm-12 col-md-4 col-lg-4" >
                    <form method="GET">
                      <div className="input-group input-group-merge input-group-custom">
                        <input
                          onChange={handleFilter}
                          type="search"
                          name="search"
                          className="form-control"
                          placeholder="Search"
                        />
                        <button type="submit" className="btn btn--primary">
                          Search
                        </button>
                      </div>
                    </form>
                  </div>
                  {/* <div className="col-md-4 col-lg-4 col-sm-12">
                    <div className="form-group">
                      
                      <p
                        className="form-control btn btn "
                        onClick={handleclick}
                      >
                        Select rating filter
                      </p>
                      {!open ? (
                        <div>
                          <p className="ml-5 mt-2">
                            <input
                              style={{
                                width: "18px",
                                height: "18px",
                                marginRight: "5px",
                                verticalAlign: "middle",
                              }}
                              type="checkbox"
                              onChange={() => handleRatingFilter(5)}
                              checked={selectedRatings.includes(5)}
                            />
                            <font>
                              <i className="fa fa-star" aria-hidden="true"></i>
                              &nbsp;
                              <i className="fa fa-star" aria-hidden="true"></i>
                              &nbsp;
                              <i className="fa fa-star" aria-hidden="true"></i>
                              &nbsp;
                              <i className="fa fa-star" aria-hidden="true"></i>
                              &nbsp;
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </font>
                            <span className="caret"></span>
                          </p>

                          <p className="ml-5 mt-2">
                            <input
                              style={{
                                width: "18px",
                                height: "18px",
                                marginRight: "5px",
                                verticalAlign: "middle",
                              }}
                              type="checkbox"
                              onChange={() => handleRatingFilter(4)}
                              checked={selectedRatings.includes(4)}
                            />
                            <font>
                              <i className="fa fa-star" aria-hidden="true"></i>
                              &nbsp;
                              <i className="fa fa-star" aria-hidden="true"></i>
                              &nbsp;
                              <i className="fa fa-star" aria-hidden="true"></i>
                              &nbsp;
                              <i className="fa fa-star" aria-hidden="true"></i>
                              &nbsp;
                              <i
                                className="fa fa-star-o"
                                aria-hidden="true"
                              ></i>
                            </font>
                            <span className="caret"></span>
                          </p>
                          <p className="ml-5 mt-2">
                            <input
                              style={{
                                width: "18px",
                                height: "18px",
                                marginRight: "5px",
                                verticalAlign: "middle",
                              }}
                              type="checkbox"
                              onChange={() => handleRatingFilter(3)}
                              checked={selectedRatings.includes(3)}
                            />
                            <font>
                              <i className="fa fa-star" aria-hidden="true"></i>
                              &nbsp;
                              <i className="fa fa-star" aria-hidden="true"></i>
                              &nbsp;
                              <i className="fa fa-star" aria-hidden="true"></i>
                              &nbsp;
                              <i
                                className="fa fa-star-o"
                                aria-hidden="true"
                              ></i>
                              &nbsp;
                              <i
                                className="fa fa-star-o"
                                aria-hidden="true"
                              ></i>
                            </font>
                            <span className="caret"></span>
                          </p>
                          <p className="ml-5 mt-2">
                            <input
                              style={{
                                width: "18px",
                                height: "18px",
                                marginRight: "5px",
                                verticalAlign: "middle",
                              }}
                              type="checkbox"
                              onChange={() => handleRatingFilter(2)}
                              checked={selectedRatings.includes(2)}
                            />
                            <font>
                              <i className="fa fa-star" aria-hidden="true"></i>
                              &nbsp;
                              <i className="fa fa-star" aria-hidden="true"></i>
                              &nbsp;
                              <i
                                className="fa fa-star-o"
                                aria-hidden="true"
                              ></i>
                              &nbsp;
                              <i
                                className="fa fa-star-o"
                                aria-hidden="true"
                              ></i>
                              &nbsp;
                              <i
                                className="fa fa-star-o"
                                aria-hidden="true"
                              ></i>
                            </font>
                            <span className="caret"></span>
                          </p>
                          <p className="ml-5 mt-2">
                            <input
                              style={{
                                width: "18px",
                                height: "18px",
                                marginRight: "5px",
                                verticalAlign: "middle",
                              }}
                              type="checkbox"
                              onChange={() => handleRatingFilter(1)}
                              checked={selectedRatings.includes(1)}
                            />
                            <font>
                              <i className="fa fa-star" aria-hidden="true"></i>
                              &nbsp;
                              <i
                                className="fa fa-star-o"
                                aria-hidden="true"
                              ></i>
                              &nbsp;
                              <i
                                className="fa fa-star-o"
                                aria-hidden="true"
                              ></i>
                              &nbsp;
                              <i
                                className="fa fa-star-o"
                                aria-hidden="true"
                              ></i>
                              &nbsp;
                              <i
                                className="fa fa-star-o"
                                aria-hidden="true"
                              ></i>
                            </font>
                            <span className="caret"></span>
                          </p>

                          <button
                            onClick={handleclick}
                            id="filter"
                            type="submit"
                            className="btn btn--primary btn-block mt-5 mb-3  filter"
                          >
                            Apply filter
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </div> */}
                </div>
              </form>
            </div>
            <div className="card mt-20 mb-20">
              <div className="table-responsive datatable-custom">
                {ProductListfilter?.length > 0 ? (
                  <table
                    style={{ textAlign: "left" }}
                    className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100"
                  >
                    <thead className="thead-light thead-50 text-capitalize">
                      <tr>
                        <th>SL</th>
                        <th>Product</th>
                        <th>Product Type</th>
                        <th>Rating</th>
                        <th>Review</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ProductListfilter.slice(
                        (activePage - 1) * itemsPerPage,
                        activePage * itemsPerPage
                      ).map((data, index) => renderProductData(data, index))}
                    </tbody>
                  </table>
                ) : (
                  <div class="text-center p-4">
                    <img
                      class="mb-3 w-160"
                      src="https://6valley.6amtech.com/public/assets/back-end/img/empty-state-icon/default.png"
                      alt="Image Description"
                    />
                    <p class="mb-0 order-stats__subtitle">No review found</p>
                  </div>
                )}
                <div className="d-flex justify-content-center mt-4">
                  {ProductListfilter.length > itemsPerPage && (
                    <Pagination
                      activePage={activePage}
                      itemsCountPerPage={itemsPerPage}
                      totalItemsCount={ProductListfilter.length}
                      pageRangeDisplayed={5}
                      onChange={handlePageChange}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  )}
                </div>
              </div>
              <div className="table-responsive mt-4">
                <div className="px-4 d-flex justify-content-lg-end"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Producsreviews;
