import axios from "axios";
import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Navigate, json, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

const Addstaff = () => {
  const [fname, setfname] = useState();
  const [phone, setphone] = useState();
  const [lanme, setlanme] = useState();
  const [dob, setdob] = useState();
  const [position, setposition] = useState();

  const [email, setemail] = useState();
  const [staff_image, setstaff_image] = useState();
  const [password, setpassword] = useState();
  const [confirmpassword, setconfirmpassword] = useState();
  const Navigate = useNavigate();

  const [checkedValues, setCheckedValues] = useState([]);

  const [CategoryList, setCategoryList] = useState();
  const [serviceName, setServiceName] = useState("");
  let serviceTypeId = secureLocalStorage.getItem("serviceTypeeee");


  const getCurrentDate = () => {
    const today = new Date();
    const yyyy = (today.getFullYear() -15);
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };
  useEffect(() => {
    getCategories();
  }, [0]);

  const getCategories = () => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}vender/api/maincategory_list`)
      .then((res) => {
        setCategoryList(res.data.data);
      })
      .catch((error) => {});
  };

  
  useEffect(() => {
    if (CategoryList) {
      const matchedCategory = CategoryList?.find(
        (category) => category?._id === serviceTypeId
      );
      if (matchedCategory) {
        setServiceName(matchedCategory?.maincategory_englishName);
      }
    }
  }, [CategoryList, serviceTypeId]);

  const [checkboxOptions] = useState([
    { label: "Orders", value: "Orders" },
    { label: "Products", value: "Products" },
    { label: "Coupons", value: "Coupons" },
    { label: "Offers", value: "Offers" },
    { label: "Refunds", value: "Refunds" },
    { label: "QR Codes", value: "QR Codes" },

    { label: "Reviews", value: "Reviews" },
    { label: "Chats", value: "Chats" },

    { label: "Wallet", value: "Wallet" },
    { label: "Bank and mobile money", value: "Bank and mobile money" },

    { label: "Subscription plans", value: "Subscription plans" },
  ]);

  const generateRestrictionsObject = (checkedValues) => {
    const restrictions = {};

    if (checkedValues.includes("Products")) {
      if (serviceName === "Innt") {
        restrictions.products = ["/Products List", "/Add New Products"];
      } else {
        restrictions.products = ["/Products List", "/Add New Product"];
      }
    }

    // if (checkedValues.includes("Products")) {
    //   restrictions.products = ["/Products List", "/Add New Products"];
    // }
    if (checkedValues.includes("Orders")) {
      restrictions.orders = [
        "/All Orders",
        "/Confirm Orders",
        "/Pending Orders",
        "/Shipped",
        "/Delivered Orders",
        "/Not Delivered",
        "/Canceled Orders",
        "/Rejected Orders",
        "/Returned Orders",
      ];
    }
    if (checkedValues.includes("Coupons")) {
      restrictions.coupons = ["/Coupon"];
    }
    if (checkedValues.includes("Offers")) {
      restrictions.offers = ["/Offer"];
    }
    if (checkedValues.includes("Refunds")) {
      restrictions.refunds = [
        "/Pending Refund",
        "/Approved Refund",
        "/Refunded",
        "/Rejected Refund",
      ];
    }
    if (checkedValues.includes("QR Codes")) {
      restrictions.qr_codes = ["/QR Codes"];
    }

    if (checkedValues.includes("Reviews")) {
      restrictions.reviews = ["/Reviews"];
    }
    if (checkedValues.includes("Chats")) {
      restrictions.chats = ["/Customers", "/Deliverymen"];
    }
    if (checkedValues.includes("Wallet")) {
      restrictions.wallet = ["/Withdraw"];
    }
    if (checkedValues.includes("Bank and mobile money")) {
      restrictions.bank_and_mobile_money = ["/My Bank info"];
    }

    if (checkedValues.includes("Subscription plans")) {
      restrictions.subscription_plans = ["/subscription_plans"];
    }

    return restrictions;
  };

  const handleCheckboxChange = (value) => {
    if (checkedValues.includes(value)) {
      setCheckedValues(checkedValues.filter((item) => item !== value));
    } else {
      setCheckedValues([...checkedValues, value]);
    }
  };

  const selectedValuesString = checkedValues.join(", ");

  let shopid = secureLocalStorage.getItem("shopidofvendor");

  const staffdata = (e) => {
    e.preventDefault();
    if (password != confirmpassword) {
      toast.error("Password & Confirm Password Not Matched");
      return;
    }
    const formData = new FormData();
    formData.append("shopId", shopid);
    formData.append("fname", fname);
    formData.append("phone", phone);
    formData.append("lname", lanme);
    formData.append("dob", dob);
    formData.append("position", position);
    formData.append("password", password);

    formData.append("email", email);
    formData.append("staff_image", staff_image);
    //formData.append("restrictons", checkedValues);
    const restrictions = generateRestrictionsObject(checkedValues);
    formData.append("restrictions", JSON.stringify(restrictions));

    axios
      .post(`${process.env.REACT_APP_API_KEY}vender/api/createStaff`, formData)
      .then((res) => {
        toast.success(res.data.message);
        setTimeout(() => {
          Navigate("/Staffs");
        }, 3000);
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data.message);
        } else {
          toast.error("An error occurred. Please try again later.");
        }
      });
  };
  return (
    <div className="container">
      <Toaster />
      {/* <Header /> */}
      <div
        className="container row"
        style={{ paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px" }}
      >
        <div className="col-lg-2 col-md-3" style={{ paddingLeft: "0px" }}>
          {/* <Sidebarr /> */}
        </div>

        <div
          className="col-lg-10 col-md-9"
          style={{ paddingLeft: "30px", marginTop: "60px" }}
        >
          <div className=" mt-3">
            <div className="d-flex justify-content-between align-items-center gap-3 mb-3">
              <h2 className="h1 mb-1 text-capitalize d-flex align-items-center gap-2 mt-3 pl-3">
                <img
                  src="https://6valley.6amtech.com/public/assets/back-end/img/add-new-seller.png"
                  alt=""
                />{" "}
                Add New Staff
              </h2>
            </div>

            <div className="col-md-12">
              <form onSubmit={staffdata}>
                <div class="card" style={{borderColor:'rgb(188, 189, 190)'}}>
                  <div class="card-body">
                    <h5 class="mb-0 page-header-title text-capitalize d-flex align-items-center gap-2 border-bottom pb-3 mb-3">
                      <i class="fa fa-user" aria-hidden="true"></i>
                      Personal information
                    </h5>
                    <div class="row">
                      <div class="col-md-6 col-lg-6 col-sm-12">
                        <div class="form-group">
                          <label for="name" class="title-color">
                            First name<span className="text-danger">*</span>
                          </label>
                          <input
                            value={fname}
                            onChange={(e) => {
                              setfname(e.target.value);
                            }}
                            type="text"
                            name="name"
                            class="form-control"
                            id="name"
                            placeholder="First Name"
                            required
                          />
                        </div>
                        <div class="form-group">
                          <label for="name" class="title-color">
                            Last name<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            name="name"
                            class="form-control"
                            id="name"
                            placeholder="Last Name"
                            value={lanme}
                            onChange={(e) => {
                              setlanme(e.target.value);
                            }}
                            required
                          />
                        </div>
                        <div class="form-group">
                          <label for="phone" class="title-color">
                            Phone<span className="text-danger">*</span>
                          </label>
                          <div class="mb-3">
                            <div class="">
                              <input
                                
                                required
                                type="text"
                                class="form-control"
                                value={phone}
                                onChange={(e) => {
                                  setphone(e.target.value);
                                }}
                                name="phone"
                                placeholder="Enter Phone number"
                              />
                            </div>
                          </div>
                        </div>

                        <div class="form-group">
                          <label for="phone" class="title-color">
                            Date of birth<span className="text-danger">*</span>
                          </label>
                           <div class="mb-3">
                            <div class="">
                              {/* <input
                                required
                                type="date"
                                class="form-control"
                                value={dob}
                                onChange={(e) => {
                                  setdob(e.target.value);
                                }}
                                name="date"
                                placeholder="Enter Phone number"
                              /> */}
                               <input
                            type="date"
                            className="form-control form-control-user"
                            value={dob}
                            onChange={(e) => {
                              setdob(e.target.value);
                            }}
                            max={getCurrentDate()}
                            placeholder="Enter your date of birth"
                            required
                          />
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <label for="role_id" class="title-color">
                            Position<span className="text-danger">*</span>
                          </label>
                          <input placeholder="Please enter position"
                            required
                            value={position}
                            onChange={(e) => {
                              setposition(e.target.value);
                            }}
                            class="form-control"
                            name="role_id"
                            id="role_id"
                          >  
                            
                            
                          </input>
                        </div>
                        <div class="form-group">
                          <div class="text-center mb-3">
                            {staff_image ? (
                              <img
                                class="upload-img-view"
                                id="viewer"
                                src={URL.createObjectURL(staff_image)}
                                alt=""
                              />
                            ) : (
                              <img
                                class="upload-img-view"
                                id="viewer"
                                src="https://6valley.6amtech.com/public/assets/back-end/img/400x400/img2.jpg"
                                alt=""
                              />
                            )}
                          </div>
                          <label for="customFileUpload" class="title-color">
                            Staff image
                          </label>
                          <span className="text-danger">*</span>
                          <div class="form-group">
                            <div class="custom-file text-left">
                              <input
                                onChange={(e) => {
                                  setstaff_image(e.target.files[0]);
                                }}
                                type="file"
                                name="image"
                                id="custom-file-upload"
                                class="custom-file-input image-input"
                                data-image-id="viewer"
                                accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                                required
                              />
                              <label
                                class="custom-file-label"
                                for="custom-file-upload"
                              >
                                Choose file
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 col-lg-6 col-sm-12">
                        <label className="title-color">
                          Restrictions: {checkedValues.join(", ")}
                        </label>
                        <div className="form-group mb-0">
                          {checkboxOptions?.map((option, index) => (
                            <div
                              key={index}
                              className="border rounded px-2 py-3 d-flex justify-content-between mb-1"
                            >
                              <h5 className="mb-1 d-flex gap-1 c1">
                                {option.label}
                              </h5>
                              <div className="position-relative">
                                <label className="switcher">
                                  <input
                                    type="checkbox"
                                    className="switcher_input toggle-switch-message"
                                    name={option.value}
                                    checked={checkedValues.includes(
                                      option.value
                                    )}
                                    onChange={() =>
                                      handleCheckboxChange(option.value)
                                    }
                                  />
                                  <span className="switcher_control"></span>
                                </label>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card mt-3 mb-3" style={{borderColor:'rgb(188, 189, 190)'}}>
                  <div className="card-body">
                    <h5 className="mb-0 page-header-title d-flex align-items-center gap-2 border-bottom pb-3 mb-3">
                      <i class="fa fa-user" aria-hidden="true"></i>
                      Account Information
                    </h5>
                    <div className="row">
                      <div className="col-md-6 col-xxl-6 col-lg-4 col-xl-4 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="email" className="title-color">
                            Email<span className="text-danger">*</span>
                          </label>
                          <input
                            type="email"
                            name="email"
                            value={email}
                            onChange={(e) => {
                              setemail(e.target.value);
                            }}
                            className="form-control"
                            id="email"
                            placeholder="Enter Email"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-xxl-6 col-lg-4 col-xl-4 col-sm-12">
                        <div className="form-group">
                          <label
                            htmlFor="user_password"
                            className="title-color d-flex align-items-center"
                          >
                            Password<span className="text-danger">*</span>
                          </label>
                          <div className="input-group input-group-merge">
                            <input
                              minLength={8}
                              value={password}
                              onChange={(e) => {
                                setpassword(e.target.value);
                              }}
                              type="password"
                              className="js-toggle-password form-control password-check"
                              name="password"
                              required
                              id="user_password"
                              placeholder="Password minimum 8 characters"
                            />
                            <div
                              id="changePassTarget"
                              className="input-group-append"
                            >
                              <a
                                className="input-group-text"
                                href="javascript:"
                              >
                                <i
                                  id="changePassIcon"
                                  className="tio-hidden-outlined"
                                />
                              </a>
                            </div>
                          </div>
                          <span className="text-danger mx-1 password-error" />
                        </div>
                      </div>
                      <div className="col-md-6 col-xxl-6 col-lg-4 col-xl-4 col-sm-12">
                        <div className="form-group">
                          <label
                            htmlFor="confirm_password"
                            className="title-color"
                          >
                            Confirm password
                            <span className="text-danger">*</span>
                          </label>
                          <div className="input-group input-group-merge">
                            <input
                              value={confirmpassword}
                              onChange={(e) => {
                                setconfirmpassword(e.target.value);
                              }}
                              type="password"
                              className="js-toggle-password form-control"
                              name="confirm_password"
                              required
                              id="confirm_password"
                              placeholder="Confirm password"
                            />
                            <div
                              id="changeConfirmPassTarget"
                              className="input-group-append"
                            >
                              <a
                                className="input-group-text"
                                href="javascript:"
                              >
                                <i
                                  id="changeConfirmPassIcon"
                                  className="tio-hidden-outlined"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end gap-3">
                      <button type="submit" className="btn btn--primary px-4">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Addstaff;
