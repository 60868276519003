import React, { useEffect, useState } from "react";
import Header from "../Header";

import "../sidebar.css";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import Sidebarr from "../Sidebar";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import Pagination from "react-js-pagination";
const Promotions = () => {
  const [expire_date, setexpire_date] = useState();
  const [start_date, setstart_date] = useState();

  const [appliesType, setappliesType] = useState();
  const [appliesCoupon, setappliesCoupon] = useState();

  const [sameUser_discountLimit, setsameUser_discountLimit] = useState();

  const [coupon_list, setcoupon_list] = useState([]);

  const [count, setcount] = useState();

  const [activePage, setActivePage] = useState(1);
  const itemsPerPage = 10;

  const [selectedProductId, setSelectedProductId] = useState("");

  const handleProductClick = (productId) => {
    setSelectedProductId((prevSelectedId) =>
      prevSelectedId === productId ? null : productId
    );
  };

  let token = secureLocalStorage.getItem("vendortoken");
  let venderIdd = secureLocalStorage.getItem("vendorid");
  const [offerType, setOfferType] = useState("");

  const handleRadioChange = (event) => {
    setOfferType(event.target.value);
  };

  const handleInputChange = (e, field) => {
    const value = e.target.value;
    if (offerType.startsWith("Percentage")) {
      setOfferType(`Percentage ${value}`);
    } else if (offerType.startsWith("Buy")) {
      const parts = offerType.split(" ");
      if (field === "buy") {
        setOfferType(`Buy ${value} Get ${parts[3] || ""}`);
      } else if (field === "get") {
        setOfferType(`Buy ${parts[1] || ""} Get ${value}`);
      }
    }
  };
  const addcoupans = (e) => {
    e.preventDefault();

    const formData = {
      vendorId: venderIdd,
      select_directly: appliesType,
      offerType: offerType,
      limitPeruser: sameUser_discountLimit,

      productId: selectedProductId ? selectedProductId : null,

      end_date: expire_date,
      start_date: start_date,
    };

    axios
      .post(`${process.env.REACT_APP_API_KEY}vender/api/addOffer`, formData)
      .then((res) => {
        getcoupon_list();
        toast.success(
          `Your offer has been submitted and is waiting for approval`
        );
      })
      .catch((error) => {});

    setappliesType("");
    setappliesCoupon("");
    setsameUser_discountLimit("");
    setexpire_date("");
    setstart_date("");
    setSelectedProductId("");
    setOfferType("");

    setstatus(false);
  };

  useEffect(() => {
    getcoupon_list();
  }, [0]);

  let getcoupon_list = () => {
    let data = {
      vendorId: venderIdd,
    };

    let options = {
      headers: {
        token: token,
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/offerList`,
        data,
        options
      )
      .then((res) => {

        setcount(res?.data?.data?.length);
        setcoupon_list(res.data.data);
      })
      .catch((error) => {});
  };

  let deletecoupan = (item) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Offer!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let deletecoupannimage = () => {
          let bannerdata = {
            offerId: item,
          };

          let options = {
            headers: {
              token: token,
            },
          };
          axios
            .post(
              `${process.env.REACT_APP_API_KEY}vender/api/deleteOffer`,
              bannerdata,
              options
            )
            .then((res) => {
              getcoupon_list();
            })
            .catch((error) => {});
        };
        deletecoupannimage();
        swal("Poof! Your Offer  has been deleted!", {
          icon: "success",
        });
      } else {
        swal("Your Offer is safe!");
      }
    });
  };

  const activecoupan = (item) => {
    const Data = {
      offerId: item,
    };

    let options = {
      hraders: {
        token: token,
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/offer_on_off`,
        Data,
        options
      )
      .then((res) => {
        toast.success(res.data.message);
        getcoupon_list();
      })
      .catch((error) => {});
  };

  const [productlist, setproductlist] = useState();
  const [productFilter, setProductFilter] = useState("");
  const filterProducts = (products, filter) => {
    return products?.filter((product) =>
      product?.product_name?.toLowerCase().includes(filter.toLowerCase())
    );
  };
  useEffect(() => {
    getproductlist();
  }, [0]);
  let getproductlist = () => {
    const data = {
      venderId: venderIdd,
    };
    const options = {
      headers: {
        token: token,
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/productList`,
        data,
        options
      )
      .then((res) => {
        setproductlist(res?.data?.data);
      })
      .catch((error) => {});
  };

  const [categoryMenuVisible, setCategoryMenuVisible] = useState(true);
  const handleDropdownToggle = () => {
    setCategoryMenuVisible(!categoryMenuVisible);
  };

  const [Coupanfilter, setCoupanfilter] = useState([]);
  useEffect(() => {
    setCoupanfilter(coupon_list);
  }, [coupon_list]);

  const handleFilter = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    const result = coupon_list.filter((item) =>
      item?.productId?.product_name?.toLowerCase().includes(searchTerm)
    );
    setCoupanfilter(result);
    setActivePage(1);
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };
  const [status, setstatus] = useState(false);

  const renderCoupanData = (data, index) => {
    const adjustedIndex = (activePage - 1) * itemsPerPage + index + 1;
    return (
      <tr>
        <td>{adjustedIndex}</td>

        <td className="text-capitalize">
          {data?.productId?.image1 ? 
          <Link
            onClick={() => {
              secureLocalStorage.setItem("productid", data?.productId?._id);
            }}
            to="/productdetails"
            className="media align-items-center gap-2 w-max-content"
          >
            <img
              src={
                data?.productId?.image1
                  ? `${process.env.REACT_APP_API_KEY}uploads/${data?.productId?.image1}`
                  : "https://6valley.6amtech.com/storage/app/public/product/thumbnail/2023-06-13-64883db39dcbb.png"
              }
              className="avatar border"
              alt
            />
            <span className="media-body title-color hover-c1">
              {data?.productId?.product_name &&
              data?.productId?.product_name.length > 20
                ? data?.productId?.product_name?.slice(0, 20) + "..."
                : data?.productId?.product_name}
            </span>
          </Link>  : <Link
            onClick={() => {
              secureLocalStorage.setItem("productid", data?.productId?._id);
            }}
            
            className="media align-items-center gap-2 w-max-content"
          >
            <img
              src={
                data?.productId?.image1
                  ? `${process.env.REACT_APP_API_KEY}uploads/${data?.productId?.image1}`
                  : "https://6valley.6amtech.com/storage/app/public/product/thumbnail/2023-06-13-64883db39dcbb.png"
              }
              className="avatar border"
              alt
            />
            <span className="media-body title-color hover-c1">
              {data?.productId?.product_name &&
              data?.productId?.product_name.length > 20
                ? data?.productId?.product_name?.slice(0, 20) + "..."
                : data?.productId?.product_name}
            </span>
          </Link>}
        </td>

        <td className="text-capitalize">
          <div>{data?.offerType}</div>
        </td>
        <td className="text-capitalize">{data?.select_directly}</td>
        <td>
          <div className="d-flex flex-wrap gap-1">
            <span>{data?.start_date} - </span>
            <span>{data?.end_date}</span>
          </div>
        </td>
        <td>
          <span>
            Limit: <strong>{data?.limitPeruser}</strong>
          </span>
        </td>

        {data?.offer_status == 0 ? (
          <td>
            <form className="banner_status_form">
              <input type="hidden" />
              <input type="hidden" name="id" />
              <label className="switcher">
                <input
                  type="checkbox"
                  className="switcher_input"
                  name="status"
                  onChange={() => activecoupan(data?._id)}
                />
                <span className="switcher_control" />
              </label>
            </form>
          </td>
        ) : (
          <td>
            <form className="banner_status_form">
              <input type="hidden" />
              <input type="hidden" name="id" />
              <label className="switcher">
                <input
                  id="coupon_status9"
                  name="status"
                  defaultValue={1}
                  defaultChecked
                  type="checkbox"
                  className="switcher_input"
                  onChange={() => activecoupan(data?._id)}
                />
                <span className="switcher_control" />
              </label>
            </form>
          </td>
        )}

        <td>
          <div className="d-flex gap-10 justify-content-center">
            {/* <Link
              onClick={() => {
                secureLocalStorage.setItem("promotion_id", data?._id);
              }}
              className="btn btn-outline--primary btn-sm edit"
              to="#"
            >
              <i class="fa fa-pencil" aria-hidden="true"></i>
            </Link> */}
            <a
              onClick={() => {
                deletecoupan(data?._id);
              }}
              className="btn btn-outline-danger btn-sm delete"
              href="javascript:"
              onclick="form_alert('coupon-9','Want to delete this coupon ?')"
              title="Delete"
            >
              <i className="fa fa-trash-o" aria-hidden="true" />
            </a>
            <form
              action="https://6valley.6amtech.com/seller/coupon/delete/9"
              method="post"
              id="coupon-9"
            >
              <input
                type="hidden"
                name="_token"
                defaultValue="YFPrIbFYlUsgcs6Ht4EEyCysWGBq8lmpLaBUW0q6"
              />{" "}
              <input type="hidden" name="_method" defaultValue="delete" />{" "}
            </form>
          </div>
        </td>
      </tr>
    );
  };
  return (
    <div className="container">
      <Toaster />
      {/* <Header /> */}
      <div
        className="container row"
        style={{ paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px" }}
      >
        <div className="col-lg-2 col-md-3" style={{ paddingLeft: "0px" }}>
          {/* <Sidebarr /> */}
        </div>

        <div
          className="col-lg-10 col-md-9"
          style={{ paddingLeft: "30px", marginTop: "60px" }}
        >
          {/* <br />
          <br /> */}

          <div className="mt-3">
            <div className="mb-3">
              <h2 className="h1 mb-0 text-capitalize d-flex align-items-center gap-2">
                <img
                  src="https://6valley.6amtech.com/public/assets/back-end/img/coupon_setup.png"
                  alt=""
                />
                Offer setup
              </h2>
            </div>
            <div className="row">
              <div className="col-sm-12 col-lg-12 mb-3 mb-lg-2">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={addcoupans}>
                      <input
                        type="hidden"
                        name="_token"
                        defaultValue="YFPrIbFYlUsgcs6Ht4EEyCysWGBq8lmpLaBUW0q6"
                      />
                      <div className="row">
                        <div className="col-md-6 col-lg-4 form-group">
                          <label htmlFor="name" className="title-color font-weight-medium d-flex">Select directory <span className="text-danger">*</span></label>
                          <select
                            onChange={(e) => {
                              setappliesType(e.target.value);
                              setstatus(false);
                            }}
                            className="form-control"
                            name="coupon_type"
                            required
                          >
                            <option disabled selected value="">
                              Select
                            </option>
                            <option value="Store front">Store front</option>
                            <option value="Specific product">
                              <li className="btn btn--info">
                                <input
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    marginRight: "5px",
                                    verticalAlign: "middle",
                                  }}
                                  type="checkbox"
                                  onClick={() => {
                                    setappliesCoupon(
                                      "Select specific products"
                                    );
                                    handleDropdownToggle();
                                  }}
                                />
                                <span
                                  onClick={() => {
                                    handleDropdownToggle();
                                    setappliesCoupon(
                                      "Select specific products"
                                    );
                                  }}
                                >
                                  Select specific products
                                </span>
                              </li>
                            </option>
                          </select>

                          {appliesType === "Infront" ? null : appliesType ===
                            "Specific product" ? (
                            <>
                              <ul
                                style={{
                                  background: "#fffefd",
                                  textAlign: "left",
                                }}
                                className="btn btn-secondary"
                              >
                                {status === false ? (
                                  <div>
                                    Select product{" "}
                                    <input
                                      placeholder="Search in products"
                                      type="text"
                                      id="categoryFilter"
                                      className="form-control"
                                      value={productFilter}
                                      onChange={(e) =>
                                        setProductFilter(e.target.value)
                                      }
                                    />
                                    <div
                                      style={{
                                        overflow: "scroll",
                                        height: "220px",
                                        scrollbarWidth: "none",

                                        background: "#fffefd",
                                      }}
                                    >
                                      {filterProducts(
                                        productlist,
                                        productFilter
                                      ).length === 0 ? (
                                        <div>
                                          <p className="btn-secondary ml-3">
                                            No data
                                          </p>
                                        </div>
                                      ) : (
                                        filterProducts(
                                          productlist,
                                          productFilter
                                        ).map((product) => (
                                          <div key={product?.productId}>
                                            <p
                                              style={{ textAlign: "left" }}
                                              className={`btn btn-secondary ${
                                                selectedProductId ===
                                                product?.productId
                                                  ? "selected"
                                                  : ""
                                              }`}
                                              onClick={() => {
                                                handleProductClick(
                                                  product?.productId
                                                );
                                                setstatus(true);
                                              }}
                                            >
                                              {product?.product_name}
                                              <span className="caret"></span>
                                            </p>
                                          </div>
                                        ))
                                      )}
                                    </div>
                                  </div>
                                ) : null}
                              </ul>
                            </>
                          ) : null}
                        </div>

                        <div className="col-md-6 col-lg-4 form-group">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="title-color font-weight-medium d-flex"
                          >
                            Offer type
                          </label>
                          <select
                            className="form-control"
                            name="coupon_type"
                            value={offerType.split(" ")[0]} // This ensures only the first part (offer type) is used
                            onChange={handleRadioChange}
                            required
                          >
                            <option disabled value="">
                              Select
                            </option>
                            <option value="Percentage">Percent off                            </option>
                            <option value="Buy">Buy X Get Y Free                            </option>
                          </select>
                        </div>

                        {offerType.startsWith("Percentage") ? (
                          <div className="col-md-6 col-lg-4 form-group">
                            <label
                              style={{ visibility: "hidden" }}
                              htmlFor="exampleFormControlInput1"
                              className="title-color font-weight-medium d-flex"
                            >
                              Limit per user
                            </label>
                            <input
                              required
                              placeholder="Enter value %"
                              className="form-control"
                              type="text"
                              value={offerType.split(" ")[1] || ""}
                              onChange={(e) =>
                                handleInputChange(e, "percentage")
                              }
                              disabled={!offerType.startsWith("Percentage")}
                            />
                          </div>
                        ) : offerType.startsWith("Buy") ? (
                          <div className="col-md-6 col-lg-4 form-group">
                            <label
                              style={{ visibility: "hidden" }}
                              htmlFor="exampleFormControlInput1"
                              className="title-color font-weight-medium d-flex"
                            >
                              Limit per user
                            </label>
                            <span className="mt-2 mr-2">Buy</span>
                            <input
                              required
                              placeholder="Enter value"
                              style={{
                                borderColor: "rgb(152 152 152)",
                                fontSize: "14px",
                                width: "35%",
                                height: "38px",
                                borderRadius: "5px",
                              }}
                              type="text"
                              value={offerType.split(" ")[1] || ""}
                              onChange={(e) => handleInputChange(e, "buy")}
                              disabled={!offerType.startsWith("Buy")}
                            />
                            <span className="mt-2 mr-2 ml-2">Get</span>
                            <input
                              required
                              placeholder="Enter value"
                              style={{
                                borderColor: "rgb(152 152 152)",
                                fontSize: "14px",
                                width: "35%",
                                height: "38px",
                                borderRadius: "5px",
                              }}
                              type="text"
                              value={offerType.split(" ")[3] || ""}
                              onChange={(e) => handleInputChange(e, "get")}
                              disabled={!offerType.startsWith("Buy")}
                            />
                          </div>
                        ) : null}

                        <div className="col-md-6 col-lg-4 form-group">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="title-color font-weight-medium d-flex"
                          >
                            Limit per user
                          </label>
                          <input
                            type="number"
                            required
                            min={0}
                            value={sameUser_discountLimit}
                            onChange={(e) => {
                              setsameUser_discountLimit(e.target.value);
                            }}
                            className="form-control"
                            placeholder="Ex: 2"
                          />
                        </div>

                        <div className="col-md-6 col-lg-4 form-group">
                          <label
                            htmlFor="name"
                            className="title-color font-weight-medium d-flex"
                          >
                            Start date
                          </label>
                          <input
                            type="date"
                            name="start_date"
                            value={start_date}
                            onChange={(e) => {
                              setstart_date(e.target.value);
                            }}
                            className="form-control"
                            placeholder="Start date"
                            required
                            min="2023-12-23"
                          />
                        </div>
                        <div className="col-md-6 col-lg-4 form-group">
                          <label
                            htmlFor="name"
                            className="title-color font-weight-medium d-flex"
                          >
                            Expire date
                          </label>
                          <input
                            value={expire_date}
                            type="date"
                            name="expire_date"
                            onChange={(e) => {
                              setexpire_date(e.target.value);
                            }}
                            className="form-control"
                            placeholder="Expire date"
                            required
                            min="2023-12-23"
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-end flex-wrap gap-10">
                        <button type="submit" className="btn btn--primary px-4">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-20 mb-20">
              <div className="col-md-12">
                <div className="card">
                  <div className="px-3 py-4">
                    <div className="row justify-content-between align-items-center flex-grow-1">
                      <div className="col-sm-4 col-md-6 col-lg-8 mb-2 mb-sm-0">
                        <h5 className="mb-0 text-capitalize d-flex gap-2">
                          Offer list
                          <span className="badge badge-soft-dark radius-50 fz-12 ml-1">
                            {count}
                          </span>
                        </h5>
                      </div>
                      <div className="col-sm-8 col-md-6 col-lg-4">
                        <form>
                          <div className="input-group input-group-merge input-group-custom">
                            <input
                              onChange={handleFilter}
                              id="datatableSearch_"
                              type="search"
                              name="search"
                              className="form-control"
                              placeholder="Search here"
                            />
                            <button type="submit" className="btn btn--primary">
                              Search
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    {Coupanfilter?.length > 0 ? (
                      <table
                        id="datatable"
                        className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table text-left"
                      >
                        <thead className="thead-light thead-50 text-capitalize">
                          <tr>
                            <th>SL</th>
                            <th>Product</th>
                            <th>Offer</th>
                            <th>Type</th>
                            <th>Duration</th>
                            <th>User limit</th>

                            <th>
                              Status{" "}
                              <i
                                class="fa fa-certificate"
                                aria-hidden="true"
                              ></i>
                            </th>
                            <th className="text-center">
                              Action{" "}
                              <i class="fa fa-bug" aria-hidden="true"></i>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {Coupanfilter.slice(
                            (activePage - 1) * itemsPerPage,
                            activePage * itemsPerPage
                          ).map((data, index) => renderCoupanData(data, index))}
                        </tbody>
                      </table>
                    ) : (
                      <div class="text-center p-4">
                        <img
                          class="mb-3 w-160"
                          src="https://6valley.6amtech.com/public/assets/back-end/img/empty-state-icon/default.png"
                          alt="Image Description"
                        />
                        <p class="mb-0 order-stats__subtitle">No data found</p>
                      </div>
                    )}
                    <div className="d-flex justify-content-center mt-4">
                      {Coupanfilter.length > itemsPerPage && (
                        <Pagination
                          activePage={activePage}
                          itemsCountPerPage={itemsPerPage}
                          totalItemsCount={Coupanfilter.length}
                          pageRangeDisplayed={5}
                          onChange={handlePageChange}
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      )}
                    </div>
                  </div>
                  <div className="table-responsive mt-4">
                    <div className="px-4 d-flex justify-content-lg-end"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Promotions;
