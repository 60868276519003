import React from 'react'
import Header from '../Header'
import '../sidebar.css'
import { Link } from 'react-router-dom'
import Sidebarr from '../Sidebar'

const Address = () => {
  return (
    <div className='container'>

    {/* <Header/> */}
    <div className='container row' style={{ paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px" }}>
    <div className="col-lg-2 col-md-3" style={{ paddingLeft: "0px" }}>
    {/* <Sidebarr/> */}
    </div>

    <div className="col-lg-10 col-md-9" style={{ paddingLeft: "30px", marginTop: "60px" }}>
   
    <br/>
    <br/>

    <div className="content container-fluid">
        <div className="mb-3">
          <h2 className="h1 mb-0 text-capitalize d-flex align-items-center gap-2">
            <img width={20} src="https://6valley.6amtech.com/public/assets/back-end/img/shop-info.png" alt="" />
            Shop Info
          </h2>
        </div>
        <div className="inline-page-menu my-4">
          <ul className="list-unstyled">
            {/* <li className=""><a href="https://6valley.6amtech.com/seller/shop/view">General</a></li> */}
          </ul>
        </div>
        <div className="card mb-3">
          <div className="card-body">
            <form action="https://6valley.6amtech.com/seller/shop/temporary-close" method="POST" id="temporary_close_form">
              <input type="hidden" name="_token" defaultValue="YFPrIbFYlUsgcs6Ht4EEyCysWGBq8lmpLaBUW0q6" /> <div className="border rounded border-color-c1 px-4 py-3 d-flex justify-content-between mb-1">
                <h5 className="mb-0 d-flex gap-1 c1">
                  Temporary close
                </h5>
                <input type="hidden" name="id" defaultValue={2} />
                <div className="position-relative">
                  <label className="switcher">
                    <input type="checkbox" className="switcher_input" name="status" defaultValue={1} id="temporary_close" onclick="toogleStatusModal(event,'temporary_close','maintenance_mode-on.png','maintenance_mode-off.png','Want to enable the Temporary Close','Want to disable the Temporary Close',`<p>If you enable this option your shop will be shown as temporarily closed in the user app and website and customers cannot add products from your shop</p>`,`<p>If you disable this option your shop will be open in the user app and website and customers can add products from your shop</p>`)" />
                    <span className="switcher_control" />
                  </label>
                </div>
              </div>
            </form>
            <p>* By turning on temporary close mode your shop will be shown as temporary off in the website and app for the customers. they cannot purchase or place order from your shop</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header">
                <div>
                  <h4 className="mb-0">My shop info </h4>
                </div>
                <div className="d-inline-flex gap-2">
                  <button className="btn btn-block __inline-70" data-toggle="modal" data-target="#balance-modal">
                    Go to Vacation Mode
                  </button>
                  <Link className="btn btn--primary __inline-70 px-4 text-white" to="/addressedit">
                    Edit
                  </Link>
                </div>
              </div>
              <div className="card-body">
                <div className="d-flex align-items-center flex-wrap gap-5">
                  <div className="text-left">
                    <img src="https://6valley.6amtech.com/storage/app/public/shop/2022-04-21-6260f6e190f4c.png" onerror="this.src='https://6valley.6amtech.com/public/assets/front-end/img/image-place-holder.png'" className="rounded-circle border" height={200} width={200} alt="" />
                  </div>
                  <div className>
                    <div className="flex-start">
                      <h4>Name : </h4>
                      <h4 className="mx-1">Graduate Tea</h4>
                    </div>
                    <div className="flex-start">
                      <h6>Phone : </h6>
                      <h6 className="mx-1">01633333339</h6>
                    </div>
                    <div className="flex-start">
                      <h6>Address : </h6>
                      <h6 className="mx-1">House-09, Road-02, Sec-25, Block-U, Mirzapur-63</h6>
                    </div>
                  </div>
                  <div className />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="balance-modal" tabIndex={-1} role="dialog" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content" style={{textAlign: 'left'}}>
              <form action="https://6valley.6amtech.com/seller/shop/vacation-add/2" method="post">
                <div className="modal-header border-bottom pb-2">
                  <div>
                    <h5 className="modal-title" id="exampleModalLabel">Vacation Mode</h5>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label className="switcher">
                        <input type="checkbox" name="vacation_status" className="switcher_input" id="vacation_close" />
                        <span className="switcher_control" />
                      </label>
                    </div>
                    <div className="col-md-6">
                      <button type="button" className="close pt-0" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="modal-body">
                  <div className="mb-5">*Set vacation mode for shop means you will be not available receive order and provider products for placed order at that time</div>
                  <input type="hidden" name="_token" defaultValue="YFPrIbFYlUsgcs6Ht4EEyCysWGBq8lmpLaBUW0q6" /> <div className="row">
                    <div className="col-md-6">
                      <label>Vacation Start</label>
                      <input type="date" name="vacation_start_date" defaultValue id="vacation_start_date" className="form-control" required />
                    </div>
                    <div className="col-md-6">
                      <label>Vacation End</label>
                      <input type="date" name="vacation_end_date" defaultValue id="vacation_end_date" className="form-control" required />
                    </div>
                    <div className="col-md-12 mt-2 ">
                      <label>Vacation Note</label>
                      <textarea className="form-control" name="vacation_note" id="vacation_note" defaultValue={""} />
                    </div>
                  </div>
                  <div className="text-end gap-5 mt-2">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="submit" className="btn btn--primary">Update</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    </div>



    </div>
  )
}

export default Address





