import React, { useEffect, useState } from "react";
import Header from "../Header";

import { Link, useNavigate } from "react-router-dom";
import Sidebarr from "../Sidebar";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import toast, { Toaster } from "react-hot-toast";

const Editmydetails = () => {
  const navigate = useNavigate();
  const [firstName, setfirstName] = useState();
  const [lastName, setlastName] = useState();
  const [upload_frontId, setupload_frontId] = useState();
  const [upload_backsideId, setupload_backsideId] = useState();
  const Navigate = useNavigate();
  const [details, setdetails] = useState();
  const [phone, setphone] = useState();

  const [email, setemail] = useState();
  let token = secureLocalStorage.getItem("vendortoken");
  let venderIdd = secureLocalStorage.getItem("vendorid");

  const editmydetails = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("venderId", venderIdd);
    formData.append("firstName", firstName ? firstName : details?.firstName);
    formData.append("lastName", lastName ? lastName : details?.lastName);
    formData.append("upload_frontId", upload_frontId);
    formData.append("upload_backsideId", upload_backsideId);

    let options = {
      hraders: {
        token: token,
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/updateseller`,
        formData,
        options
      )
      .then((res) => {
        setTimeout(() => {
          Navigate("/profile");
        }, 3000);
        toast.success(res.data.message);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    sellerdetails();
  }, [0]);

  const sellerdetails = () => {
    const sellerdata = {
      venderId: venderIdd,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/getVenderProfile`,
        sellerdata
      )
      .then((res) => {
        setdetails(res.data.data);
        setfirstName(res.data.data.firstName)
        setlastName(res.data.data.lastName)
        setphone(res.data.data.mobile_number)
        setemail(res.data.data.email)

        
      })
      .catch((error) => {});
  };

  const handlesubmitphone = () => {
    if (!phone || phone.length < 10 || phone.length > 10) {
      toast.error(
        "Please enter a valid phone number with at least 10 characters."
      );
    }

    const userdata = {
      venderId: venderIdd,
      mobile_number: phone,
    };

    axios
      .post(`${process.env.REACT_APP_API_KEY}vender/api/updateMobile`, userdata)
      .then((response) => {
        secureLocalStorage.setItem("otp", response.data.data.otp);
        secureLocalStorage.setItem(
          "newphone",
          response.data.data.newMobile_number
        );

        toast.success(response.data.message);
        setTimeout(() => {
          navigate("/editphoneinfo");
        }, 3000);
      })
      .catch((error) => {});
  };

  const handlesubmitemail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email || !emailRegex.test(email)) {
      toast.error("Please enter a valid email address.");
    }

    const userdata = {
      venderId: venderIdd,
      email: email,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/updateEmail`,
        userdata
      )
      .then((response) => {
        

        toast.success(response.data.message);
        
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Invalid Data Entered by you.");
        }
      });
  };
  return (
    <div className="container">
      {/* <Header /> */}
      <Toaster />
      <div
        className="container row"
        style={{ paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px" }}
      >
        <div className="col-lg-2 col-md-3" style={{ paddingLeft: "0px" }}>
          {/* <Sidebarr /> */}
        </div>

        <div
          className="col-lg-10 col-md-9"
          style={{ paddingLeft: "30px", marginTop: "60px" }}
        >
          <div className="mt-3">
            <div className="mb-3" >
              <h2 className="h1 mb-0 text-capitalize d-flex align-items-center gap-2">
                <img
                  width={20}
                  src="https://6valley.6amtech.com/public/assets/back-end/img/shop-info.png"
                  alt
                />
                Personal information
              </h2>
            </div>
            <div className="row mb-5">
              <div className="col-md-12">
                <div className="card" style={{borderColor:'rgb(188, 189, 190)'}}>
                  <div className="card-header">
                    <h5 className="mb-0 ">Personal information</h5>
                    <Link
                      to="/profile"
                      className="btn btn--primary __inline-70 px-4 text-white"
                    >
                      Back
                    </Link>
                  </div>
                  <div className="card-body">
                    <form
                      style={{ textAlign: "left" }}
                      encType="multipart/form-data"
                      onSubmit={editmydetails}
                    >
                      <input
                        type="hidden"
                        name="_token"
                        defaultValue="ZzkfkLNbAUt4VTq5h8M6WE4WDu8lkTMJLYAhZQBe"
                      />{" "}
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="name" className="title-color">
                              First Name <span className="text-danger"></span>
                            </label>
                            <input
                              value={firstName}
                              type="text"
                              name="name"
                              onChange={(e) => {
                                setfirstName(e.target.value);
                              }}
                              className="form-control"
                              id="name"
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="name" className="title-color">
                              Last Name <span className="text-danger"></span>
                            </label>
                            <input
                              value={lastName}
                              type="text"
                              name="name"
                              onChange={(e) => {
                                setlastName(e.target.value);
                              }}
                              className="form-control"
                              id="name"
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="name" className="title-color">
                              Phone{" "}
                              <span className="text-danger">
                                {" "}
                                <i
                                  onClick={handlesubmitphone}
                                  class="fa fa-pencil btn"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </label>
                            <input
                              value={phone}
                              type="text"
                              
                              onChange={(e) => {
                                setphone(e.target.value);
                              }}
                              className="form-control"
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="name" className="title-color">
                              Email{" "}
                              <span className="text-danger">
                                {" "}
                                <i
                                  onClick={handlesubmitemail}
                                  class="fa fa-pencil btn"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </label>
                            <input
                              value={email}
                              type="email"
                              name="email"
                              onChange={(e) => {
                                setemail(e.target.value);
                              }}
                              className="form-control"
                              id="email"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="name" className="title-color">
                              Upload FrontId
                            </label>
                            <div className="custom-file text-left">
                              <input
                                onChange={(e) => {
                                  setupload_frontId(e.target.files[0]);
                                }}
                                type="file"
                                name="image"
                                id="customFileUpload"
                                className="custom-file-input"
                                accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="customFileUpload"
                              >
                                Choose File
                              </label>
                            </div>
                          </div>
                          <div className="text-center">
                            {upload_frontId ? (
                              <img
                                className="upload-img-view"
                                id="viewer"
                                src={URL.createObjectURL(upload_frontId)}
                                alt="Product thumbnail"
                              />
                            ) : (
                              <img
                                className="upload-img-view"
                                id="viewer"
                                src={
                                  `${process.env.REACT_APP_API_KEY}uploads/` +
                                  details?.upload_frontId
                                }
                                alt="Product thumbnail"
                              />
                            )}
                          </div>
                        </div>
                        <div className="col-md-6 mb-4 mt-2">
                          <div className="form-group">
                            <div className="flex-start">
                              <label htmlFor="name" className="title-color">
                                Upload BacksideId{" "}
                              </label>
                              {/* <div className="mx-1" htmlFor="ratio">
                                <span className="text-info">
                                  Ratio : ( 6:1 )
                                </span>
                              </div> */}
                            </div>
                            <div className="custom-file text-left">
                              <input
                                onChange={(e) => {
                                  setupload_backsideId(e.target.files[0]);
                                }}
                                type="file"
                                name="banner"
                                id="BannerUpload"
                                className="custom-file-input"
                                accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="BannerUpload"
                              >
                                Choose File
                              </label>
                            </div>
                          </div>
                          <div className="text-center">
                            <center>
                              {upload_backsideId ? (
                                <img
                                  className="upload-img-view upload-img-view__banner"
                                  id="viewerBanner"
                                  src={URL.createObjectURL(upload_backsideId)}
                                  alt="banner image"
                                />
                              ) : (
                                <img
                                  className="upload-img-view upload-img-view__banner"
                                  id="viewerBanner"
                                  src={
                                    `${process.env.REACT_APP_API_KEY}uploads/` +
                                    details?.upload_backsideId
                                  }
                                  alt="banner image"
                                />
                              )}
                            </center>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end gap-2">
                        <button
                          type="submit"
                          className="btn btn--primary"
                          id="btn_update"
                        >
                          Update
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Editmydetails;
