import React, { useEffect, useState } from "react";
import Header from "../Header";

import Sidebarr from "../Sidebar";
import { Link, Navigate, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

const Editphoneinfo = () => {
  let navigate = useNavigate();
  let login = () => {
    navigate(`/home`);
  };

  const [vendoremail, setvendormobile] = useState("");

  const [genrateotp, setgenrateotp] = useState("");
  const [verifydata, setverifydata] = useState();

  const [ttrue, settrue] = useState(false);
  const [hide, sethide] = useState(false);

  const [resendgenrateotp, setresendgenrateotp] = useState("");
  const [useremail, setuseremail] = useState();

  const [userephone, setuserephone] = useState();
  const [resendgenrateotpa, setresendgenrateotpa] = useState();
  const [resendhide, setresendhide] = useState(false);
  let venderIdd = secureLocalStorage.getItem("vendorid");
  let otp = secureLocalStorage.getItem("otp");
  let phonenew = secureLocalStorage.getItem("newphone");

  const resendotp = () => {
    const userdata = {
      venderId: venderIdd,
      mobile_number: phonenew,
    };

    axios
      .post(`${process.env.REACT_APP_API_KEY}vender/api/updateMobile`, userdata)
      .then((response) => {
        secureLocalStorage.setItem("otp", response.data.data.otp);
        secureLocalStorage.setItem(
          "newphone",
          response.data.data.newMobile_number
        );

        toast.success(response.data.message);
        setTimeout(() => {
          navigate("/editphoneinfo");
        }, 3000);
      })
      .catch((error) => {});
  };

  const verifyotp = (e) => {
    e.preventDefault();

    const userdata = {
      venderId: venderIdd,
      newMobile_number: phonenew,
      otp: genrateotp,
    };

    axios
      .post(`${process.env.REACT_APP_API_KEY}vender/api/verifyPhone`, userdata)
      .then((response) => {
        setTimeout(() => {
          navigate("/profile");
        }, 3000);
        toast.success(response.data.message);
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Invalid Data Entered by you.");
        }
      });
  };
  return (
    <div className="container">
      {/* <Header /> */}
      <Toaster />
      <div
        className="container row"
        style={{ paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px" }}
      >
        <div className="col-lg-2 col-md-3" style={{ paddingLeft: "0px" }}>
          {/* <Sidebarr /> */}
        </div>

        <div
          className="col-lg-7 col-md-7"
          style={{ paddingLeft: "30px", marginTop: "60px" }}
        >
          <div className="mt-3" style={{ textAlign: "left" }}>
            <div className="mb-3">
              <h2 className="h1 mb-0 text-capitalize d-flex align-items-center gap-2">
                <img
                  width={20}
                  src="https://6valley.6amtech.com/public/assets/back-end/img/my-bank-info.png"
                  alt
                />
                Personal information
              </h2>
            </div>
            <div className="row mt-4">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body" style={{ marginTop: "0px" }}>
                    {otp && !hide && !ttrue ? (
                      <div className="card-body" style={{ marginTop: "10px" }}>
                        <form onSubmit={verifyotp}>
                          <div className="js-form-message form-group">
                            <div
                              className="input-group input-group-merge iconinput mb-3"
                              style={{ justifyContent: "center" }}
                            >
                              <h2>Verify your phone number</h2>
                            </div>
                            <div
                              className="input-group input-group-merge iconinput mb-3"
                              style={{ justifyContent: "center" }}
                            >
                              <h3>Please enter the code sent to</h3>
                            </div>
                            <div
                              className="input-group input-group-merge iconinput mb-3"
                              style={{ justifyContent: "center" }}
                            >
                              <h3>+ 91 {phonenew}</h3>
                            </div>
                            <div className="input-group input-group-merge iconinput">
                              <input
                                value={genrateotp}
                                placeholder={otp}
                                onChange={(e) => {
                                  setgenrateotp(e.target.value);
                                }}
                                type="number"
                                className=" form-control form-control-lg"
                              />
                            </div>
                            <br />
                            <div>
                              <button
                                onClick={() => {
                                  resendotp();
                                  settrue(true);
                                }}
                                className="btn btn-lg btn-block btn--info"
                              >
                                Resend code
                              </button>
                            </div>

                            <br />
                            <button
                              type="submit"
                              className="btn btn-lg btn-block btn--primary"
                            >
                              Verify
                            </button>
                            <br />
                            <Link
                              to="/editmydetails"
                              type="submit"
                              className="btn btn-lg btn-block btn--info"
                            >
                              Back
                            </Link>
                          </div>
                        </form>
                      </div>
                    ) : null}

                    {ttrue && !resendgenrateotp ? (
                      <div className="card-body" style={{ marginTop: "10px" }}>
                        <form onSubmit={verifyotp}>
                          <div className="js-form-message form-group">
                            <div
                              className="input-group input-group-merge iconinput mb-3"
                              style={{ justifyContent: "center" }}
                            >
                              <h2>Verify your phone number</h2>
                            </div>
                            <div
                              className="input-group input-group-merge iconinput mb-3"
                              style={{ justifyContent: "center" }}
                            >
                              <h3>Please enter the code sent to</h3>
                            </div>
                            <div
                              className="input-group input-group-merge iconinput mb-3"
                              style={{ justifyContent: "center" }}
                            >
                              <h3>+ 91 {phonenew}</h3>
                            </div>
                            <div className="input-group input-group-merge iconinput">
                              <input
                                value={genrateotp}
                                placeholder={otp}
                                onChange={(e) => {
                                  setgenrateotp(e.target.value);
                                }}
                                type="number"
                                className=" form-control form-control-lg"
                              />
                            </div>
                            <br />
                            <div className="row"></div>

                            <br />
                            <button
                              type="submit"
                              className="btn btn-lg btn-block btn--primary"
                            >
                              Verify
                            </button>
                            <br />
                            <Link
                              to="/editmydetails"
                              type="submit"
                              className="btn btn-lg btn-block btn--info"
                            >
                              Back
                            </Link>
                          </div>
                        </form>
                      </div>
                    ) : null}

                    {resendgenrateotp && !resendhide ? (
                      <div className="card-body" style={{ marginTop: "-50px" }}>
                        <form onSubmit={verifyotp}>
                          <div className="js-form-message form-group">
                            <div
                              className="input-group input-group-merge iconinput mb-3"
                              style={{ justifyContent: "center" }}
                            >
                              <h2>Verify your email or phone</h2>
                            </div>
                            <div
                              className="input-group input-group-merge iconinput mb-3"
                              style={{ justifyContent: "center" }}
                            >
                              <h3>Please enter the code sent to</h3>
                            </div>
                            <div
                              className="input-group input-group-merge iconinput mb-3"
                              style={{ justifyContent: "center" }}
                            >
                              <h3>+ 91 xxx xxx xxx</h3>
                            </div>
                            <div className="input-group input-group-merge iconinput">
                              <input
                                value={genrateotp}
                                placeholder={resendgenrateotp}
                                onChange={(e) => {
                                  setgenrateotp(e.target.value);
                                }}
                                type="number"
                                className=" form-control form-control-lg"
                              />
                            </div>
                            <br />
                            <div></div>

                            <br />
                            <button
                              type="submit"
                              className="btn btn-lg btn-block btn--primary"
                            >
                              Verify
                            </button>
                            <br />
                            <Link
                              to="/editmydetails"
                              type="submit"
                              className="btn btn-lg btn-block btn--info"
                            >
                              Back
                            </Link>
                          </div>
                        </form>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Editphoneinfo;
