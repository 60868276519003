import Allproducts from "./Components/page/Allproducts";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import Home from "./Components/Home";
import Panding from "./Components/page/Panding";
import Confrimproducts from "./Components/page/Confrimproducts";
import Products_list from "./Components/page/Products_list";
import Addnewproducts from "./Components/page/Addnewproducts";
import Orderdetails from "./Components/page/Orderdetails";
import { useEffect, useState } from "react";
import Sidebar from "./Components/Sidebar";
import Header from "./Components/Header";
import Bannerlist from "./Components/page/Bannerlist";
import Editbanner from "./Components/page/Editbanner";
import Addbanner from "./Components/page/Addbanner";
import Notifications from "./Components/page/Notifications ";
import NotificationsEdit from "./Components/page/NotificationsEdit";
import Usermanagement from "./Components/page/Usermanagement";
import UserOrderdetails from "./Components/page/UserOrderdetails";
import Paymentmanagement from "./Components/page/Paymentmanagement";
import Vendor_manage from "./Components/page/Vendor_manage";
import Subscription from "./Components/page/Subscription";
import Profile from "./Components/page/Profile";
import Login from "./Components/Login";
import Packaging from "./Components/page/Packaging";
import Screen from "./Components/Screen";
import Products from "./Components/page/Products";
import Productdetails from "./Components/page/Productdetails";
import Chatwithadmin from "./Components/page/Chatwithadmin";
import Promotions from "./Components/page/Promotions";
import Filterproduct from "./Components/page/Filterproduct";
import Promotionsupdate from "./Components/page/Promotionsupdate";
import Barcode from "./Components/page/Barcode";
import Productedit from "./Components/page/Productedit";
import Message from "./Components/page/Message";
import Address from "./Components/page/Address";
import Addressedit from "./Components/page/Addressedit";
import Transacation_report from "./Components/page/Transacation_report";
import Transactionexpenselist from "./Components/page/Transactionexpenselist";
import Myshop from "./Components/page/Myshop";
import Editmyshop from "./Components/page/Editmyshop";
import Mybankinfo from "./Components/page/Mybankinfo";
import Editbankinfo from "./Components/page/Editbankinfo";
import Withdraw from "./Components/page/Withdraw";
import Chatdeliveryman from "./Components/page/Chatdeliveryman";
import Producsreviews from "./Components/Producsreviews";
import Helloside from "./Components/page/Helloside";
import OutForDelivery from "./Components/page/OutForDelivery";
import Delivered from "./Components/page/Delivered";
import Canceled from "./Components/page/Canceled";
import Returned from "./Components/page/Returned";
import FailedToDelivery from "./Components/page/FailedToDelivery";
import Refundpanding from "./Components/page/Refundpanding";
import Refunddetails from "./Components/page/Refunddetails";
import Refundapproved from "./Components/page/Refundapproved";
import Bulkimport from "./Components/page/Bulkimport";
import Protect from "./Components/page/Protect";
import Forgotpassword from "./Components/page/Forgotpassword";
import Test from "./Components/page/Test";
import Editproduct from "./Components/page/Editproduct";
import Coupan from "./Components/page/Coupan";
import Editmydetails from "./Components/page/Editmydetails";
import Error from "./Components/Error";
import Editphoneinfo from "./Components/page/Editphoneinfo";
import Deletaccount from "./Components/page/Deletaccount";
import Addnewproduct from "./Components/page/Addnewproduct";
import Reviews from "./Components/page/Reviews";
import Productqrcode from "./Components/page/Productqrcode";
import Deliverymessage from "./Components/page/Deliverymessage";
import Topsellingproducts from "./Components/page/Topsellingproducts";
import Refundpolicy from "./Components/page/Refundpolicy";
import Cancellationpolicy from "./Components/page/Cancellationpolicy";
import Aboutus from "./Components/page/Aboutus";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import Refundedpro from "./Components/page/Refundedpro";
import RefundRejected from "./Components/page/RefundRejected";
import Staffs from "./Components/page/Staffs";
import Staffrole from "./Components/page/Staffrole";
import Addstaff from "./Components/page/Addstaff";
import Updatestaff from "./Components/page/Updatestaff";
import Staffdetails from "./Components/page/Staffdetails";
import Qrcodes from "./Components/page/Qrcodes";
import Addnewqr from "./Components/page/Addnewqr";
import Qrcodedetails from "./Components/page/Qrcodedetails";
import Signup from "./Components/Signup";
import { messaging, onMessage } from "./firebase";
function App() {
  const [randomResult, setRandomResult] = useState("hello");

  useEffect(() => {
    const randomNumber = Math.floor(Math.random() * 90000000) + 10000000;

    const randomAlphabets =
      String.fromCharCode(65 + Math.floor(Math.random() * 26)) +
      String.fromCharCode(65 + Math.floor(Math.random() * 26));

    const result = randomNumber.toString() + randomAlphabets;
  }, []);

  const location = useLocation();

  useEffect(() => {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
      } else {
      }
    });

    onMessage(messaging, (payload) => {
      new Notification(payload.notification.title, {
        body: payload.notification.body,
      });
    });
  }, []);

  // useEffect(() => {
  //   document.addEventListener("contextmenu", (e) => {
  //     e.preventDefault();
  //   });

  //   document.addEventListener("keydown", (e) => {
  //     if (
  //       e.keyCode === 123 ||
  //       (e.ctrlKey && e.shiftKey && (e.keyCode === 73 || e.keyCode === 74))
  //     ) {
  //       e.preventDefault();
  //     }
  //   });

  //   return () => {
  //     document.removeEventListener("contextmenu", (e) => {
  //       e.preventDefault();
  //     });
  //     document.removeEventListener("keydown", (e) => {
  //       if (
  //         e.keyCode === 123 ||
  //         (e.ctrlKey && e.shiftKey && (e.keyCode === 73 || e.keyCode === 74))
  //       ) {
  //         e.preventDefault();
  //       }
  //     });
  //   };
  // }, []);
  return (
    <div>
      {location.pathname !== "/forgotpassword" &&
        location.pathname !== "/" &&
        location.pathname !== "/signup" &&
        location.pathname !== "/test" && <Header />}
      {location.pathname !== "/forgotpassword" &&
        location.pathname !== "/" &&
        location.pathname !== "/test" &&
        location.pathname !== "/signup" && <Sidebar />}

      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/forgotpassword" element={<Forgotpassword />} />

        <Route
          path="/profile"
          element={<Protect ComponentName={Profile} />}
        ></Route>

        <Route
          path="/Deliverymen"
          element={<Protect ComponentName={Deliverymessage} />}
        ></Route>

        <Route path="/home" element={<Protect ComponentName={Home} />}></Route>
        <Route
          path="/All Orders"
          element={<Protect ComponentName={Allproducts} />}
        ></Route>
        <Route
          path="/Pending Orders"
          element={<Protect ComponentName={Panding} />}
        ></Route>

        <Route
          path="/Confirm Orders"
          element={<Protect ComponentName={Confrimproducts} />}
        ></Route>
        <Route
          path="/Rejected Orders"
          element={<Protect ComponentName={Packaging} />}
        ></Route>
        <Route
          path="/products_list"
          element={<Protect ComponentName={Products_list} />}
        ></Route>
        <Route
          path="/Shipped"  
          element={<Protect ComponentName={OutForDelivery} />}
        ></Route>
        <Route
          path="/Delivered Orders"
          element={<Protect ComponentName={Delivered} />}
        ></Route>

        <Route
          path="/Canceled Orders"
          element={<Protect ComponentName={Canceled} />}
        ></Route>
        <Route
          path="/Returned Orders"
          element={<Protect ComponentName={Returned} />}
        ></Route>
        <Route
          path="/Not Delivered"
          element={<Protect ComponentName={FailedToDelivery} />}
        ></Route>
        <Route
          path="/Add New Products"
          element={<Protect ComponentName={Addnewproducts} />}
        ></Route>
        <Route
          path="/orderdetails"
          element={<Protect ComponentName={Orderdetails} />}
        ></Route>
        <Route
          path="/add_banner"
          element={<Protect ComponentName={Addbanner} />}
        ></Route>
        <Route
          path="/bannerlist"
          element={<Protect ComponentName={Bannerlist} />}
        ></Route>
        <Route
          path="/editbannerlist"
          element={<Protect ComponentName={Editbanner} />}
        ></Route>

        <Route
          path="/Notifications"
          element={<Protect ComponentName={Notifications} />}
        ></Route>
        <Route
          path="/editnotification"
          element={<Protect ComponentName={NotificationsEdit} />}
        ></Route>
        <Route
          path="/userlist"
          element={<Protect ComponentName={Usermanagement} />}
        ></Route>
        <Route
          path="/userorderlist"
          element={<Protect ComponentName={UserOrderdetails} />}
        ></Route>

        <Route
          path="/About Us"
          element={<Protect ComponentName={Aboutus} />}
        ></Route>

        <Route
          path="/Cancellation Policy"
          element={<Protect ComponentName={Cancellationpolicy} />}
        ></Route>

        <Route
          path="/Refund & Return policy"
          element={<Protect ComponentName={Refundpolicy} />}
        ></Route>

        <Route
          path="/topsellingproducts"
          element={<Protect ComponentName={Topsellingproducts} />}
        ></Route>

        <Route
          path="/Payment Management"
          element={<Protect ComponentName={Paymentmanagement} />}
        ></Route>
        <Route
          path="/vendormanage"
          element={<Protect ComponentName={Vendor_manage} />}
        ></Route>
        <Route
          path="/Terms & privacy policy"
          element={<Protect ComponentName={Subscription} />}
        ></Route>

        <Route
          path="/Products List"
          element={<Protect ComponentName={Products} />}
        ></Route>
        <Route
          path="/productdetails"
          element={<Protect ComponentName={Productdetails} />}
        ></Route>

        <Route
          path="/chatwithadmin"
          element={<Protect ComponentName={Chatwithadmin} />}
        ></Route>

        <Route
          path="/Offer"
          element={<Protect ComponentName={Promotions} />}
        ></Route>

        <Route
          path="/filterproduct"
          element={<Protect ComponentName={Filterproduct} />}
        ></Route>
        <Route
          path="/promotionsupdate"
          element={<Protect ComponentName={Promotionsupdate} />}
        ></Route>

        <Route path="/signup" element={<Signup />}></Route>

        <Route
          path="/barcode"
          element={<Protect ComponentName={Barcode} />}
        ></Route>
        <Route
          path="/productedit"
          element={<Protect ComponentName={Productedit} />}
        ></Route>
        <Route
          path="/Customers"
          element={<Protect ComponentName={Message} />}
        ></Route>

        <Route
          path="/address"
          element={<Protect ComponentName={Address} />}
        ></Route>

        <Route
          path="/addressedit"
          element={<Protect ComponentName={Addressedit} />}
        ></Route>

        <Route
          path="/transactionreport"
          element={<Protect ComponentName={Transacation_report} />}
        ></Route>

        <Route
          path="/transactionexpenselist"
          element={<Protect ComponentName={Transactionexpenselist} />}
        ></Route>

        <Route
          path="/My Shop"
          element={<Protect ComponentName={Myshop} />}
        ></Route>

        <Route
          path="/editmyshop"
          element={<Protect ComponentName={Editmyshop} />}
        ></Route>

        <Route
          path="/Withdraw"
          element={<Protect ComponentName={Withdraw} />}
        ></Route>

        <Route
          path="/My Bank info"
          element={<Protect ComponentName={Mybankinfo} />}
        ></Route>

        <Route
          path="/editmybankinfo"
          element={<Protect ComponentName={Editbankinfo} />}
        ></Route>

        <Route
          path="/chatdeliveryman"
          element={<Protect ComponentName={Chatdeliveryman} />}
        ></Route>

        <Route
          path="/Reviews"
          element={<Protect ComponentName={Producsreviews} />}
        ></Route>
        <Route
          path="/reviews"
          element={<Protect ComponentName={Reviews} />}
        ></Route>

        <Route
          path="/productqrcode"
          element={<Protect ComponentName={Productqrcode} />}
        ></Route>

        <Route
          path="/Pending Refund"
          element={<Protect ComponentName={Refundpanding} />}
        ></Route>

        <Route
          path="/Staffs"
          element={<Protect ComponentName={Staffs} />}
        ></Route>

        <Route
          path="/staffrole"
          element={<Protect ComponentName={Staffrole} />}
        ></Route>

        <Route
          path="/addstaff"
          element={<Protect ComponentName={Addstaff} />}
        ></Route>

        <Route
          path="/staffdetails"
          element={<Protect ComponentName={Staffdetails} />}
        ></Route>

        <Route
          path="/updatestaff"
          element={<Protect ComponentName={Updatestaff} />}
        ></Route>

        <Route
          path="/QR Codes"
          element={<Protect ComponentName={Qrcodes} />}
        ></Route>

        <Route
          path="/qrcodedetails"
          element={<Protect ComponentName={Qrcodedetails} />}
        ></Route>
        <Route
          path="/addnewqr"
          element={<Protect ComponentName={Addnewqr} />}
        ></Route>
        <Route
          path="/Refunded"
          element={<Protect ComponentName={Refundedpro} />}
        ></Route>

        <Route
          path="/Rejected Refund"
          element={<Protect ComponentName={RefundRejected} />}
        ></Route>
        <Route
          path="/refundstatus"
          element={<Protect ComponentName={Refunddetails} />}
        ></Route>
        <Route
          path="/Approved Refund"
          element={<Protect ComponentName={Refundapproved} />}
        ></Route>
        <Route
          path="/bulkimport"
          element={<Protect ComponentName={Bulkimport} />}
        ></Route>
        <Route
          path="/hello"
          element={<Protect ComponentName={Screen} />}
        ></Route>

        <Route
          path="/helloside"
          element={<Protect ComponentName={Helloside} />}
        ></Route>
        <Route
          path="/editproduct"
          element={<Protect ComponentName={Editproduct} />}
        ></Route>

        <Route
          path="/editmydetails"
          element={<Protect ComponentName={Editmydetails} />}
        ></Route>

        <Route
          path="/editphoneinfo"
          element={<Protect ComponentName={Editphoneinfo} />}
        ></Route>

        <Route
          path="/Coupon"
          element={<Protect ComponentName={Coupan} />}
        ></Route>
        <Route
          path="/deletaccount"
          element={<Protect ComponentName={Deletaccount} />}
        ></Route>
        <Route
          path="/Add New Product"
          element={<Protect ComponentName={Addnewproduct} />}
        ></Route>
        <Route path="*" element={<Protect ComponentName={Error} />}></Route>

        <Route path="/test" element={<Test />}></Route>
      </Routes>
      {location.pathname !== "/forgotpassword" && location.pathname !== "/"}
    </div>
  );
}

export default App;
