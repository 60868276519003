import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import Header from "../Header";
import "../sidebar.css";
import { Link, useNavigate } from "react-router-dom";
import Sidebarr from "../Sidebar";
import "./Add.css";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import ReactPlayer from "react-player";
const Addnewproducts = () => {
  const [subcategorylistt, setsubcategorylistt] = useState();

  const [colors, setcategoryId] = useState();

  const [subcategoryId, setsubcategoryId] = useState();

  const [product_name, setproduct_name] = useState();
  const [frnz_product_name, setfrnz_product_name] = useState();
  const [description, setdescription] = useState();
  const [frnz_description, setfrnz_description] = useState();
  const [product_details, setproduct_details] = useState();
  const [product_code, setproduct_code] = useState();
  const [brand_name, setbrand_name] = useState();
  const [frnz_product_details, setfrnz_product_details] = useState();
  const [image1, setimage1] = useState();
  const [image2, setimage2] = useState();
  const [image3, setimage3] = useState();
  const [image4, setimage4] = useState();
  const [image5, setimage5] = useState();

  const [colorlist, setcolorlist] = useState();

  const [sizelist, setsizelist] = useState();

  const [sizeofselected, setsizeofselected] = useState();

  const [producttypedata, setproducttypedata] = useState([]);

  let token = secureLocalStorage.getItem("vendortoken");
  let venderIdd = secureLocalStorage.getItem("vendorid");

  const Navigate = useNavigate();

  const [categoryMenuVisible, setCategoryMenuVisible] = useState(true);
  const [subcategoryMenuVisible, setSubcategoryMenuVisible] = useState(false);
  const [subsubcategoryMenuVisible, setSubSubcategoryMenuVisible] =
    useState(false);

  const [categoryList, setCategoryList] = useState([]);
  const [subcategoryList, setSubcategoryList] = useState([]);
  const [subSubcategoryList, setSubSubcategoryList] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState(null);

  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [selectedSubSubcategory, setSelectedSubSubcategory] = useState(null);

  const [categoryFilter, setCategoryFilter] = useState("");

  let vendorId = secureLocalStorage.getItem("vendorid");

  useEffect(() => {
    getCategories();
  }, [0]);

  const getCategories = () => {
    const data = {
      venderId: vendorId,
    };

    let options = {
      headers: {
        token: token,
      },
    };

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/venderCategory_list`,
        data,
        options
      )
      .then((res) => {
        setCategoryList(res.data.data);
      })
      .catch((error) => {});
  };

  const handleCategoryClick = (categoryId) => {
    setSelectedCategory(categoryId);
    setSubcategoryMenuVisible(true);
    setSubSubcategoryMenuVisible(false);
    getSubcategories(categoryId);
  };

  const getSubcategories = (categoryId) => {
    const data = {
      categoryId: categoryId,
    };

    let options = {
      headers: {
        token: token,
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/venderSubcategory_list`,
        data,
        options
      )
      .then((res) => {
        setSubcategoryList(res.data.data);
        setSelectedSubcategory(null);
      })
      .catch((error) => {});
  };

  const handleSubcategoryClick = (subcategoryId) => {
    setSelectedSubcategory(subcategoryId);
    setSubSubcategoryMenuVisible(true);
    getSubSubcategories(subcategoryId);
  };

  const getSubSubcategories = (subcategoryId) => {
    const data = {
      subcategoryId: subcategoryId,
    };

    let options = {
      headers: {
        token: token,
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/sub_subcategory_list`,
        data,
        options
      )
      .then((res) => {
        setSubSubcategoryList(res.data.data);

        setSelectedSubSubcategory(null);
      })
      .catch((error) => {});
  };

  const filterCategories = (categories, filter) => {
    return categories.filter((category) =>
      category.category_englishName.toLowerCase().includes(filter.toLowerCase())
    );
  };

  useEffect(() => {
    getproducttype();
  }, [0]);
  let getproducttype = () => {
    let options = {
      headers: {
        token: token,
      },
    };
    axios
      .get(
        `${process.env.REACT_APP_API_KEY}vender/api/productType_list`,

        options
      )
      .then((res) => {
        setproducttypedata(res?.data?.data);
      })
      .catch((error) => {});
  };

  const [selectedCategories, setSelectedCategories] = useState(new Set());

  const [Selectedsize, setSelectedsize] = useState(new Set());

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleCheckboxChange = (color) => {
    setcategoryId((prevIds) => {
      const updatedIds = new Set(prevIds);

      if (updatedIds.has(color)) {
        setSelectedCategories((prevSelected) => {
          const updatedSet = new Set(prevSelected);
          updatedSet.delete(color);
          return updatedSet;
        });

        updatedIds.delete(color);
      } else {
        setSelectedCategories(
          (prevSelected) => new Set([...prevSelected, color])
        );

        updatedIds.add(color);
      }

      return Array.from(updatedIds);
    });
  };

  const handleCheckboxChangesize = (size) => {
    setsizeofselected((prevIds) => {
      const updatedIds = new Set(prevIds);

      if (updatedIds.has(size)) {
        setSelectedsize((prevSelected) => {
          const updatedSet = new Set(prevSelected);
          updatedSet.delete(size);
          return updatedSet;
        });

        updatedIds.delete(size);
      } else {
        setSelectedsize((prevSelected) => new Set([...prevSelected, size]));

        updatedIds.add(size);
      }

      return Array.from(updatedIds);
    });
  };

  // useEffect(() => {
  //   colordata();
  // },[0]);

  // useEffect(() => {
  //   getbrand_list();
  // }, [0]);
  let getbrand_list = () => {
    let data = {
      categoryId: selectedCategory,
    };

    let options = {
      headers: {
        token: token,
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/brand_list`,
        data,

        options
      )
      .then((res) => {
        setBrandlist(res?.data?.data);
      })
      .catch((error) => {});
  };
  const colordata = () => {
    const dataaboutt = {
      categoryId: selectedCategory,
    };

    let options = {
      headers: {
        token: token,
      },
    };

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/colorList`,
        dataaboutt,
        options
      )
      .then((res) => {
        setcolorlist(res.data.data);
      })
      .catch((error) => {});
  };

  // useEffect(() => {
  //   sizedata();
  // },[0]);

  const [productPreparation_time, setproductPreparation_time] = useState();
  const [productPreparation_time1, setproductPreparation_time1] = useState();
  const [warranty, setwarranty] = useState();
  const [warranty1, setwarranty1] = useState();
  const [note, setnote] = useState();
  const [unit_price, setunit_price] = useState();
  const [productType, setproductType] = useState();
  const [video, setvideo] = useState();

  const [deliveryType, setdeliveryType] = useState();
  const [minimum_order, setminimum_order] = useState();

  const [stockData, setStockData] = useState([
    { stock_name: "", stock_value: "" },
  ]);

  const handleStockChange = (index, field, updatedValue) => {
    const updatedStockData = [...stockData];
    updatedStockData[index][field] = updatedValue[field];
    setStockData(updatedStockData);
  };

  const [taxData, setTaxData] = useState([{ tax_name: "", tax_value: "" }]);

  const handleTaxChange = (index, field, updatedValue) => {
    const updatedTaxData = [...taxData];
    updatedTaxData[index][field] = updatedValue[field];
    setTaxData(updatedTaxData);
  };

  const [ReturnData, setReturnData] = useState([{ type: "", value: "" }]);

  const handleReturnChange = (index, field, updatedValue) => {
    setReturnData((prevReturnData) => {
      const updatedReturnData = [...prevReturnData];
      const updatedReturnItem = {
        ...updatedReturnData[index],
        [field]: updatedValue[field],
      };
      updatedReturnData[index] = updatedReturnItem;
      return updatedReturnData;
    });
  };

  const [discountData, setDiscountData] = useState([
    { discount_type: "", discount_value: "" },
  ]);

  const handleDiscountChange = (index, field, updatedValue) => {
    const updatedDiscountData = [...discountData];
    updatedDiscountData[index][field] = updatedValue[field];
    setDiscountData(updatedDiscountData);
  };

  const [product_weight, setproduct_weight] = useState();
  const [product_weight1, setproduct_weight1] = useState();
  const preparationTime = `${productPreparation_time} ${productPreparation_time1}`;
  const productWeight = `${product_weight} ${product_weight1}`;
  const warrantyDuration = `${warranty} ${warranty1}`;
  const [stockk, setstockk] = useState();

  // sadsadasd
  let [loaderstatus, setloaderstatus] = useState(true);
  let [simbolstatus, setsimbolstatusstatus] = useState(true);
  let [errorstatus, seterrorstatus] = useState(true);

  const addproduct = (e) => {
    e.preventDefault();

    if (
      !selectedCategory ||
      !selectedSubcategory ||
      !productType ||
      !selectedSubSubcategory ||
      !product_name ||
      !description ||
      !product_code ||
      !brand_name ||
      !productWeight ||
      !unit_price ||
      !discountData ||
      !taxData ||
      !stockData ||
      !ReturnData ||
      !minimum_order ||
      !deliveryType ||
      !image1
    ) {
      toast.error("Please fill in all fields...");
      return;
    }
    const formData = new FormData();

    formData.append("venderId", venderIdd);

    formData.append("categoryId", selectedCategory);
    formData.append("subcategoryId", selectedSubcategory);
    formData.append("productType", productType);
    formData.append("sub_subcategoryId", selectedSubSubcategory);
    formData.append("product_name", product_name);
    formData.append("frnz_product_name", frnz_product_name);
    formData.append("description", description);

    formData.append("product_code", product_code);
    formData.append("brand_name", brand_name);

    formData.append("productPreparation_time", preparationTime);
    formData.append("product_variation", JSON.stringify(productVariation));

    formData.append("product_details", product_details);
    formData.append("frnz_product_details", frnz_product_details);
    formData.append("warranty", warrantyDuration);

    formData.append("product_weight", productWeight);

    formData.append("note", note);
    formData.append("unit_price", unit_price);

    formData.append("discount", JSON.stringify(discountData));
    formData.append("Tax", JSON.stringify(taxData));

    formData.append("stock", stockk);

    formData.append("returnType", JSON.stringify(ReturnData));

    formData.append("minimum_order", minimum_order);
    formData.append("deliveryType", deliveryType);

    formData.append("image1", image1);
    formData.append("image2", image2);
    formData.append("image3", image3);
    formData.append("image4", image4);
    formData.append("image5", image5);
    formData.append("video", video);

    let options = {
      headers: {
        token: token,
      },
    };

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/addProduct`,
        formData,
        options
      )
      .then((res) => {
        setsimbolstatusstatus(false);
        setloaderstatus(true);
        setTimeout(() => {
          Navigate("/Products List");
        }, 5000);
        toast.success(`Your product has been successfully
        listed and is waiting for approval!`);
        //toast.success(res.data.message);
      })
      .catch((error) => {

      });
    setloaderstatus(false);
  };

  const handleDropdownToggle = () => {
    setCategoryMenuVisible(!categoryMenuVisible);
  };
  const [SubSubcategoryName, setSubSubcategoryName] = useState();

  const [brandlist, setBrandlist] = useState([]);

  const [filterText, setFilterText] = useState("");
  const [brandlistdetails, setBrandlistDetails] = useState(true);

  const handleDropdownToggle1 = () => {
    setBrandlistDetails(!brandlistdetails);
  };

  const handleBrandSelection = (selectedBrand) => {
    setbrand_name(selectedBrand);
    setBrandlistDetails(true);
  };

  const filteredBrandList = brandlist.filter((brandItem) =>
    brandItem?.brand?.some((brand) =>
      brand?.toLowerCase().includes(filterText.toLowerCase())
    )
  );

  const generatecode = () => {
    const length = 6;
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let result = "";

    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }

    return result;
  };

  const handleClick = () => {
    const code = generatecode();
    setproduct_code(code);
  };

  const [dataofattlist1, setDataofattlist1] = useState([]);
  const [ttlistdetails1, setTtlistdetails1] = useState([]);
  const [selectedVariations, setSelectedVariations] = useState([]);
  const [variationMenuVisible, setVariationMenuVisible] = useState(false);
  const [subVariationMenuVisible, setSubVariationMenuVisible] = useState(false);
  const [variationFilter, setVariationFilter] = useState("");
  const [selectedCategories23, setSelectedCategories23] = useState(new Map());

  const variationlist = () => {
    const data = {
      categoryId: selectedCategory,
    };
    axios
      .post(`${process.env.REACT_APP_API_KEY}vender/api/attributes_list`, data)
      .then((res) => {
        setDataofattlist1(res.data.data);
      })
      .catch((error) => {

      });
  };

  const getdetailsattribute = (item) => {
    const data = {
      attributeId: item,
    };
    axios
      .post(`${process.env.REACT_APP_API_KEY}vender/api/get_attribute`, data)
      .then((res) => {
        setTtlistdetails1(res.data.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleVariationClick = (variationId) => {
    setSelectedVariations([...selectedVariations, variationId]);
    setSubVariationMenuVisible(true);
    getdetailsattribute(variationId);
  };

  const filteredVariations = dataofattlist1.filter((variation) =>
    variation?.attribute_name
      ?.toLowerCase()
      .includes(variationFilter?.toLowerCase())
  );

  const handleCheckboxChange23 = (variationId, value) => {
    setSelectedCategories23((prevSelectedCategories23) => {
      const newSelectedCategories = new Map(prevSelectedCategories23);
      const currentValues = newSelectedCategories.get(variationId) || new Set();
      if (currentValues.has(value)) {
        currentValues.delete(value);
      } else {
        currentValues.add(value);
      }
      newSelectedCategories.set(variationId, currentValues);
      return newSelectedCategories;
    });
  };

  const productVariation = Array.from(selectedCategories23.entries()).map(
    ([variationId, values]) => ({
      attribute_name: dataofattlist1.find((v) => v._id === variationId)
        ?.attribute_name,
      attribute_values: Array.from(values).join(","),
    })
  );

  const close = () => {
    setSubVariationMenuVisible(false);
    setVariationMenuVisible(false);
  };

  return (
    <div className="container">
      <Toaster />
      {/* <Header /> */}
      <div
        className="container row"
        style={{ paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px" }}
      >
        <div className="col-lg-2 col-md-3" style={{ paddingLeft: "0px" }}>
          {/* <Sidebarr /> */}
        </div>

        <div
          className="col-lg-10 col-md-9"
          style={{ paddingLeft: "30px", marginTop: "60px" }}
        >
          <div className="d-flex flex-wrap gap-2 align-items-center mb-3 mt-3 pl-3">
            <h2 className="h1 mb-0 d-flex gap-2">
              <img
                src="https://6valley.6amtech.com/public/assets/back-end/img/inhouse-product-list.png"
                alt
              />
              Add product
            </h2>
          </div>
          <form className="product-form text-start" onSubmit={addproduct}>
            <input type="hidden" name="_token" />{" "}
            <div
              style={{ borderColor: "rgb(188 189 190)" }}
              className="card mt-3 rest-part"
            >
              <div
                style={{ borderBottomColor: "rgb(188 189 190)" }}
                className="card-header"
              >
                <div className="d-flex gap-2">
                  <i class="fa fa-user" aria-hidden="true"></i>
                  <h4 className="mb-0"> Product set up</h4>
                </div>
              </div>
              <div className="card-body">
                <div className="row mb">
                  <div className="col-md-12 col-lg-6 col-sm-12">
                    <div className="form-group">
                      <label className="title-color" htmlFor="en_name">
                        Product name (EN)<span className="text-danger">*</span>
                      </label>

                      <input
                        required
                        type="text"
                        value={product_name}
                        onChange={(e) => {
                          setproduct_name(e.target.value);
                        }}
                        className="form-control"
                        placeholder="Product name"
                      />
                    </div>
                  </div>

                  <div className="col-md-12 col-lg-6 col-sm-12">
                    <div className="form-group">
                      <label className="title-color" htmlFor="en_name">
                        Product name (FR) <span className="text-danger">*</span>
                      </label>

                      <input
                        required
                        type="text"
                        value={frnz_product_name}
                        onChange={(e) => {
                          setfrnz_product_name(e.target.value);
                        }}
                        className="form-control"
                        placeholder="Product name"
                      />
                    </div>
                  </div>

                  <div className="col-md-12 col-lg-6 col-sm-12">
                    <div className="form-group">
                      <label className="title-color">
                        Product description (EN){" "}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        required
                        value={description}
                        onChange={(e) => {
                          setdescription(e.target.value);
                        }}
                        className="js-select2-custom form-control"
                        name="brand_id"
                        placeholder="Enter Description"
                      ></input>
                    </div>
                  </div>

                  <div className="col-md-12 col-lg-6 col-sm-12">
                    <div className="form-group">
                      <label className="title-color">
                        Product description (FR){" "}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        required
                        value={frnz_description}
                        onChange={(e) => {
                          setfrnz_description(e.target.value);
                        }}
                        className="js-select2-custom form-control"
                        name="brand_id"
                        placeholder="Enter Description"
                      ></input>
                    </div>
                  </div>

                  <div className="col-md-12 col-lg-6 col-sm-12">
                    <div className="form-group">
                      <div className="dropdown">
                        <label className="title-color">
                          Product category{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <p
                          style={{ background: "#fffefd", color: "black" }}
                          className="js-select2-custom form-control mt-0"
                          onClick={handleDropdownToggle}
                        >
                          Product category {SubSubcategoryName}
                        </p>
                        {!categoryMenuVisible ? (
                          <div className="mb-2">
                            <input
                              placeholder="Search in categories"
                              style={{ width: "200px" }}
                              type="text"
                              id="categoryFilter"
                              className="form-control mt-2"
                              value={categoryFilter}
                              onChange={(e) =>
                                setCategoryFilter(e.target.value)
                              }
                            />
                          </div>
                        ) : null}

                        <ul
                          style={{
                            overflow: "scroll",
                            height: "270px",
                            scrollbarWidth: "none",
                            marginBottom: "20px",
                            background: "#fffefd",
                          }}
                          className={`dropdown-menu ${
                            !categoryMenuVisible ? "show" : ""
                          }`}
                        >
                          {filterCategories(categoryList, categoryFilter)
                            .length === 0 ? (
                            <li>
                              {" "}
                              <p className="btn btn-secondary ml-3">No data</p>
                            </li>
                          ) : (
                            filterCategories(categoryList, categoryFilter).map(
                              (category) => (
                                <li key={category.categoryId}>
                                  <p
                                    className={`btn btn-secondary ml-3 ${
                                      selectedCategory === category.categoryId
                                        ? "selected"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      handleCategoryClick(category.categoryId)
                                    }
                                  >
                                    {category.category_englishName}{" "}
                                    <span className="caret"></span>
                                  </p>
                                </li>
                              )
                            )
                          )}
                        </ul>
                        <ul
                          className={`dropdown-menu ${
                            !categoryMenuVisible && subcategoryMenuVisible
                              ? "show"
                              : ""
                          }`}
                          style={{
                            left: "200px",
                            overflow: "scroll",
                            height: "260px",
                            scrollbarWidth: "none",
                            background: "#fffefd",
                          }}
                        >
                          {subcategoryList.map((subcategory) => (
                            <li key={subcategory.subcategoryId}>
                              <p
                                className={`btn btn-secondary ml-3 ${
                                  selectedSubcategory ===
                                  subcategory.subcategoryId
                                    ? "selected"
                                    : ""
                                }`}
                                onClick={() =>
                                  handleSubcategoryClick(
                                    subcategory.subcategoryId
                                  )
                                }
                              >
                                {subcategory.english_subcategory_name}{" "}
                                <span className="caret"></span>
                              </p>
                            </li>
                          ))}
                        </ul>
                        <ul
                          className={`dropdown-menu ${
                            !categoryMenuVisible && subsubcategoryMenuVisible
                              ? "show"
                              : ""
                          }`}
                          style={{
                            left: "400px",
                            overflow: "scroll",
                            height: "260px",
                            scrollbarWidth: "none",
                            background: "#fffefd",
                          }}
                        >
                          {subSubcategoryList?.map((subcategory) => (
                            <li className="mt-3" key={subcategory._id}>
                              <p
                                className={`btn btn-secondary ml-3  ${
                                  selectedSubSubcategory === subcategory._id
                                    ? "selected"
                                    : ""
                                }`}
                                onClick={() => {
                                  setSelectedSubSubcategory(subcategory._id);
                                  setSubSubcategoryName(
                                    subcategory.english_sub_subcategory_name
                                  );
                                  handleDropdownToggle();
                                  colordata();
                                  getbrand_list();
                                  variationlist();
                                }}
                              >
                                {subcategory.english_sub_subcategory_name}{" "}
                                <span className="caret"></span>
                              </p>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 col-lg-6 col-sm-12">
                    <div className="form-group">
                      <label htmlFor="name" className="title-color">
                        Product type <span className="text-danger">*</span>
                      </label>

                      <select
                        required
                        className="js-select2-custom form-control"
                        name="sub_category_id"
                        value={productType}
                        onChange={(e) => {
                          setproductType(e.target.value);
                        }}
                      >
                        <option value="" disabled selected>
                          Select type
                        </option>
                        {producttypedata?.map((type, index) => (
                          <option key={index} value={type?.productType}>
                            {type?.productType}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-12 col-lg-6 col-sm-12">
                    <div className="form-group">
                      <label class="title-color d-flex justify-content-between gap-2">
                        <span class="d-flex align-items-center gap-2">
                          Product SKU<span className="text-danger">*</span>
                        </span>
                        <span
                          onClick={handleClick}
                          class="style-one-pro cursor-pointer user-select-none text--primary"
                        >
                          Generate code
                        </span>
                      </label>
                      <input
                        required
                        maxLength={6}
                        minLength={6}
                        placeholder="Product SKU"
                        type="text"
                        value={product_code}
                        onChange={(e) => {
                          setproduct_code(e.target.value);
                        }}
                        className="js-select2-custom form-control"
                      ></input>
                    </div>
                  </div>

                  <div className="col-md-12 col-lg-3 col-sm-12">
                    <div className="form-group">
                      <label className="title-color">Product weight</label>
                      <input
                        type="number"
                        value={product_weight}
                        onChange={(e) => {
                          setproduct_weight(e.target.value);
                        }}
                        className="js-select2-custom form-control"
                        placeholder="EX 10"
                      />
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-3 col-sm-12">
                    <div className="form-group">
                      <label
                        style={{ visibility: "hidden" }}
                        className="title-color"
                      >
                        Order preparation time
                      </label>
                      <select
                        className="js-select2-custom form-control"
                        name="sub_category_id"
                        value={product_weight1}
                        onChange={(e) => {
                          setproduct_weight1(e.target.value);
                        }}
                      >
                        <option value="" selected disabled>
                          Select unit
                        </option>
                        <option>Kg</option>
                        <option>g</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3 col-xl-3  col-sm-12">
                    <div className="form-group">
                      <label htmlFor="name" className="title-color">
                        Select brand <span className="text-danger">*</span>
                      </label>

                      <div className="" name="sub_category_id">
                        <select
                          class="js-select2-custom form-control"
                          onClick={handleDropdownToggle1}
                        >
                          <option>Select Brand</option>
                        </select>

                        {!brandlistdetails && (
                          <div
                            className=""
                            style={{
                              overflow: "scroll",
                              height: "160px",
                              scrollbarWidth: "none",
                              backgroundColor: "rgb(255, 254, 253)",
                            }}
                          >
                            <input
                              placeholder="Search in brand"
                              type="text"
                              id="categoryFilter"
                              className="form-control"
                              value={filterText}
                              onChange={(e) => setFilterText(e.target.value)}
                            />
                            {filteredBrandList?.length === 0 ? (
                              <li className="form-control"> No data</li>
                            ) : (
                              filteredBrandList?.map((brandItem, index) =>
                                brandItem?.brand?.map((brand, subIndex) => (
                                  <li
                                    className="form-control"
                                    key={`${index}-${subIndex}`}
                                    onClick={() => handleBrandSelection(brand)}
                                    style={{
                                      cursor: "pointer",
                                      backgroundColor: "#f4f5f7",
                                    }}
                                  >
                                    {brand}
                                  </li>
                                ))
                              )
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3 col-xl-3 col-sm-12">
                    <div className="form-group">
                      <label className="title-color">Brand not found?</label>
                      <input
                        type="text"
                        value={brand_name}
                        onChange={(e) => {
                          setbrand_name(e.target.value);
                        }}
                        className="js-select2-custom form-control"
                        placeholder="Type the brand name here"
                      ></input>
                    </div>
                  </div>

                  <div className="col-md-12 col-lg-3 col-sm-12">
                    <div className="form-group">
                      <label className="title-color">
                        Product order preparation time
                      </label>
                      <input
                        type="number"
                        value={productPreparation_time}
                        onChange={(e) => {
                          setproductPreparation_time(e.target.value);
                        }}
                        className="js-select2-custom form-control"
                        placeholder="0"
                      />
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-3 col-sm-12">
                    <div className="form-group">
                      <label
                        style={{ visibility: "hidden" }}
                        className="title-color"
                      >
                        Product order preparation time
                      </label>
                      <select
                        className="js-select2-custom form-control"
                        name="sub_category_id"
                        value={productPreparation_time1}
                        onChange={(e) => {
                          setproductPreparation_time1(e.target.value);
                        }}
                      >
                        <option value="" selected disabled>
                          minutes
                        </option>
                        <option>Minutes</option>
                        <option>Hours</option>
                        <option>Days</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{ borderColor: "rgb(188 189 190)" }}
              className="card mt-3 rest-part physical_product_show"
            >
              <div
                style={{ borderBottomColor: "rgb(188 189 190)" }}
                className="card-header"
              >
                <div className="d-flex gap-2">
                  <i class="fa fa-user" aria-hidden="true"></i>
                  <h4 className="mb-0">Product variation </h4>
                </div>
              </div>
              <div className="card-body mb-10">
                <div className="row align-items-end">
                  <div className="col-6">
                    <div className="">
                      <div>
                        {Array.from(selectedCategories23.entries()).map(
                          ([variationId, values]) => (
                            <p key={variationId}>
                              {
                                dataofattlist1.find(
                                  (v) => v._id === variationId
                                )?.attribute_name
                              }
                              : {Array.from(values).join(", ")}
                            </p>
                          )
                        )}
                        <p
                          style={{
                            background: "#fffefd",
                            color: "black",
                            width: "50%",
                          }}
                          className="form-control btn btn--secondary"
                          onClick={() =>
                            setVariationMenuVisible(!variationMenuVisible)
                          }
                        >
                          + Add variation
                        </p>
                        {variationMenuVisible && (
                          <div className="">
                            <input
                              style={{ width: "50%" }}
                              placeholder="Search variations"
                              type="text"
                              className="form-control mb-0"
                              onChange={(e) =>
                                setVariationFilter(e.target.value)
                              }
                            />
                          </div>
                        )}
                        <ul
                          style={{
                            overflow: "scroll",
                            height: "270px",
                            scrollbarWidth: "none",
                            background: "#fffefd",
                            marginTop: "0px",
                          }}
                          className={`dropdown-menu ${
                            variationMenuVisible ? "show" : ""
                          }`}
                        >
                          {filteredVariations.length > 0 ? (
                            filteredVariations?.map((variation) => (
                              <div
                                className=" cursor-pointer"
                                key={variation._id}
                              >
                                <p
                                  className={`btn btn-secondary ml-4 ${
                                    selectedVariations.includes(variation._id)
                                      ? "selected"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    handleVariationClick(variation._id)
                                  }
                                >
                                  {variation?.attribute_name}{" "}
                                  <span className="caret"></span>
                                </p>
                              </div>
                            ))
                          ) : (
                            <div>
                              <p className="btn btn-secondary ml-3">
                                Data not found
                              </p>
                            </div>
                          )}
                        </ul>
                        <div
                          className={`dropdown-menu ${
                            subVariationMenuVisible ? "show" : ""
                          }`}
                          style={{
                            left: "200px",
                            overflow: "scroll",
                            height: "260px",
                            scrollbarWidth: "none",
                            background: "#fffefd",
                          }}
                        >
                          {ttlistdetails1?.map((subVariation, index) => {
                            const values = Array.isArray(
                              subVariation?.attribute_values
                            )
                              ? subVariation?.attribute_values
                              : [];

                            return values.map((value, i) => (
                              <div
                                key={`${index}-${i}`}
                                className=" cursor-pointer col-md-12"
                              >
                                <div className="form-group d-flex gap-5 form-check ml-4">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id={`subVariation-${value}`}
                                    checked={selectedCategories23
                                      .get(selectedVariations.slice(-1)[0])
                                      ?.has(value)}
                                    onChange={() =>
                                      handleCheckboxChange23(
                                        selectedVariations.slice(-1)[0],
                                        value
                                      )
                                    }
                                  />
                                  <label
                                    className="form-check-label mt-1"
                                    htmlFor={`subVariation-${value}`}
                                  >
                                    {value}
                                  </label>
                                </div>
                              </div>
                            ));
                          })}
                          <div className="col-12 d-flex justify-content-end flex-wrap gap-10 mt-3">
                            <button
                              onClick={close}
                              className="btn btn-primary cancel px-4"
                              type="reset"
                            >
                              Done
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="col-md-12 mt-2 mb-2">
                <div
                  className="row customer_choice_options mt-2"
                  id="customer_choice_options"
                />
                <div
                  className="form-group sku_combination"
                  id="sku_combination"
                />
              </div> */}
            </div>
            <div
              style={{ borderColor: "rgb(188 189 190)" }}
              className="card mt-3 rest-part physical_product_show"
            >
              <div
                style={{ borderBottomColor: "rgb(188 189 190)" }}
                className="card-header"
              >
                <div className="d-flex gap-2">
                  <i class="fa fa-user" aria-hidden="true"></i>
                  <h4 className="mb-0">Product specifications</h4>
                </div>
              </div>
              <div className="card-body">
                <div className="row align-items-end">
                  <div className="col-lg-6 form-group">
                    <label className="title-color">
                      Main composition (EN) (optional)
                      <span className="text-danger"></span>
                    </label>
                    <input
                      type="text"
                      value={product_details}
                      onChange={(e) => {
                        setproduct_details(e.target.value);
                      }}
                      className="js-select2-custom form-control"
                      placeholder="Main composition"
                    ></input>
                  </div>
                  <div className="col-lg-6 form-group">
                    <label className="title-color">
                      Main composition (FR) (optional)
                      <span className="text-danger"></span>
                    </label>
                    <input
                      type="text"
                      value={frnz_product_details}
                      onChange={(e) => {
                        setfrnz_product_details(e.target.value);
                      }}
                      className="js-select2-custom form-control"
                      placeholder="Main composition"
                    ></input>
                  </div>
                  <div className="col-md-12 col-lg-6 col-sm-12 form-group">
                    <label className="title-color">
                      Additional note (optional)
                      <span className="text-danger"></span>
                    </label>
                    <input
                      type="text"
                      value={note}
                      onChange={(e) => {
                        setnote(e.target.value);
                      }}
                      className="js-select2-custom form-control"
                      placeholder="Additional note"
                    ></input>
                  </div>

                  <div className="col-md-6 col-lg-6 mb-0 col-sm-12">
                    <div className="form-group">
                      <label className="title-color">
                        Is this product eligible for delivery?
                      </label>
                      <select
                        className="js-select2-custom form-control"
                        name="sub_category_id"
                        value={deliveryType}
                        onChange={(e) => {
                          setdeliveryType(e.target.value);
                        }}
                      >
                        <option value="" selected disabled>
                          Select
                        </option>

                        <option>Yes</option>
                        <option>No</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-md-12 col-lg-3 col-sm-12">
                    <div className="form-group ">
                      <label className="title-color">
                        Warranty (optional)<span className="text-danger"></span>
                      </label>
                      <input
                        type="number"
                        placeholder="0"
                        className="js-select2-custom form-control"
                        name="sub_category_id"
                        value={warranty}
                        onChange={(e) => {
                          setwarranty(e.target.value);
                        }}
                      ></input>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-3 col-sm-12">
                    <div className="form-group">
                      <label
                        style={{ visibility: "hidden" }}
                        className="title-color"
                      >
                        Product order preparation time
                      </label>
                      <select
                        className="js-select2-custom form-control"
                        name="sub_category_id"
                        value={warranty1}
                        onChange={(e) => {
                          setwarranty1(e.target.value);
                        }}
                      >
                        <option value="" selected disabled>
                          Select duration
                        </option>
                        <option>Day</option>
                        <option>Week</option>
                        <option>Month</option>
                        <option>Year</option>
                      </select>
                    </div>
                  </div>

                  <div className="card-body pt-0 pb-0">
                    {ReturnData.map((Return, index) => (
                      <div key={index} className="row">
                        <div className="col-md-5 col-lg-5  col-sm-12">
                          <div className="form-group">
                            <label className="title-color">
                              Do you accept returns? <span className="text-danger">*</span>
                            </label>
                            <select rel=""
                              className="js-select2-custom form-control"
                              value={Return.type || ""}
                              onChange={(e) =>
                                handleReturnChange(index, "type", {
                                  ...Return,
                                  type: e.target.value,
                                })
                              }
                            >
                              <option disabled selected value="">
                                Select
                              </option>
                              <option value={1}>Yes</option>
                              <option value={0}>No</option>
                            </select>
                          </div>
                        </div>

                        {ReturnData[0].type == "1" ? (
                          <div className="col-md-7 col-lg-7 col-sm-12">
                            <div className="form-group">
                              <label className="title-color">
                                Until how many days after purchase?
                              </label>
                              <input
                                type="text"
                                placeholder="Ex: 7"
                                className="js-select2-custom form-control"
                                value={Return.value || ""}
                                onChange={(e) =>
                                  handleReturnChange(index, "value", {
                                    ...Return,
                                    value: e.target.value,
                                  })
                                }
                              ></input>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    ))}
                  </div>

                  {/* </div> */}
                </div>
              </div>

              {/* <div className="col-md-12 mt-2 mb-2">
                <div
                  className="row customer_choice_options mt-2"
                  id="customer_choice_options"
                />
                <div
                  className="form-group sku_combination"
                  id="sku_combination"
                />
              </div> */}
            </div>
            <div
              style={{ borderColor: "rgb(188 189 190)" }}
              className="card mt-3 rest-part"
            >
              <div
                style={{ borderBottomColor: "rgb(188 189 190)" }}
                className="card-header"
              >
                <div className="d-flex gap-2">
                  <i class="fa fa-user" aria-hidden="true"></i>
                  <h4 className="mb-0">Price and &amp; stock</h4>
                </div>
              </div>
              <div className="card-body">
                <div className="row ">
                  <div className="col-md-6 col-lg-6 col-xl-6 mb-0 col-sm-12">
                    <div className="form-group">
                      <div className="d-flex gap-2 mb-2">
                        <label className="title-color mb-0">
                          Unit price <span className="text-danger">*</span>
                        </label>
                        <span
                          className="input-label-secondary cursor-pointer"
                          data-toggle="tooltip"
                          title="Add the unit price for this product."
                        >
                          <img
                            src="https://6valley.6amtech.com/public/assets/back-end/img/info-circle.svg"
                            alt
                          />
                        </span>
                      </div>
                      <input
                        required
                        type="number"
                        min={0}
                        step="0.01"
                        placeholder="Unit price "
                        value={unit_price}
                        onChange={(e) => {
                          setunit_price(e.target.value);
                        }}
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6 col-xl-6 mb-0 col-sm-12">
                    <div className="card-body pt-0 pb-0">
                      {taxData.map((tax, index) => (
                        <div key={index} className="row">
                          <div className="col-md-12 col-lg-6 col-sm-12">
                            <div className="form-group">
                              <label className="title-color">Tax</label>
                              <select
                                className="js-select2-custom form-control"
                                value={tax.tax_name}
                                onChange={(e) =>
                                  handleTaxChange(index, "tax_name", {
                                    tax_name: e.target.value,
                                  })
                                }
                              >
                                <option value="" disabled>
                                  Select
                                </option>
                                <option>Exclude</option>
                                <option>in-Exclude</option>
                                <option>op-Exclude</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-12 col-lg-6 col-sm-12">
                            <div className="form-group">
                              <label className="title-color">
                                Tax percent (%)
                              </label>
                              <input
                                type="number"
                                value={tax.tax_value}
                                onChange={(e) =>
                                  handleTaxChange(index, "tax_value", {
                                    tax_value: parseInt(e.target.value, 10),
                                  })
                                }
                                className="js-select2-custom form-control"
                                placeholder="0"
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-3 col-xl-3">
                    <div className="form-group">
                      <div className="d-flex gap-2 mb-2">
                        <label className="title-color mb-0">
                          Minimum order <span className="text-danger">*</span>
                        </label>
                        <span
                          className="input-label-secondary cursor-pointer"
                          data-toggle="tooltip"
                          title="Set the selling price for each unit of this product. This Unit Price section wonâ€™t be applied if you set a variation wise price."
                        ></span>
                      </div>
                      <input
                        required
                        value={minimum_order}
                        type="number"
                        min={0}
                        step="0.01"
                        placeholder="minimum_order"
                        onChange={(e) => {
                          setminimum_order(e.target.value);
                        }}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3 col-xl-3">
                    <div className="form-group">
                      <div className="d-flex gap-2 mb-2">
                        <label className="title-color mb-0">
                          Stock Quantity <span className="text-danger">*</span>
                        </label>
                        <span
                          className="input-label-secondary cursor-pointer"
                          data-toggle="tooltip"
                          title="Set the selling price for each unit of this product. This Unit Price section wonâ€™t be applied if you set a variation wise price."
                        ></span>
                      </div>
                      <input
                        required
                        value={stockk}
                        type="number"
                        min={0}
                        step="0.01"
                        placeholder="stock quantity"
                        onChange={(e) => {
                          setstockk(e.target.value);
                        }}
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6 col-xl-6 mb-0 col-sm-12">
                    <div className="card-body pt-0 pb-0">
                      {discountData.map((discount, index) => (
                        <div key={index} className="row">
                          <div className="col-md-12 col-lg-6 col-sm-12">
                            <div className="form-group">
                              <label className="title-color">
                                Discount type
                              </label>
                              <select
                                className="js-select2-custom form-control"
                                value={discount.discount_type}
                                onChange={(e) =>
                                  handleDiscountChange(index, "discount_type", {
                                    discount_type: e.target.value,
                                  })
                                }
                              >
                                <option value="" disabled>
                                  Select
                                </option>
                                <option value="Unit">Unit</option>
                                <option value="Percent">Percent</option>
                              </select>
                            </div>
                          </div>

                          <div className="col-md-12 col-lg-6 col-sm-12">
                            <div className="form-group">
                              <label className="title-color">
                                Discount Amount
                              </label>
                              <input
                                type="number"
                                value={discount.discount_value}
                                onChange={(e) =>
                                  handleDiscountChange(
                                    index,
                                    "discount_value",
                                    {
                                      discount_value: parseInt(
                                        e.target.value,
                                        10
                                      ),
                                    }
                                  )
                                }
                                className="js-select2-custom form-control"
                                placeholder="0"
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3 rest-part">
              <div className="row g-2">
                <div className="col-md-4">
                  <div className="card h-100">
                    <div className="card-body">
                      <div className="form-group">
                        <div className="d-flex align-items-center gap-2 mb-3">
                          <label
                            htmlFor="name"
                            className="title-color text-capitalize font-weight-bold mb-0"
                          >
                            Product Image <span className="text-danger">*</span>
                          </label>
                        </div>
                        <div>
                          <div>
                            <div className="custom_upload_input">
                              <input
                                required
                                onChange={(e) => {
                                  setimage1(e.target.files[0]);
                                }}
                                type="file"
                                name="image"
                                className="custom-upload-input-file"
                                id
                                data-imgpreview="pre_img_viewer"
                                accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                              />
                              <span
                                className="delete_file_input btn btn-outline-danger btn-sm square-btn"
                                style={{ display: "none" }}
                              >
                                <i className="tio-delete" />
                              </span>
                              <div className="img_area_with_preview position-absolute z-index-2">
                                <img
                                  id="pre_img_viewer"
                                  className="h-auto aspect-1 bg-white d-none"
                                  src="img"
                                  onerror="this.classList.add('d-none')"
                                />
                              </div>
                              <div className="position-absolute h-100 top-0 w-100 d-flex align-content-center justify-content-center">
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                  {image1 ? (
                                    <img
                                      src={URL.createObjectURL(image1)}
                                      className="w-50"
                                    />
                                  ) : (
                                    <>
                                      <img
                                        src="https://6valley.6amtech.com/public/assets/back-end/img/icons/product-upload-icon.svg"
                                        className="w-50"
                                      />
                                      <h3 className="text-muted">
                                        Upload Image
                                      </h3>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                            <p className="text-muted mt-2">
                              Image format : Jpg, png, jpeg <br />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card h-100">
                    <div className="card-body">
                      <div className="form-group">
                        <div className="d-flex align-items-center gap-2 mb-3">
                          <label
                            htmlFor="name"
                            className="title-color text-capitalize font-weight-bold mb-0"
                          >
                            Product Image
                          </label>
                        </div>
                        <div>
                          <div>
                            <div className="custom_upload_input">
                              <input
                                type="file"
                                name="image"
                                className="custom-upload-input-file"
                                id
                                data-imgpreview="pre_img_viewer"
                                accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                                onChange={(e) => {
                                  setimage2(e.target.files[0]);
                                }}
                              />
                              <span
                                className="delete_file_input btn btn-outline-danger btn-sm square-btn"
                                style={{ display: "none" }}
                              >
                                <i className="tio-delete" />
                              </span>
                              <div className="img_area_with_preview position-absolute z-index-2">
                                <img
                                  id="pre_img_viewer"
                                  className="h-auto aspect-1 bg-white d-none"
                                  src="img"
                                  onerror="this.classList.add('d-none')"
                                />
                              </div>
                              <div className="position-absolute h-100 top-0 w-100 d-flex align-content-center justify-content-center">
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                  {image2 ? (
                                    <img
                                      src={URL.createObjectURL(image2)}
                                      className="w-50"
                                    />
                                  ) : (
                                    <>
                                      <img
                                        src="https://6valley.6amtech.com/public/assets/back-end/img/icons/product-upload-icon.svg"
                                        className="w-50"
                                      />
                                      <h3 className="text-muted">
                                        Upload Image
                                      </h3>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                            <p className="text-muted mt-2">
                              Image format : Jpg, png, jpeg <br />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card h-100">
                    <div className="card-body">
                      <div className="form-group">
                        <div className="d-flex align-items-center gap-2 mb-3">
                          <label
                            htmlFor="name"
                            className="title-color text-capitalize font-weight-bold mb-0"
                          >
                            Product Image
                          </label>
                        </div>
                        <div>
                          <div>
                            <div className="custom_upload_input">
                              <input
                                type="file"
                                name="image"
                                className="custom-upload-input-file"
                                id
                                data-imgpreview="pre_img_viewer"
                                accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                                onChange={(e) => {
                                  setimage3(e.target.files[0]);
                                }}
                              />
                              <span
                                className="delete_file_input btn btn-outline-danger btn-sm square-btn"
                                style={{ display: "none" }}
                              >
                                <i className="tio-delete" />
                              </span>
                              <div className="img_area_with_preview position-absolute z-index-2">
                                <img
                                  id="pre_img_viewer"
                                  className="h-auto aspect-1 bg-white d-none"
                                  src="img"
                                  onerror="this.classList.add('d-none')"
                                />
                              </div>
                              <div className="position-absolute h-100 top-0 w-100 d-flex align-content-center justify-content-center">
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                  {image3 ? (
                                    <img
                                      src={URL.createObjectURL(image3)}
                                      className="w-50"
                                    />
                                  ) : (
                                    <>
                                      <img
                                        src="https://6valley.6amtech.com/public/assets/back-end/img/icons/product-upload-icon.svg"
                                        className="w-50"
                                      />
                                      <h3 className="text-muted">
                                        Upload Image
                                      </h3>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                            <p className="text-muted mt-2">
                              Image format : Jpg, png, jpeg <br />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="card h-100">
                    <div className="card-body">
                      <div className="form-group">
                        <div className="d-flex align-items-center gap-2 mb-3">
                          <label
                            htmlFor="name"
                            className="title-color text-capitalize font-weight-bold mb-0"
                          >
                            Product Image
                          </label>
                        </div>
                        <div>
                          <div>
                            <div className="custom_upload_input">
                              <input
                                type="file"
                                name="image"
                                className="custom-upload-input-file"
                                id
                                data-imgpreview="pre_img_viewer"
                                accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                                onChange={(e) => {
                                  setimage4(e.target.files[0]);
                                }}
                              />
                              <span
                                className="delete_file_input btn btn-outline-danger btn-sm square-btn"
                                style={{ display: "none" }}
                              >
                                <i className="tio-delete" />
                              </span>
                              <div className="img_area_with_preview position-absolute z-index-2">
                                <img
                                  id="pre_img_viewer"
                                  className="h-auto aspect-1 bg-white d-none"
                                  src="img"
                                  onerror="this.classList.add('d-none')"
                                />
                              </div>
                              <div className="position-absolute h-100 top-0 w-100 d-flex align-content-center justify-content-center">
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                  {image4 ? (
                                    <img
                                      src={URL.createObjectURL(image4)}
                                      className="w-50"
                                    />
                                  ) : (
                                    <>
                                      <img
                                        src="https://6valley.6amtech.com/public/assets/back-end/img/icons/product-upload-icon.svg"
                                        className="w-50"
                                      />
                                      <h3 className="text-muted">
                                        Upload Image
                                      </h3>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                            <p className="text-muted mt-2">
                              Image format : Jpg, png, jpeg <br />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="card h-100">
                    <div className="card-body">
                      <div className="form-group">
                        <div className="d-flex align-items-center gap-2 mb-3">
                          <label
                            htmlFor="name"
                            className="title-color text-capitalize font-weight-bold mb-0"
                          >
                            Product Image
                          </label>
                        </div>
                        <div>
                          <div>
                            <div className="custom_upload_input">
                              <input
                                type="file"
                                name="image"
                                className="custom-upload-input-file"
                                id
                                data-imgpreview="pre_img_viewer"
                                accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                                onChange={(e) => {
                                  setimage5(e.target.files[0]);
                                }}
                              />
                              <span
                                className="delete_file_input btn btn-outline-danger btn-sm square-btn"
                                style={{ display: "none" }}
                              >
                                <i className="tio-delete" />
                              </span>
                              <div className="img_area_with_preview position-absolute z-index-2">
                                <img
                                  id="pre_img_viewer"
                                  className="h-auto aspect-1 bg-white d-none"
                                  src="img"
                                  onerror="this.classList.add('d-none')"
                                />
                              </div>
                              <div className="position-absolute h-100 top-0 w-100 d-flex align-content-center justify-content-center">
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                  {image5 ? (
                                    <img
                                      src={URL.createObjectURL(image5)}
                                      className="w-50"
                                    />
                                  ) : (
                                    <>
                                      <img
                                        src="https://6valley.6amtech.com/public/assets/back-end/img/icons/product-upload-icon.svg"
                                        className="w-50"
                                      />
                                      <h3 className="text-muted">
                                        Upload Image
                                      </h3>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                            <p className="text-muted mt-2">
                              Image format : Jpg, png, jpeg <br />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="card h-100">
                    <div className="card-body">
                      <div className="form-group">
                        <div className="d-flex align-items-center gap-2 mb-3">
                          <label
                            htmlFor="name"
                            className="title-color text-capitalize font-weight-bold mb-0"
                          >
                            Product Video
                          </label>
                        </div>
                        <div>
                          <div>
                            <div className="custom_upload_input">
                              <input
                                type="file"
                                name="image"
                                className="custom-upload-input-file"
                                id
                                data-imgpreview="pre_img_viewer"
                                accept=".mp4, .mov, .wmv, .flv, .avi, .avchd|video/*"
                                onChange={(e) => {
                                  setvideo(e.target.files[0]);
                                }}
                              />
                              <span
                                className="delete_file_input btn btn-outline-danger btn-sm square-btn"
                                style={{ display: "none" }}
                              >
                                <i className="tio-delete" />
                              </span>
                              <div className="img_area_with_preview position-absolute z-index-2">
                                <img
                                  id="pre_img_viewer"
                                  className="h-auto aspect-1 bg-white d-none"
                                  src="img"
                                  onerror="this.classList.add('d-none')"
                                />
                              </div>
                              <div className="position-absolute h-100 top-0 w-100 d-flex align-content-center justify-content-center">
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                  {video ? (
                                    <ReactPlayer
                                      controls
                                      playing
                                      url={URL.createObjectURL(video)}
                                      className="w-50"
                                    />
                                  ) : (
                                    <>
                                      <img
                                        src="https://6valley.6amtech.com/public/assets/back-end/img/icons/product-upload-icon.svg"
                                        className="w-50"
                                      />
                                      <h3 className="text-muted">
                                        Upload Video
                                      </h3>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                            <p className="text-muted mt-2">
                              Video format : .MP4, .mov, .wmv, .flv, .avi,
                              .avchd <br />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-end gap-3 mt-3 mx-1 mb-8">
              <div className="text-center form-group text-center qout-submit-btn">
                {loaderstatus == true ? (
                  simbolstatus == true ? (
                    <button
                      type="submit"
                      className="btn btn--primary text-center site-button clickksidebutton"
                    >
                      Done
                    </button>
                  ) : errorstatus == true ? (
                    <>
                      <h6 className="text-success m-2">Success</h6>
                    </>
                  ) : (
                    <>
                      <h6 className="text-danger m-2">Try-Agian</h6>
                    </>
                  )
                ) : errorstatus == true ? (
                  <button className="loader m-2"></button>
                ) : (
                  <>
                    <h6 className="text-danger m-2">Try-Again</h6>
                  </>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Addnewproducts;
