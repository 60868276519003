import React from 'react'
import Header from '../Header'

import Sidebarr from '../Sidebar'

const Paymentmanagement = () => {
  return (
    <div className='container'>


    {/* <Header /> */}
    <div className='container row' style={{ paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px" }}>

        <div className="col-lg-2 col-md-3" style={{ paddingLeft: "0px" }}>
            {/* <Sidebarr /> */}
        </div>

        <div className="col-lg-10 col-md-9" style={{ paddingLeft: "30px", marginTop: "60px" }}>
            <div className=" mt-3">
            <div className="card  mt-3">
            <div className="card-header text-capitalize">
              <h4 className="mb-0">Filter options</h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-12 pt-3">
                  <form >
                    <div className="row">
                      <div className="col-sm-6 col-12">
                        <div className="mb-3">
                          <input type="date" name="from" id="from_date" defaultValue className="form-control" title="From date" />
                        </div>
                      </div>
                      <div className="col-sm-6 col-12">
                        <div className="mb-3">
                          <input type="date" name="to" id="to_date" defaultValue className="form-control" title="To date" />
                        </div>
                      </div>
                      <div className="col-sm-6 col-12">
                        <div className="mb-3">
                          <select name="transaction_type" id className="form-control" title="Select transaction type">
                            <option value>All</option>
                            <option value="add_fund_by_admin">Add fund by admin</option>
                            <option value="order_refund">Refund order</option>
                            <option value="loyalty_point">Customer loyalty point</option>
                            <option value="order_place">Order place</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-6 col-12">
                        <div className="mb-3">
                          <select id="customer" name="customer_id" data-placeholder="Select customer" className="js-data-example-ajax form-control" title="Select customer">
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end">
                      <button type="submit" className="btn btn--primary px-4"><i class="fa fa-filter" aria-hidden="true"></i> Filter</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="card mt-3">
            <div className="card-header text-capitalize">
              <h4 className="mb-0">Summary</h4>
            </div>
            <div className="card-body">
              <div className="d-flex flex-wrap gap-3">
                <div className="order-stats flex-grow-1">
                  <div className="order-stats__content">
                  <i class="fa fa-cc-visa" aria-hidden="true"></i>
                    <h6 className="order-stats__subtitle">Debit</h6>
                  </div>
                  <span className="order-stats__title fz-14 text--primary">
                    $7,335.00
                  </span>
                </div>
                <div className="order-stats flex-grow-1">
                  <div className="order-stats__content">
                  <i class="fa fa-money" aria-hidden="true"></i>
                    <h6 className="order-stats__subtitle">Credit</h6>
                  </div>
                  <span className="order-stats__title fz-14 text-warning">
                    $64,739.00
                  </span>
                </div>
                <div className="order-stats flex-grow-1">
                  <div className="order-stats__content">
                    <i class="fa fa-money" aria-hidden="true"></i>
                    <h6 className="order-stats__subtitle">Balance</h6>
                  </div>
                  <span className="order-stats__title fz-14 text-success">
                    $57,404.00
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="card mt-3">
            <div className="card-header text-capitalize gap-2">
              <h4 className="mb-0 text-nowrap ">
                Transactions
                <span className="badge badge-soft-dark radius-50 fz-12 ml-1">14</span>
              </h4>
           
            </div>
            <div className="table-responsive">
              <table id="datatable" className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table text-left">
                <thead className="thead-light thead-50 text-capitalize">
                  <tr>
                    <th>SL</th>
                    <th>Transaction ID</th>
                    <th>Customer</th>
                    <th>Credit</th>
                    <th>Debit</th>
                    <th>Balance</th>
                    <th>Transaction type</th>
                    <th>Reference</th>
                    <th className="text-center">Created at</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>05f4fb07-e72a-48e4-825c-2aa2b14e1b5a</td>
                    <td><a href="#" className="title-color hover-c1">Devid Jack</a></td>
                    <td>$500.00</td>
                    <td>$0.00</td>
                    <td>$1,725.00</td>
                    <td>
                      <span className="badge badge-soft-success">
                        Add fund
                      </span>
                    </td>
                    <td>Add funds to wallet</td>
                    <td className="text-center">2023/10/12 </td>
                  </tr>
                 
                  
                </tbody>
              </table>
            </div>
            <div className="table-responsive mt-4">
              <div className="px-4 d-flex justify-content-lg-end">
              </div>
            </div>
          </div>
            </div>
        </div>



    </div>




    </div>
  )
}

export default Paymentmanagement
