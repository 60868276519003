import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./manubar.css";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { BiCategory } from "react-icons/bi";
import {
  MdOutlineDescription,
  MdPolicy,
  MdProductionQuantityLimits,
} from "react-icons/md";
import { MdDiscount } from "react-icons/md";
import { GrTransaction } from "react-icons/gr";
import { MdPayments } from "react-icons/md";
import { MdSubscriptions } from "react-icons/md";
import { MdOutlineFreeCancellation } from "react-icons/md";

import { MdReviews } from "react-icons/md";
import { IoMdNotifications } from "react-icons/io";
import { FaRocketchat, FaUser } from "react-icons/fa";
import { BiMoneyWithdraw } from "react-icons/bi";
import { CiBank } from "react-icons/ci";
import { FaShoppingBag } from "react-icons/fa";
import { TbBrandProducthunt } from "react-icons/tb";

import { RiRefundLine } from "react-icons/ri";
const Login = () => {
  let navigate = useNavigate();
  let login = () => {
    navigate(`/home`);
  };

  let [passwordtype, setpasswordtype] = useState("password");
  let [iconstatus, seticonstatus] = useState(false);

  let passwordicon = () => {
    setpasswordtype("text");
    seticonstatus(true);
  };
  let passwordicon1 = () => {
    seticonstatus(false);
    setpasswordtype("password");
  };

  const [vendoremail, setvendormobile] = useState("");
  const [vendorpassword, setadminpassword] = useState("");
  const Navigate = useNavigate();
  let fcm = secureLocalStorage.getItem("currentToken");

  const handlesubmit = (e) => {
    e.preventDefault();

    const userdata = {
      key: vendoremail,
      password: vendorpassword,
      fcm_Id: fcm,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/venderLogin_withEmail`,
        userdata
      )
      .then((response) => {


        secureLocalStorage.setItem("roue", response?.data?.restrictions);
        secureLocalStorage.setItem(
          "serviceTypeeee",
          response?.data?.data?.serviceType
        );

        if (response.data.message === "Staff login sucessfully")
           {
            secureLocalStorage.setItem("iconss", [
              "BiCategory",
              "BiCategory",
              "BiCategory",
              "BiCategory",
              "BiCategory",
              "BiCategory",
              "BiCategory",
              "BiCategory",
              "BiCategory",
              "BiCategory",
              "BiCategory",
              "BiCategory",
              "BiCategory",
              "BiCategory",
              "BiCategory",
              "BiCategory",
              "BiCategory",
              "BiCategory",
            ]);
          secureLocalStorage.setItem("vendorid", response?.data?.data.shopId);
          secureLocalStorage.setItem(
            "shopidofvendor",
            response?.data?.data.shopId
          );
          secureLocalStorage.setItem("firstnamee", response?.data?.data?.fname);
        } else {
          secureLocalStorage.setItem("iconss", response?.data?.icons);
          secureLocalStorage.setItem("vendorid", response?.data?.data._id);
          secureLocalStorage.setItem(
            "shopidofvendor",
            response?.data?.data?._id
          );
          secureLocalStorage.setItem(
            "firstnamee",
            response?.data?.data?.firstName
          );
        }

        // secureLocalStorage.setItem("vendorid", response?.data?.data._id);
        secureLocalStorage.setItem("vendortoken", response?.data?.token);
        secureLocalStorage.setItem(
          "vendormobile",
          response?.data?.data.mobile_number
        );
        secureLocalStorage.setItem("vendoremail", response?.data?.data?.email);

        secureLocalStorage.setItem("shopname", response?.data?.data?.shop_name);
        // secureLocalStorage.setItem(
        //   "firstnamee",
        //   response?.data?.data?.firstName
        // );
        // secureLocalStorage.setItem("shopidofvendor", response?.data?.data?._id);

        if (response.data.message === "Staff login sucessfully") {
          toast.success(response?.data?.message);
        } else {
          toast.success(`Welcome ${response?.data?.data?.firstName}`);
        }

        // toast.success(`Welcome ${response?.data?.data?.firstName}`);

        setTimeout(() => {
          Navigate("/home");
        }, 3000);
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Invalid Data Entered by you.");
        }
      });
  };

  return (
    <div className="">
      <Toaster />
      <div
        className="position-fixed top-0 right-0 left-0 bg-img-hero __inline-1"
        style={{
          backgroundImage:
            "url(https://6valley.6amtech.com/public/assets/admin/svg/components/abstract-bg-4.svg)",
        }}
      >
        <figure className="position-absolute right-0 bottom-0 left-0">
          <svg
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1921 273"
          >
            <polygon fill="#f9f9fb" points="0,273 1921,273 1921,0 " />
          </svg>
        </figure>
      </div>
      <div className="container py-5 py-sm-7">
        <a className="d-flex justify-content-center mb-5" href="javascript:">
          <img
            className=""
            style={{ height: "80%" }}
            src="./logonew.png"
            alt="Logo"
          />
        </a>
        <div className="row justify-content-center">
          <div className="col-md-7 col-lg-5">
            <div className="card card-lg mb-5">
              <div className="card-body">
                <form onSubmit={handlesubmit}>
                  <input type="hidden" name="_token" />
                  <div className="text-center">
                    <div className="mb-5">
                      <span>(Seller Login)</span>
                    </div>
                  </div>
                  <input
                    type="hidden"
                    className="form-control mb-3"
                    name="role"
                  />
                  <div className="js-form-message form-group">
                    <label className="input-label" htmlFor="signinSrEmail">
                      Email or Phone <span className="text-danger">*</span>
                    </label>
                    <input
                      required
                      type="text"
                      className="form-control form-control-lg"
                      name="email"
                      onChange={(e) => {
                        setvendormobile(e.target.value);
                      }}
                      placeholder="Email or Phone"
                    />
                  </div>
                  <div className="js-form-message form-group">
                    {/* <label
                      className="input-label"
                      htmlFor="signupSrPassword"
                      tabIndex={0}
                    >
                      <span className="d-flex justify-content-between align-items-center">
                        Password<span className='text-danger'>*</span>
                      </span>
                    </label> */}
                    <label className="input-label" htmlFor="signinSrEmail">
                      Password <span className="text-danger">*</span>
                    </label>
                    <div className="input-group input-group-merge iconinput">
                      <input
                        required
                        placeholder="Password "
                        onChange={(e) => {
                          setadminpassword(e.target.value);
                        }}
                        type={passwordtype == "password" ? "password" : "text"}
                        className="js-toggle-password form-control form-control-lg"
                        name="password"
                      />

                      {iconstatus == false ? (
                        <i
                          onClick={passwordicon}
                          class="fa fa-eye eyeicon"
                          aria-hidden="true"
                        ></i>
                      ) : (
                        <i
                          onClick={passwordicon1}
                          class="fa fa-eye-slash eyeicon"
                          aria-hidden="true"
                        ></i>
                      )}
                    </div>
                    <div
                      id="recaptcha_element"
                      className="w-100;"
                      data-type="image"
                    />
                    <br />
                    <button
                      type="submit"
                      className="btn btn-lg btn-block btn--primary"
                    >
                      Sign in
                    </button>
                  </div>

                  <div className="js-form-message form-group">
                    <div class="d-flex justify-content-center align-items-center">
                      <Link
                        to="/forgotpassword"
                        className="input-label"
                        htmlFor="signupSrPassword"
                        tabIndex={0}
                      >
                        <span
                          style={{ color: "#004BAD", fontStyle: "italic" }}
                          className="d-flex justify-content-center align-items-center"
                        >
                          Forgot Password ?
                        </span>
                      </Link>
                      {/* <Link
                        to="/signup"
                        className="input-label"
                        htmlFor="signupSrPassword"
                        tabIndex={0}
                      >
                        <span className="d-flex justify-content-between align-items-center">
                          Sign Up
                        </span>
                      </Link> */}
                    </div>
                  </div>
                  <div className="js-form-message form-group">
                    <div class="d-flex justify-content-center align-items-center">
                      {/* <Link
                        to="/forgotpassword"
                        className="input-label"
                        htmlFor="signupSrPassword"
                        tabIndex={0}
                      >
                        <span style={{color:'#004BAD', fontStyle:'italic'}} className="d-flex justify-content-center align-items-center">
                          Forgot Password ?
                        </span>
                      </Link> */}
                      {/* <Link
                        to="/signup"
                        className="input-label"
                        htmlFor="signupSrPassword"
                        tabIndex={0}
                      > */}
                      <span className="d-flex justify-content-center align-items-center">
                        Don't have account yet? &nbsp;{" "}
                        <Link
                          to="/signup"
                          className="input-label"
                          htmlFor="signupSrPassword"
                          tabIndex={0}
                        >
                          {" "}
                          <span
                            style={{ color: "#004BAD", fontStyle: "italic" }}
                          >
                            {" "}
                            Sign up
                          </span>
                        </Link>
                      </span>
                      {/* </Link>  */}
                    </div>
                  </div>

                  {/* <div
                    id="recaptcha_element"
                    className="w-100;"
                    data-type="image"
                  />
                  <br />
                  <button
                    type="submit"
                    className="btn btn-lg btn-block btn--primary"
                  >
                    Sign in
                  </button> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
