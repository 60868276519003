import React from 'react'

import Header from '../Header'
import { Link } from 'react-router-dom'
import Sidebarr from '../Sidebar'

const Transactionexpenselist = () => {
  return (
    <div className='container'>

      {/* <Header /> */}
      <div className='container row' style={{ paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px" }}>
        <div className="col-lg-2 col-md-3" style={{ paddingLeft: "0px" }}>
          {/* <Sidebarr /> */}
        </div>

        <div className="col-lg-10 col-md-9" style={{ paddingLeft: "30px", marginTop: "60px" }}>
          <div className="mt-3">
            <div className="mb-4">
              <h2 className="h1 mb-0 text-capitalize d-flex align-items-center gap-2">
                <img width={20} src="https://6valley.6amtech.com/public/assets/back-end/img/order_report.png" alt />
                Transaction report
              </h2>
            </div>
            <div className="inline-page-menu my-4 ml-3">
              <ul className="list-unstyled">
                <li className><Link to="/transactionreport">Order Transactions</Link></li>
                <li><Link className="text-info" to="/transactionexpenselist">Expense Transactions</Link></li>
              </ul>
            </div>
            <div className="card mb-2">
              <div className="card-body">
                <form action="#" id="form-data" method="GET">
                  <h4 className="mb-3">Filter Data</h4>
                  <div className="row  gy-2 align-items-center text-left">
                    <div className="col-sm-6 col-md-3">
                      <select className="form-control __form-control" name="date_type" id="date_type">
                        <option value="this_year" selected>This year</option>
                        <option value="this_month">This month</option>
                        <option value="this_week">This week</option>
                        <option value="custom_date">Custom date</option>
                      </select>
                    </div>
                    <div className="col-sm-6 col-md-3" id="from_div" style={{ display: 'none' }}>
                      <div className="form-floating">
                        <input type="date" name="from" defaultValue id="from_date" className="form-control __form-control" />
                        <label>Start Date</label>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-3" id="to_div" style={{ display: 'none' }}>
                      <div className="form-floating">
                        <input type="date" defaultValue name="to" id="to_date" className="form-control __form-control" />
                        <label>End Date</label>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                      <button type="submit" className="btn btn--primary px-4 w-100" onclick="formUrlChange(this)" data-action="https://6valley.6amtech.com/seller/transaction/expense-list">
                        Filter
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="store-report-content mb-2">
              <div className="left-content expense--content">
                <div className="left-content-card">
                  <img src="https://6valley.6amtech.com/public/assets/back-end/img/expense.svg" alt />
                  <div className="info">
                    <h4 className="subtitle">$10.00</h4>
                    <h6 className="subtext">
                      <span>Total Expense</span>
                      <span className="ml-2" data-toggle="tooltip" data-placement="top" title data-original-title="Free delivery, Coupon discount will be shown here">
                        <img className="info-img" src="https://6valley.6amtech.com/public/assets/back-end/img/info-circle.svg" alt="img" />
                      </span>
                    </h6>
                  </div>
                </div>
                <div className="left-content-card">
                  <img src="https://6valley.6amtech.com/public/assets/back-end/img/free-delivery.svg" alt />
                  <div className="info">
                    <h4 className="subtitle">$0.00</h4>
                    <h6 className="subtext">Free Delivery</h6>
                  </div>
                </div>
                <div className="left-content-card">
                  <img src="https://6valley.6amtech.com/public/assets/back-end/img/coupon-discount.svg" alt />
                  <div className="info">
                    <h4 className="subtitle">$10.00</h4>
                    <h6 className="subtext">
                      <span>Coupon Discount</span>
                      <span className="ml-2" data-toggle="tooltip" data-placement="top" title data-original-title="Discount on purchase and first delivery coupon amount will be shown here">
                        <img className="info-img" src="https://6valley.6amtech.com/public/assets/back-end/img/info-circle.svg" alt="img" />
                      </span>
                    </h6>
                  </div>
                </div>
              </div>
              <div className="center-chart-area"><div className="chartjs-size-monitor"><div className="chartjs-size-monitor-expand"><div className /></div><div className="chartjs-size-monitor-shrink"><div className /></div></div>
                <div className="center-chart-header">
                  <h3 className="title">Expense Statistics</h3>
                </div>
                <canvas id="updatingData" className="store-center-chart chartjs-render-monitor" data-hs-chartjs-options="{
            &quot;type&quot;: &quot;bar&quot;,
            &quot;data&quot;: {
              &quot;labels&quot;: [&quot;January-2023&quot;,&quot;February-2023&quot;,&quot;March-2023&quot;,&quot;April-2023&quot;,&quot;May-2023&quot;,&quot;June-2023&quot;,&quot;July-2023&quot;,&quot;August-2023&quot;,&quot;September-2023&quot;,&quot;October-2023&quot;,&quot;November-2023&quot;,&quot;December-2023&quot;],
              &quot;datasets&quot;: [{
                &quot;label&quot;: &quot;Total expense amount&quot;,
                &quot;data&quot;: [ &quot;0&quot;,&quot;0&quot;,&quot;0&quot;,&quot;0&quot;,&quot;0&quot;,&quot;0&quot;,&quot;0&quot;,&quot;0&quot;,&quot;0&quot;,&quot;10&quot;,&quot;0&quot;,&quot;0&quot;],
                &quot;backgroundColor&quot;: &quot;#a2ceee&quot;,
                &quot;hoverBackgroundColor&quot;: &quot;#0177cd&quot;,
                &quot;borderColor&quot;: &quot;#a2ceee&quot;
              }]
            },
            &quot;options&quot;: {
              &quot;scales&quot;: {
                &quot;yAxes&quot;: [{
                  &quot;gridLines&quot;: {
                    &quot;color&quot;: &quot;#e7eaf3&quot;,
                    &quot;drawBorder&quot;: false,
                    &quot;zeroLineColor&quot;: &quot;#e7eaf3&quot;
                  },
                  &quot;ticks&quot;: {
                    &quot;beginAtZero&quot;: true,
                    &quot;fontSize&quot;: 12,
                    &quot;fontColor&quot;: &quot;#97a4af&quot;,
                    &quot;fontFamily&quot;: &quot;Open Sans, sans-serif&quot;,
                    &quot;padding&quot;: 5,
                    &quot;postfix&quot;: &quot; $&quot;
                  }
                }],
                &quot;xAxes&quot;: [{
                  &quot;gridLines&quot;: {
                    &quot;display&quot;: false,
                    &quot;drawBorder&quot;: false
                  },
                  &quot;ticks&quot;: {
                    &quot;fontSize&quot;: 12,
                    &quot;fontColor&quot;: &quot;#97a4af&quot;,
                    &quot;fontFamily&quot;: &quot;Open Sans, sans-serif&quot;,
                    &quot;padding&quot;: 5
                  },
                  &quot;categoryPercentage&quot;: 0.3,
                  &quot;maxBarThickness&quot;: &quot;10&quot;
                }]
              },
              &quot;cornerRadius&quot;: 5,
              &quot;tooltips&quot;: {
                &quot;prefix&quot;: &quot; &quot;,
                &quot;hasIndicator&quot;: true,
                &quot;mode&quot;: &quot;index&quot;,
                &quot;intersect&quot;: false
              },
              &quot;hover&quot;: {
                &quot;mode&quot;: &quot;nearest&quot;,
                &quot;intersect&quot;: true
              }
            }
          }" style={{ display: 'block', height: 357, width: 473 }} width={709} height={535}>
                </canvas>
              </div>
            </div>
            <div className="card">
              <div className="card-header border-0">
                <div className="w-100 d-flex flex-wrap gap-3 align-items-center">
                  <h4 className="mb-0 mr-auto">
                    Total Transactions
                    <span className="badge badge-soft-dark radius-50 fz-12">1</span>
                  </h4>
                  <form action method="GET" className="mb-0">
                    <div className="input-group input-group-merge input-group-custom">
                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <i className="fa fa-search" aria-hidden="true" />
                        </div>
                      </div>
                      <input type="hidden" name="date_type" defaultValue="this_year" />
                      <input type="hidden" name="from" defaultValue />
                      <input type="hidden" name="to" defaultValue />
                      <input id="datatableSearch_" type="search" name="search" className="form-control" placeholder="Search by Order ID or Transaction ID" />
                      <button type="submit" className="btn btn--primary">Search</button>
                    </div>
                  </form>
                  <div>
                    <a href="#" className="btn btn-outline--primary text-nowrap btn-block">
                      Download PDF
                    </a>
                  </div>
                  <div>


                  </div>
                </div>
              </div>
              <div className="card-body p-0">
                <div className="table-responsive">
                  <table id="datatable" style={{ textAlign: 'left' }} className="table __table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100">
                    <thead className="thead-light thead-50 text-capitalize">
                      <tr>
                        <th>SL</th>
                        <th>XID</th>
                        <th>Transaction Date</th>
                        <th>Order ID</th>
                        <th>Expense Amount</th>
                        <th>Expense Type</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>1380-Z94WY-1673347312</td>
                        <td>10 January 2023, 02:41:52 am</td>
                        <td>
                          <a className="title-color" href="#">
                            100167
                          </a>
                        </td>
                        <td>$10.00</td>
                        <td>
                          Discount On Purchase
                          <br />
                        </td>
                        <td>
                          <div className="d-flex justify-content-center">
                            <a href="#" className="btn btn-outline-success square-btn btn-sm">
                              <i className="fa fa-download" aria-hidden="true" />
                            </a>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>





    </div>
  )
}

export default Transactionexpenselist
