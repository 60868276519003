import React, { useEffect, useState } from "react";
import Header from "../Header";
import Sidebarr from "./../Sidebar";
import axios from "axios";
import { Link } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import Pagination from "react-js-pagination";
import QRCode from "react-qr-code";
const Qrcodes = () => {
  const [valueof, setvalueof] = useState();

  const [dataofwithdraw, setdataofwithdraw] = useState();
  const [count, setcount] = useState();
  const [activePage, setActivePage] = useState(1);
  const itemsPerPage = 10;

  let venderIdd = secureLocalStorage.getItem("vendorid");

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };
  useEffect(() => {
    getwithdrow();
  }, [valueof]);

  const getwithdrow = () => {
    const data = {
      shopId: venderIdd,
    };
    axios
      .post(`${process.env.REACT_APP_API_KEY}vender/api/qrcodesList`, data)
      .then((res) => {
        console.log("resss",res)
        setcount(res.data.data?.length);
        setdataofwithdraw(res.data.data);
      })
      .catch((error) => {});
  };

  const renderProductData = (data, index) => {
    const adjustedIndex = (activePage - 1) * itemsPerPage + index + 1;
    return (
      <tr>
        <td>{adjustedIndex}</td>

        <td className="text-center">
          <div className="text-center">
            <Link
              onClick={() => {
                secureLocalStorage.setItem("qrcodee", data?._id);
              }}
              to="/qrcodedetails"
            >
              {" "}
              <QRCode
                size={90}
                bgColor="white"
                fgColor="black"
                value={data?.qrcode}
              />
            </Link>
          </div>
          <div style={{backgroundColor:'black', borderRadius:'unset'}} className="mt-1 text-center btn text-white px-4 py-1 mb-0 fz-12">
            {data?.qrcode_name}
          </div>
        </td>
        {/* <td className="text-center">
          <Link
            onClick={() => {
              secureLocalStorage.setItem("qrcodee", data?._id);
            }}
            to="/qrcodedetails"
            className="media align-items-center gap-2 w-max-content"
          >
            {" "}
            <QRCode
              size={50}
              bgColor="white"
              fgColor="black"
              value={data?.qrcode}
            />
          </Link>
          
        </td> */}

        <td>
          {data?.createdAt.slice(0, 10)} {data?.createdAt.slice(11, 19)}
        </td>
        <td className="text-capitalize">{data?.qrcode_name}</td>
      </tr>
    );
  };
  return (
    <div className="container">
      {/* <Header /> */}
      <div
        className="container row"
        style={{ paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px" }}
      >
        <div className="col-lg-2 col-md-3" style={{ paddingLeft: "0px" }}>
          {/* <Sidebarr /> */}
        </div>

        <div
          className="col-lg-10 col-md-9"
          style={{ paddingLeft: "30px", marginTop: "60px" }}
        >
          <div className="mt-3">
            <div className="mb-3">
              <h2 className="h1 mb-0 text-capitalize d-flex align-items-center gap-2">
                <img
                  width={20}
                  src="https://6valley.6amtech.com/public/assets/back-end/img/withdraw-icon.png"
                  alt
                />
                QR Codes
              </h2>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header flex-wrap gap-2">
                    <h5 className="mb-0 text-capitalize">
                      QR Codes
                      <span className="badge badge-soft-dark radius-50 fz-12 ml-1">
                        {count}
                      </span>
                    </h5>
                    <div class="col-lg-6 col-md-4 col-sm-4 mt-3 mt-lg-0 d-flex flex-wrap gap-3 justify-content-lg-end">
                      <Link class="btn btn--primary" to="/addnewqr">
                        <i class="fa fa-plus-circle" aria-hidden="true"></i>{" "}
                        <span class="text">Add New QR Codes</span>
                      </Link>
                    </div>
                  </div>
                  <div className="table-responsive">
                    {dataofwithdraw?.length > 0 ? (
                      <table
                        id="datatable"
                        style={{ textAlign: "left" }}
                        className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100"
                      >
                        <thead className="thead-light thead-50 text-capitalize">
                          <tr>
                            <th>SL</th>
                            <th className="text-center">QR Codes</th>
                            <th>Created time</th>
                            <th>QR Name</th>
                            {/* <th className="text-center">Action</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {dataofwithdraw
                            ?.slice(
                              (activePage - 1) * itemsPerPage,
                              activePage * itemsPerPage
                            )
                            .map((dataofwithdraw, index) =>
                              renderProductData(dataofwithdraw, index)
                            )}
                        </tbody>
                      </table>
                    ) : (
                      <div class="text-center p-4">
                        <img
                          class="mb-3 w-160"
                          src="https://6valley.6amtech.com/public/assets/back-end/img/empty-state-icon/default.png"
                          alt="Image Description"
                        />
                        <p class="mb-0 order-stats__subtitle">No data found</p>
                      </div>
                    )}
                    <div className="d-flex justify-content-center mt-4">
                      {dataofwithdraw?.length > itemsPerPage && (
                        <Pagination
                          activePage={activePage}
                          itemsCountPerPage={itemsPerPage}
                          totalItemsCount={dataofwithdraw?.length}
                          pageRangeDisplayed={5}
                          onChange={handlePageChange}
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="table-responsive mt-4">
                  <div className="px-4 d-flex justify-content-lg-end"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Qrcodes;
