import React, { useEffect, useState } from "react";
import Header from "../Header";
import Sidebarr from "./../Sidebar";
import axios from "axios";
import { Link } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import Pagination from "react-js-pagination";
const Withdraw = () => {
  const [valueof, setvalueof] = useState();

  const [dataofwithdraw, setdataofwithdraw] = useState();
  const [count, setcount] = useState();
  const [activePage, setActivePage] = useState(1);
  const itemsPerPage = 10;

  let venderIdd = secureLocalStorage.getItem("vendorid");

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };
  useEffect(() => {
    getwithdrow();
  }, [valueof]);

  const getwithdrow = () => {
    const data = {
      vendorId: venderIdd,
    };
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/vendorWithdraw_requestList`,
        data
      )
      .then((res) => {
        let filteredData = [];

        if (valueof == "0") {
          filteredData = res?.data?.data?.reverse().filter((item) => item.status == "0");
        } else if (valueof == "1") {
          filteredData = res?.data?.data?.reverse().filter((item) => item.status == "1");
        } else if (valueof == "2") {
          filteredData = res?.data?.data?.reverse().filter((item) => item.status == "2");
        } else {
          filteredData = res?.data?.data?.reverse();
        }

        setcount(filteredData?.length);
        setdataofwithdraw(filteredData);
      })
      .catch((error) => {});
  };

  const renderProductData = (data, index) => {
    const adjustedIndex = (activePage - 1) * itemsPerPage + index + 1;
    return (
      <tr>
        <td>{adjustedIndex}</td>
        <td className="text-center">RTRT74EWE</td>
        <td className="text-center">{data?.amount}CFA</td>

        <td>
          {data?.createdAt.slice(0, 10)} {data?.createdAt.slice(11, 19)}
        </td>

        <td className="text-center">{data?.withdrawalType}</td>

        {data?.status == "0" ? (
          <td>
            <label className="badge badge-soft-primary">Pending</label>
          </td>
        ) : data.status == "1" ? (
          <td>
            <label className="badge badge-soft-success">Confirm</label>
          </td>
        ) : (
          <td>
            <label className="badge badge-soft-danger">Denied</label>
          </td>
        )}
      </tr>
    );
  };
  return (
    <div className="container">
      {/* <Header /> */}
      <div
        className="container row"
        style={{ paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px" }}
      >
        <div className="col-lg-2 col-md-3" style={{ paddingLeft: "0px" }}>
          {/* <Sidebarr /> */}
        </div>

        <div
          className="col-lg-10 col-md-9"
          style={{ paddingLeft: "30px", marginTop: "60px" }}
        >
          <div className="mt-3">
            <div className="mb-3">
              <h2 className="h1 mb-0 text-capitalize d-flex align-items-center gap-2">
                <img
                  width={20}
                  src="https://6valley.6amtech.com/public/assets/back-end/img/withdraw-icon.png"
                  alt
                />
                Withdraw
              </h2>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header flex-wrap gap-2">
                    <h5 className="mb-0 text-capitalize">
                      Withdraw Request Table
                      <span className="badge badge-soft-dark radius-50 fz-12 ml-1">
                        {count}
                      </span>
                    </h5>

                    <select
                      name="withdraw_status_filter"
                      onChange={(e) => {
                        setvalueof(e.target.value);
                      }}
                      className="custom-select max-w-200"
                    >
                      <option value="all">All</option>
                      <option value="1">Approved</option>
                      <option value="2">Denied</option>
                      <option value="0">Pending</option>
                    </select>
                  </div>
                  <div className="table-responsive">
                    {dataofwithdraw?.length > 0 ? (
                      <table
                        id="datatable"
                        style={{ textAlign: "left" }}
                        className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100"
                      >
                        <thead className="thead-light thead-50 text-capitalize">
                          <tr>
                            <th>SL</th>
                            <th className="text-center">Transaction ID</th>
                            <th className="text-center">Amount</th>
                            <th>Request time</th>
                            <th className="text-center">Withdraw Type</th>
                            <th>Status</th>
                            {/* <th className="text-center">Action</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {dataofwithdraw
                            ?.slice(
                              (activePage - 1) * itemsPerPage,
                              activePage * itemsPerPage
                            )
                            .map((dataofwithdraw, index) =>
                              renderProductData(dataofwithdraw, index)
                            )}
                        </tbody>
                      </table>
                    ) : (
                      <div class="text-center p-4">
                        <img
                          class="mb-3 w-160"
                          src="https://6valley.6amtech.com/public/assets/back-end/img/empty-state-icon/default.png"
                          alt="Image Description"
                        />
                        <p class="mb-0 order-stats__subtitle">No data found</p>
                      </div>
                    )}
                    <div className="d-flex justify-content-center mt-4">
                      {dataofwithdraw?.length > itemsPerPage && (
                        <Pagination
                          activePage={activePage}
                          itemsCountPerPage={itemsPerPage}
                          totalItemsCount={dataofwithdraw?.length}
                          pageRangeDisplayed={5}
                          onChange={handlePageChange}
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="table-responsive mt-4">
                  <div className="px-4 d-flex justify-content-lg-end"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Withdraw;
