import React, { useEffect, useState } from "react";

import Header from "../Header";
import { Link } from "react-router-dom";
import Sidebarr from "../Sidebar";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";

const Mybankinfo = () => {
  const [details, setdetails] = useState();
  let token = secureLocalStorage.getItem("vendortoken");
  let venderid = secureLocalStorage.getItem("vendorid");

  useEffect(() => {
    sellerdetails();
  }, [0]);

  const sellerdetails = () => {
    const sellerdata = {
      venderId: venderid,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/getVenderProfile`,
        sellerdata
      )
      .then((res) => {

        setdetails(res.data.data);
      })
      .catch((error) => {});
  };
  return (
    <div className="container">
      {/* <Header /> */}
      <div
        className="container row"
        style={{ paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px" }}
      >
        <div className="col-lg-2 col-md-3" style={{ paddingLeft: "0px" }}>
          {/* <Sidebarr /> */}
        </div>

        <div
          className="col-lg-10 col-md-9"
          style={{ paddingLeft: "30px", marginTop: "60px" }}
        >
          <div className="mt-3">
            <div className="mb-3">
              <h2 className="h1 mb-0 text-capitalize d-flex align-items-center gap-2">
                <img
                  width={20}
                  src="https://6valley.6amtech.com/public/assets/back-end/img/my-bank-info.png"
                  alt
                />
                My bank info
              </h2>
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <div className="card" style={{ textAlign: "left" }}>
                  <div className="border-bottom d-flex gap-3 flex-wrap justify-content-between align-items-center px-4 py-3">
                    <div className="d-flex gap-2 align-items-center">
                      <img
                        width={20}
                        src="https://6valley.6amtech.com/public/assets/back-end/img/bank.png"
                        alt
                      />
                      <h3 className="mb-0">Bank Information</h3>
                    </div>
                    <div className="d-flex gap-2 align-items-center">
                      <Link to="/editmybankinfo" className="btn btn--primary">
                        Edit
                      </Link>
                    </div>
                  </div>
                  <div className="card-body p-30">
                    <div className="row justify-content-center">
                      <div className="col-sm-6 col-md-8 col-lg-6 col-xl-5">
                        <div
                          className="card bank-info-card bg-bottom bg-contain bg-img"
                          style={{
                            backgroundImage:
                              "url(https://6valley.6amtech.com/public/assets/back-end/img/bank-info-card-bg.png)",
                          }}
                        >
                          <div className="border-bottom p-3">
                            <h4 className="mb-0 fw-semibold text-capitalize">
                              Holder Name :{" "}
                              <strong>
                                {details?.bankAccount_name !== "undefined"
                                  ? details?.bankAccount_name
                                  : "NA"}
                              </strong>
                            </h4>
                          </div>
                          <div className="card-body position-relative">
                            <img
                              className="bank-card-img"
                              width={78}
                              src="https://6valley.6amtech.com/public/assets/back-end/img/bank-card.png"
                              alt
                            />
                            <ul className="list-unstyled d-flex flex-column gap-4">
                              <li>
                                <h3 className="mb-2">Bank Name :</h3>
                                <div className="text-capitalize">
                                  {details?.bankAccount_name !== "undefined"
                                    ? details?.bankAccount_name
                                    : "NA"}{" "}
                                </div>
                              </li>
                              <li>
                                <h3 className="mb-2">Branch Name :</h3>
                                <div className="text-capitalize">
                                  {details?.bank_name !== "undefined"
                                    ? details?.bank_name
                                    : "NA"}
                                </div>
                              </li>
                              <li>
                                <h3 className="mb-2">Account Number : </h3>
                                <div>
                                  {details?.acc_number !== null
                                    ? details?.acc_number
                                    : "NA"}
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mybankinfo;
