import React, { useEffect, useState } from "react";
import Header from "../Header";
import Sidebarr from "../Sidebar";
import axios from "axios";

const Refundpolicy = () => {
  const [termacondtions, settermacondtions] = useState(null);

  const [termacondtionss, settermacondtionss] = useState(null);

  useEffect(() => {
    getData();
  }, [0]);

  const getData = () => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}vender/api/refundPolicyList`)
      .then((res) => settermacondtions(res?.data?.data[0]))
      .catch((error) => error);
  };

  useEffect(() => {
    getDataofpolicy();
  }, [0]);

  const getDataofpolicy = () => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}vender/api/returnPolicyList`)
      .then((res) => settermacondtionss(res?.data?.data[0]))
      .catch((error) => error);
  };

  return (
    <div className="container">
      {/* <Header /> */}

      <div
        className="container row"
        style={{ paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px" }}
      >
        <div className="col-lg-2 col-md-3" style={{ paddingLeft: "0px" }}>
          {/* <Sidebarr /> */}
        </div>

        <div
          className="col-lg-10 col-md-9"
          style={{ paddingLeft: "30px", marginTop: "60px" }}
        >
          <div className="row mt-20">
            <div className="col-md-12">
              <div className="card mb-3" style={{borderColor:'rgb(188, 189, 190)'}}>
                <div className="px-3 py-4">
                  <div className="row align-items-center">
                    <div className="col-lg-4"></div>
                    <div className="col-lg-12 mt-3 mt-lg-0 d-flex flex-wrap gap-3 justify-content-lg-end">
                      <div className="content container-fluid text-start">
                        <div className="d-flex align-items-center justify-content-between flex-wrap gap-10 mb-3">
                          <div className>
                            <h2 className="h1 mb-0 text-capitalize d-flex align-items-center gap-2">
                              <img
                                width={20}
                                onerror="this.src='https://6valley.6amtech.com/public/assets/back-end/img/160x160/img2.jpg'"
                                src="https://6valley.6amtech.com/public/assets/back-end/img/products.png"
                                alt=""
                              />
                              Refund & Return policy
                            </h2>
                          </div>
                        </div>

                        <div className="">
                          <div className="card-body">
                            <h1>{termacondtions?.title}</h1>
                            <div className="d-flex flex-wrap flex-lg-nowrap gap-3 justify-content-between">
                              <p
                                className="mb-4"
                                dangerouslySetInnerHTML={{
                                  __html: termacondtions?.text,
                                }}
                              />
                            </div>
                            <hr />
                          </div>
                        </div>
                        <hr />
                        <div className="">
                          <div className="card-body">
                            <h1>{termacondtionss?.title}</h1>
                            <div className="d-flex flex-wrap flex-lg-nowrap gap-3 justify-content-between">
                              <p
                                className="mb-4"
                                dangerouslySetInnerHTML={{
                                  __html: termacondtionss?.text,
                                }}
                              />
                            </div>
                            <hr />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Refundpolicy;
