import React, { useEffect, useState } from "react";
import Header from "../Header";

import "../sidebar.css";
import { Link } from "react-router-dom";
import Sidebarr from "../Sidebar";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import "./Productdetails.css";
import toast, { Toaster } from "react-hot-toast";
const Reviews = () => {
  const [productdata, setproductdata] = useState();
  const [isOpen, setIsOpen] = useState(true);
  const [reviewdata, setreviewdata] = useState();
  const [rplay, setrplay] = useState();
  let productidd = secureLocalStorage.getItem("productid");

  let token = secureLocalStorage.getItem("vendortoken");
  let venderIdd = secureLocalStorage.getItem("vendorid");
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    Products_details();
  }, [0]);

  const Products_details = () => {
    const options = {
      headers: {
        token: token,
      },
    };
    const data = {
      productId: productidd,
      venderId: venderIdd,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/reviewsDetails`,
        data,
        options
      )
      .then((res) => {
        setproductdata(res?.data?.data);
      })
      .catch((error) => {});
  };

  const review_details = (item) => {
    const options = {
      headers: {
        token: token,
      },
    };
    const data = {
      ratingId: item,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/singleReview_detail`,
        data,
        options
      )
      .then((res) => {
        setreviewdata(res?.data?.data);
      })
      .catch((error) => {});
  };
  const [idofreplayy, setidofreplayy] = useState();
  const [idofshop, setidofshop] = useState();

  const setidofreplay = (idofreplayyy) => {
    setidofreplayy(idofreplayyy);
  };

  const setidofsho = (idofshopp) => {
    setidofshop(idofshopp);
  };

  const reviewplyay = (e) => {
    e.preventDefault();

    const userdata = {
      ratingId: idofreplayy,
      shopId: idofshop,
      message: rplay,
    };

    const options = {
      headers: {
        token: token,
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/reviewReplay`,
        userdata,
        options
      )
      .then((response) => {
        review_details();
        Products_details();
        toast.success(response.data.message);
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data.message);
        } else {
        }
      });

    setrplay("");
  };

  const reviewreport = (e) => {
    e.preventDefault();

    const userdata = {
      ratingId: idofreplayy,
      shopId: idofshop,
      message: rplay,
    };

    const options = {
      headers: {
        token: token,
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/reviewReport`,
        userdata,
        options
      )
      .then((response) => {
        review_details();
        Products_details();
        toast.success(response.data.message);
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data.message);
        } else {
        }
      });

    setrplay("");
  };
  return (
    <div className="container">
      {/* <Header /> */}
      <Toaster />
      <div
        className="container row"
        style={{ paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px" }}
      >
        <div className="col-lg-2 col-md-3" style={{ paddingLeft: "0px" }}>
          {/* <Sidebarr /> */}
        </div>

        <div
          className="col-lg-10 col-md-9"
          style={{ paddingLeft: "30px", marginTop: "60px" }}
        >
          <div className="row mt-20">
            <div className="col-md-12">
              <div className="card">
                <div className="px-3 py-4">
                  <div className="row align-items-center">
                    <div className="col-lg-4"></div>
                    <div className="col-lg-12 mt-3 mt-lg-0 d-flex flex-wrap gap-3 justify-content-lg-end">
                      <div className="content container-fluid text-start">
                        <div className="d-flex align-items-center justify-content-between flex-wrap gap-10 mb-3">
                          <div className>
                            <h2 className="h1 mb-0 text-capitalize d-flex align-items-center gap-2">
                              <img
                                width={20}
                                onerror="this.src='https://6valley.6amtech.com/public/assets/back-end/img/160x160/img2.jpg'"
                                src="https://6valley.6amtech.com/public/assets/back-end/img/products.png"
                                alt=""
                              />
                              Reviews
                            </h2>
                          </div>
                        </div>
                        {productdata?.map((data) => {
                          return (
                            <div className="card">
                              <div className="card-body">
                                <div className="d-flex flex-wrap flex-lg-nowrap gap-3 justify-content-between">
                                  <div className="media flex-wrap flex-sm-nowrap gap-3">
                                    <a
                                      className="aspect-1 float-left overflow-hidden"
                                      data-lightbox="mygallery"
                                    >
                                      {data?.image ? (
                                        <img
                                          className="avatar avatar-170 rounded-0"
                                          src={
                                            `${process.env.REACT_APP_API_KEY}uploads/` +
                                            data?.image
                                          }
                                          alt="Image Description"
                                        />
                                      ) : (
                                        <img
                                          className="avatar avatar-170 rounded-0"
                                          src="https://6valley.6amtech.com/storage/app/public/product/thumbnail/2023-06-13-64883db39dcbb.png"
                                          alt="Image Description"
                                        />
                                      )}
                                    </a>
                                    <div className="d-block">
                                      <div className="d-block">
                                        <div className="d-flex">
                                          <h2 className="mb-2 pb-1 text-gulf-blue text-capitalize">
                                            {data?.product_name}
                                          </h2>
                                        </div>
                                        <div className="d-flex gap-3 flex-wrap mb-3 lh-1">
                                          <span className="border-left" />
                                          <div className="review-hover position-relative cursor-pointer d-flex gap-2 align-items-center">
                                            <i
                                              className="fa fa-star"
                                              aria-hidden="true"
                                            />
                                            <span>{data?.rating}/5</span>
                                          </div>

                                          <span className="border-left" />
                                          <a
                                            href="javascript:"
                                            className="text-dark"
                                          >
                                            {data?.raters} Reviews
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <hr />
                              </div>
                            </div>
                          );
                        })}

                        <div className="card mt-3">
                          <div className="table-responsive datatable-custom">
                            <table className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100 text-start">
                              <thead className="thead-light thead-50 text-capitalize">
                                <tr>
                                  <th>SL</th>
                                  <th>Reviewer</th>
                                  <th>Rating</th>
                                  <th>Review</th>
                                  <th>Reply</th>
                                </tr>
                              </thead>
                              <tbody>
                                {productdata?.map((data, index) => (
                                  <React.Fragment key={index}>
                                    {data?.ratingsDetails.length > 0 ? (
                                      data?.ratingsDetails?.map(
                                        (detail, detailIndex) => (
                                          <tr key={detailIndex}>
                                            <td>{detailIndex + 1}</td>
                                            <td className="text-capitalize">
                                              <div className="d-flex align-items-center">
                                                <div className="avatar rounded">
                                                  <img
                                                    className="avatar-img"
                                                    src={`${process.env.REACT_APP_API_KEY}uploads/${detail?.userDetails?.user_profile}`}
                                                    alt=""
                                                  />
                                                </div>
                                                <div className="ml-3">
                                                  <span className="d-block h5 text-hover-primary mb-0">
                                                    {
                                                      detail?.userDetails
                                                        ?.first_name
                                                    }
                                                  </span>
                                                  <span className="d-block font-size-sm text-body"></span>
                                                </div>
                                              </div>
                                            </td>
                                            <td>
                                              <div className="d-flex align-items-center gap-2 text-primary">
                                                <i
                                                  className="fa fa-star"
                                                  aria-hidden="true"
                                                ></i>
                                                <span>{detail?.rating}</span>
                                              </div>
                                            </td>
                                            <td>
                                              <div className="text-wrap max-w-400 min-w-200">
                                                <p>{detail?.comment}</p>
                                              </div>
                                            </td>
                                            <td>
                                              <i
                                                style={{ cursor: "pointer" }}
                                                onClick={toggleDropdown}
                                                className="fa fa-ellipsis-h"
                                                aria-hidden="true"
                                              ></i>
                                              {!isOpen ? (
                                                <div
                                                  style={{
                                                    borderRadius: "5px",
                                                    backgroundColor: "#fff",
                                                  }}
                                                >
                                                  <p
                                                    onClick={() => {
                                                      review_details(
                                                        detail?._id
                                                      );
                                                      setidofreplay(
                                                        detail?._id
                                                      );
                                                      setidofsho(data?.shopId);
                                                    }}
                                                    className="ml-1 mt-2 pt-2 cursor-pointer"
                                                    data-toggle="modal"
                                                    data-target="#balance-modal"
                                                  >
                                                    Reply &nbsp;&nbsp;&nbsp;
                                                    <i
                                                      className="fa fa-reply mr-2"
                                                      aria-hidden="true"
                                                    ></i>
                                                  </p>
                                                  <hr />
                                                  <p
                                                    onClick={() => {
                                                      review_details(
                                                        detail?._id
                                                      );
                                                      setidofreplay(
                                                        detail?._id
                                                      );
                                                      setidofsho(data?.shopId);
                                                    }}
                                                    className="ml-1 mt-2 pb-2 cursor-pointer"
                                                    data-toggle="modal"
                                                    data-target="#report-modal"
                                                  >
                                                    Report &nbsp;&nbsp;&nbsp;
                                                    <i
                                                      className="fa fa-exclamation-triangle"
                                                      aria-hidden="true"
                                                    ></i>
                                                  </p>
                                                </div>
                                              ) : null}
                                            </td>
                                          </tr>
                                        )
                                      )
                                    ) : (
                                      <div class="text-center p-4">
                                        <img
                                          class="mb-3 w-160"
                                          src="https://6valley.6amtech.com/public/assets/back-end/img/empty-state-icon/default.png"
                                          alt="Image Description"
                                        />
                                        <p class="mb-0 order-stats__subtitle">
                                          No review found
                                        </p>
                                      </div>
                                    )}
                                  </React.Fragment>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          <div className="table-responsive mt-4">
                            <div className="px-4 d-flex justify-content-lg-end"></div>
                          </div>
                          {/* <div className="text-center p-4">
                            <img
                              className="mb-3 w-160"
                              src="https://6valley.6amtech.com/public/assets/back-end/svg/illustrations/sorry.svg"
                              alt="Image Description"
                            />
                            <p className="mb-0">No data to show</p>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="balance-modal"
        tabIndex={-1}
        style={{ display: "none" }}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content" style={{ textAlign: "left" }}>
            <section className="col-xl-12 col-lg-12 mt-4 mb-3 mt-lg-0">
              <div class="mb-3 mt-3">
                <h2 style={{ textAlign: "center" }}>
                  Reviews{" "}
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">
                      <i class="fa fa-times" aria-hidden="true"></i>
                    </span>
                  </button>
                </h2>
              </div>
              <div className="card card-body card-chat justify-content-between Chat mt-3">
                <div className="inbox_msg_header d-flex flex-wrap gap-3 justify-content-between align-items-center border px-3 py-2 rounded mb-4">
                  <div className="media align-items-center gap-3">
                    <div className="avatar avatar-sm avatar-circle">
                      <img
                        className="avatar-img"
                        src={
                          reviewdata?.userImage
                            ? `${process.env.REACT_APP_API_KEY}uploads/${reviewdata?.userImage}`
                            : "https://6valley.6amtech.com/storage/app/public/seller/2022-10-12-63467dae3ef83.png"
                        }
                        alt="Image Description"
                      />
                      {/* <span className="avatar-status avatar-sm-status avatar-status-success" /> */}
                    </div>
                    <div className="media-body">
                      <h5 className="profile-name mb-1" id="profile_name">
                        {reviewdata?.userFirst_name} {reviewdata?.userLast_name}
                      </h5>
                      <span className="fz-12" id="profile_phone">
                        {reviewdata?.rating && (
                          <div>
                            {[...Array(parseInt(reviewdata?.rating))]?.map(
                              (_, index) => (
                                <i
                                  key={index}
                                  className="fa fa-star rating-color"
                                  aria-hidden="true"
                                ></i>
                              )
                            )}
                          </div>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="messaging">
                  <div className="inbox_msg">
                    <div className="mesgs">
                      <div
                        className="msg_history d-flex flex-column pr-2"
                        id="show_msg"
                      >
                        <div className="incoming_msg">
                          <div className="received_msg">
                            <div className="received_withd_msg">
                              <div className="d-flex justify-content-start">
                                <p className="bg-chat rounded px-3 py-2 mb-1">
                                  {reviewdata?.comment}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {reviewdata?.ratingsDetails?.map((listdata) => {
                          return (
                            <div className="outgoing_msg">
                              <div className="sent_msg p-2">
                                <div className="justify-content-end">
                                  <p className="bg-c1 text-white rounded px-3 py-2 mb-1">
                                    {listdata?.message}
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="type_msg">
                        <div className="input_msg_write">
                          <form className="mt-4" onSubmit={reviewplyay}>
                            <input
                              type="hidden"
                              name="_token"
                              defaultValue="YFPrIbFYlUsgcs6Ht4EEyCysWGBq8lmpLaBUW0q6"
                            />{" "}
                            <input
                              type="hidden"
                              id="hidden_value"
                              name="user_id"
                              defaultValue={9}
                            />
                            <div className="position-relative">
                              <div className="row">
                                <div className="col-md-9 col-lg-9 col-sm-12">
                                  <label className="py-0 px-3 d-flex align-items-center m-0 cursor-pointer position-absolute top-3"></label>

                                  <input
                                    onChange={(e) => {
                                      setrplay(e.target.value);
                                    }}
                                    value={rplay}
                                    style={{ borderRadius: "20px" }}
                                    className="form-control"
                                    name="message"
                                    type="text"
                                    required
                                    placeholder="Type your message here"
                                    aria-label="Search"
                                  />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                  <button
                                    type="submit"
                                    className="bg-c1 btn btn text-white"
                                  >
                                    <i
                                      className="fa fa-paper-plane-o"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="mt-3 d-flex justify-content-between">
                              <button
                                style={{ visibility: "hidden" }}
                                className="aSend btn btn--primary form-control"
                                type="submit"
                              ></button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="report-modal"
        tabIndex={-1}
        style={{ display: "none" }}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content" style={{ textAlign: "left" }}>
            <section className="col-xl-12 col-lg-12 mt-4 mb-3 mt-lg-0">
              <div class="mb-3 mt-3">
                <h2 style={{ textAlign: "center" }}>
                  Report review
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">
                      <i class="fa fa-times" aria-hidden="true"></i>
                    </span>
                  </button>
                </h2>
              </div>

              <div className="card card-body card-chat justify-content-between Chat mt-3">
                <div className="inbox_msg_header d-flex flex-wrap gap-3 justify-content-between align-items-center border px-3 py-2 rounded mb-4">
                  <div className="media align-items-center gap-3">
                    <div className="avatar avatar-sm avatar-circle">
                      <img
                        className="avatar-img"
                        src={
                          reviewdata?.userImage
                            ? `${process.env.REACT_APP_API_KEY}uploads/${reviewdata?.userImage}`
                            : "https://6valley.6amtech.com/storage/app/public/seller/2022-10-12-63467dae3ef83.png"
                        }
                        alt="Image Description"
                      />
                      {/* <span className="avatar-status avatar-sm-status avatar-status-success" /> */}
                    </div>
                    <div className="media-body">
                      <h5 className="profile-name mb-1" id="profile_name">
                        {reviewdata?.userFirst_name} {reviewdata?.userLast_name}
                      </h5>
                      <span className="fz-12" id="profile_phone">
                        {reviewdata?.rating && (
                          <div>
                            {[...Array(parseInt(reviewdata?.rating))]?.map(
                              (_, index) => (
                                <i
                                  key={index}
                                  className="fa fa-star rating-color"
                                  aria-hidden="true"
                                ></i>
                              )
                            )}
                          </div>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="messaging">
                  <div className="inbox_msg">
                    <div className="mesgs">
                      <div
                        className="msg_history d-flex flex-column-reverse pr-2"
                        id="show_msg"
                      >
                        <div className="incoming_msg">
                          <div className="received_msg">
                            <div className="received_withd_msg">
                              <div className="d-flex justify-content-start">
                                <p className="bg-chat rounded px-3 py-2 mb-1">
                                  {reviewdata?.comment}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* {reviewdata?.ratingsDetails?.map((listdata) => {
                            return(
                        <div className="outgoing_msg">
                          <div className="sent_msg p-2">
                            <div className="d-flex justify-content-end">
                              <p className="bg-c1 text-white rounded px-3 py-2 mb-1">
                                {listdata?.message}
                                
                              </p>
                            </div>
                          </div>
                        </div>)
                    })} */}
                        {/* <div className="incoming_msg">
                          <div className="received_msg">
                            <div className="received_withd_msg">
                              <div className="d-flex justify-content-start">
                                <p className="bg-chat rounded px-3 py-2 mb-1">
                                  {reviewdata?.comment}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </div>
                      <div className="type_msg">
                        <div className="input_msg_write">
                          <form
                            className="mt-4"
                            onSubmit={reviewreport}
                            encType="multipart/form-data"
                          >
                            <input
                              type="hidden"
                              name="_token"
                              defaultValue="YFPrIbFYlUsgcs6Ht4EEyCysWGBq8lmpLaBUW0q6"
                            />{" "}
                            <input
                              type="hidden"
                              id="hidden_value"
                              name="user_id"
                              defaultValue={9}
                            />
                            <div className="position-relative">
                              <label className="py-0 px-3 d-flex align-items-center m-0 cursor-pointer position-absolute top-3"></label>
                              Please tell us why you’re reporting
                              <span className="text-danger"> *</span>
                              <input
                                onChange={(e) => {
                                  setrplay(e.target.value);
                                }}
                                value={rplay}
                                required
                                className="form-control"
                                name="message"
                                type="text"
                                placeholder="Tell us here…"
                                aria-label="Search"
                              />
                            </div>
                            <div className="mt-3 d-flex justify-content-between">
                              <button
                                className="aSend btn btn--primary form-control"
                                type="submit"
                                id="msgSendBtn"
                              >
                                Send report
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
