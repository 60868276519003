import axios from "axios";
import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

const Test = () => {
  const [fname, setFname] = useState("");
  const [phone, setPhone] = useState("");
  const [lname, setLname] = useState("");
  const [dob, setDob] = useState("");
  const [position, setPosition] = useState("");
  const [email, setEmail] = useState("");
  const [staffImage, setStaffImage] = useState(null);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();

  const [checkedValues, setCheckedValues] = useState([]);

  const [checkboxOptions] = useState([
    { label: "Orders", value: "Orders" },
    { label: "Chats", value: "Chats" },
    { label: "Products", value: "Products" },
    { label: "Reviews", value: "Reviews" },
    { label: "Coupons", value: "Coupons" },
    { label: "Wallet", value: "Wallet" },
    { label: "Bank and mobile money", value: "Bank and mobile money" },
    { label: "Refunds", value: "Refunds" },
    { label: "QR Codes", value: "QR Codes" },
    { label: "Offers", value: "Offers" },
    { label: "Subscription plans", value: "Subscription plans" },
  ]);

  const handleCheckboxChange = (value) => {
    if (checkedValues.includes(value)) {
      setCheckedValues(checkedValues.filter((item) => item !== value));
    } else {
      setCheckedValues([...checkedValues, value]);
    }
  };

  const generateRestrictionsObject = (checkedValues) => {
    const restrictions = {};

    if (checkedValues.includes("Products")) {
      restrictions.products = ["/products", "/product_list"];
    }
    if (checkedValues.includes("Orders")) {
      restrictions.orders = ["/orders_listt"];
    }
    if (checkedValues.includes("Chats")) {
      restrictions.chats = ["/chats"];
    }
    if (checkedValues.includes("Reviews")) {
      restrictions.reviews = ["/reviews"];
    }
    if (checkedValues.includes("Coupons")) {
      restrictions.coupons = ["/coupons"];
    }
    if (checkedValues.includes("Wallet")) {
      restrictions.wallet = ["/wallet"];
    }
    if (checkedValues.includes("Bank and mobile money")) {
      restrictions.bank_and_mobile_money = ["/bank", "/mobile_money"];
    }
    if (checkedValues.includes("Refunds")) {
      restrictions.refunds = ["/refunds"];
    }
    if (checkedValues.includes("QR Codes")) {
      restrictions.qr_codes = ["/qr_codes"];
    }
    if (checkedValues.includes("Offers")) {
      restrictions.offers = ["/offers"];
    }
    if (checkedValues.includes("Subscription plans")) {
      restrictions.subscription_plans = ["/subscription_plans"];
    }

    return restrictions;
  };

  let shopId = secureLocalStorage.getItem("shopidofvendor");

  const staffData = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error("Password & Confirm Password Not Matched");
      return;
    }

    const formData = new FormData();
    formData.append("shopId", shopId);
    formData.append("fname", fname);
    formData.append("phone", phone);
    formData.append("lname", lname);
    formData.append("dob", dob);
    formData.append("position", position);
    formData.append("password", password);
    formData.append("email", email);
    formData.append("staff_image", staffImage);

    const restrictions = generateRestrictionsObject(checkedValues);
    formData.append("restrictions", JSON.stringify(restrictions));

    axios
      .post(`${process.env.REACT_APP_API_KEY}vender/api/createStaff`, formData)
      .then((res) => {
        toast.success(res.data.message);
        setTimeout(() => {
          navigate("/staffs");
        }, 3000);
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data.message);
        } else {
          toast.error("An error occurred. Please try again later.");
        }
      });
  };

  return (
    <div className="container">
      <Toaster />
      <div className="container row" style={{ paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px" }}>
        <div className="col-lg-2 col-md-3" style={{ paddingLeft: "0px" }}>
          {/* Sidebar Component */}
        </div>
        <div className="col-lg-10 col-md-9" style={{ paddingLeft: "30px", marginTop: "60px" }}>
          <div className="mt-3">
            <div className="d-flex justify-content-between align-items-center gap-3 mb-3">
              <h2 className="h1 mb-1 text-capitalize d-flex align-items-center gap-2 mt-3 pl-3">
                <img src="https://6valley.6amtech.com/public/assets/back-end/img/add-new-seller.png" alt="" /> 
                Add New Staff
              </h2>
            </div>
            <div className="col-md-12">
              <form onSubmit={staffData}>
                <div className="card">
                  <div className="card-body">
                    <h5 className="mb-0 page-header-title text-capitalize d-flex align-items-center gap-2 border-bottom pb-3 mb-3">
                      <i className="fa fa-user" aria-hidden="true"></i>
                      Personal information
                    </h5>
                    <div className="row">
                      <div className="col-md-6 col-lg-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="fname" className="title-color">
                            First name<span className="text-danger">*</span>
                          </label>
                          <input
                            value={fname}
                            onChange={(e) => setFname(e.target.value)}
                            type="text"
                            name="fname"
                            className="form-control"
                            id="fname"
                            placeholder="First Name"
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="lname" className="title-color">
                            Last name<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            name="lname"
                            className="form-control"
                            id="lname"
                            placeholder="Last Name"
                            value={lname}
                            onChange={(e) => setLname(e.target.value)}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="phone" className="title-color">
                            Phone<span className="text-danger">*</span>
                          </label>
                          <div className="mb-3">
                            <div className="">
                              <input
                                minLength={10}
                                maxLength={10}
                                required
                                type="text"
                                className="form-control"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                name="phone"
                                placeholder="Enter Phone number"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="dob" className="title-color">
                            Date of birth<span className="text-danger">*</span>
                          </label>
                          <div className="mb-3">
                            <div className="">
                              <input
                                required
                                type="date"
                                className="form-control"
                                value={dob}
                                onChange={(e) => setDob(e.target.value)}
                                name="dob"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="position" className="title-color">
                            Role<span className="text-danger">*</span>
                          </label>
                          <select
                            required
                            value={position}
                            onChange={(e) => setPosition(e.target.value)}
                            className="form-control"
                            name="position"
                            id="position"
                          >
                            <option value="" disabled selected>
                              Select
                            </option>
                            <option value="Product manager">
                              Product manager
                            </option>
                            <option value="Role">Role</option>
                          </select>
                        </div>
                        <div className="form-group">
                          <div className="text-center mb-3">
                            {staffImage ? (
                              <img
                                className="upload-img-view"
                                id="viewer"
                                src={URL.createObjectURL(staffImage)}
                                alt=""
                              />
                            ) : (
                              <img
                                className="upload-img-view"
                                id="viewer"
                                src="https://6valley.6amtech.com/public/assets/back-end/img/400x400/img2.jpg"
                                alt=""
                              />
                            )}
                          </div>
                          <label htmlFor="staffImage" className="title-color">
                            Staff image
                          </label>
                          <span className="text-danger">*</span>
                          <div className="form-group">
                            <div className="custom-file text-left">
                              <input
                                onChange={(e) => setStaffImage(e.target.files[0])}
                                type="file"
                                name="staffImage"
                                id="staffImage"
                                className="custom-file-input image-input"
                                accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                                required
                              />
                              <label className="custom-file-label" htmlFor="staffImage">
                                Choose file
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 col-lg-6 col-sm-12">
                        <label className="title-color">
                          Restrictions: {checkedValues.join(", ")}
                        </label>
                        <div className="form-group mb-0">
                          {checkboxOptions?.map((option, index) => (
                            <div
                              key={index}
                              className="border rounded px-2 py-3 d-flex justify-content-between mb-1"
                            >
                              <h5 className="mb-1 d-flex gap-1 c1">{option.label}</h5>
                              <div className="position-relative">
                                <label className="switcher">
                                  <input
                                    type="checkbox"
                                    className="switcher_input toggle-switch-message"
                                    name={option.value}
                                    checked={checkedValues.includes(option.value)}
                                    onChange={() => handleCheckboxChange(option.value)}
                                  />
                                  <span className="switcher_control"></span>
                                </label>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card mt-3 mb-3">
                  <div className="card-body">
                    <h5 className="mb-0 page-header-title d-flex align-items-center gap-2 border-bottom pb-3 mb-3">
                      <i className="fa fa-user" aria-hidden="true"></i>
                      Account Information
                    </h5>
                    <div className="row">
                      <div className="col-md-6 col-xxl-6 col-lg-4 col-xl-4 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="email" className="title-color">
                            Email<span className="text-danger">*</span>
                          </label>
                          <input
                            type="email"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="form-control"
                            id="email"
                            placeholder="Enter Email"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-xxl-6 col-lg-4 col-xl-4 col-sm-12">
                        <div className="form-group">
                          <label
                            htmlFor="user_password"
                            className="title-color d-flex align-items-center"
                          >
                            Password<span className="text-danger">*</span>
                          </label>
                          <div className="input-group input-group-merge">
                            <input
                              minLength={8}
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              type="password"
                              className="js-toggle-password form-control password-check"
                              name="password"
                              required
                              id="user_password"
                              placeholder="Password minimum 8 characters"
                            />
                            <div id="changePassTarget" className="input-group-append">
                              <a className="input-group-text" href="javascript:">
                                <i id="changePassIcon" className="tio-hidden-outlined" />
                              </a>
                            </div>
                          </div>
                          <span className="text-danger mx-1 password-error" />
                        </div>
                      </div>
                      <div className="col-md-6 col-xxl-6 col-lg-4 col-xl-4 col-sm-12">
                        <div className="form-group">
                          <label
                            htmlFor="confirm_password"
                            className="title-color"
                          >
                            Confirm password
                            <span className="text-danger">*</span>
                          </label>
                          <div className="input-group input-group-merge">
                            <input
                              value={confirmPassword}
                              onChange={(e) => setConfirmPassword(e.target.value)}
                              type="password"
                              className="js-toggle-password form-control"
                              name="confirm_password"
                              required
                              id="confirm_password"
                              placeholder="Confirm password"
                            />
                            <div id="changeConfirmPassTarget" className="input-group-append">
                              <a className="input-group-text" href="javascript:">
                                <i id="changeConfirmPassIcon" className="tio-hidden-outlined" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end gap-3">
                      <button type="submit" className="btn btn--primary px-4">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Test;






















// import React, { useEffect, useState } from "react";
// import Sidebar from "../Sidebar";
// import Header from "../Header";
// import "../sidebar.css";
// import { Link, useNavigate } from "react-router-dom";
// import Sidebarr from "../Sidebar";
// import "./Add.css";
// import toast, { Toaster } from "react-hot-toast";
// import axios from "axios";
// import secureLocalStorage from "react-secure-storage";
// import ReactPlayer from "react-player";

// const Test = () => {
//   const [dataofattlist1, setDataofattlist1] = useState([]);
//   const [ttlistdetails1, setTtlistdetails1] = useState([]);
//   const [selectedVariations, setSelectedVariations] = useState([]);
//   const [variationMenuVisible, setVariationMenuVisible] = useState(false);
//   const [subVariationMenuVisible, setSubVariationMenuVisible] = useState(false);
//   const [variationFilter, setVariationFilter] = useState("");
//   const [selectedCategories23, setSelectedCategories23] = useState(new Map());

//   let token = secureLocalStorage.getItem("vendortoken");
//   let venderIdd = secureLocalStorage.getItem("vendorid");

//   useEffect(() => {
//     variationlist();
//   }, []);

//   const variationlist = () => {
//     const data = {
//       categoryId: "65eddc05e73fd3071ed36c10",
//     };
//     axios
//       .post(`${process.env.REACT_APP_API_KEY}vender/api/attributes_list`, data)
//       .then((res) => {
//         setDataofattlist1(res.data.data);
//       })
//       .catch((error) => {
//         console.log("error", error);
//       });
//   };

//   const getdetailsattribute = (item) => {
//     const data = {
//       attributeId: item,
//     };
//     axios
//       .post(`${process.env.REACT_APP_API_KEY}vender/api/get_attribute`, data)
//       .then((res) => {
//         setTtlistdetails1(res.data.data);
//       })
//       .catch((error) => {
//         console.log("error", error);
//       });
//   };

//   const handleVariationClick = (variationId) => {
//     if (!selectedVariations.includes(variationId)) {
//       setSelectedVariations([...selectedVariations, variationId]);
//     }
//     setSubVariationMenuVisible(true);
//     getdetailsattribute(variationId);
//   };

//   const filteredVariations = dataofattlist1.filter((variation) =>
//     variation?.attribute_name?.toLowerCase().includes(variationFilter?.toLowerCase())
//   );

//   const handleCheckboxChange23 = (variationId, value) => {
//     setSelectedCategories23((prevSelectedCategories23) => {
//       const newSelectedCategories = new Map(prevSelectedCategories23);
//       const currentValues = newSelectedCategories.get(variationId) || new Set();
//       if (currentValues.has(value)) {
//         currentValues.delete(value);
//       } else {
//         currentValues.add(value);
//       }
//       newSelectedCategories.set(variationId, currentValues);
//       return newSelectedCategories;
//     });
//   };

//   const close = () => {
//     setSubVariationMenuVisible(false);
//     setVariationMenuVisible(false);
//   };

//   const handleSubmit = () => {
//     const productVariation = Array.from(selectedCategories23.entries()).map(
//       ([variationId, values]) => ({
//         attribute_name: dataofattlist1.find((v) => v._id === variationId)?.attribute_name,
//         attribute_values: Array.from(values).join(","),
//       })
//     );
    
//     const data = {
//       product_variation: productVariation,
//       // Include other necessary data for the API here
//     };

//     axios
//       .post(`${process.env.REACT_APP_API_KEY}vender/api/post_endpoint`, data) // Replace with your actual endpoint
//       .then((res) => {
//         toast.success("Product variation added successfully!");
//       })
//       .catch((error) => {
//         console.log("error", error);
//         toast.error("Failed to add product variation.");
//       });
//   };

//   return (
//     <>
//       <div className="container-fluid">
//         <div className="row">
//           <div className="col-6">
//             <div className="card mt-3">
//               <div style={{ borderBottomColor: 'rgb(188 189 190)' }} className="card-header">
//                 <div className="d-flex gap-2">
//                   <i className="fa fa-user" aria-hidden="true"></i>
//                   <h4 className="mb-0">Product variation</h4>
//                 </div>
//               </div>
//               <div className="card-body mb-10">
//                 <div className="row align-items-end">
//                   <div className="col-6">
//                     <div>
//                       {Array.from(selectedCategories23.entries()).map(([variationId, values]) => (
//                         <p key={variationId}>
//                           {dataofattlist1.find((v) => v._id === variationId)?.attribute_name}:{" "}
//                           {Array.from(values).join(", ")}
//                         </p>
//                       ))}
//                       <p
//                         style={{ background: "#fffefd", color: "black", width: '50%' }}
//                         className="form-control btn btn--secondary"
//                         onClick={() => setVariationMenuVisible(!variationMenuVisible)}
//                       >
//                         + Add variation
//                       </p>
//                       {variationMenuVisible && (
//                         <div>
//                           <input style={{ width: '50%' }}
//                             placeholder="Search variations"
//                             type="text"
//                             className="form-control mb-0"
//                             onChange={(e) => setVariationFilter(e.target.value)}
//                           />
//                         </div>
//                       )}
//                       <ul
//                         style={{
//                           overflow: "scroll",
//                           height: "270px",
//                           scrollbarWidth: "none",
//                           background: "#fffefd",
//                           marginTop: '0px'
//                         }}
//                         className={`dropdown-menu ${variationMenuVisible ? "show" : ""}`}
//                       >
//                         {filteredVariations.length > 0 ? (
//                           filteredVariations?.map((variation) => (
//                             <div className="cursor-pointer" key={variation._id}>
//                               <p
//                                 className={`btn btn-secondary ml-4 ${
//                                   selectedVariations.includes(variation._id) ? "selected" : ""
//                                 }`}
//                                 onClick={() => handleVariationClick(variation._id)}
//                               >
//                                 {variation?.attribute_name} <span className="caret"></span>
//                               </p>
//                             </div>
//                           ))
//                         ) : (
//                           <div>
//                             <p className="btn btn-secondary ml-3">Data not found</p>
//                           </div>
//                         )}
//                       </ul>
//                       <div
//                         className={`dropdown-menu ${subVariationMenuVisible ? "show" : ""}`}
//                         style={{
//                           left: "200px",
//                           overflow: "scroll",
//                           height: "260px",
//                           scrollbarWidth: "none",
//                           background: "#fffefd",
//                         }}
//                       >
//                         {ttlistdetails1?.map((subVariation, index) => {
//                           const values = Array.isArray(subVariation?.attribute_values)
//                             ? subVariation?.attribute_values
//                             : [];

//                           return values.map((value, i) => (
//                             <div key={`${index}-${i}`} className="cursor-pointer col-md-12">
//                               <div className="form-group d-flex gap-5 form-check ml-4">
//                                 <input
//                                   className="form-check-input"
//                                   type="checkbox"
//                                   id={`subVariation-${value}`}
//                                   checked={selectedCategories23.get(selectedVariations.slice(-1)[0])?.has(value)}
//                                   onChange={() => handleCheckboxChange23(selectedVariations.slice(-1)[0], value)}
//                                 />
//                                 <label
//                                   className="form-check-label mt-1"
//                                   htmlFor={`subVariation-${value}`}
//                                 >
//                                   {value}
//                                 </label>
//                               </div>
//                             </div>
//                           ));
//                         })}
//                         <div className="col-12 d-flex justify-content-end flex-wrap gap-10 mt-3">
//                           <button onClick={close} className="btn btn-primary cancel px-4" type="reset">Done</button>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="col-12 d-flex justify-content-end flex-wrap gap-10 mt-3">
//                   <button onClick={handleSubmit} className="btn btn-primary px-4">Save Variations</button>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Test;



























// import React, { useEffect, useState } from "react";
// import Sidebar from "../Sidebar";
// import Header from "../Header";
// import "../sidebar.css";
// import { Link, useNavigate } from "react-router-dom";
// import Sidebarr from "../Sidebar";
// import "./Add.css";
// import toast, { Toaster } from "react-hot-toast";
// import axios from "axios";
// import secureLocalStorage from "react-secure-storage";
// import ReactPlayer from "react-player";

// const Test = () => {
//   const [dataofattlist1, setDataofattlist1] = useState([]);
//   const [ttlistdetails1, setTtlistdetails1] = useState([]);
//   const [selectedVariation, setSelectedVariation] = useState(null);
//   const [variationMenuVisible, setVariationMenuVisible] = useState(false);
//   const [subVariationMenuVisible, setSubVariationMenuVisible] = useState(false);
//   const [variationFilter, setVariationFilter] = useState("");
//   const [selectedCategories23, setSelectedCategories23] = useState(new Set());

//   let token = secureLocalStorage.getItem("vendortoken");
//   let venderIdd = secureLocalStorage.getItem("vendorid");

//   useEffect(() => {
//     variationlist();
//   }, []);

//   const variationlist = () => {
//     const data = {
//       categoryId: "65eddc05e73fd3071ed36c10",
//     };
//     axios
//       .post(`${process.env.REACT_APP_API_KEY}vender/api/attributes_list`, data)
//       .then((res) => {
//         setDataofattlist1(res.data.data);
//       })
//       .catch((error) => {
//         console.log("error", error);
//       });
//   };

//   const getdetailsattribute = (item) => {
//     const data = {
//       attributeId: item,
//     };
//     axios
//       .post(`${process.env.REACT_APP_API_KEY}vender/api/get_attribute`, data)
//       .then((res) => {
//         setTtlistdetails1(res.data.data);
//       })
//       .catch((error) => {
//         console.log("error", error);
//       });
//   };

//   const handleVariationClick = (variationId) => {
//     setSelectedVariation(variationId);
//     setSubVariationMenuVisible(true);
//     getdetailsattribute(variationId);
//   };

//   const filteredVariations = dataofattlist1.filter((variation) =>
//     variation?.attribute_name?.toLowerCase().includes(variationFilter?.toLowerCase())
//   );

//   const handleCheckboxChange = (value) => {
//     setSelectedCategories23((prevSelectedCategories23) => {
//       const newSelectedCategories = new Set(prevSelectedCategories23);
//       if (newSelectedCategories.has(value)) {
//         newSelectedCategories.delete(value);
//       } else {
//         newSelectedCategories.add(value);
//       }
//       return newSelectedCategories;
//     });
//   };
// const close = () => {
// console.log("aaa","hello")
//   setSubVariationMenuVisible(false);
//   setVariationMenuVisible(false)
// }
//   return (
//     <>
//       <div className="container-fluid">
//         <div className="row">
//           <div className="col-6">
//             <div className="card mt-3">
//               <div className="card-body">
//                 <div className="form-group">

//                   <label className="title-color" onClick={() => setVariationMenuVisible(!variationMenuVisible)}>
//                   + Add variation <span className="text-danger">*</span> {Array?.from(selectedCategories23).join(", ")}
//                   </label>
//                   <p
//                     style={{ background: "#fffefd", color: "black" }}
//                     className="form-control"
//                     onClick={() => setVariationMenuVisible(!variationMenuVisible)}
//                   >
//                     {selectedVariation
//                       ? dataofattlist1.find((v) => v._id === selectedVariation)
//                           ?.attribute_name
//                       : "Select Variation"}
//                   </p>
//                   {variationMenuVisible && (
//                     <div className="mb-2">
//                       <input
//                         placeholder="Search variations"
//                         style={{ width: "200px" }}
//                         type="text"
//                         className="form-control mt-2"
//                         onChange={(e) => setVariationFilter(e.target.value)}
//                       />
//                     </div>
//                   )}
//                   <div
//                     style={{
//                       overflow: "scroll",
//                       height: "270px",
//                       scrollbarWidth: "none",
//                       background: "#fffefd",
//                     }}
//                     className={`dropdown-menu ${variationMenuVisible ? "show" : ""}`}
//                   >
//                     {filteredVariations.length > 0 ? (
//                       filteredVariations?.map((variation) => (
//                         <div className="form-control cursor-pointer"  key={variation._id}>
//                           <p
//                             className={`  ml-3 ${
//                               selectedVariation === variation._id ? "selected" : ""
//                             }`}
//                             onClick={() => handleVariationClick(variation._id)}
//                           >
//                             {variation?.attribute_name} <span className="caret"></span>
//                           </p>
//                         </div>
//                       ))
//                     ) : (
//                       <div>
//                         <p className="btn btn-secondary ml-3">Data not found</p>
//                       </div>
//                     )}
//                   </div>
//                   <ul
//                     className={`dropdown-menu ${subVariationMenuVisible ? "show" : ""}`}
//                     style={{
//                       left: "200px",
//                       overflow: "scroll",
//                       height: "260px",
//                       scrollbarWidth: "none",
//                       background: "#fffefd",
//                     }}
//                   >
//                     {ttlistdetails1?.map((subVariation, index) => {
//                       const values = Array.isArray(subVariation?.attribute_values)
//                         ? subVariation?.attribute_values
//                         : [];

//                       return values.map((value, i) => (
//                         <div key={`${index}-${i}`} className="form-control cursor-pointer col-md-12">
//                           <div className="form-check">
//                             <input
//                               className="form-check-input"
//                               type="checkbox"
//                               id={`subVariation-${value}`}
//                               checked={selectedCategories23.has(value)}
//                               onChange={() => handleCheckboxChange(value)}
//                             />
//                             <label
//                               className=" form-check-label"
//                               htmlFor={`subVariation-${value}`}
//                             >
//                               {value}
//                             </label>
//                           </div>
                          
//                         </div>
                        
//                       ));
//                     })}
//                     <div class="col-12 d-flex justify-content-end flex-wrap gap-10 mt-3">
// <button onClick={close} class="btn btn-primary cancel px-4" type="reset">Done</button>
// </div>
                   
//                   </ul>
                  
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Test;



// import React, { useState } from "react";
// import axios from "axios";

// function Test() {
//   const [recorder, setRecorder] = useState(null);
//   const [audioChunks, setAudioChunks] = useState([]);
//   const [recordedAudio, setRecordedAudio] = useState(null); // State variable to store recorded audio

//   const startRecording = () => {
//     navigator.mediaDevices.getUserMedia({ audio: true })
//       .then(stream => {
//         const mediaRecorder = new MediaRecorder(stream);
//         mediaRecorder.addEventListener("dataavailable", event => {
//           setAudioChunks(prevChunks => [...prevChunks, event.data]);
//         });
//         mediaRecorder.start();
//         setRecorder(mediaRecorder);
//         console.log("Recording started");
//       })
//       .catch(error => {
//         console.error("Error accessing microphone:", error);
//       });
//   };

//   const stopRecording = () => {
//     if (recorder) {
//       recorder.stop();
//       console.log("Recording stopped");
//     }
//   };

//   const uploadAudio = () => {
//     if (audioChunks.length === 0) {
//       console.error("No audio recorded to upload");
//       return;
//     }

//     const audioBlob = new Blob(audioChunks, { type: 'audio/mp3' });
//     setRecordedAudio(audioBlob); // Save recorded audio in state variable

//     const formData = new FormData();
//     formData.append("user_audio", audioBlob, "recorded-audio.mp3");

//     axios.post("http://localhost:8080/upload-audio", formData, {
//       headers: {
//         "Content-Type": "multipart/form-data"
//       }
//     })
//       .then(response => {
//         console.log("Upload successful");
//         // Handle response if needed
//       })
//       .catch(error => {
//         console.error("Upload failed:", error);
//         // Handle error if needed
//       });
//   };

//   return (
//     <div>
//       <button onClick={startRecording} type="button" className="btn btn-success">
//         Start
//       </button>
//       <button onClick={stopRecording} type="button" className="btn btn-danger">
//         Stop
//       </button>
//       <button onClick={uploadAudio} type="button" className="btn btn-primary">
//         Upload Audio
//       </button>
//       {recordedAudio && (
//         <audio controls>
//           <source src={URL.createObjectURL(recordedAudio)} type="audio/mp3" />
//           Your browser does not support the audio element.
//         </audio>
//       )}
//     </div>
//   );
// }

// export default Test;

// import React, { useEffect, useState } from "react";
// import Header from "../Header";

// import { Link, Navigate, useNavigate } from "react-router-dom";
// import Sidebarr from "../Sidebar";
// import secureLocalStorage from "react-secure-storage";
// import axios from "axios";
// import toast, { Toaster } from "react-hot-toast";
// import PlacesAutocomplete, {
//   geocodeByAddress,
//   getLatLng,
// } from "react-places-autocomplete";
// const Test = () => {
// const [workHourss, setWorkHourss] = useState([]);
// const [profiledetails, setprofiledetails] = useState();
// const [shop_name, setshop_name] = useState();
// const [shop_address, setshop_address] = useState();
// const [country, setcountry] = useState();
// const [city, setcity] = useState();

// const [longitude, setlongitude] = useState();
// const [latitude, setlatitude] = useState();
// const [shop_logo, setshop_logo] = useState();
// const [shop_licence, setshop_licence] = useState();
// const [vender_profile, setvender_profile] = useState();
// const [address, setAddress] = useState("");

// const [details, setDetails] = useState(null);
// const Navigate = useNavigate();
// let venderid = secureLocalStorage.getItem("vendorid");
//   let token = secureLocalStorage.getItem("vendortoken");
//   const [workHours, setWorkHours] = useState([
//     {
//       day_name: "Monday",
//       status: false,
//       open_time: "",
//       close_time: "",
//     },

//     {
//       day_name: "Tuesday",
//       status: false,
//       open_time: "",
//       close_time: "",
//     },
//     {
//       day_name: "Wensday",
//       status: false,
//       open_time: "",
//       close_time: "",
//     },
//     {
//       day_name: "Thursday",
//       status: false,
//       open_time: "",
//       close_time: "",
//     },
//     {
//       day_name: "Friday",
//       status: false,
//       open_time: "",
//       close_time: "",
//     },
//     {
//       day_name: "Saturday",
//       status: false,
//       open_time: "",
//       close_time: "",
//     },
//     {
//       day_name: "Sunday",
//       status: false,
//       open_time: "",
//       close_time: "",
//     },
//   ]);
//   const handleInputChange = (index, field, value) => {
//     setWorkHours((prevWorkHours) => {
//       const newWorkHours = [...prevWorkHours];
//       if (field === "status") {
//         newWorkHours[index][field] = value;
//       } else {
//         newWorkHours[index][field + "_time"] =
//           value &&
//           new Date(`2000-01-01T${value}`).toLocaleTimeString("en-US", {
//             hour: "numeric",
//             minute: "numeric",
//             hour12: true,
//           });
//       }
//       return newWorkHours;
//     });
//   };

//   const handleSelect = async (value) => {
//     try {
//       const results = await geocodeByAddress(value);
//       const latLng = await getLatLng(results[0]);
//       setAddress(value);
//       setDetails(latLng);
//     } catch (error) {}
//   };

//   const updateshopdetails = (e) => {
//     e.preventDefault();

//     const formData = new FormData();

//     formData.append("venderId", venderid);

//     formData.append(
//       "shop_name",
//       shop_name ? shop_name : profiledetails?.shop_name
//     );

//     formData.append(
//       "shop_address",
//       shop_address ? shop_address : profiledetails?.shop_address
//     );

//     formData.append("country", country ? country : profiledetails?.country);

//     formData.append("city", city ? city : profiledetails?.city);

//     // formData.append("workHours", JSON.stringify(workHours) ? JSON.stringify(workHours) : JSON.stringify(profiledetails?.workHours));
//     const updatedWorkHours = workHours.some((day) => day.status)
//       ? workHours
//       : profiledetails?.workHours;

//     formData.append("workHours", JSON.stringify(updatedWorkHours));
//     formData.append("longitude", details?.lng);

//     formData.append("latitude", details?.lat);
//     formData.append("shop_logo", shop_logo);

//     formData.append("shop_licence", shop_licence);
//     formData.append("vender_profile", vender_profile);

//     const options = {
//       headers: {
//         token: token,
//       },
//     };
//     axios
//       .post(
//         `${process.env.REACT_APP_API_KEY}vender/api/updateShop_information`,
//         formData,
//         options
//       )
//       .then((res) => {
//         // setTimeout(() => {
//         //   Navigate("/myshop");
//         // }, 4000);
//         toast.success(res.data.message);
//       })
//       .catch((error) => {});
//   };

// useEffect(() => {
//   profiledetailss();
// }, [0]);

// const profiledetailss = () => {
//   const sellerdata = {
//     venderId: venderid,
//   };

//   axios
//     .post(
//       `${process.env.REACT_APP_API_KEY}vender/api/getVenderProfile`,
//       sellerdata
//     )
//     .then((res) => {
//       setWorkHourss(res?.data?.data?.workHours);
//       setprofiledetails(res.data.data);
//     })
//     .catch((error) => {});
// };

//   console.log(workHourss, "workHourss");
//   return (
//     <div className="container">
//       <Header />
//       <Toaster />
//       <div
//         className="container row"
//         style={{ paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px" }}
//       >
//         <div className="col-lg-2 col-md-3" style={{ paddingLeft: "0px" }}>
//           <Sidebarr />
//         </div>

//         <div
//           className="col-lg-10 col-md-9"
//           style={{
//             paddingLeft: "30px",
//             marginTop: "60px",
//             marginBottom: "60px",
//           }}
//         >
//           <div className="mt-3">
//             <div className="mb-3">
//               <h2 className="h1 mb-0 text-capitalize d-flex align-items-center gap-2">
//                 <img
//                   width={20}
//                   src="https://6valley.6amtech.com/public/assets/back-end/img/shop-info.png"
//                   alt
//                 />
//                 Edit My shop information
//               </h2>
//             </div>
//             <div className="row">
//               <div className="col-md-12">
//                 <div className="card">
//                   <div className="card-header">
//                     <h5 className="mb-0 ">Edit My shop information</h5>
//                     <Link
//                       to="/myshop"
//                       className="btn btn--primary __inline-70 px-4 text-white"
//                     >
//                       Back
//                     </Link>
//                   </div>
//                   <div className="card-body">
//                     <form
//                       onSubmit={updateshopdetails}
//                       style={{ textAlign: "left" }}
//                       encType="multipart/form-data"
//                     >
//                       <input
//                         type="hidden"
//                         name="_token"
//                         defaultValue="ZzkfkLNbAUt4VTq5h8M6WE4WDu8lkTMJLYAhZQBe"
//                       />{" "}
//                       <div className="row">
//                         <div className="row card-body">
//                           <h5 className="ml-3">Working hours</h5>
//                           {workHours?.map((day, index) => (
//                             <div
//                               key={index}
//                               className="col-lg-12 col-md-12 col-sm-12"
//                               style={{ marginBottom: "-40px" }}
//                             >
//                               <div className="d-flex align-items-center mb-0"></div>

//                               <div className="form-group d-flex align-items-center ">
//                                 <h5 style={{ border: "none", width: "100px" }}>
//                                   {day?.day_name}
//                                 </h5>{" "}
//                                 <label className="switcher">
//                                   <input
//                                     type="checkbox"
//                                     id={`status-${index}`}
//                                     checked={
//                                       workHourss[index]?.status || day.status
//                                     }
//                                     onChange={(e) =>
//                                       handleInputChange(
//                                         index,
//                                         "status",
//                                         e.target.checked
//                                       )
//                                     }
//                                     className="switcher_input"
//                                   />
//                                   <span className="switcher_control" />
//                                 </label>
//                                 <span
//                                   style={{ fontWeight: "bold" }}
//                                   className="mr-3 ml-3"
//                                 >
//                                   {workHourss[index]?.status
//                                     ? "Open"
//                                     : "Closed"}
//                                 </span>
//                                 <div className="form-group">
//                                   <label
//                                     style={{
//                                       visibility: workHourss[index]?.status
//                                         ? "visible"
//                                         : "hidden",
//                                     }}
//                                     htmlFor={`open-${index}`}
//                                     className="mr-2"
//                                   >
//                                     Open time: {workHourss[index]?.open_time}
//                                   </label>
//                                   <input
//                                     type="time"
//                                     id={`open-${index}`}
//                                     value={day?.open}
//                                     onChange={(e) =>
//                                       handleInputChange(
//                                         index,
//                                         "open",
//                                         e.target.value
//                                       )
//                                     }
//                                     className="form-control"
//                                   />
//                                 </div>
//                                 <div className="form-group ml-3">
//                                   <label
//                                     style={{
//                                       visibility: workHourss[index]?.status
//                                         ? "visible"
//                                         : "hidden",
//                                     }}
//                                     htmlFor={`close-${index}`}
//                                     className="mr-2"
//                                   >
//                                     Close time: {workHourss[index]?.close_time}
//                                   </label>
//                                   <input
//                                     type="time"
//                                     id={`close-${index}`}
//                                     value={day?.close}
//                                     onChange={(e) =>
//                                       handleInputChange(
//                                         index,
//                                         "close",
//                                         e.target.value
//                                       )
//                                     }
//                                     className="form-control"
//                                   />
//                                 </div>
//                               </div>
//                             </div>
//                           ))}
//                         </div>
//                       </div>
//                       <div className="d-flex justify-content-end gap-2">
//                         <Link to="/myshop" className="btn btn-danger">
//                           Cancel
//                         </Link>
//                         <button
//                           type="submit"
//                           className="btn btn--primary"
//                           id="btn_update"
//                         >
//                           Update
//                         </button>
//                       </div>
//                     </form>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Test;

// const [CategoryList, setCategoryList] = useState();
// const [serviceName, setServiceName] = useState("");
// let serviceTypeId =secureLocalStorage.getItem("serviceTypeeee")

// useEffect(() => {
//   getCategories();
// }, [0]);

// const getCategories = () => {
//   axios
//     .get(
//       `${process.env.REACT_APP_API_KEY}vender/api/maincategory_list`,
//     )
//     .then((res) => {
//       setCategoryList(res.data.data);
//     })
//     .catch((error) => {});
// };

// useEffect(() => {
//   if (CategoryList) {
//     const matchedCategory = CategoryList?.find(category => category?._id === serviceTypeId);
//     if (matchedCategory) {
//       setServiceName(matchedCategory?.maincategory_englishName);
//     }
//   }
// }, [CategoryList, serviceTypeId]);

// {serviceName === "Innt" ? () : ()}

// import React, { useEffect, useState } from 'react';
// import axios from 'axios';

// const Test = () => {
//   const [brandlist, setBrandlist] = useState([]);
//   const [brand_name, setbrand_name] = useState('');

//   const [filterText, setFilterText] = useState('');
//   const [brandlistdetails, setBrandlistDetails] = useState(true);

//   useEffect(() => {
//     getBrandList();
//   }, []);

//   const getBrandList = () => {
//     let data = {
//       categoryId: "65ec018e97d37cd76d3de84f",
//     };

//     axios
//       .post(
//         `${process.env.REACT_APP_API_KEY}vender/api/brand_list`,
//         data,
//       )
//       .then((res) => {
//         setBrandlist(res?.data?.data);
//       })
//       .catch((error) => {});
//   };

//   const handleDropdownToggle = () => {
//     setBrandlistDetails(!brandlistdetails);
//   };

//   const handleBrandSelection = (selectedBrand) => {
//     setbrand_name(selectedBrand);
//     setBrandlistDetails(true);
//   };

//   const filteredBrandList = brandlist.filter(brandItem =>
//     brandItem?.brand?.some(brand =>
//       brand?.toLowerCase().includes(filterText.toLowerCase())
//     )
//   );

//   return (
//     <div className="col-md-6 col-lg-6 col-xl-3">
//       <div className="form-group">
//         <label htmlFor="name" className="title-color">
//           Select brand <span className="text-danger">*</span>
//         </label>

//         <ul className="js-select2-custom form-control" name="sub_category_id" >
//           <p onClick={handleDropdownToggle}>Select brand</p>

//           {!brandlistdetails && (
//             <div className="mb-2">
//               <input
//                 placeholder="Search in categories"
//                 style={{ width: "200px" }}
//                 type="text"
//                 id="categoryFilter"
//                 className="form-control mt-2"
//                 value={filterText}
//                 onChange={(e) => setFilterText(e.target.value)}
//               />
//               {filteredBrandList?.length === 0 ? (
//   <li className="form-control mt-2"> No data</li>
// ) : (
//   filteredBrandList?.map((brandItem, index) =>
//     brandItem?.brand?.map((brand, subIndex) => (
//       <li
//         className="form-control mt-2"
//         key={`${index}-${subIndex}`}
//         onClick={() => handleBrandSelection(brand)}
//         style={{ cursor: 'pointer' }}
//       >
//         {brand}
//       </li>
//     ))
//   )
// )}

//               {/* {filteredBrandList?.map((brandItem, index) =>
//                 brandItem?.brand?.map((brand, subIndex) => (
//                   <li
//                     className="form-control mt-2"
//                     key={`${index}-${subIndex}`}
//                     onClick={() => handleBrandSelection(brand)}
//                     style={{ cursor: 'pointer' }}
//                   >
//                     {brand}
//                   </li>
//                 ))
//               )} */}
//             </div>
//           )}
//         </ul>
//       </div>
//     </div>
//   );
// };

// export default Test;

// import React, { useEffect, useState } from 'react';
// import axios from 'axios';

// const Test = () => {
//   const [brandlist, setBrandlist] = useState([]);
//   const [brand_name, setbrand_name] = useState('');

//   const [filterText, setFilterText] = useState('');
//   const [brandlistdetails, setBrandlistDetails] = useState(true);
// console.log("aa",brand_name)
//   useEffect(() => {
//     getBrandList();
//   }, []);

//   const getBrandList = () => {
//     let data = {
//       categoryId: "65ec018e97d37cd76d3de84f",
//     };

//     axios
//       .post(
//         `${process.env.REACT_APP_API_KEY}vender/api/brand_list`,
//         data,
//       )
//       .then((res) => {
//         setBrandlist(res?.data?.data);
//       })
//       .catch((error) => {});
//   };

//   const handleDropdownToggle = () => {
//     setBrandlistDetails(!brandlistdetails);
//   };

//   // Filter the brandlist based on the input text
//   const filteredBrandList = brandlist.filter(brandItem =>
//     brandItem.brand.some(brand =>
//       brand.toLowerCase().includes(filterText.toLowerCase())
//     )
//   );

//   return (
//     <div className="col-md-6 col-lg-6 col-xl-3">
//       <div className="form-group">
//         <label htmlFor="name" className="title-color">
//           Select brand <span className="text-danger">*</span>
//         </label>

//         <ul className="js-select2-custom form-control" name="sub_category_id" >
//           <p onClick={handleDropdownToggle}>Select brand</p>

//           {!brandlistdetails && (
//             <div className="mb-2">
//               <input
//                 placeholder="Search in categories"
//                 style={{ width: "200px" }}
//                 type="text"
//                 id="categoryFilter"
//                 className="form-control mt-2"
//                 value={filterText}
//                 onChange={(e) => setFilterText(e.target.value)}
//               />

//               {filteredBrandList.map((brandItem, index) =>
//                 brandItem?.brand?.map((brand, subIndex) => (
//                   <li
//                   onChange={(e) => {
//                     setbrand_name(e.target.value);
//                   }} className="form-control mt-2" key={`${index}-${subIndex}`} value={brand}>
//                     {brand}
//                   </li>
//                 ))
//               )}
//             </div>
//           )}
//         </ul>
//       </div>
//     </div>
//   );
// };

// export default Test;

// import axios from "axios";
// import React, { useEffect, useState } from "react";

// const Test = () => {
//   const [brandlist, setBrandlist] = useState([]);
//   const [brand_name, setBrandName] = useState("");
//   const [filterText, setFilterText] = useState("");
//   console.log(brand_name, "brand_name");
//   useEffect(() => {
//     getBrandList();
//   }, []);

//   const getBrandList = () => {
//     let data = {
//       categoryId: "65ec018e97d37cd76d3de84f",
//     };

//     axios
//       .post(`${process.env.REACT_APP_API_KEY}vender/api/brand_list`, data)
//       .then((res) => {
//         setBrandlist(res?.data?.data);
//       })
//       .catch((error) => {});
//   };

//   const filteredBrandList = brandlist.filter((brandItem) =>
//     brandItem?.brand?.some((brand) =>
//       brand?.toLowerCase().includes(filterText.toLowerCase())
//     )
//   );
//   const [brandlistdetails, setbrandlistdetails] = useState(true);

//   const handleDropdownToggle = () => {
//     setbrandlistdetails(!brandlistdetails);
//   };

//   return (
//     <div className="col-md-6 col-lg-6 col-xl-3">
//       <div className="form-group">
//         <label htmlFor="name" className="title-color">
//           Select brand <span className="text-danger">*</span>
//         </label>

//         <ul
//           className="js-select2-custom form-control"
//           name="sub_category_id"
//           value={brand_name}
//           onChange={(e) => {
//             setBrandName(e.target.value);
//           }}
//         >
//           <p onClick={handleDropdownToggle}>Select brand</p>

//           {!brandlistdetails ? (
//             <div className="mb-2">
//               <input
//                 placeholder="Search in categories"
//                 style={{ width: "200px" }}
//                 type="text"
//                 id="categoryFilter"
//                 className="form-control mt-2"
//                 value={filterText}
//                 onChange={(e) => setFilterText(e.target.value)}
//               />
//               {filteredBrandList.map((brandItem, index) =>
//                 brandItem?.brand?.map((brand, subIndex) => (
//                   <li
//                     className="form-control mt-2"
//                     key={`${index}-${subIndex}`}
//                     value={brand}
//                   >
//                     {brand}
//                   </li>
//                 ))
//               )}
//             </div>
//           ) : null}
//         </ul>
//       </div>
//     </div>
//   );
// };

// export default Test;

// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import secureLocalStorage from "react-secure-storage";
// import "./Test.css"; // Import CSS file for additional styling

// const Test = () => {
//   const [categoryMenuVisible, setCategoryMenuVisible] = useState(true);
//   const [subcategoryMenuVisible, setSubcategoryMenuVisible] = useState(false);
//   const [subsubcategoryMenuVisible, setSubSubcategoryMenuVisible] = useState(false);

//   const [categoryList, setCategoryList] = useState([]);
//   const [subcategoryList, setSubcategoryList] = useState([]);
//   const [subSubcategoryList, setSubSubcategoryList] = useState([]);

//   const [selectedCategory, setSelectedCategory] = useState(null);
//   const [selectedSubcategory, setSelectedSubcategory] = useState(null);
//   const [selectedSubSubcategory, setSelectedSubSubcategory] = useState(null);

//   let token = secureLocalStorage.getItem("vendortoken");
//   let vendorId = secureLocalStorage.getItem("vendorid");

//   useEffect(() => {
//     getCategories();
//   }, []);

//   const getCategories = () => {
//     const data = {
//       venderId: vendorId,
//     };
//     let options = {
//       headers: {
//         token: token,
//       },
//     };
//     axios
//       .post(
//         `${process.env.REACT_APP_API_KEY}vender/api/venderCategory_list`,
//         data,
//         options
//       )
//       .then((res) => {
//         setCategoryList(res.data.data);
//       })
//       .catch((error) => {});
//   };

//   const handleCategoryClick = (categoryId) => {
//     setSelectedCategory(categoryId);
//     setSubcategoryMenuVisible(true);
//     setSubSubcategoryMenuVisible(false);
//     getSubcategories(categoryId);
//   };

//   const getSubcategories = (categoryId) => {
//     const data = {
//       categoryId: categoryId,
//     };

//     let options = {
//       headers: {
//         token: token,
//       },
//     };
//     axios
//       .post(
//         `${process.env.REACT_APP_API_KEY}vender/api/venderSubcategory_list`,
//         data,
//         options
//       )
//       .then((res) => {
//         setSubcategoryList(res.data.data);
//         setSelectedSubcategory(null);
//       })
//       .catch((error) => {});
//   };

//   const handleSubcategoryClick = (subcategoryId) => {
//     setSelectedSubcategory(subcategoryId);
//     setSubSubcategoryMenuVisible(true);
//     getSubSubcategories(subcategoryId);
//   };

//   const getSubSubcategories = (subcategoryId) => {
//     const data = {
//       subcategoryId: subcategoryId,
//     };

//     let options = {
//       headers: {
//         token: token,
//       },
//     };
//     axios
//       .post(
//         `${process.env.REACT_APP_API_KEY}vender/api/sub_subcategory_list`,
//         data,
//         options
//       )
//       .then((res) => {
//         setSubSubcategoryList(res.data.data);
//         setSelectedSubSubcategory(null);
//       })
//       .catch((error) => {});
//   };

//   return (
//     <div className="container mt-5">
//       <div className="dropdown">
//         <ul className={`dropdown-menu ${categoryMenuVisible ? "show" : ""}`}>
//           {categoryList.map((category) => (
//             <li key={category.categoryId}>
//               <button
//                 className={`btn btn-secondary ml-3 ${selectedCategory === category.categoryId ? "selected" : ""}`}
//                 onClick={() => handleCategoryClick(category.categoryId)}
//               >
//                 {category.category_englishName} <span className="caret"></span>
//               </button>
//             </li>
//           ))}
//         </ul>
//         <ul
//           className={`dropdown-menu ${subcategoryMenuVisible ? "show" : ""}`}
//           style={{ left: "200px" }}
//         >
//           {subcategoryList.map((subcategory) => (
//             <li key={subcategory.subcategoryId}>
//               <button
//                 className={`btn btn-secondary ml-3 ${selectedSubcategory === subcategory.subcategoryId ? "selected" : ""}`}
//                 onClick={() => handleSubcategoryClick(subcategory.subcategoryId)}
//               >
//                 {subcategory.english_subcategory_name}{" "}
//                 <span className="caret"></span>
//               </button>
//             </li>
//           ))}
//         </ul>
//         <ul
//           className={`dropdown-menu ${subsubcategoryMenuVisible ? "show" : ""}`}
//           style={{ left: "400px" }}
//         >

//             <li key={subSubcategoryList.sub_subcategoryId}>
//               <button
//                 className={`btn btn-secondary ml-3 ${selectedSubSubcategory === subSubcategoryList.sub_subcategoryId ? "selected" : ""}`}
//                 onClick={() => setSelectedSubSubcategory(subSubcategoryList.sub_subcategoryId)}
//               >
//                 {subSubcategoryList.english_sub_subcategory_name} <span className="caret"></span>
//               </button>
//             </li>

//         </ul>
//       </div>
//     </div>
//   );
// };

// export default Test;

// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import secureLocalStorage from "react-secure-storage";
// import "./Test.css"; // Import CSS file for additional styling

// const Test = () => {
//   const [categoryMenuVisible, setCategoryMenuVisible] = useState(true);
//   const [subcategoryMenuVisible, setSubcategoryMenuVisible] = useState(false);
//   const [subsubcategoryMenuVisible, setSubSubcategoryMenuVisible] = useState(false);

//   const [categoryList, setCategoryList] = useState([]);
//   const [subcategoryList, setSubcategoryList] = useState([]);
//   const [subSubcategoryList, setSubSubcategoryList] = useState([]);

//   const [selectedCategory, setSelectedCategory] = useState(null);
//   const [selectedSubcategory, setSelectedSubcategory] = useState(null);
//   const [selectedSubSubcategory, setSelectedSubSubcategory] = useState(null);

//   const [categoryFilter, setCategoryFilter] = useState("");

//   let token = secureLocalStorage.getItem("vendortoken");
//   let vendorId = secureLocalStorage.getItem("vendorid");

//   useEffect(() => {
//     getCategories();
//   }, []);

//   const getCategories = () => {
//     const data = {
//       venderId: vendorId,
//     };
//     let options = {
//       headers: {
//         token: token,
//       },
//     };
//     axios
//       .post(
//         `${process.env.REACT_APP_API_KEY}vender/api/venderCategory_list`,
//         data,
//         options
//       )
//       .then((res) => {
//         setCategoryList(res.data.data);
//       })
//       .catch((error) => {});
//   };

//   const handleCategoryClick = (categoryId) => {
//     setSelectedCategory(categoryId);
//     setSubcategoryMenuVisible(true);
//     setSubSubcategoryMenuVisible(false);
//     getSubcategories(categoryId);
//   };

//   const getSubcategories = (categoryId) => {
//     const data = {
//       categoryId: categoryId,
//     };

//     let options = {
//       headers: {
//         token: token,
//       },
//     };
//     axios
//       .post(
//         `${process.env.REACT_APP_API_KEY}vender/api/venderSubcategory_list`,
//         data,
//         options
//       )
//       .then((res) => {
//         setSubcategoryList(res.data.data);
//         setSelectedSubcategory(null);
//       })
//       .catch((error) => {});
//   };

//   const handleSubcategoryClick = (subcategoryId) => {
//     setSelectedSubcategory(subcategoryId);
//     setSubSubcategoryMenuVisible(true);
//     getSubSubcategories(subcategoryId);
//   };

//   const getSubSubcategories = (subcategoryId) => {
//     const data = {
//       subcategoryId: subcategoryId,
//     };

//     let options = {
//       headers: {
//         token: token,
//       },
//     };
//     axios
//       .post(
//         `${process.env.REACT_APP_API_KEY}vender/api/sub_subcategory_list`,
//         data,
//         options
//       )
//       .then((res) => {
//         setSubSubcategoryList(res.data.data);
//         setSelectedSubSubcategory(null);
//       })
//       .catch((error) => {});
//   };

//   const filterCategories = (categories, filter) => {
//     return categories.filter((category) =>
//       category.category_englishName.toLowerCase().includes(filter.toLowerCase())
//     );
//   };

//   return (
//     <div className="container mt-5">
//       <div className="dropdown">
//         <div className="mb-2">
//           <label htmlFor="categoryFilter">Filter Categories:</label>
//           <input style={{width:'200px'}}
//             type="text"
//             id="categoryFilter"
//             className="form-control"
//             value={categoryFilter}
//             onChange={(e) => setCategoryFilter(e.target.value)}
//           />
//         </div>
//         <ul className={`dropdown-menu ${categoryMenuVisible ? "show" : ""}`}>
//           {filterCategories(categoryList, categoryFilter).map((category) => (
//             <li key={category.categoryId}>
//               <button
//                 className={`btn btn-secondary ml-3 ${selectedCategory === category.categoryId ? "selected" : ""}`}
//                 onClick={() => handleCategoryClick(category.categoryId)}
//               >
//                 {category.category_englishName} <span className="caret"></span>
//               </button>
//             </li>
//           ))}
//         </ul>
//         <ul
//           className={`dropdown-menu ${subcategoryMenuVisible ? "show" : ""}`}
//           style={{ left: "200px" }}
//         >
//           {subcategoryList.map((subcategory) => (
//             <li key={subcategory.subcategoryId}>
//               <button
//                 className={`btn btn-secondary ml-3 ${selectedSubcategory === subcategory.subcategoryId ? "selected" : ""}`}
//                 onClick={() => handleSubcategoryClick(subcategory.subcategoryId)}
//               >
//                 {subcategory.english_subcategory_name}{" "}
//                 <span className="caret"></span>
//               </button>
//             </li>
//           ))}
//         </ul>
//         <ul
//           className={`dropdown-menu ${subsubcategoryMenuVisible ? "show" : ""}`}
//           style={{ left: "400px" }}
//         >
//           <li key={subSubcategoryList.sub_subcategoryId}>
//             <button
//               className={`btn btn-secondary ml-3 ${selectedSubSubcategory === subSubcategoryList.sub_subcategoryId ? "selected" : ""}`}
//               onClick={() => setSelectedSubSubcategory(subSubcategoryList.sub_subcategoryId)}
//             >
//               {subSubcategoryList.english_sub_subcategory_name} <span className="caret"></span>
//             </button>
//           </li>
//         </ul>
//       </div>
//     </div>
//   );
// };

// export default Test;
