import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

const Forgotpassword = () => {
  let navigate = useNavigate();
  let login = () => {
    navigate(`/home`);
  };

  let [passwordtype, setpasswordtype] = useState("password");
  let [iconstatus, seticonstatus] = useState(false);

  let passwordicon = () => {
    setpasswordtype("text");
    seticonstatus(true);
  };
  let passwordicon1 = () => {
    seticonstatus(false);
    setpasswordtype("password");
  };

  const [vendoremail, setvendormobile] = useState("");
  const [otp, setotp] = useState();

  const [genrateotp, setgenrateotp] = useState("");
  const [verifydata, setverifydata] = useState();

  const Navigate = useNavigate();
  const [password, setpassword] = useState();
  const [confirmpassword, setconfirmpassword] = useState();
  const [ttrue, settrue] = useState(false);
  const [hide, sethide] = useState(false);

  const [resendgenrateotp, setresendgenrateotp] = useState("");
  const [useremail, setuseremail] = useState();

  const [userephone, setuserephone] = useState();
  const [resendgenrateotpa, setresendgenrateotpa] = useState();
  const [resendhide, setresendhide] = useState(false);
  const handlesubmit = (e) => {
    e.preventDefault();

    const userdata = {
      key: vendoremail,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/forgotPassword`,
        userdata
      )
      .then((response) => {
        setuseremail(response.data.data.email);
        setuserephone(response.data.data.mobile_number);
        setotp(response.data.data.otp);
        toast.success(response.data.message);
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Invalid Data Entered by you.");
        }
      });
  };

  const verifyotp = (e) => {
    e.preventDefault();

    const userdata = {
      otp: genrateotp ? genrateotp : resendgenrateotpa,
      key: vendoremail,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/verifyEmail_andMobile`,
        userdata
      )
      .then((response) => {
        setverifydata(response.data.data.otp);
        sethide(true);
        setresendhide(true);
        toast.success(response.data.message);
      })
      .catch((error) => {

        if (error.response && error.response.status === 400) {
          toast.error(error.response.data.message);
        }
      });
  };
  const [dataemail, setdataemail]= useState();
  const phone = (e) => {
    e.preventDefault();

    const userdata = {
      mobile_number: userephone,
      choose_status: "1",
    };

    axios
      .post(`${process.env.REACT_APP_API_KEY}vender/api/resendOtp`, userdata)
      .then((response) => {
        setdataemail(response?.data?.data?.mobile_number
          )
        setresendgenrateotp(response.data.data.otp);

        toast.success(response.data.message);
      })
      .catch((error) => {
        toast.error("Invalid Data Enter By you.");
      });
  };


  const email = (e) => {
    e.preventDefault();

    const userdata = {
      email: useremail,
      choose_status: "2",
    };

    axios
      .post(`${process.env.REACT_APP_API_KEY}vender/api/resendOtp`, userdata)
      .then((response) => {
        
        setdataemail(response?.data?.data?.email
          )
        setresendgenrateotp(response?.data?.data?.otp);
        toast.success(response?.data?.message);
      })
      .catch((error) => {
        toast.error("Invalid Data Enter By you.");
      });
  };

  const changepassword = (e) => {
    e.preventDefault();
    if (password != confirmpassword) {
      toast.error(
        "Please Enter Same input in Both filed (Password & Confirmpassword)"
      );
      return;
    }
    const userdata = {
      key: vendoremail,
      password: password,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/resetPassword`,
        userdata
      )
      .then((res) => {
        setTimeout(() => {
          navigate("/");
        }, 3000);
        toast.success(res.data.message);
      })
      .catch((error) => {});
    setpassword();
    setconfirmpassword();
  };
  return (
    <div>
      <Toaster />
      <div
        className="position-fixed top-0 right-0 left-0 bg-img-hero __inline-1"
        style={{
          backgroundImage:
            "url(https://6valley.6amtech.com/public/assets/admin/svg/components/abstract-bg-4.svg)",
        }}
      >
        <figure className="position-absolute right-0 bottom-0 left-0">
          <svg
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1921 273"
          >
            <polygon fill="#f9f9fb" points="0,273 1921,273 1921,0 " />
          </svg>
        </figure>
      </div>
      <div className="container py-5 py-sm-7">
        <a className="d-flex justify-content-center mb-5" href="javascript:">
          <img
            className="z-index-2"
            height={100}
            src="./logonew.png"
            alt="Logo"
            onerror="this.src='https://6valley.6amtech.com/public/assets/back-end/img/400x400/img2.jpg'"
          />
        </a>
        <div className="row justify-content-center">
          <div className="col-md-7 col-lg-5">
            <div className="card card-lg mb-5">
              <div className="card-body">
                <form onSubmit={handlesubmit}>
                  <input type="hidden" name="_token" />
                  <div className="text-center"></div>
                  <input
                    type="hidden"
                    className="form-control mb-3"
                    name="role"
                  />

                  <div
                    id="recaptcha_element"
                    className="w-100;"
                    data-type="image"
                  />
                  <br />
                  {otp ? (
                    <></>
                  ) : (
                    <>
                      <div className="js-form-message form-group">
                        <label className="input-label" htmlFor="signinSrEmail">
                          Email or phone <span className="text-danger">*</span>
                        </label>
                        <input
                          required
                          type="text"
                          className="form-control form-control-lg"
                          name="email"
                          onChange={(e) => {
                            setvendormobile(e.target.value);
                          }}
                          placeholder="Email or Phone"
                        />
                      </div>

                      <h6>
                        We’ll send a verification code to this email or phone
                        number if it matches an existing innt account.
                      </h6>
                      <br />

                      <button
                        type="submit"
                        className="btn btn-lg btn-block btn--primary"
                      >
                        Continue
                      </button>
                      <br />

                      <Link to="/" className="btn btn-lg btn-block btn--info">
                        Back
                      </Link>
                    </>
                  )}
                </form>
              </div>

              {/* {otp ? (
                <div className="card-body" style={{ marginTop: "-50px" }}>
                  <form onSubmit={verifyotp}>
                  
                    <div className="js-form-message form-group">

                    <div className="input-group input-group-merge iconinput mb-3"  style={{justifyContent:'center'}}>
                        <h2>Verify your email or phone</h2>
                      </div>
                      <div className="input-group input-group-merge iconinput mb-3"  style={{justifyContent:'center'}}>
                        <h3>Please enter the code sent to</h3>
                      </div>

                      <div className="input-group input-group-merge iconinput mb-3"  style={{justifyContent:'center'}}>
                        <h3>+ 91 xxx xxx xxx</h3>
                      </div>
                    
                      
                      <div className="input-group input-group-merge iconinput">
                        <input
                          required
                          value={genrateotp}
                          placeholder={otp}
                          onChange={(e) => {
                            setgenrateotp(e.target.value);
                          }}
                          type="number"
                          className=" form-control form-control-lg"
                        />
                      </div>
                      <br />
                      <button
                        type="submit"
                        className="btn btn-lg btn-block btn--info"
                      >
                        Resend code
                      </button>
                      <br />
                      <button
                        type="submit"
                        className="btn btn-lg btn-block btn--primary"
                      >
                        Verify
                      </button>
                      <br />
                      <Link to='/'
                        type="submit"
                        className="btn btn-lg btn-block btn--info"
                      >
                        Back
                      </Link>
                    </div>
                  </form>
                </div>
              ) : (
                <></>
              )} */}

              {otp && !hide && !ttrue ? (
                <div className="card-body" style={{ marginTop: "-50px" }}>
                  <form onSubmit={verifyotp}>
                    <div className="js-form-message form-group">
                      <div
                        className="input-group input-group-merge iconinput mb-3"
                        style={{ justifyContent: "center" }}
                      >
                        <h2>Verify your email or phone</h2>
                      </div>
                      <div
                        className="input-group input-group-merge iconinput mb-3"
                        style={{ justifyContent: "center" }}
                      >
                        <h3>Please enter the code sent to</h3>
                      </div>
                      <div
                        className="input-group input-group-merge iconinput mb-3"
                        style={{ justifyContent: "center" }}
                      >
                        <h3>{vendoremail}</h3>
                      </div>
                      <div className="input-group input-group-merge iconinput">
                        <input
                          value={genrateotp}
                          placeholder={otp}
                          onChange={(e) => {
                            setgenrateotp(e.target.value);
                          }}
                          type="number"
                          className=" form-control form-control-lg"
                        />
                      </div>
                      <br />
                      <div>
                        <button
                          onClick={() => {
                            settrue(true);
                          }}
                          className="btn btn-lg btn-block btn--info"
                        >
                          Resend code
                        </button>
                      </div>

                      <br />
                      <button
                        type="submit"
                        className="btn btn-lg btn-block btn--primary"
                      >
                        Verify
                      </button>
                      <br />
                      <Link
                        to="/"
                        type="submit"
                        className="btn btn-lg btn-block btn--info"
                      >
                        Back
                      </Link>
                    </div>
                  </form>
                </div>
              ) : null}

              {ttrue && !resendgenrateotp ? (
                <div className="card-body" style={{ marginTop: "-50px" }}>
                  <form onSubmit={verifyotp}>
                    <div className="js-form-message form-group">
                      <div
                        className="input-group input-group-merge iconinput mb-3"
                        style={{ justifyContent: "center" }}
                      >
                        <h2>Verify your email or phone</h2>
                      </div>
                      <div
                        className="input-group input-group-merge iconinput mb-3"
                        style={{ justifyContent: "center" }}
                      >
                        <h3>Please enter the code sent to</h3>
                      </div>
                      <div
                        className="input-group input-group-merge iconinput mb-3"
                        style={{ justifyContent: "center" }}
                      >
                        <h3>{vendoremail}</h3>
                      </div>
                      <div className="input-group input-group-merge iconinput">
                        <input
                          placeholder={vendoremail}
                          onChange={(e) => {
                            setresendgenrateotpa(e.target.value);
                          }}
                          type="number"
                          className=" form-control form-control-lg"
                        />
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-lg-6">
                          {" "}
                          <button
                            onClick={email}
                            className="btn btn-lg btn-block btn--primary"
                          >
                            Email
                          </button>
                        </div>
                        <div className="col-lg-6">
                          <button
                            onClick={phone}
                            className="btn btn-lg btn-block btn--primary"
                          >
                            Phone
                          </button>
                        </div>
                      </div>

                      <br />
                      <button
                        type="submit"
                        className="btn btn-lg btn-block btn--primary"
                      >
                        Verify
                      </button>
                      <br />
                      <Link
                        to="/"
                        type="submit"
                        className="btn btn-lg btn-block btn--info"
                      >
                        Back
                      </Link>
                    </div>
                  </form>
                </div>
              ) : null}

              {resendgenrateotp && !resendhide ? (
                <div className="card-body" style={{ marginTop: "-50px" }}>
                  <form onSubmit={verifyotp}>
                    <div className="js-form-message form-group">
                      <div
                        className="input-group input-group-merge iconinput mb-3"
                        style={{ justifyContent: "center" }}
                      >
                        <h2>Verify your email or phone</h2>
                      </div>
                      <div
                        className="input-group input-group-merge iconinput mb-3"
                        style={{ justifyContent: "center" }}
                      >
                        <h3>Please enter the code sent to</h3>
                      </div>
                      <div
                        className="input-group input-group-merge iconinput mb-3"
                        style={{ justifyContent: "center" }}
                      >
                        <h3>{dataemail}</h3>
                      </div>
                      <div className="input-group input-group-merge iconinput">
                        <input
                          value={genrateotp}
                          placeholder={resendgenrateotp}
                          onChange={(e) => {
                            setgenrateotp(e.target.value);
                          }}
                          type="number"
                          className=" form-control form-control-lg"
                        />
                      </div>
                      <br />
                      <div>
                        {/* <button 
           onClick={(()=>{settrue(true)})}
          className="btn btn-lg btn-block btn--info"
        >
          Resend code
        </button> */}
                      </div>

                      <br />
                      <button
                        type="submit"
                        className="btn btn-lg btn-block btn--primary"
                      >
                        Verify
                      </button>
                      <br />
                      <Link
                        to="/"
                        type="submit"
                        className="btn btn-lg btn-block btn--info"
                      >
                        Back
                      </Link>
                    </div>
                  </form>
                </div>
              ) : null}

              {verifydata ? (
                <div className="card-body" style={{ marginTop: "-45px" }}>
                  <div
                    className="input-group input-group-merge iconinput mb-3"
                    style={{ justifyContent: "center" }}
                  >
                    <h2>Create your new password</h2>
                  </div>
                  <form onSubmit={changepassword}>
                    <input type="hidden" />
                    <div className="row form-group">
                      <label
                        htmlFor="newPassword"
                        className="col-sm-12 col-form-label input-label"
                      >
                        {" "}
                        Enter your new password
                      </label>
                      <div className="col-sm-12">
                        <input
                          required
                          value={password}
                          onChange={(e) => {
                            setpassword(e.target.value);
                          }}
                          type="password"
                          className="js-pwstrength form-control"
                          name="password"
                          placeholder="Enter new password"
                          aria-label="Enter new password"
                        />
                        <p className="form-text mb-2" />
                        <div id="passwordStrengthProgress" />
                      </div>
                    </div>
                    <div className="row form-group">
                      <label
                        htmlFor="confirmNewPasswordLabel"
                        className="col-sm-12 col-form-label input-label"
                      >
                        {" "}
                        Confirm your new password{" "}
                      </label>
                      <div className="col-sm-12">
                        <div className="mb-3">
                          <input
                            required
                            value={confirmpassword}
                            onChange={(e) => {
                              setconfirmpassword(e.target.value);
                            }}
                            type="password"
                            className="form-control"
                            name="confirm_password"
                            placeholder="Confirm your new password"
                            aria-label="Confirm your new password"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <button
                        type="submit"
                        className="btn btn--primary col-sm-12"
                      >
                        Continue
                      </button>
                      <br />
                      <Link
                        to="/"
                        type="submit"
                        className="btn btn-lg btn-block btn--info"
                      >
                        Back
                      </Link>
                    </div>
                  </form>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forgotpassword;
