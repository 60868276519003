import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

const Protect = (props) => {
  let { ComponentName } = props;
  // console.log("sdacomponent name",ComponentName)
  let navigate = useNavigate();

  useEffect(() => {
    //let status = 123
    let status = secureLocalStorage.getItem("vendorid");

    if (!status) {
      navigate("/");
    }
  }, []);

  let logout = () => {
    secureLocalStorage.removeItem("vendorid");
    navigate("login");
  };
  return (
    <>
      <ComponentName />
    </>
  );
};

export default Protect;
