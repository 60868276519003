import React, { useEffect, useState } from "react";
import Header from "../Header";

import Sidebarr from "../Sidebar";
import { Link, Navigate, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

const Editbankinfo = () => {
    const Navigate = useNavigate();
  const [details, setdetails] = useState();
  const [mobile_money_number, setmobile_money_number] = useState();
  const [bank_name, setbank_name] = useState();
  const [acc_number, setacc_number] = useState();
  const [bankAccount_name, setbankAccount_name] = useState();
  const [swift_code, setswift_code] = useState();
  let venderid = secureLocalStorage.getItem("vendorid");
  let token = secureLocalStorage.getItem("vendortoken");
  useEffect(() => {
    sellerdetails();
  }, [0]);

  const sellerdetails = () => {
    const sellerdata = {
      venderId: venderid,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/getVenderProfile`,
        sellerdata
      )
      .then((res) => {
        console.log("res",res)
        setdetails(res.data.data);
        setbank_name(res?.data?.data?.bank_name)
        setacc_number(res?.data?.data?.acc_number)
        setbankAccount_name(res?.data?.data?.bankAccount_name)
        setswift_code(res?.data?.data?.swift_code)
        setmobile_money_number(res?.data?.data?.mobile_money_number)

        
      })
      .catch((error) => {});
  };

  const updatebankdetails = (e) => {
    e.preventDefault();

    const formData = {
      venderId: venderid,
      mobile_money_number: mobile_money_number,
      bank_name: bank_name,
      acc_number: acc_number,
      bankAccount_name: bankAccount_name,
      swift_code: swift_code,
    };
    const options = {
      headers: {
        token: token,
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/update_BankInformation`,
        formData,
        options
      )
      .then((res) => {
        setTimeout(() => {
          Navigate("/profile");
        }, 3000);

        toast.success(`Your bank and mobile money
        information has been updated!`);
      })
      .catch((error) => {});
  };
  return (
    <div className="container">
      {/* <Header /> */}
      <Toaster />
      <div
        className="container row"
        style={{ paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px" }}
      >
        <div className="col-lg-2 col-md-3" style={{ paddingLeft: "0px" }}>
          {/* <Sidebarr /> */}
        </div>

        <div
          className="col-lg-10 col-md-9"
          style={{ paddingLeft: "30px", marginTop: "60px" }}
        >
          <div className="mt-3" style={{ textAlign: "left" }}>
            <div className="mb-3">
              <h2 className="h1 mb-0 text-capitalize d-flex align-items-center gap-2">
                <img
                  width={20}
                  src="https://6valley.6amtech.com/public/assets/back-end/img/my-bank-info.png"
                  alt
                />
                Edit Bank info
              </h2>
            </div>
            <div className="row mt-4">
              <div className="col-md-12">
                <div className="card" style={{borderColor:'rgb(188, 189, 190)'}}>
                  <div className="card-header">
                    <h4 className="mb-0 ">Edit Bank Info</h4>
                  </div>
                  <div className="card-body">
                    <form onSubmit={updatebankdetails}>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-lg-4 col-md-6 mb-3">
                            <label htmlFor="name" className="title-color">
                              {/* Bank name <span className="text-danger">*</span> */}
                            </label>
                            <input
                              type="text"
                              value={bank_name}
                              className="form-control"
                              onChange={(e) => {
                                setbank_name(e.target.value);
                              }}
                            />
                          </div>
                          <div className="col-lg-4 col-md-6 mb-3">
                            <label htmlFor="name" className="title-color">
                              Bank account number{" "}
                              {/* <span className="text-danger">*</span> */}
                            </label>
                            <input
                              type="text"
                              value={acc_number}
                              onChange={(e) => {
                                setacc_number(e.target.value);
                              }}
                              className="form-control"
                            />
                          </div>
                          <div className="col-lg-4 col-md-6 mb-3">
                            <label htmlFor="name" className="title-color">
                              Bank account name{" "}
                              {/* <span className="text-danger">*</span> */}
                            </label>
                            <input
                              type="text"
                              value={bankAccount_name}
                              onChange={(e) => {
                                setbankAccount_name(e.target.value);
                              }}
                              className="form-control"
                            />
                          </div>
                          <div className="col-lg-4 col-md-6 mb-3">
                            <label htmlFor="name" className="title-color">
                              {/* SWIFT Code <span className="text-danger">*</span> */}
                            </label>
                            <input
                              type="text"
                              value={swift_code}
                              onChange={(e) => {
                                setswift_code(e.target.value);
                              }}
                              className="form-control"
                            />
                          </div>
                          <div className="col-lg-4 col-md-6 mb-3">
                            <label htmlFor="name" className="title-color">
                              Mobile money number
                              {/* <span className="text-danger">*</span> */}
                            </label>
                            <input
                              type="text"
                              value={mobile_money_number}
                              onChange={(e) => {
                                setmobile_money_number(e.target.value);
                              }}
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end gap-2">
                        <Link to="/profile" className="btn btn-danger">
                          Cancel
                        </Link>
                        <button
                          type="submit"
                          className="btn btn--primary"
                          id="btn_update"
                        >
                          Update
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Editbankinfo;
