import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./manubar.css";
import Sidebar from "./Sidebar";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";

const Header = () => {
  let navigate = useNavigate();
  const [details, setdetails] = useState();
  let userprofile = () => {
    navigate(`/profile`);
  };
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    updateScreenWidth();

    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, [screenWidth]);

  useEffect(() => {
    window.addEventListener("resize", updateScreenWidth);

    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, [screenWidth]);

  let notification = () => {
    navigate(`/Notifications`);
  };

  let profileinfo = () => {
    navigate(`/profile`);
  };
  let token = secureLocalStorage.getItem("vendortoken");
  let venderid = secureLocalStorage.getItem("vendorid");

  useEffect(() => {
    sellerdetails();
  }, [0]);

  const sellerdetails = () => {
    const sellerdata = {
      venderId: venderid,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/getVenderProfile`,
        sellerdata
      )
      .then((res) => {
        setdetails(res.data.data);
      })
      .catch((error) => {});
  };

  const [sidebarStatus, setSidebarStatus] = useState(() => {
    return localStorage.getItem("setstatus");
  });

  useEffect(() => {
    const handleStorageChange = () => {
      setSidebarStatus(localStorage.getItem("setstatus"));
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <>
      {screenWidth >= 768 ? (
        <header
          id="header"
          style={{ zIndex: 1 }}
          className="navbar navbar-expand-lg navbar-fixed navbar-height navbar-flush navbar-container navbar-bordered"
        >
          <div className="navbar-nav-wrap">
            <div style={{ cursor: "pointer" }} className="navbar-brand-wrapper">
              <Link to="/home" className="navbar-brand" aria-label>
                <img
                  className="navbar-brand-logo-mini"
                  src="./logonew.png"
                  alt="Logo"
                  height={40}
                />
              </Link>
            </div>

            <div
              className="navbar-nav-wrap-content-right"
              style={{
                marginRight: "unset",
                marginLeft: "auto",
                cursor: "pointer",
              }}
            >
              <ul className="navbar-nav align-items-center flex-row">
                <li className="nav-item d-none d-md-inline-block">
                  <div className="hs-unfold">
                    <Link
                      className="js-hs-unfold-invoker btn btn-icon btn-ghost-secondary rounded-circle media align-items-center gap-3 navbar-dropdown-account-wrapper dropdown-toggle-left-arrow dropdown-toggle-empty"
                      to="/Notifications"
                      data-hs-unfold-target="#notificationDropdown"
                      data-hs-unfold-invoker
                    >
                      <i
                        onClick={userprofile}
                        className="fa fa-bell"
                        aria-hidden="true"
                      ></i>
                      <span className="btn-status btn-sm-status btn-status-danger">
                        0
                      </span>
                    </Link>
                    <div
                      id="notificationDropdown"
                      className="hs-unfold-content dropdown-unfold dropdown-menu dropdown-menu-right navbar-dropdown-menu navbar-dropdown-account py-0 overflow-hidden hs-unfold-content-initialized hs-unfold-css-animation animated hs-unfold-hidden"
                      style={{ width: "20rem", animationDuration: "300ms" }}
                      data-hs-target-height={0}
                      data-hs-unfold-content
                      data-hs-unfold-content-animation-in="slideInUp"
                      data-hs-unfold-content-animation-out="fadeOut"
                    ></div>
                  </div>
                </li>
                <li className="nav-item d-none d-md-inline-block">
                  <div className="hs-unfold">
                    <Link
                      className="js-hs-unfold-invoker btn btn-icon btn-ghost-secondary rounded-circle media align-items-center gap-3 navbar-dropdown-account-wrapper dropdown-toggle dropdown-toggle-left-arrow"
                      to="/Notifications"
                      data-hs-unfold-options='{
                                     "target": "#messageDropdown",
                                     "type": "css-animation"
                                   }'
                      data-hs-unfold-target="#messageDropdown"
                      data-hs-unfold-invoker
                    >
                      <i
                        onClick={notification}
                        class="fa fa-envelope-o"
                        aria-hidden="true"
                      ></i>
                      <span className="btn-status btn-sm-status btn-status-danger">
                        0
                      </span>
                    </Link>
                    <div
                      id="messageDropdown"
                      className="hs-unfold-content dropdown-unfold dropdown-menu dropdown-menu-right navbar-dropdown-menu navbar-dropdown-account hs-unfold-content-initialized hs-unfold-css-animation animated hs-unfold-hidden"
                      style={{ width: "16rem", animationDuration: "300ms" }}
                      data-hs-target-height="117.375"
                      data-hs-unfold-content
                      data-hs-unfold-content-animation-in="slideInUp"
                      data-hs-unfold-content-animation-out="fadeOut"
                    >
                      <a
                        className="dropdown-item position-relative"
                        href="https://6valley.6amtech.com/seller/messages/chat/customer"
                      >
                        <span className="text-truncate pr-2" title="Settings">
                          Customer
                        </span>
                        <span className="btn-status btn-sm-status-custom btn-status-danger">
                          1
                        </span>
                      </a>
                      <div className="dropdown-divider" />
                      <a
                        className="dropdown-item position-relative"
                        href="https://6valley.6amtech.com/seller/messages/chat/delivery-man"
                      >
                        <span className="text-truncate pr-2" title="Settings">
                          Delivery man
                        </span>
                      </a>
                    </div>
                  </div>
                </li>

                <li className="nav-item view-web-site-info">
                  <div className="hs-unfold">
                    <a
                      className="bg-white"
                      onclick="openInfoWeb()"
                      href="javascript:"
                    >
                      <i className="tio-info" />
                    </a>
                  </div>
                </li>
                <li className="nav-item">
                  <div className="hs-unfold">
                    <a
                      className="js-hs-unfold-invoker media align-items-center gap-3 navbar-dropdown-account-wrapper  - hs-active"
                      href="javascript:;"
                      data-hs-unfold-options='{
                                     "target": "#accountNavbarDropdown",
                                     "type": "css-animation"
                                   }'
                      data-hs-unfold-target="#accountNavbarDropdown"
                      data-hs-unfold-invoker
                    >
                      <div className="d-none d-md-block media-body text-right">
                        <h5 className="profile-name mb-0" />
                        <span onClick={profileinfo} className="fz-12 text-capitalize">
                          {secureLocalStorage.getItem("firstnamee")}
                        </span>
                      </div>
                      <div
                        onClick={profileinfo}
                        className="avatar avatar-sm avatar-circle"
                      >
                        <img
                          className="avatar-img"
                          src={
                            details?.vender_profile
                              ? `${process.env.REACT_APP_API_KEY}uploads/${details?.vender_profile}`
                              : `https://6valley.6amtech.com/storage/app/public/seller/2022-10-12-63467dae3ef83.png`
                          }
                          alt="Image Description"
                        />
                        <span className="avatar-status avatar-sm-status avatar-status-success" />
                      </div>
                    </a>
                    {/* <div id="accountNavbarDropdown" className="hs-unfold-content dropdown-unfold dropdown-menu dropdown-menu-right navbar-dropdown-menu navbar-dropdown-account __w-16rem hs-unfold-content-initialized hs-unfold-css-animation animated slideInUp" data-hs-target-height="166.766" data-hs-unfold-content data-hs-unfold-content-animation-in="slideInUp" data-hs-unfold-content-animation-out="fadeOut" style={{animationDuration: '300ms'}}>
              <div className="dropdown-item-text">
                <div className="media align-items-center text-break">
                  <div className="avatar avatar-sm avatar-circle mr-2">
                    <img className="avatar-img" onerror="this.src='https://6valley.6amtech.com/public/assets/back-end/img/160x160/img1.jpg'" src="https://6valley.6amtech.com/storage/app/public/seller/2022-10-12-63467dae3ef83.png" alt="Image Description" />
                  </div>
                  <div className="media-body">
                    <span className="card-title h5">kamrujjaman</span>
                    <span className="card-text">test.seller@gmail.com</span>
                  </div>
                </div>
              </div>
              <div className="dropdown-divider" />
               <Link to='/profile' className="dropdown-item">
                <span className="text-truncate pr-2" title="Settings">Profile</span>
              </Link> 
              <div className="dropdown-divider" />
              <Link to='/' className="dropdown-item" href="javascript:" >
                <span className="text-truncate pr-2" title="Sign out">Sign out</span>
              </Link>
            </div> */}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </header>
      ) : (
        <header
          id="header"
          style={{ zIndex: 1 }}
          className="navbar navbar-expand-lg navbar-fixed navbar-height navbar-flush navbar-container navbar-bordered"
        >
          <div className="navbar-nav-wrap">
          <div
              onClick={() => {
                const currentStatus = localStorage.getItem("setstatus");
                const newStatus = currentStatus === "false" ? "true" : "false";
                localStorage.setItem("setstatus", newStatus);
                window.dispatchEvent(new Event("storage"));
              }}
              className="navbar-brand"
              aria-label="Toggle Sidebar"
            >
              {sidebarStatus === "true" ? (
                <i
                  className="fa fa-times"
                  aria-hidden="true"
                  style={{ fontSize: "30px", color: "rgb(7, 59, 116)" }}
                ></i>
              ) : (
                <i
                  className="fa fa-bars chkicons"
                  aria-hidden="true"
                  style={{ fontSize: "28px", color: "rgb(7, 59, 116)" }}
                ></i>
              )}
            </div>
            <div style={{ cursor: "pointer" }} className="navbar-brand-wrapper">
              <Link className="navbar-brand" to="/home" aria-label>
                <img
                  className="navbar-brand-logo-mini"
                  src="./logonew.png"
                  alt="Logo"
                  height={40}
                />
              </Link>
            </div>
          

            <div
              className="navbar-nav-wrap-content-right"
              style={{
                marginRight: "unset",
                marginLeft: "auto",
                cursor: "pointer",
              }}
            >
              <ul className="navbar-nav align-items-center flex-row">
                <li className="nav-item ">
                  <div className="hs-unfold">
                    <Link
                      className="js-hs-unfold-invoker btn btn-icon btn-ghost-secondary rounded-circle media align-items-center gap-3 navbar-dropdown-account-wrapper dropdown-toggle-left-arrow dropdown-toggle-empty"
                      to="/Notifications"
                      data-hs-unfold-target="#notificationDropdown"
                      data-hs-unfold-invoker
                    >
                      <i
                        onClick={notification}
                        className="fa fa-bell"
                        aria-hidden="true"
                      ></i>
                      <span className="btn-status btn-sm-status btn-status-danger">
                        0
                      </span>
                    </Link>
                    <div
                      id="notificationDropdown"
                      className="hs-unfold-content dropdown-unfold dropdown-menu dropdown-menu-right navbar-dropdown-menu navbar-dropdown-account py-0 overflow-hidden hs-unfold-content-initialized hs-unfold-css-animation animated hs-unfold-hidden"
                      style={{ width: "20rem", animationDuration: "300ms" }}
                      data-hs-target-height={0}
                      data-hs-unfold-content
                      data-hs-unfold-content-animation-in="slideInUp"
                      data-hs-unfold-content-animation-out="fadeOut"
                    ></div>
                  </div>
                </li>
                <li className="nav-item ">
                  <div className="hs-unfold">
                    <Link
                      onClick={notification}
                      className="js-hs-unfold-invoker btn btn-icon btn-ghost-secondary rounded-circle media align-items-center gap-3 navbar-dropdown-account-wrapper dropdown-toggle dropdown-toggle-left-arrow"
                      to="/Notifications"
                      data-hs-unfold-options='{
                                     "target": "#messageDropdown",
                                     "type": "css-animation"
                                   }'
                      data-hs-unfold-target="#messageDropdown"
                      data-hs-unfold-invoker
                    >
                      <i
                        onClick={notification}
                        class="fa fa-envelope-o"
                        aria-hidden="true"
                      ></i>
                      <span className="btn-status btn-sm-status btn-status-danger">
                        0
                      </span>
                    </Link>
                    <div
                      id="messageDropdown"
                      className="hs-unfold-content dropdown-unfold dropdown-menu dropdown-menu-right navbar-dropdown-menu navbar-dropdown-account hs-unfold-content-initialized hs-unfold-css-animation animated hs-unfold-hidden"
                      style={{ width: "16rem", animationDuration: "300ms" }}
                      data-hs-target-height="117.375"
                      data-hs-unfold-content
                      data-hs-unfold-content-animation-in="slideInUp"
                      data-hs-unfold-content-animation-out="fadeOut"
                    >
                      <a
                        className="dropdown-item position-relative"
                        href="https://6valley.6amtech.com/seller/messages/chat/customer"
                      >
                        <span className="text-truncate pr-2" title="Settings">
                          Customer
                        </span>
                        <span className="btn-status btn-sm-status-custom btn-status-danger">
                          1
                        </span>
                      </a>
                      <div className="dropdown-divider" />
                      <a
                        className="dropdown-item position-relative"
                        href="https://6valley.6amtech.com/seller/messages/chat/delivery-man"
                      >
                        <span className="text-truncate pr-2" title="Settings">
                          Delivery man
                        </span>
                      </a>
                    </div>
                  </div>
                </li>

                <li className="nav-item">
                  <div className="hs-unfold">
                    <span
                      onClick={profileinfo}
                      className="js-hs-unfold-invoker media align-items-center gap-3 navbar-dropdown-account-wrapper "
                    >
                      <div className="d-none d-md-block media-body text-right">
                        <h5 className="profile-name mb-0" />
                        <span className="fz-12">innt Seller</span>
                      </div>
                      <div
                        onClick={profileinfo}
                        className="avatar avatar-sm avatar-circle"
                      >
                        <img
                          className="avatar-img"
                          src={
                            details?.vender_profile
                              ? `${process.env.REACT_APP_API_KEY}uploads/${details?.vender_profile}`
                              : `https://cdn.pixabay.com/photo/2022/03/11/06/14/indian-man-7061278_1280.jpg`
                          }
                          alt="Image Description"
                        />
                        <span className="avatar-status avatar-sm-status avatar-status-success" />
                      </div>
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </header>
      )}
    </>
  );
};

export default Header;
