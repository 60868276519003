import React from 'react'
import Header from '../Header'

import '../sidebar.css'
import { Link } from 'react-router-dom'
import Sidebarr from '../Sidebar'

const Barcode = () => {
  return (
    <div className='container'>

    {/* <Header/> */}
    <div className='container row' style={{ paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px" }}>
    <div className="col-lg-2 col-md-3" style={{ paddingLeft: "0px" }}>
    {/* <Sidebarr/> */}
    </div>

    <div className="col-lg-10 col-md-9" style={{ paddingLeft: "30px", marginTop: "60px" }}>
   <br/>
   <br/>
    <div   div className="mb-4">
      <h2 className="h1 mb-0 text-capitalize d-flex align-items-center gap-2">
        <img width={20} src="https://6valley.6amtech.com/public/assets/back-end/img/products.png" alt />
        Barcode
        <span className="badge badge-soft-dark radius-50 fz-14 ml-1">11</span>
      </h2>
    </div>
    <div className="row m-2 show-div">
        <div className="col-sm-12 col-lg-12 mb-3 mb-lg-2">
          <div className="card">
            <div className="card-header">
              <h3>
                Generate barcode
              </h3>
            </div>
            <div className="card-body">
              <div className="table-responsive-lg">
                <table className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table">
                  <thead className="thead-light thead-50 text-capitalize">
                    <tr>
                      <th>Code</th>
                      <th>Name</th>
                      <th>Quantity</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <form action="https://6valley.6amtech.com/seller/product/barcode/54" method="GET" />
                      <th>
                        <span>
                          134979
                        </span>
                      </th>
                      <th>Secret Covered in Sa...</th>
                      <th>
                        <input id="limit" type="number" name="limit" min={1} className="form-control" defaultValue />
                        <span className="text-info mt-1 d-block">Maximum quantity 270</span>
                      </th>
                      <th>
                        <button className="btn btn-info" type="submit">Generate barcode</button>
                        <a href="#" className="btn btn-danger">Reset</a>
                        <button type="button" id="print_bar" onclick="printDiv('printarea')" className="btn btn--primary ">Print</button>
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

      </div>


      
    </div>
  
    </div>



    </div>
  )
}

export default Barcode



