import React from 'react'
import Header from '../Header'

import '../sidebar.css'
import { Link } from 'react-router-dom'
import Sidebarr from '../Sidebar'

const Addressedit = () => {
  return (
    <div className='container'>

    {/* <Header/> */}
    <div className='container row' style={{ paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px" }}>
    <div className="col-lg-2 col-md-3" style={{ paddingLeft: "0px" }}>
    {/* <Sidebarr/> */}
    </div>

    <div className="col-lg-10 col-md-9" style={{ paddingLeft: "30px", marginTop: "60px" }}>
   
    <br/>
    <br/>

    <div className="content container-fluid">
        <div className="mb-3">
          <h2 className="h1 mb-0 text-capitalize d-flex align-items-center gap-2">
            <img width={20} src="https://6valley.6amtech.com/public/assets/back-end/img/shop-info.png" alt="" />
            Edit Shop Info
          </h2>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header">
                <h5 className="mb-0 ">Edit Shop Info</h5>
                <a href="https://6valley.6amtech.com/seller/shop/view" className="btn btn--primary __inline-70 px-4 text-white">Back</a>
              </div>
              <div className="card-body">
                <form action="https://6valley.6amtech.com/seller/shop/update/2" method="post" style={{textAlign: 'left'}} encType="multipart/form-data">
                  <input type="hidden" name="_token" defaultValue="YFPrIbFYlUsgcs6Ht4EEyCysWGBq8lmpLaBUW0q6" /> <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="name" className="title-color">Shop Name <span className="text-danger">*</span></label>
                        <input type="text" name="name" defaultValue="Graduate Tea" className="form-control" id="name" required />
                      </div>
                      <div className="form-group">
                        <label htmlFor="name" className="title-color">Contact <span className="text-info">( * Country code is must like for BD 880 )</span></label>
                        <input type="number" name="contact"  className="form-control" id="name" required />
                      </div>
                      <div className="form-group">
                        <label htmlFor="address" className="title-color">Address <span className="text-danger">*</span></label>
                        <textarea type="text" rows={4} name="address" value className="form-control" id="address" required defaultValue={"House-09, Road-02, Section-15, Block-D, Mirpur-13"} />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="name" className="title-color">Upload Image</label>
                        <div className="custom-file text-left">
                          <input type="file" name="image" id="customFileUpload" className="custom-file-input" accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*" />
                          <label className="custom-file-label" htmlFor="customFileUpload">Choose File</label>
                        </div>
                      </div>
                      <div className="text-center">
                        <img className="upload-img-view" id="viewer" onerror="this.src='https://6valley.6amtech.com/public/assets/front-end/img/image-place-holder.png'" src="https://6valley.6amtech.com/storage/app/public/shop/2022-04-21-6260f6e190f4c.png" alt="Product thumbnail" />
                      </div>
                    </div>
                    <div className="col-md-6 mb-4 mt-2">
                      <div className="form-group">
                        <div className="flex-start">
                          <label htmlFor="name" className="title-color">Upload Banner </label>
                          <div className="mx-1" htmlFor="ratio">
                            <span className="text-info">Ratio : ( 6:1 )</span>
                          </div>
                        </div>
                        <div className="custom-file text-left">
                          <input type="file" name="banner" id="BannerUpload" className="custom-file-input" accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*" />
                          <label className="custom-file-label" htmlFor="BannerUpload">Choose File</label>
                        </div>
                      </div>
                      <div className="text-center">
                        <center>
                          <img className="upload-img-view upload-img-view__banner" id="viewerBanner" onerror="this.src='https://6valley.6amtech.com/public/assets/back-end/img/400x400/img2.jpg'" src="https://6valley.6amtech.com/storage/app/public/shop/banner/2023-06-14-6488758b807b1.png" alt="banner image" />
                        </center>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end gap-2">
                    <Link className="btn btn-danger" to="/address">Cancel</Link>
                    <button type="submit" className="btn btn--primary" id="btn_update">Update</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    </div>



    </div>
  )
}

export default Addressedit





