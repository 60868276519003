import React, { useEffect, useState } from "react";
import Header from "../Header";

import "../sidebar.css";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import Sidebarr from "../Sidebar";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import Pagination from "react-js-pagination";
const Coupan = () => {
  const [cupon_title, setcupon_title] = useState();

  const [expire_date, setexpire_date] = useState();
  const [start_date, setstart_date] = useState();
  const [cupon_type, setcupon_type] = useState();
  const [numberOfcoupon, setnumberOfcoupon] = useState();
  const [appliesType, setappliesType] = useState();
  const [appliesCoupon, setappliesCoupon] = useState();

  const [sameUser_discountLimit, setsameUser_discountLimit] = useState();

  const [cupon_code, setcupon_code] = useState();
  const [coupon_list, setcoupon_list] = useState([]);
  const [Detils, setDetils] = useState();
  const [discount_value, setdiscount_value] = useState();
  const [discount_type, setdiscount_type] = useState();

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [count, setcount] = useState();

  const [activePage, setActivePage] = useState(1);
  const itemsPerPage = 10;

  const generatecode = () => {
    const length = 6;
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let result = "";

    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }

    return result;
  };
  const handleClick = () => {
    const code = generatecode();
    setcupon_code(code);
  };
  const handleCategoryClickk = (categoryId) => {
    if (selectedCategories.includes(categoryId)) {
      setSelectedCategories(
        selectedCategories.filter((id) => id !== categoryId)
      );
    } else {
      setSelectedCategories([...selectedCategories, categoryId]);
    }
  };

  const handleProductClickk = (categoryId) => {
    if (selectedProducts.includes(categoryId)) {
      setSelectedProducts(selectedProducts.filter((id) => id !== categoryId));
    } else {
      setSelectedProducts([...selectedProducts, categoryId]);
    }
  };

  let token = secureLocalStorage.getItem("vendortoken");
  let venderIdd = secureLocalStorage.getItem("vendorid");

  const addcoupans = (e) => {
    e.preventDefault();

    const formData = {
      venderId: venderIdd,
      cupon_code: cupon_code,
      //cupon_type: cupon_type,
      cupon_title: cupon_title,
      numberOfcoupon: numberOfcoupon,
      discountType: discount_type,
      discountAmount: discount_value,

      appliesType: appliesType,
      appliesCoupon: appliesCoupon,

      id:
        selectedCategories.length > 0
          ? selectedCategories.join(",")
          : selectedProducts.join(","),

      sameUser_discountLimit: sameUser_discountLimit,
      expire_date: expire_date ? expire_date : "",
      start_date: start_date ? start_date : "",
    };

    axios
      .post(`${process.env.REACT_APP_API_KEY}vender/api/promotinAdd`, formData)
      .then((res) => {
        getcoupon_list();
        toast.success(`Coupon created`);
      })
      .catch((error) => {});
    setcupon_code("");
    setcupon_type("");
    setcupon_title("");
    setnumberOfcoupon("");
    setdiscount_type("");
    setdiscount_value("");
    setappliesType("");
    setappliesCoupon("");
    setsameUser_discountLimit("");
    setexpire_date("");
    setstart_date("");
  };

  useEffect(() => {
    getcoupon_list();
  }, [0]);

  let getcoupon_list = () => {
    let data = {
      venderId: venderIdd,
    };

    let options = {
      headers: {
        token: token,
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/promotinList`,
        data,
        options
      )
      .then((res) => {
        setcount(res?.data?.data?.length);
        setcoupon_list(res.data.data);
      })
      .catch((error) => {});
  };

  let deletecoupan = (item) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this coupan!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let deletecoupannimage = () => {
          let bannerdata = {
            venderId: venderIdd,
            promotionId: item,
          };

          let options = {
            headers: {
              token: token,
            },
          };
          axios
            .post(
              `${process.env.REACT_APP_API_KEY}vender/api/promotinDelete`,
              bannerdata,
              options
            )
            .then((res) => {
              getcoupon_list();
            })
            .catch((error) => {});
        };
        deletecoupannimage();
        swal("Poof! Your coupan  has been deleted!", {
          icon: "success",
        });
      } else {
        swal("Your coupan is safe!");
      }
    });
  };

  const activecoupan = (item) => {
    const Data = {
      promotionId: item,
    };

    let options = {
      hraders: {
        token: token,
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/changePromotion_status`,
        Data,
        options
      )
      .then((res) => {
        toast.success(res.data.message);
        getcoupon_list();
      })
      .catch((error) => {});
  };

  useEffect(() => {
    coupanDetails();
  }, [0]);
  const coupanDetails = (item) => {
    const Data = {
      promotionId: item,
    };

    let options = {
      hraders: {
        token: token,
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/promotinDetails`,
        Data,
        options
      )
      .then((res) => {
        setDetils(res.data.data);
      })
      .catch((error) => {});
  };
  const [categoryList, setCategoryList] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState("");

  const [categoryMenuVisible, setCategoryMenuVisible] = useState(true);
  const handleDropdownToggle = () => {
    setCategoryMenuVisible(!categoryMenuVisible);
  };

  const filterCategories = (categories, filter) => {
    return categories?.filter((category) =>
      category?.category_englishName
        ?.toLowerCase()
        .includes(filter.toLowerCase())
    );
  };

  useEffect(() => {
    getCategories();
  }, [0]);
  const getCategories = () => {
    const data = {
      venderId: venderIdd,
    };

    let options = {
      headers: {
        token: token,
      },
    };

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/venderCategory_list`,
        data,
        options
      )
      .then((res) => {
        setCategoryList(res.data.data);
      })
      .catch((error) => {});
  };

  const [productlist, setproductlist] = useState();
  const [productFilter, setProductFilter] = useState("");
  const filterProducts = (products, filter) => {
    return products?.filter((product) =>
      product?.product_name?.toLowerCase().includes(filter.toLowerCase())
    );
  };
  useEffect(() => {
    getproductlist();
  }, [0]);
  let getproductlist = () => {
    const data = {
      venderId: venderIdd,
    };
    const options = {
      headers: {
        token: token,
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/productList`,
        data,
        options
      )
      .then((res) => {
        setproductlist(res?.data?.data);
      })
      .catch((error) => {});
  };

  const [Coupanfilter, setCoupanfilter] = useState([]);
  useEffect(() => {
    setCoupanfilter(coupon_list);
  }, [coupon_list]);

  const handleFilter = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    const result = coupon_list.filter((item) =>
      item?.cupon_title?.toLowerCase().includes(searchTerm)
    );
    setCoupanfilter(result);
    setActivePage(1);
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const renderCoupanData = (data, index) => {
    const adjustedIndex = (activePage - 1) * itemsPerPage + index + 1;
    return (
      <tr>
        <td>{adjustedIndex}</td>
        <td className="text-capitalize">
          {data?.cupon_title?.length > 10
            ? data?.cupon_title?.slice(0, 10) + `...`
            : data?.cupon_title}
        </td>
        <td className="text-capitalize">
          <div>
            {data?.discountAmount} {data?.discountType == "Money off" ? <>Money off</> : <>%</>} discount on Purc
          </div>
          <strong>Code: {data?.cupon_code}</strong>
        </td>
        {/* <td className="text-capitalize">{data?.cupon_type}</td> */}
        <td>
          <div className="d-flex flex-wrap gap-1">
            <span>{data?.start_date} to </span>
            <span>{data?.expire_date}</span>
          </div>
        </td>
        <td>
          <span>
            Limit: <strong>{data?.sameUser_discountLimit}</strong>
          </span>
        </td>
        {/* <td>seller</td> */}
        {data?.act_status != 0 ? (
          <td>
            <form className="banner_status_form">
              <input type="hidden" />
              <input type="hidden" name="id" />
              <label className="switcher">
                <input
                  type="checkbox"
                  className="switcher_input"
                  name="status"
                  onChange={() => activecoupan(data?._id)}
                />
                <span className="switcher_control" />
              </label>
            </form>
          </td>
        ) : (
          <td>
            <form className="banner_status_form">
              <input type="hidden" />
              <input type="hidden" name="id" />
              <label className="switcher">
                <input
                  id="coupon_status9"
                  name="status"
                  defaultValue={1}
                  defaultChecked
                  type="checkbox"
                  className="switcher_input"
                  onChange={() => activecoupan(data?._id)}
                />
                <span className="switcher_control" />
              </label>
            </form>
          </td>
        )}

        <td>
          <div className="d-flex gap-10 justify-content-center">
            <button
              onClick={() => {
                coupanDetails(data?._id);
              }}
              data-toggle="modal"
              data-target="#quick-view"
              className="btn btn-outline-info btn-sm square-btn"
            >
              <i className="fa fa-eye" aria-hidden="true" />
            </button>
            {/* <Link
                                    onClick={() => {
                                      secureLocalStorage.setItem(
                                        "promotion_id",
                                        data?._id
                                      );
                                    }}
                                    className="btn btn-outline--primary btn-sm edit"
                                    to="/promotionsupdate"
                                  >
                                    <i
                                      class="fa fa-pencil"
                                      aria-hidden="true"
                                    ></i>
                                  </Link> */}
            <a
              onClick={() => {
                deletecoupan(data?._id);
              }}
              className="btn btn-outline-danger btn-sm delete"
              href="javascript:"
              onclick="form_alert('coupon-9','Want to delete this coupon ?')"
              title="Delete"
            >
              <i className="fa fa-trash-o" aria-hidden="true" />
            </a>
            <form
              action="https://6valley.6amtech.com/seller/coupon/delete/9"
              method="post"
              id="coupon-9"
            >
              <input
                type="hidden"
                name="_token"
                defaultValue="YFPrIbFYlUsgcs6Ht4EEyCysWGBq8lmpLaBUW0q6"
              />{" "}
              <input type="hidden" name="_method" defaultValue="delete" />{" "}
            </form>
          </div>
        </td>
      </tr>
    );
  };
  return (
    <div className="container">
      <Toaster />
      {/* <Header /> */}
      <div
        className="container row"
        style={{ paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px" }}
      >
        <div className="col-lg-2 col-md-3" style={{ paddingLeft: "0px" }}>
          {/* <Sidebarr /> */}
        </div>

        <div
          className="col-lg-10 col-md-9"
          style={{ paddingLeft: "30px", marginTop: "60px" }}
        >
          {/* <br />
          <br /> */}

          <div className="mt-3">
            <div className="mb-3">
              <h2 className="h1 mb-0 text-capitalize d-flex align-items-center gap-2">
                <img
                  src="https://6valley.6amtech.com/public/assets/back-end/img/coupon_setup.png"
                  alt=""
                />
                Coupans setup
              </h2>
            </div>
            <div className="row">
              <div className="col-sm-12 col-lg-12 mb-3 mb-lg-2">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={addcoupans}>
                      <input
                        type="hidden"
                        name="_token"
                        defaultValue="YFPrIbFYlUsgcs6Ht4EEyCysWGBq8lmpLaBUW0q6"
                      />
                      <div className="row">
                        {/* <div className="col-md-6 col-lg-6 form-group">
                          <label
                            htmlFor="name"
                            className="title-color font-weight-medium d-flex"
                          >
                            Coupon type
                          </label>
                          <select
                            onChange={(e) => {
                              setcupon_type(e.target.value);
                            }}
                            className="form-control"
                            name="coupon_type"
                            required
                          >
                            <option disabled selected value="">
                              Select coupon type
                            </option>
                            <option value="Discount on Purchase">
                              Discount on Purchase
                            </option>
                          </select>
                        </div> */}
                        <div className="col-md-6 col-lg-4 ">
                          <div className="form-group">
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="title-color font-weight-medium d-flex"
                            >
                              Coupon title&nbsp;
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              required
                              value={cupon_title}
                              onChange={(e) => {
                                setcupon_title(e.target.value);
                              }}
                              className="form-control"
                              placeholder="Title"
                            />
                          </div>
                        </div>

                        <div className="col-md-6 col-lg-4 ">
                          {/* <label
                            htmlFor="exampleFormControlInput1"
                            className="title-color font-weight-medium d-flex"
                          >
                            Coupon Code&nbsp;
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            required
                            value={cupon_code}
                            onChange={(e) => {
                              setcupon_code(e.target.value);
                            }}
                            className="form-control"
                            placeholder="EX:DERT74"
                          /> */}

                          <div className="form-group">
                            <label class="title-color d-flex justify-content-between gap-2">
                              <span class="d-flex align-items-center gap-1">
                                Coupon Code{" "}
                                <span className="text-danger">*</span>
                              </span>
                              <span
                                onClick={handleClick}
                                class="style-one-pro cursor-pointer user-select-none text--primary"
                              >
                                Generate code
                              </span>
                            </label>
                            <input
                              required
                              maxLength={6}
                              minLength={6}
                              placeholder="Product SKU"
                              type="text"
                              value={cupon_code}
                              onChange={(e) => {
                                setcupon_code(e.target.value);
                              }}
                              className="js-select2-custom form-control"
                            ></input>
                          </div>
                        </div>

                        <div className="col-md-6 col-lg-4 ">
                          <div className="form-group">
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="title-color font-weight-medium d-flex"
                            >
                              Number of coupons&nbsp;
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="number"
                              required
                              value={numberOfcoupon}
                              onChange={(e) => {
                                setnumberOfcoupon(e.target.value);
                              }}
                              className="form-control"
                              placeholder="EX:100"
                            />
                          </div>
                        </div>

                        <div className="col-md-6 col-lg-4 ">
                          <div className="form-group">
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="title-color font-weight-medium d-flex"
                            >
                              Limit per user&nbsp;
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="number"
                              required
                              min={0}
                              value={sameUser_discountLimit}
                              onChange={(e) => {
                                setsameUser_discountLimit(e.target.value);
                              }}
                              className="form-control"
                              placeholder="Ex: 2"
                            />
                          </div>
                        </div>

                        <div className="col-md-6 col-lg-4 ">
                          <div className="form-group">
                            <label className="title-color">
                              Discount type&nbsp;
                              <span className="text-danger">*</span>
                            </label>
                            <select
                              required
                              className="js-select2-custom form-control"
                              value={discount_type}
                              onChange={(e) => {
                                setdiscount_type(e.target.value);
                              }}
                            >
                              <option selected value="" disabled>
                                Select
                              </option>
                              <option value="Percent off">Percent off</option>
                              <option value="Money off">Money off</option>
                            </select>
                          </div>
                        </div>

                        {discount_type == "Percent off" ? (
                          <div className="col-md-6 col-lg-4 ">
                            <div className="form-group">
                              <label className="title-color">Percent off</label>
                              <input
                                required
                                style={{ width: "100%" }}
                                type="number"
                                value={discount_value}
                                onChange={(e) => {
                                  setdiscount_value(e.target.value);
                                }}
                                className="js-select2-custom form-control"
                                placeholder="%"
                              />
                            </div>
                          </div>
                        ) : discount_type == "Money off" ? (
                          <div className="col-md-6 col-lg-4 ">
                            <div className="form-group">
                              <label className="title-color">Money off</label>
                              <input
                                required
                                style={{ width: "100%" }}
                                type="number"
                                value={discount_value}
                                onChange={(e) => {
                                  setdiscount_value(e.target.value);
                                }}
                                className="js-select2-custom form-control"
                                placeholder="0"
                              />
                            </div>
                          </div>
                        ) : null}
                        {/* <div className="col-md-6 col-lg-4 form-group">
                          <label className="title-color">Amount</label>
                          <input
                            required
                            style={{ width: "100%" }}
                            type="text"
                            value={discount_value}
                            onChange={(e) => {
                              setdiscount_value(e.target.value);
                            }}
                            className="js-select2-custom form-control"
                            placeholder="0"
                          />
                        </div> */}

                        <div className="col-md-6 col-lg-4 ">
                          <div className="form-group">
                            <label
                              htmlFor="name"
                              className="title-color font-weight-medium d-flex"
                            >
                              Start date&nbsp;
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="date"
                              name="start_date"
                              value={start_date}
                              onChange={(e) => {
                                setstart_date(e.target.value);
                              }}
                              className="form-control"
                              placeholder="Start date"
                              required
                              min="2023-12-23"
                            />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-4 ">
                          <div className="form-group">
                            <label
                              htmlFor="name"
                              className="title-color font-weight-medium d-flex"
                            >
                              Expire date&nbsp;
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              value={expire_date}
                              type="date"
                              name="expire_date"
                              onChange={(e) => {
                                setexpire_date(e.target.value);
                              }}
                              className="form-control"
                              placeholder="Expire date"
                              required
                              min="2023-12-23"
                            />
                          </div>
                        </div>

                        <div className="col-md-6 col-lg-4">
                          <div className="form-group">
                            <label
                              htmlFor="name"
                              className="title-color font-weight-medium d-flex"
                            >
                              Coupon applies to
                              <span className="text-danger">*</span>&nbsp;-
                              <span style={{ color: "rgb(53, 149, 246)" }}>
                                {" "}
                                &nbsp; {appliesCoupon}
                              </span>
                            </label>
                            <select
                              onChange={(e) => {
                                setappliesType(e.target.value);
                              }}
                              className="form-control"
                              name="coupon_type"
                              required
                            >
                              <option disabled selected value="">
                                Select
                              </option>
                              <option value="Categories">Categories</option>
                              <option value="Products">Products</option>
                            </select>

                            {appliesType === "Categories" ? (
                              <>
                                <div
                                  style={{
                                    background: "#fffefd",
                                    textAlign: "left",
                                  }}
                                  className="btn btn-secondary"
                                >
                                  <li className="btn btn--info">
                                    <input
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                        marginRight: "5px",
                                        verticalAlign: "middle",
                                      }}
                                      type="checkbox"
                                      onClick={() => {
                                        setappliesCoupon("All categories");
                                      }}
                                    />
                                    <span
                                      onClick={() => {
                                        setappliesCoupon("All categories");
                                      }}
                                    >
                                      All categories
                                    </span>
                                  </li>
                                  <li className="btn btn--info">
                                    <input
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                        marginRight: "5px",
                                        verticalAlign: "middle",
                                      }}
                                      type="checkbox"
                                      onClick={() => {
                                        setappliesCoupon(
                                          "Select specific categories"
                                        );
                                        handleDropdownToggle();
                                      }}
                                    />
                                    <span
                                      onClick={() => {
                                        handleDropdownToggle();
                                        setappliesCoupon(
                                          "Select specific categories"
                                        );
                                      }}
                                    >
                                      Select specific categories
                                    </span>
                                  </li>

                                  {!categoryMenuVisible ? (
                                    <div>
                                      <input
                                        placeholder="Search in categories"
                                        type="text"
                                        id="categoryFilter"
                                        className="form-control"
                                        value={categoryFilter}
                                        onChange={(e) =>
                                          setCategoryFilter(e.target.value)
                                        }
                                      />
                                      <div
                                        style={{
                                          overflow: "scroll",
                                          height: "170px",
                                          scrollbarWidth: "none",
                                          marginTop: "-8px",
                                          background: "#fffefd",
                                        }}
                                        className={`dropdown-menu ${
                                          !categoryMenuVisible ? "show" : ""
                                        }`}
                                      >
                                        {filterCategories(
                                          categoryList,
                                          categoryFilter
                                        ).length === 0 ? (
                                          <li>
                                            <p className="btn-secondary ml-3">
                                              No data
                                            </p>
                                          </li>
                                        ) : (
                                          filterCategories(
                                            categoryList,
                                            categoryFilter
                                          ).map((category) => (
                                            <li key={category.categoryId}>
                                              <p
                                                style={{ textAlign: "left" }}
                                                className={`btn btn-secondary  ${
                                                  selectedCategories.includes(
                                                    category.categoryId
                                                  )
                                                    ? "selected"
                                                    : ""
                                                }`}
                                              >
                                                <input
                                                  style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    marginRight: "5px",
                                                    verticalAlign: "middle",
                                                  }}
                                                  type="checkbox"
                                                  checked={selectedCategories.includes(
                                                    category.categoryId
                                                  )}
                                                  onChange={() =>
                                                    handleCategoryClickk(
                                                      category.categoryId
                                                    )
                                                  }
                                                />
                                                {category.category_englishName}
                                                <span className="caret"></span>
                                              </p>
                                            </li>
                                          ))
                                        )}
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </>
                            ) : appliesType === "Products" ? (
                              <>
                                <div
                                  style={{
                                    background: "#fffefd",
                                    textAlign: "left",
                                  }}
                                  className="btn btn-secondary"
                                >
                                  <li className="btn btn--info">
                                    <input
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                        marginRight: "5px",
                                        verticalAlign: "middle",
                                      }}
                                      type="checkbox"
                                      onClick={() => {
                                        setappliesCoupon("All products");
                                      }}
                                    />
                                    <span
                                      onClick={() => {
                                        setappliesCoupon("All products");
                                      }}
                                    >
                                      All products
                                    </span>
                                  </li>
                                  <li className="btn btn--info">
                                    <input
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                        marginRight: "5px",
                                        verticalAlign: "middle",
                                      }}
                                      type="checkbox"
                                      onClick={() => {
                                        setappliesCoupon(
                                          "Select specific products"
                                        );
                                        handleDropdownToggle();
                                      }}
                                    />
                                    <span
                                      onClick={() => {
                                        handleDropdownToggle();
                                        setappliesCoupon(
                                          "Select specific products"
                                        );
                                      }}
                                    >
                                      Select specific products
                                    </span>
                                  </li>

                                  {!categoryMenuVisible ? (
                                    <div>
                                      <input
                                        placeholder="Search in products"
                                        type="text"
                                        id="categoryFilter"
                                        className="form-control"
                                        value={productFilter}
                                        onChange={(e) =>
                                          setProductFilter(e.target.value)
                                        }
                                      />
                                      <div
                                        style={{
                                          overflow: "scroll",
                                          height: "220px",
                                          scrollbarWidth: "none",
                                          marginTop: "-8px",
                                          background: "#fffefd",
                                        }}
                                        className={`dropdown-menu ${
                                          !categoryMenuVisible ? "show" : ""
                                        }`}
                                      >
                                        {filterProducts(
                                          productlist,
                                          productFilter
                                        ).length === 0 ? (
                                          <li>
                                            <p className="btn-secondary ml-3">
                                              No data
                                            </p>
                                          </li>
                                        ) : (
                                          filterProducts(
                                            productlist,
                                            productFilter
                                          ).map((product) => (
                                            <div key={product?.productId}>
                                              <p
                                                style={{ textAlign: "left" }}
                                                className={`btn btn-secondary  ${
                                                  selectedProducts.includes(
                                                    product?.productId
                                                  )
                                                    ? "selected"
                                                    : ""
                                                }`}
                                              >
                                                <input
                                                  style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    marginRight: "5px",
                                                    verticalAlign: "middle",
                                                  }}
                                                  type="checkbox"
                                                  checked={selectedProducts.includes(
                                                    product?.productId
                                                  )}
                                                  onChange={() =>
                                                    handleProductClickk(
                                                      product?.productId
                                                    )
                                                  }
                                                />
                                                <font>
                                                  {product?.product_name}
                                                </font>
                                                <span className="caret"></span>
                                              </p>
                                            </div>
                                          ))
                                        )}
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-end flex-wrap gap-10">
                        <button type="submit" className="btn btn--primary px-4">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-20 mb-20">
              <div className="col-md-12">
                <div className="card">
                  <div className="px-3 py-4">
                    <div className="row justify-content-between align-items-center flex-grow-1">
                      <div className="col-sm-4 col-md-6 col-lg-8 mb-2 mb-sm-0">
                        <h5 className="mb-0 text-capitalize d-flex gap-2">
                          Coupon list
                          <span className="badge badge-soft-dark radius-50 fz-12 ml-1">
                            {count}
                          </span>
                        </h5>
                      </div>
                      <div className="col-sm-8 col-md-6 col-lg-4">
                        <form>
                          <div className="input-group input-group-merge input-group-custom">
                            <input
                              onChange={handleFilter}
                              id="datatableSearch_"
                              type="search"
                              name="search"
                              className="form-control"
                              placeholder="Search by Coupon"
                            />
                            <button type="submit" className="btn btn--primary">
                              Search
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    {Coupanfilter?.length > 0 ? (
                      <table
                        id="datatable"
                        className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table text-left"
                      >
                        <thead className="thead-light thead-50 text-capitalize">
                          <tr>
                            <th>SL</th>
                            <th>Title</th>
                            <th>Coupon</th>
                            {/* <th> Type</th> */}
                            <th>Duration</th>
                            <th>User limit</th>
                            {/* <th>Discount bearer</th> */}
                            <th>
                              Status{" "}
                              <i
                                class="fa fa-certificate"
                                aria-hidden="true"
                              ></i>
                            </th>
                            <th className="text-center">
                              Action{" "}
                              <i class="fa fa-bug" aria-hidden="true"></i>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {Coupanfilter.slice(
                            (activePage - 1) * itemsPerPage,
                            activePage * itemsPerPage
                          ).map((data, index) => renderCoupanData(data, index))}
                        </tbody>
                      </table>
                    ) : (
                      <div class="text-center p-4">
                        <img
                          class="mb-3 w-160"
                          src="https://6valley.6amtech.com/public/assets/back-end/img/empty-state-icon/default.png"
                          alt="Image Description"
                        />
                        <p class="mb-0 order-stats__subtitle">
                          No coupon found
                        </p>
                      </div>
                    )}
                    <div className="d-flex justify-content-center mt-4">
                      {Coupanfilter.length > itemsPerPage && (
                        <Pagination
                          activePage={activePage}
                          itemsCountPerPage={itemsPerPage}
                          totalItemsCount={Coupanfilter.length}
                          pageRangeDisplayed={5}
                          onChange={handlePageChange}
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      )}
                    </div>
                  </div>
                  <div className="table-responsive mt-4">
                    <div className="px-4 d-flex justify-content-lg-end"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="quick-view"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        style={{ display: "none" }}
      >
        <div
          className="modal-dialog modal-dialog-centered coupon-details"
          role="document"
        >
          <div className="modal-content" id="quick-view-modal">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
            {Detils?.map((promotion) => {
              return (
                <div class="coupon__details">
                  <div class="coupon__details-left">
                    <div class="text-center">
                      <h6 class="title" id="title">
                        get {promotion?.discountAmount}
                        {promotion?.discountType == "Money off" ? (
                          <>Money off</>
                        ) : (
                          <>%</>
                        )}{" "}
                      </h6>
                      <h6 class="subtitle">
                        Code :{" "}
                        <span id="coupon_code">{promotion?.cupon_code}</span>
                      </h6>
                      <div class="text-capitalize">
                        <span>{promotion?.cupon_type}</span>
                      </div>
                    </div>
                    <div class="coupon-info">
                      {/* <div class="coupon-info-item">
                        <span>Minimum purchase :</span>
                        <strong id="min_purchase">
                          {promotion?.minimum_purchase}
                        </strong>
                      </div> */}
                      <div class="coupon-info-item" id="max_discount_modal_div">
                        <span>Maximum discount : </span>
                        <strong id="max_discount">
                          {promotion?.discountAmount} {promotion?.discountType == "Money off" ? (
                          <>Money off</>
                        ) : (
                          <>%</>
                        )}{" "}
                        </strong>
                      </div>
                      <div class="coupon-info-item">
                        <span>Start date : </span>
                        <span id="start_date">{promotion?.start_date}</span>
                      </div>
                      <div class="coupon-info-item">
                        <span>Expire date : </span>
                        <span id="expire_date">{promotion?.expire_date}</span>
                      </div>

                      <div class="coupon-info-item">
                        <span>Limit For Same User : </span>
                        <span id="expire_date">
                          {promotion?.sameUser_discountLimit}
                        </span>
                      </div>
                      <div class="coupon-info-item">
                        <span>Discount bearer : </span>
                        <span id="expire_date">Vendor</span>
                      </div>
                    </div>
                  </div>
                  <div class="coupon__details-right">
                    <div class="coupon">
                      <div class="d-flex">
                        <h4 id="discount">
                          {promotion?.discountAmount}
                          {promotion?.discountType == "Money off" ? (
                            <>Money</>
                          ) : (
                            <>%</>
                          )}
                        </h4>
                      </div>

                      <span>Off</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Coupan;
