import React, { useEffect, useState } from "react";
import Header from "../Header";

import { Link, Navigate, useNavigate } from "react-router-dom";
import Sidebarr from "../Sidebar";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
const Editmyshop = () => {
  const [profiledetails, setprofiledetails] = useState();
  const [shop_name, setshop_name] = useState();
  const [shop_address, setshop_address] = useState();
  const [country, setcountry] = useState();
  const [city, setcity] = useState();

  const [longitude, setlongitude] = useState();
  const [latitude, setlatitude] = useState();
  const [shop_logo, setshop_logo] = useState();
  const [shop_licence, setshop_licence] = useState();
  const [vender_profile, setvender_profile] = useState();
  const [address, setAddress] = useState("");

  const [details, setDetails] = useState(null);
  const Navigate = useNavigate();
  let venderid = secureLocalStorage.getItem("vendorid");
 
  let token = secureLocalStorage.getItem("vendortoken");
  const [workHourss, setWorkHourss] = useState([]);

  const [workHours, setWorkHours] = useState(workHourss);
  const handleInputChange = (index, field, value) => {
    setWorkHourss((prevWorkHours) => {
      const newWorkHours = [...prevWorkHours];
      if (index < 0 || index >= newWorkHours.length) {
        return prevWorkHours;
      }

      newWorkHours[index] = {
        ...newWorkHours[index],
        [field]: value,
      };

      return newWorkHours;
    });
  };

  const handleSelect = async (value) => {
    try {
      const results = await geocodeByAddress(value);
      const latLng = await getLatLng(results[0]);
      setAddress(value);
      setDetails(latLng);
    } catch (error) {}
  };

  const updateshopdetails = (e) => {
    e.preventDefault();

    const formData = new FormData();
    const updatedWorkHours = workHourss?.map((day) => ({
      day_name: day.day_name,
      status: day.status,
      open_time: day.status ? day.open_time : "",
      close_time: day.status ? day.close_time : "",
    }));

    formData.append("workHours", JSON.stringify(updatedWorkHours));

    formData.append("venderId", venderid);

    formData.append(
      "shop_name",
      shop_name ? shop_name : profiledetails?.shop_name
    );

    formData.append(
      "shop_address",
      shop_address ? shop_address : profiledetails?.shop_address
    );

    formData.append("country", country ? country : profiledetails?.country);

    formData.append("city", city ? city : profiledetails?.city);

    // formData.append("workHours", JSON.stringify(workHours) ? JSON.stringify(workHours) : JSON.stringify(profiledetails?.workHours));
    // const updatedWorkHours = workHours.some((day) => day.status)
    //   ? workHours
    //   : profiledetails?.workHours;

    // formData.append("workHours", JSON.stringify(updatedWorkHours));
    formData.append("longitude", details?.lng);

    formData.append("latitude", details?.lat);
    formData.append("shop_logo", shop_logo);

    formData.append("shop_licence", shop_licence);
    formData.append("vender_profile", vender_profile);

    const options = {
      headers: {
        token: token,
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/updateShop_information`,
        formData,
        options
      )
      .then((res) => {
        setTimeout(() => {
          Navigate("/My Shop");
        }, 4000);
        toast.success("Updated !");
      })
      .catch((error) => {});
  };

  useEffect(() => {
    profiledetailss();
  }, [0]);

  const profiledetailss = () => {
    const sellerdata = {
      venderId: venderid,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/getVenderProfile`,
        sellerdata
      )
      .then((res) => {
        setWorkHourss(res?.data?.data?.workHours);
        setprofiledetails(res.data.data);
      })
      .catch((error) => {});
  };
  return (
    <div className="container">
      {/* <Header /> */}
      <Toaster />
      <div
        className="container row"
        style={{ paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px" }}
      >
        <div className="col-lg-2 col-md-3" style={{ paddingLeft: "0px" }}>
          {/* <Sidebarr /> */}
        </div>

        <div
          className="col-lg-10 col-md-9"
          style={{
            paddingLeft: "30px",
            marginTop: "60px",
            marginBottom: "60px",
          }}
        >
          <div className="mt-3">
            <div className="mb-3">
              <h2 className="h1 mb-0 text-capitalize d-flex align-items-center gap-2">
                <img
                  width={20}
                  src="https://6valley.6amtech.com/public/assets/back-end/img/shop-info.png"
                  alt
                />
                Edit My shop information
              </h2>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h5 className="mb-0 ">Edit My shop information</h5>
                    <Link
                      to="/My Shop"
                      className="btn btn--primary __inline-70 px-4 text-white"
                    >
                      Back
                    </Link>
                  </div>
                  <div className="card-body">
                    <form
                      onSubmit={updateshopdetails}
                      style={{ textAlign: "left" }}
                      encType="multipart/form-data"
                    >
                      <input
                        type="hidden"
                        name="_token"
                        defaultValue="ZzkfkLNbAUt4VTq5h8M6WE4WDu8lkTMJLYAhZQBe"
                      />{" "}
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="name" className="title-color">
                              Store name <span className="text-danger">*</span>
                            </label>
                            <input
                              onChange={(e) => {
                                setshop_name(e.target.value);
                              }}
                              type="text"
                              name="name"
                              placeholder={profiledetails?.shop_name}
                              className="form-control"
                              id="name"
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="name" className="title-color">
                              Store address{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              onChange={(e) => {
                                setshop_address(e.target.value);
                              }}
                              type="text"
                              name="contact"
                              placeholder={profiledetails?.shop_address}
                              className="form-control"
                              id="name"
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="name" className="title-color">
                              Country <span className="text-danger">*</span>
                            </label>

                            <select
                              className="js-select2-custom form-control"
                              name="sub_category_id"
                              value={country}
                              onChange={(e) => {
                                setcountry(e.target.value);
                              }}
                            >
                              <option value="" disabled selected>
                                Select country
                              </option>
                              <option>California</option>
                              <option>Western America</option>
                              <option>Western Dubai</option>

                              {/* {producttypedata?.data?.map((type, index) => (
                          <option key={index} value={type}>
                            {type}
                          </option>
                        ))} */}
                            </select>
                          </div>

                          <div className="form-group">
                            <label htmlFor="name" className="title-color">
                              City <span className="text-danger">*</span>
                            </label>

                            <select
                              className="js-select2-custom form-control"
                              name="sub_category_id"
                              value={city}
                              onChange={(e) => {
                                setcity(e.target.value);
                              }}
                            >
                              <option value="" disabled selected>
                                Select city
                              </option>
                              <option>Northya</option>
                              <option>Pondicherry</option>
                              <option>Chandigarh</option>

                              {/* {producttypedata?.data?.map((type, index) => (
                          <option key={index} value={type}>
                            {type}
                          </option>
                        ))} */}
                            </select>
                          </div>

                          <div className="form-group">
                            <label htmlFor="address" className="title-color">
                              Store location
                              <span className="text-danger">*</span>
                            </label>
                            <PlacesAutocomplete
                              value={address}
                              onChange={(newValue) => setAddress(newValue)}
                              onSelect={handleSelect}
                            >
                              {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                                loading,
                              }) => (
                                <div>
                                  <input
                                    style={{ width: "100%", marginTop: "0px" }}
                                    className="form-control"
                                    {...getInputProps({
                                      placeholder: "Select location",
                                    })}
                                  />
                                  <div>
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map((suggestion) => {
                                      const style = {
                                        backgroundColor: suggestion.active
                                          ? "#41b6e6"
                                          : "#fff",
                                      };
                                      return (
                                        <div
                                          {...getSuggestionItemProps(
                                            suggestion,
                                            {
                                              style,
                                            }
                                          )}
                                        >
                                          {suggestion.description}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="name" className="title-color">
                              Upload your store logo
                            </label>
                            <div className="custom-file text-left">
                              <input
                                onChange={(e) =>
                                  setshop_logo(e.target.files[0])
                                }
                                type="file"
                                name="image"
                                id="customFileUpload"
                                className="custom-file-input"
                                accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="customFileUpload"
                              >
                                Choose File
                              </label>
                            </div>
                          </div>

                          <div className="text-center">
                            {shop_logo ? (
                              <img
                                className="upload-img-view"
                                id="viewer"
                                src={URL.createObjectURL(shop_logo)}
                                alt="Product thumbnail"
                              />
                            ) : profiledetails?.shop_logo ? (
                              <img
                                className="upload-img-view"
                                id="viewer"
                                src={
                                  `${process.env.REACT_APP_API_KEY}uploads/` +
                                  profiledetails?.shop_logo
                                }
                                alt="Product thumbnail"
                              />
                            ) : (
                              <img
                                className="upload-img-view"
                                id="viewer"
                                src="https://6valley.6amtech.com/storage/app/public/shop/2022-04-21-6260f6e190f4c.png"
                                alt="Product thumbnail"
                              />
                            )}
                          </div>
                        </div>

                        <div className="row card-body">
                          <h5 className="ml-3">Working hours</h5>
                          {workHourss?.map((day, index) => (
                            <div
                              key={index}
                              className="col-lg-12 col-md-12 col-sm-12"
                              style={{ marginBottom: "-40px" }}
                            >
                              <div className="d-flex align-items-center mb-0"></div>

                              <div className="form-group d-flex align-items-center ">
                                <h5 style={{ border: "none", width: "100px" }}>
                                  {day?.day_name}
                                </h5>{" "}
                                {day && (
                                  <>
                                    <label className="switcher">
                                      <input
                                        type="checkbox"
                                        id={`status-${index}`}
                                        checked={day.status}
                                        onChange={(e) =>
                                          handleInputChange(
                                            index,
                                            "status",
                                            e.target.checked
                                          )
                                        }
                                        className="switcher_input"
                                      />

                                      <span className="switcher_control" />
                                    </label>
                                    <span
                                      style={{ fontWeight: "bold" }}
                                      className="mr-3 ml-3"
                                    >
                                      {day?.status ? "Open" : "Closed"}
                                    </span>
                                    <div className="form-group">
                                      <label
                                        style={{ visibility: "hidden" }}
                                        htmlFor={`open-${index}`}
                                        className="mr-2"
                                      >
                                        {day?.status == true ? (
                                          <>Open time: {day?.open_time}</>
                                        ) : null}
                                      </label>
                                      <input
                                        type="time"
                                        id={`open-${index}`}
                                        value={day?.open_time}
                                        onChange={(e) =>
                                          handleInputChange(
                                            index,
                                            "open_time",
                                            e.target.value
                                          )
                                        }
                                        className="form-control"
                                      />
                                    </div>
                                    <div className="form-group ml-3">
                                      <label
                                        style={{ visibility: "hidden" }}
                                        htmlFor={`close-${index}`}
                                        className="mr-2"
                                      >
                                        {day?.status == true ? (
                                          <>Close time: {day?.close_time}</>
                                        ) : null}
                                      </label>
                                      <input
                                        type="time"
                                        id={`close-${index}`}
                                        value={day?.close_time}
                                        onChange={(e) =>
                                          handleInputChange(
                                            index,
                                            "close_time",
                                            e.target.value
                                          )
                                        }
                                        className="form-control"
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="name" className="title-color">
                              Upload your front image
                            </label>
                            <div className="custom-file text-left">
                              <input
                                onChange={(e) =>
                                  setvender_profile(e.target.files[0])
                                }
                                type="file"
                                name="image"
                                id="customFileUpload"
                                className="custom-file-input"
                                accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="customFileUpload"
                              >
                                Choose File
                              </label>
                            </div>
                          </div>

                          <div className="text-center">
                            {vender_profile ? (
                              <img
                                className="upload-img-view upload-img-view__banner"
                                id="viewer"
                                src={URL.createObjectURL(vender_profile)}
                                alt="Product thumbnail"
                              />
                            ) : profiledetails?.vender_profile ? (
                              <img
                                className="upload-img-view upload-img-view__banner"
                                id="viewer"
                                src={
                                  `${process.env.REACT_APP_API_KEY}uploads/` +
                                  profiledetails?.vender_profile
                                }
                                alt="Product thumbnail"
                              />
                            ) : (
                              <img
                                className="upload-img-view"
                                id="viewer"
                                src="https://6valley.6amtech.com/storage/app/public/shop/2022-04-21-6260f6e190f4c.png"
                                alt="Product thumbnail"
                              />
                            )}
                          </div>
                        </div>

                        <div className="col-md-6 mb-4">
                          <div className="form-group">
                            <div className="flex-start">
                              <label htmlFor="name" className="title-color">
                                Upload your store license (for enterprise)
                              </label>
                              <div className="mx-1" htmlFor="ratio">
                                {/* <span className="text-info">
                                  Ratio : ( 6:1 )
                                </span> */}
                              </div>
                            </div>
                            <div className="custom-file text-left">
                              <input
                                onChange={(e) =>
                                  setshop_licence(e.target.files[0])
                                }
                                type="file"
                                name="banner"
                                id="BannerUpload"
                                className="custom-file-input"
                                accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="BannerUpload"
                              >
                                Choose File
                              </label>
                            </div>
                          </div>
                          <div className="text-center">
                            <center>
                              {shop_licence ? (
                                <img
                                  className="upload-img-view upload-img-view__banner"
                                  id="viewerBanner"
                                  src={URL.createObjectURL(shop_licence)}
                                  alt="banner image"
                                />
                              ) : profiledetails?.shop_licence ? (
                                <img
                                  className="upload-img-view upload-img-view__banner"
                                  id="viewerBanner"
                                  src={
                                    `${process.env.REACT_APP_API_KEY}uploads/` +
                                    profiledetails?.shop_licence
                                  }
                                  alt="banner image"
                                />
                              ) : (
                                <img
                                  className="upload-img-view upload-img-view__banner"
                                  id="viewerBanner"
                                  src="https://6valley.6amtech.com/storage/app/public/shop/banner/2023-06-14-6488758b807b1.png"
                                  alt="banner image"
                                />
                              )}
                            </center>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end gap-2">
                        <Link to="/myshop" className="btn btn-danger">
                          Cancel
                        </Link>
                        <button
                          type="submit"
                          className="btn btn--primary"
                          id="btn_update"
                        >
                          Update
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Editmyshop;
