import React from 'react'
import Sidebarr from '../Sidebar'
import Header from '../Header'

const Bulkimport = () => {
    return (
        <div>



            {/* <Header /> */}
            <div className='container row' style={{ paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px" }}>
                <div className="col-lg-2 col-md-3" style={{ paddingLeft: "0px" }}>
                    {/* <Sidebarr /> */}
                </div>

                <div className="col-lg-10 col-md-9" style={{ paddingLeft: "30px", marginTop: "60px" }}>
                    <div className="mt-3">
                        <div className="mb-4">
                            <h2 className="h1 mb-1 text-capitalize">
                                <img src="https://6valley.6amtech.com/public/assets/back-end/img/bulk-import.png" className="mb-1 mr-1" alt />
                                Bulk Import
                            </h2>
                        </div>
                        <div className="row" style={{ textAlign: 'left' }}>
                            <div className="col-12">
                                <div className="card card-body">
                                    <h1 className="display-4">Instructions : </h1>
                                    <p>1. Download the format file and fill it with proper data.</p>
                                    <p>2. You can download the example file to understand how the data must be filled.</p>
                                    <p>3. Once you have-downloaded and filled the format file upload it in the form below and submit.</p>
                                    <p>4. After uploading products you need to edit them and set products images and choices.</p>
                                    <p>5. You can get brand and category id from their list please input the right ids.</p>
                                    <p>6. You can upload your product images in product folder from gallery and copy images path.</p>
                                </div>
                            </div>
                            <div className="col-12 mt-2">
                                <form className="product-form" action="https://6valley.6amtech.com/seller/product/bulk-import" method="POST" encType="multipart/form-data">
                                    <input type="hidden" name="_token" defaultValue="ZRoI2FNEOVX7v22Z1Jakm18zKUkHwDJhY9a62Rih" /> <div className="card rest-part">
                                        <div className="px-3 py-4 d-flex flex-wrap align-items-center gap-10 justify-content-center">
                                            <h4 className="mb-0">Import Products File</h4>
                                            <a href="https://6valley.6amtech.com/public/assets/product_bulk_format.xlsx" download className="btn-link text-capitalize fz-16 font-weight-medium">Download Format</a>
                                        </div>
                                        <div className="card-body">
                                            <div className="form-group">
                                                <div className="row justify-content-center">
                                                    <div className="col-auto">
                                                        <div className="upload-file">
                                                            <input type="file" name="products_file" accept=".xlsx, .xls" className="upload-file__input" />
                                                            <div className="upload-file__img upload-file__img_drag">
                                                                <img src="https://6valley.6amtech.com/public/assets/back-end/img/drag-upload-file.png" alt />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-wrap gap-10 align-items-center justify-content-end">
                                                <button type="reset" id="reset" onclick="resetImg();" className="btn btn-secondary px-4">Reset</button>
                                                <button type="submit" className="btn btn--primary px-4">Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>





        </div>
    )
}

export default Bulkimport
