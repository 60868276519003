import React, { useEffect, useState } from "react";
import Header from "../Header";
import "../sidebar.css";
import { Link } from "react-router-dom";
import Sidebarr from "../Sidebar";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import toast, { Toaster } from "react-hot-toast";
import swal from "sweetalert";
import Pagination from "react-js-pagination";
import QRCode from "react-qr-code";
const Staffs = () => {
  const [productlist, setproductlist] = useState([]);
  const [count, setcount] = useState();
  const [ProductListfilter, setProductListfilter] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const itemsPerPage = 10;
  let token = secureLocalStorage.getItem("vendortoken");
  let shopid = secureLocalStorage.getItem("shopidofvendor");

  useEffect(() => {
    getproductlist();
  }, [0]);
  let getproductlist = () => {
    const data = {
      shopId: shopid,
    };
    const options = {
      headers: {
        token: token,
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/staffList`,
        data,
        options
      )
      .then((res) => {
       
        setcount(res?.data?.data?.length);
        setproductlist(res?.data?.data);
      })
      .catch((error) => {});
  };
  useEffect(() => {
    setProductListfilter(productlist);
  }, [productlist]);
  const handleFilter = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    const result = productlist.filter(
      (item) =>
        item?.fname?.toLowerCase().includes(searchTerm) ||
        item?.email?.toLowerCase().includes(searchTerm)
    );
    setProductListfilter(result);
    setActivePage(1);
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  let deletebanner = (item) => {
    swal({
      title: "Are you sure you want to delete this account?",
      //text: "Are you sure you want to delete this account?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let deletebannerimage = () => {
          let bannerdata = {
            staffId: item,
          };

          let options = {
            headers: {
              token: token,
            },
          };

          axios
            .post(
              `${process.env.REACT_APP_API_KEY}vender/api/delete_staff`,
              bannerdata,
              options
            )
            .then((res) => {
              getproductlist();
            })
            .catch((error) => {});
        };
        deletebannerimage();
        swal("Account deleted", {
          icon: "success",
        });
      } else {
        swal("Your account is safe!");
      }
    });
  };

  const activedeactive = (item) => {
    const Data = {
      staffId: item,
    };

    let options = {
      hraders: {
        token: token,
      },
    };

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/temporaryOnandOff_staff`,
        Data,
        options
      )
      .then((res) => {
        toast.success(res.data.message);
        getproductlist();
      })
      .catch((error) => {});
  };
  const renderProductData = (data, index) => {
    const adjustedIndex = (activePage - 1) * itemsPerPage + index + 1;
    return (
      <tr>
        <td>{adjustedIndex}</td>
        <td class="text-capitalize">
          <div class="media align-items-center gap-10">
            <img
              class="rounded-circle avatar avatar-lg"
              alt=""
              staff_image
              src={
                data?.staff_image
                  ? `${process.env.REACT_APP_API_KEY}uploads/${data?.staff_image}`
                  : "https://6valley.6amtech.com/storage/app/public/admin/2022-10-12-634695f03aebb.png"
              }
            />
            <div class="media-body">
              {data?.fname && data?.fname.length > 20
                ? data?.fname?.slice(0, 20) + "..."
                : data?.fname}
            </div>
          </div>
        </td>
        <td className="text-capitalize">
          {data?.email && data?.email.length > 20
            ? data?.email?.slice(0, 20) + "..."
            : data?.email}
        </td>
        <td>{data?.phone}</td>

        <td className="text-capitalize">{data?.position}</td>

        {data?.active_status == 1 ? (
          <td>
            <form className="banner_status_form">
              <input type="hidden" />
              <input type="hidden" name="id" />
              <label className="switcher mx-auto">
                <input
                  type="checkbox"
                  className="switcher_input"
                  name="status"
                  onChange={() => activedeactive(data?._id)}
                />
                <span className="switcher_control" />
              </label>
            </form>
          </td>
        ) : (
          <td>
            <form className="banner_status_form">
              <input type="hidden" />
              <input type="hidden" name="id" />
              <label className="switcher mx-auto">
                <input
                  id="coupon_status9"
                  name="status"
                  defaultValue={1}
                  defaultChecked
                  type="checkbox"
                  className="switcher_input"
                  onChange={() => activedeactive(data?._id)}
                />
                <span className="switcher_control" />
              </label>
            </form>
          </td>
        )}
        <td class="text-center">
          <div class="d-flex gap-10 justify-content-center">
            <Link
              to="/updatestaff"
              onClick={() => {
                secureLocalStorage.setItem("staffid", data._id);
                secureLocalStorage.setItem("fname",data?.fname);
                secureLocalStorage.setItem("phone",data?.phone);
                secureLocalStorage.setItem("lanme",data?.lname);
                secureLocalStorage.setItem("dob",data?.dob);
                secureLocalStorage.setItem("position",data?.position);
                secureLocalStorage.setItem("restrictions",data?.restrictions);
                secureLocalStorage.setItem("email",data?.email);
                secureLocalStorage.setItem("img",data?.staff_image);

                
              }}
              class="btn btn-outline--primary btn-sm square-btn"
              title="Edit"
            >
              <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
            </Link>
            <Link
              to="/staffdetails"
              onClick={() => {
                secureLocalStorage.setItem("staffid", data._id);
              }}
              class="btn btn-outline-info btn-sm square-btn"
              title="View"
            >
              <i class="fa fa-eye" aria-hidden="true"></i>
            </Link>
            <a
              onClick={() => {
                deletebanner(data?._id);
              }}
              className="btn btn-outline-danger btn-sm cursor-pointer delete"
              title="Delete"
              id={37}
            >
              <i className="fa fa-trash-o" aria-hidden="true" />
            </a>
          </div>
        </td>
      </tr>
    );
  };

  return (
    <div className="container">
      <Toaster />
      {/* <Header /> */}
      <div
        className="container row"
        style={{ paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px" }}
      >
        <div className="col-lg-2 col-md-3" style={{ paddingLeft: "0px" }}>
          {/* <Sidebarr /> */}
        </div>

        <div
          className="col-lg-10 col-md-9"
          style={{ paddingLeft: "30px", marginTop: "60px" }}
        >
          <div div className="mt-3 mb-4">
            <h2 className="h1 mb-0 text-capitalize d-flex align-items-center gap-2">
              <img
                width={20}
                src="https://6valley.6amtech.com/public/assets/back-end/img/employee.png"
                alt
              />
              My staff 
              <span className="badge badge-soft-dark radius-50 fz-14 ml-1">
                {count}
              </span>
            </h2>
          </div>

          <div className="row mt-20 mb-3">
            <div className="col-md-12">
              <div className="card">
                <div className="px-3 py-4 light-bg">
                  <div className="row g-2 align-items-center flex-grow-1">
                    <div className="col-md-4">
                      <h5 className="text-capitalize d-flex gap-1">
                        Staff
                        <span className="badge badge-soft-dark radius-50 fz-12">
                          {count}
                        </span>
                      </h5>
                    </div>
                    <div className="col-md-8 d-flex gap-3 flex-wrap flex-sm-nowrap justify-content-md-end">
                      <form>
                        <div className="input-group input-group-merge input-group-custom">
                          <input
                            id="datatableSearch_"
                            onChange={handleFilter}
                            type="search"
                            name="search"
                            className="form-control"
                            placeholder="Search by name email"
                            aria-label="Search orders"
                            required
                          />
                          <button type="submit" className="btn btn--primary">
                            Search
                          </button>
                        </div>
                      </form>
                      <div class="">
                        <Link
                          to="/addstaff"
                          class="btn btn--primary text-nowrap"
                        >
                          <i class="fa fa-plus-circle" aria-hidden="true"></i>
                          <span class="text "> Add new</span>
                        </Link>
                      </div>
                      <div className="dropdown">
                        <ul className="dropdown-menu dropdown-menu-right">
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://6valley.6amtech.com/seller/orders/export-order-data/all?date_type=this_year"
                            >
                              <img
                                width={14}
                                src="https://6valley.6amtech.com/public/assets/back-end/img/excel.png"
                                alt
                              />
                              Excel
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  {ProductListfilter?.length > 0 ? (
                    <table
                      style={{ textAlign: "left" }}
                      className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100"
                    >
                      <thead className="thead-light thead-50 text-capitalize">
                        <tr>
                          <th>SL</th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Phone</th>
                          <th>Position</th>
                          <th>Temporarily suspend this account</th>
                          <th class="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ProductListfilter.slice(
                          (activePage - 1) * itemsPerPage,
                          activePage * itemsPerPage
                        ).map((data, index) => renderProductData(data, index))}
                      </tbody>
                    </table>
                  ) : (
                    <div class="text-center p-4">
                      <img
                        class="mb-3 w-160"
                        src="https://6valley.6amtech.com/public/assets/back-end/img/empty-state-icon/default.png"
                        alt="Image Description"
                      />
                      <p class="mb-0 order-stats__subtitle">No staff found</p>
                    </div>
                  )}
                  <div className="d-flex justify-content-center mt-4">
                    {ProductListfilter.length > itemsPerPage && (
                      <Pagination
                        activePage={activePage}
                        itemsCountPerPage={itemsPerPage}
                        totalItemsCount={ProductListfilter.length}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                      />
                    )}
                  </div>
                </div>

                <div className="table-responsive mt-4">
                  <div className="px-4 d-flex justify-content-lg-end"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Staffs;
