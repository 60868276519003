import React, { useEffect, useState } from "react";
import Header from "../Header";

import { Link, useNavigate } from "react-router-dom";
import Sidebarr from "../Sidebar";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import swal from "sweetalert";

const Editbanner = () => {
  const [bannertype, setbannertype] = useState();
  const [resoucetype, setresoucetype] = useState();
  const [bannerimage, setbannerimage] = useState();
  const [banner, setbanner] = useState();

  const [banner_type, setbanner_type] = useState();
  const [resource_type, setresource_type] = useState();
  const [banner_url, setbanner_url] = useState();
  const [title, settitle] = useState();

  const Navigate = useNavigate();
  let token = secureLocalStorage.getItem("vendortoken");

  let bannerid = secureLocalStorage.getItem("banner_id");

  useEffect(() => {
    getbannerby();
  }, [0]);
  let getbannerby = () => {
    const bannerdata = {
      bannerId: bannerid,
    };
    let options = {
      headers: {
        token: token,
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/getBanner_byId`,
        bannerdata,
        options
      )
      .then((res) => {
        setbanner(res.data.data);
      })
      .catch((error) => {});
  };

  let editdata = () => {
    swal({
      title: "Banner Update Successfully",
      text: "Banner inserted sucessfully",
      icon: "success",
      buttons: true,
    });
  };
  const editbanner = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("image", bannerimage);
    formData.append("bannerId", bannerid);
    formData.append("title", title ? title : banner?.title);
    // formData.append("banner_type", banner_type);
    // formData.append("resource_type", resource_type);
    // formData.append("banner_url", banner_url);
    // let options = {
    //   hraders: {
    //     token: token,
    //   },
    // };
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/update_banner_api`,

        formData
      )
      .then((res) => {

        setTimeout(() => {
          Navigate("/bannerlist");
        }, 3000);
        editdata();
      })
      .catch((error) => {

      });
    setbannerimage("");
  };

  useEffect(() => {
    getbannertype();
  }, [0]);
  const getbannertype = () => {
    let options = {
      hraders: {
        token: token,
      },
    };
    axios
      .get(
        `${process.env.REACT_APP_API_KEY}vender/api/bannerType_list`,
        options
      )
      .then((res) => {

        setbannertype(res.data.data);
      })
      .catch((error) => {

      });
  };

  useEffect(() => {
    resourcetype();
  }, [0]);
  const resourcetype = () => {
    let options = {
      hraders: {
        token: token,
      },
    };
    axios
      .get(
        `${process.env.REACT_APP_API_KEY}vender/api/resourceType_list`,
        options
      )
      .then((res) => {
        setresoucetype(res.data.data);
      })
      .catch((error) => {
       
      });
  };
  return (
    <div className="container">
      {/* <Header /> */}
      <div
        className="container row"
        style={{ paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px" }}
      >
        <div className="col-lg-2 col-md-3" style={{ paddingLeft: "0px" }}>
          {/* <Sidebarr /> */}
        </div>

        <div
          className="col-lg-10 col-md-9"
          style={{ paddingLeft: "30px", marginTop: "60px" }}
        >
          <div className=" mt-3">
            <div className="d-flex justify-content-between mb-3">
              <div>
                <h2 className="h1 mb-1 text-capitalize d-flex align-items-center gap-2">
                  <img
                    width={20}
                    src="https://6valley.6amtech.com/public/assets/back-end/img/banner.png"
                    alt
                  />
                  Banner update form
                </h2>
              </div>
              <div>
                <Link className="btn btn--primary text-white" to="/bannerlist">
                  Back
                </Link>
              </div>
            </div>
            <div className="row" style={{ textAlign: "left" }}>
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <form
                      onSubmit={editbanner}
                      encType="multipart/form-data"
                      className="banner_form"
                    >
                      <input
                        type="hidden"
                        name="_token"
                        defaultValue="M5Ms1Z4GEx6hqRPEuULXw4EVAXN9PGreUwLBXESa"
                      />{" "}
                      <input type="hidden" name="_method" defaultValue="put" />{" "}
                      <div className="row g-3">
                        <div className="col-md-6">
                          <div className="form-group">
                            <input type="hidden" id="id" name="id" />
                          </div>
                          <div className="form-group">
                            <label
                              htmlFor="name"
                              className="title-color text-capitalize"
                            >
                              Banner type
                            </label>
                            <select
                              className="js-example-responsive form-control w-100"
                              name="banner_type"
                              value={banner_type}
                              onChange={(e) => {
                                setbanner_type(e.target.value);
                              }}
                            >
                              <option value="" selected disabled>
                                {banner?.banner_type}
                              </option>

                              {bannertype?.map((type) => (
                                <option
                                  key={type?._id}
                                  value={type?.bannerType}
                                >
                                  {type?.bannerType}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="form-group mb-3">
                            <label
                              htmlFor="name"
                              className="title-color text-capitalize"
                            >
                              Banner URL
                            </label>
                            <input
                              className="form-control"
                              placeholder={banner?.banner_url}
                              value={banner_url}
                              onChange={(e) => {
                                setbanner_url(e.target.value);
                              }}
                            />
                          </div>

                          <div className="form-group mb-3">
                            <label
                              htmlFor="name"
                              className="title-color text-capitalize"
                            >
                              Banner Title
                            </label>
                            <input
                              
                              type="text"
                              className="form-control"
                              placeholder={banner?.title}
                              value={title}
                              onChange={(e) => {
                                settitle(e.target.value);
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <label
                              htmlFor="resource_id"
                              className="title-color text-capitalize"
                            >
                              Resource type
                            </label>
                            <select
                              className="js-example-responsive form-control w-100"
                              name="resource_type"
                              value={resource_type}
                              onChange={(e) => {
                                setresource_type(e.target.value);
                              }}
                            >
                              <option value="" selected disabled>
                                {banner?.resource_type}
                              </option>
                              {resoucetype?.map((restype) => (
                                <option
                                  key={restype?._id}
                                  value={restype?.resourceType}
                                >
                                  {restype?.resourceType}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="col-lg-6 mt-4 mt-lg-0 from_part_2">
                          <div className="form-group mb-5">
                            <center>
                              {bannerimage ? (
                                <img
                                  className="upload-img-view"
                                  id="viewer"
                                  src={URL.createObjectURL(bannerimage)}
                                  
                                  alt="image"
                                />
                              ) : (
                                <img
                                  className="upload-img-view"
                                  id="viewer"
                                  src={
                                    `${process.env.REACT_APP_API_KEY}uploads/` +
                                    banner?.image
                                  }
                                  alt="image"
                                />
                              )}
                            </center>
                          </div>

                          <div className="from_part_2 ">
                            <label className="title-color">Banner image</label>
                            <span className="text-info">
                              <span className="text-danger">*</span>
                            </span>
                            <div className="custom-file text-left">
                              <input
                                onChange={(e) => {
                                  setbannerimage(e.target.files[0]);
                                }}
                                type="file"
                                name="image"
                                className="custom-file-input"
                                accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                                required
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="customFileEg1"
                              >
                                Choose File
                              </label>
                            </div>
                          </div>
                        </div>

                        {/* <div className="col-md-6 d-flex flex-column justify-content-center">
                          <div>
                            <center className="mx-auto">





                              <div className="uploadDnD">
                                <div
                                  className="form-group inputDnD input_image input_image_edit"
                                  style={{
                                    backgroundImage:
                                      'url("https://6valley.6amtech.com/storage/app/public/banner/2023-06-13-64885dd743c93.png")',
                                  }}
                                  data-title
                                >
                                  <input
                                    onChange={(e) => {
                                      setbannerimage(e.target.files[0]);
                                    }}
                                    type="file"
                                    name="image"
                                    required
                                    className="form-control-file text--primary font-weight-bold"
                                    accept=".jpg, .png, .jpeg, .gif, .bmp, .webp |image/*"
                                  />
                                </div>
                              </div>
                            </center>
                            <label
                              htmlFor="name"
                              className="title-color text-capitalize"
                            >
                              <span
                                className="input-label-secondary cursor-pointer"
                                data-toggle="tooltip"
                                data-placement="right"
                                title
                                data-original-title="Banner image ratio is not same for all sections in website Please review the ratio before upload"
                              >
                                <img
                                  width={16}
                                  src="https://6valley.6amtech.com/public/assets/back-end/img/info-circle.svg"
                                  alt
                                  className="m-1"
                                />
                              </span>
                              Banner image
                            </label>
                            <span className="text-info" id="theme_ratio">
                              
                            </span>
                            <p>
                              Banner Image is not same for all sections. Please
                              review the image before upload
                            </p>
                          </div>
                        </div> */}
                        <div className="col-md-12 d-flex justify-content-end gap-3">
                          <button
                            type="reset"
                            className="btn btn-secondary px-4"
                          >
                            Reset
                          </button>
                          <button
                            type="submit"
                            className="btn btn--primary px-4"
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Editbanner;
