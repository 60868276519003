import React, { useEffect, useRef, useState } from "react";
import Header from "../Header";

import { Link, Navigate, useNavigate } from "react-router-dom";
import Sidebarr from "../Sidebar";
import secureLocalStorage from "react-secure-storage";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import swal from "sweetalert";

const Profile = () => {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [password, setpassword] = useState();
  const [confirmpassword, setconfirmpassword] = useState();
  const [details, setdetails] = useState();

  const [vender_profile, setvender_profile] = useState();
  const [mobile_money_number, setmobile_money_number] = useState();
  const [bank_name, setbank_name] = useState();
  const [acc_number, setacc_number] = useState();
  const [bankAccount_name, setbankAccount_name] = useState();
  const [swift_code, setswift_code] = useState();

  const [currentpassword, setcurrentpassword] = useState("");
  const [shopdetails, setshopdetails] = useState();
  let logout = () => {
    secureLocalStorage.removeItem("vendorid");
    secureLocalStorage.removeItem("vendortoken");
    secureLocalStorage.removeItem("vendormobile");
    secureLocalStorage.removeItem("vendoremail");
    secureLocalStorage.removeItem("subcategoryid");
    secureLocalStorage.removeItem("categoryid");
    secureLocalStorage.removeItem("shopname");
    secureLocalStorage.removeItem("banner_id");
    secureLocalStorage.removeItem("serviceTypeeee");
    secureLocalStorage.removeItem("firstnamee");
    secureLocalStorage.removeItem("productid");
    secureLocalStorage.removeItem("shopidofvendor");
    secureLocalStorage.removeItem("paymentwallet");

    navigate("/");
  };

  let mobile = secureLocalStorage.getItem("vendormobile");
  let token = secureLocalStorage.getItem("vendortoken");
  let venderid = secureLocalStorage.getItem("vendorid");

  useEffect(() => {
    shoppdetails();
  }, [0]);

  const shoppdetails = () => {
    const sellerdata = {
      venderId: venderid,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/shopDetails`,
        sellerdata
      )
      .then((res) => {
        setshopdetails(res.data.data);
      })
      .catch((error) => {});
  };

  const changepassword = (e) => {
    e.preventDefault();

    const userdata = {
      venderId: venderid,
      password: currentpassword,
      newpassword: password,
      confirm_password: confirmpassword,
    };

    const options = {
      headers: {
        token: token,
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/changeVendor_password`,
        userdata,
        options
      )
      .then((response) => {
        setTimeout(() => {
          navigate("/home");
        }, 3000);

        toast.success(`Your password has been changed successfully`);
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data.message);
        } else {
        }
      });
    setcurrentpassword("");
    setpassword("");
    setconfirmpassword("");
  };

  useEffect(() => {
    sellerdetails();
  }, [0]);

  const sellerdetails = () => {
    const sellerdata = {
      venderId: venderid,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/getVenderProfile`,
        sellerdata
      )
      .then((res) => {
        setdetails(res.data.data);
      })
      .catch((error) => {});
  };

  const handleFileChange = () => {
    const file = fileInputRef.current.files[0];

    if (file) {
      const formData = new FormData();
      formData.append("venderId", venderid);
      formData.append("vender_profile", file);

      const options = {
        headers: {
          token: token,
        },
      };

      axios
        .post(
          `${process.env.REACT_APP_API_KEY}vender/api/Update_venderProfile`,
          formData,
          options
        )
        .then((res) => {
          sellerdetails();
          toast.success(res.data.message);
        })
        .catch((error) => {});
    }
  };

  const updatebankdetails = (e) => {
    e.preventDefault();

    const formData = {
      venderId: venderid,
      mobile_money_number: mobile_money_number,
      bank_name: bank_name,
      acc_number: acc_number,
      bankAccount_name: bankAccount_name,
      swift_code: swift_code,
    };
    const options = {
      headers: {
        token: token,
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/update_BankInformation`,
        formData,
        options
      )
      .then((res) => {
        sellerdetails();
        toast.success(res.data.message);
      })
      .catch((error) => {});
  };

  let deletebanner = (item) => {
    swal({
      title: "Are you sure you want to delete your account?",

      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let deletebannerimage = () => {
          navigate("/deletaccount");
        };
        deletebannerimage();
      } else {
      }
    });
  };

  return (
    <div className="container">
      <Toaster />

      {/* <Header /> */}
      <div
        className="container row"
        style={{ paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px" }}
      >
        <div className="col-lg-2 col-md-3" style={{ paddingLeft: "0px" }}>
          {/* <Sidebarr /> */}
        </div>

        <div
          className="col-lg-10 col-md-9"
          style={{ paddingLeft: "30px", marginTop: "60px" }}
        >
          <div className=" mt-3">
            <div className="page-header">
              <div className="row align-items-end">
                <h2 className="col-sm mb-2 mb-sm-0 h1 mb-0 text-capitalize d-flex align-items-center gap-2">
                  <img
                    width={20}
                    src="https://6valley.6amtech.com/public/assets/back-end/img/profile_setting.png"
                    alt
                  />
                  Settings
                </h2>
                <div className="col-sm-auto">
                  <Link onClick={logout} className="btn btn--primary" to="/">
                    Log Out
                  </Link>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <>
                  <input
                    type="hidden"
                    name="_token"
                    defaultValue="M5Ms1Z4GEx6hqRPEuULXw4EVAXN9PGreUwLBXESa"
                  />

                  <form>
                    <div className="card mb-3 mb-lg-5" id="generalDiv">
                      <div className="profile-cover">
                        <div
                          className="profile-cover-img-wrapper"
                          style={{
                            backgroundImage: `url(${process.env.REACT_APP_API_KEY}uploads/${details?.vender_profile})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                          }}
                        />
                      </div>

                      <label
                        className="avatar avatar-xxl avatar-circle avatar-border-lg avatar-uploader profile-cover-avatar"
                        htmlFor="customFileUpload"
                      >
                        {details?.vender_profile ? (
                          <img
                            className="avatar-img"
                            src={
                              `${process.env.REACT_APP_API_KEY}uploads/` +
                              details?.vender_profile
                            }
                            alt="Image"
                          />
                        ) : (
                          <img className="avatar-img" src="img/earning.png" />
                        )}
                      </label>

                      <input
                        required
                        accept=".jpg, .png, .jpeg, .gif, .bmp, .webp |image/*"
                        type="file"
                        id="customFileUpload"
                        style={{ display: "none" }}
                        ref={fileInputRef}
                        onChange={handleFileChange}
                      />
                    </div>
                  </form>

                  {/* <div className="card mb-3 mb-lg-5">
                    <div className="card-header">
                      <h2 className="card-title h4">Basic Information</h2>
                    </div>
                    <div className="card-body">
                      <div className="row form-group">
                        <label
                          htmlFor="firstNameLabel"
                          className="col-sm-3 col-form-label input-label"
                        >
                          Full name
                          <i
                            class="fa fa-question-circle text-body ml-1"
                            title="Display name"
                          ></i>
                        </label>
                        <div className="col-sm-9">
                          <div className="input-group input-group-sm-down-break">
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              id="firstNameLabel"
                              placeholder="Your first name"
                              aria-label="Your first name"
                              defaultValue="Admin"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row form-group">
                        <label
                          htmlFor="phoneLabel"
                          className="col-sm-3 col-form-label input-label"
                        >
                          Phone{" "}
                          <span className="input-label-secondary">
                            (Optional)
                          </span>
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            className="js-masked-input form-control"
                            name="phone"
                            id="phoneLabel"
                            placeholder="+x(xxx)xxx-xx-xx"
                            aria-label="+(xxx)xx-xxx-xxxxx"
                            defaultValue="+00xxxxxxxxxxxx"
                            data-hs-mask-options='{
                                         "template": "+(880)00-000-00000"
                                       }'
                          />
                        </div>
                      </div>
                      <div className="row form-group">
                        <label
                          htmlFor="newEmailLabel"
                          className="col-sm-3 col-form-label input-label"
                        >
                          Email
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            id="newEmailLabel"
                            defaultValue="admin@admin.com"
                            placeholder="Enter new email address"
                            aria-label="Enter new email address"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <label
                          htmlFor="newEmailLabel"
                          className="col-sm-3 input-label"
                        >
                          Profile Image
                        </label>
                        <div className="form-group col-md-9" id="select-img">
                          <span className="d-block mb-2 text-info">
                            ( Ratio 1:1 )
                          </span>
                          <div className="custom-file">
                            <input
                              type="file"
                              name="image"
                              id="customFileUpload"
                              className="custom-file-input"
                              accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="customFileUpload"
                            >
                              Image Upload
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end">
                        <button
                          type="button"
                          onclick="call_demo()"
                          className="btn btn--primary"
                        >
                          Save changes
                        </button>
                      </div>
                    </div>
                  </div> */}

                  <div className="card mt-3 mb-5">
                    <div className="card-body">
                      <div className="d-flex flex-wrap gap-3 justify-content-between">
                        <div className="media flex-column flex-sm-row gap-3">
                          {details?.shop_logo ? (
                            <img
                              className="avatar avatar-170 rounded-0"
                              src={
                                `${process.env.REACT_APP_API_KEY}uploads/` +
                                details?.shop_logo
                              }
                              alt="image"
                            />
                          ) : (
                            <img
                              className="avatar avatar-170 rounded-0"
                              src="img/earning.png"
                              alt="image"
                            />
                          )}
                          <div className="media-body">
                            <div className="d-block">
                              <h2 className="mb-2 pb-1 text-capitalize">
                                {details?.shop_name}
                              </h2>
                              <div className="d-flex gap-3 flex-wrap mb-3 lh-1">
                                <div className="review-hover position-relative cursor-pointer d-flex gap-2 align-items-center">
                                  <i class="fa fa-star" aria-hidden="true"></i>
                                  <span>{shopdetails?.shop_rating}</span>
                                  {/* <div className="review-details-popup">
                                    <h6 className="mb-2">Rating</h6>
                                    <div className>
                                      <ul className="list-unstyled list-unstyled-py-2 mb-0">
                                        <li className="d-flex align-items-center font-size-sm">
                                          <span className="mr-3">5 Star</span>
                                          <div className="progress flex-grow-1">
                                            <div
                                              className="progress-bar"
                                              role="progressbar"
                                              style={{ width: "0%" }}
                                              aria-valuenow={0}
                                              aria-valuemin={0}
                                              aria-valuemax={100}
                                            />
                                          </div>
                                          <span className="ml-3">0</span>
                                        </li>
                                        <li className="d-flex align-items-center font-size-sm">
                                          <span className="mr-3">4 Star</span>
                                          <div className="progress flex-grow-1">
                                            <div
                                              className="progress-bar"
                                              role="progressbar"
                                              style={{ width: "0%" }}
                                              aria-valuenow={0}
                                              aria-valuemin={0}
                                              aria-valuemax={100}
                                            />
                                          </div>
                                          <span className="ml-3">0</span>
                                        </li>
                                        <li className="d-flex align-items-center font-size-sm">
                                          <span className="mr-3">3 Star</span>
                                          <div className="progress flex-grow-1">
                                            <div
                                              className="progress-bar"
                                              role="progressbar"
                                              style={{ width: "0%" }}
                                              aria-valuenow={0}
                                              aria-valuemin={0}
                                              aria-valuemax={100}
                                            />
                                          </div>
                                          <span className="ml-3">0</span>
                                        </li>
                                        <li className="d-flex align-items-center font-size-sm">
                                          <span className="mr-3">2 Star</span>
                                          <div className="progress flex-grow-1">
                                            <div
                                              className="progress-bar"
                                              role="progressbar"
                                              style={{ width: "0%" }}
                                              aria-valuenow={0}
                                              aria-valuemin={0}
                                              aria-valuemax={100}
                                            />
                                          </div>
                                          <span className="ml-3">0</span>
                                        </li>
                                        <li className="d-flex align-items-center font-size-sm">
                                          <span className="mr-3">1 Star</span>
                                          <div className="progress flex-grow-1">
                                            <div
                                              className="progress-bar"
                                              role="progressbar"
                                              style={{ width: "0%" }}
                                              aria-valuenow={0}
                                              aria-valuemin={0}
                                              aria-valuemax={100}
                                            />
                                          </div>
                                          <span className="ml-3">0</span>
                                        </li>
                                      </ul>
                                    </div>
                                  </div> */}
                                </div>
                                <span className="border-left" />
                                <a href="#" className="text-dark">
                                  {shopdetails?.pageViews} Ratings
                                </a>
                                <span className="border-left" />
                                <a href="#" className="text-dark">
                                  {shopdetails?.totalReviews} Reviews
                                </a>
                              </div>
                              {/* <a
                                href="#"
                                className="btn btn-outline--primary px-4"
                              >
                                {" "}
                                View live
                              </a> */}
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-sm-end flex-wrap gap-2 mb-3">
                          <form className="d-inline-block">
                            <input
                              type="hidden"
                              name="_token"
                              defaultValue="syfKyDoYJ3mCIdT6gR7YRHkRYnQRfWf2KcUadmlv"
                            />{" "}
                            <input type="hidden" name="id" defaultValue={1} />
                            <input
                              type="hidden"
                              name="status"
                              defaultValue="suspended"
                            />
                            {/* <button type="submit" className="btn btn-danger px-5">
                      Suspend this Vendor
                    </button>  */}
                            <Link
                              onClick={() => {
                                deletebanner();
                              }}
                              className="btn btn-danger cursor-pointer"
                            >
                              <i className="fa fa-trash-o" aria-hidden="true" />{" "}
                              Delete my account
                            </Link>
                          </form>
                        </div>
                      </div>

                      <hr />
                      <div className="d-flex gap-3 flex-wrap flex-lg-nowrap">
                        <div className="border p-3 w-170">
                          <div className="d-flex flex-column mb-1">
                            <h6 className="font-weight-normal">
                              Total Products :
                            </h6>
                            <h3 className="text-primary fs-18">
                              {shopdetails?.totalProduct}
                            </h3>
                          </div>
                          <div className="d-flex flex-column">
                            <h6 className="font-weight-normal">
                              Total Orders :
                            </h6>
                            <h3 className="text-primary fs-18">
                              {shopdetails?.totalSales}
                            </h3>
                          </div>
                        </div>
                        <div className="row gy-3 flex-grow-1 w-100">
                          <div className="col-sm-6 col-xxl-3">
                            <h4 className="mb-3 text-capitalize">
                              Store information
                            </h4>
                            <div className="pair-list">
                              <div>
                                <span className="key text-nowrap">
                                  Store Name
                                </span>
                                <span>:</span>
                                <span className="value text-capitalize">
                                  {details?.shop_name}
                                </span>
                              </div>
                              {/* <div>
                                <span className="key">Phone</span>
                                <span>:</span>
                                <span className="value">
                                  {details?.mobile_number}
                                </span>
                              </div> */}
                              <strong className="key">Open Time</strong>
                              <br />

                              {details?.workHours?.map((hours) => {
                                return (
                                  <div>
                                    {/* <span>:</span> */}
                                    <span className="value">
                                      {hours?.day_name}{" "}
                                      {hours?.status == true ? (
                                        <>
                                          {hours?.open_time} {hours?.close_time}
                                        </>
                                      ) : (
                                        <> - Closed</>
                                      )}
                                    </span>
                                  </div>
                                );
                              })}

                              {/* <div>
                                <span className="key">Delivery Charge</span>
                                <span>:</span>
                                <span className="value">
                                  {details?.delivery_charge}
                                </span>
                              </div> */}

                              <div>
                                <span className="key">Address</span>
                                <span>:</span>
                                <span className="value text-capitalize">
                                  {details?.shop_address}
                                </span>
                              </div>
                              {/* <div>
                                <span className="key">Residencey City</span>
                                <span>:</span>
                                <span className="value">
                                {details?.residenceyCity}  
                                </span>
                              </div> */}

                              {/* <div>
                                <span className="key">Date of birth</span>
                                <span>:</span>
                                <span className="value">
                                 {details?.dob}  
                                </span>
                              </div> */}

                              {/* <div>
                                <span className="key">Mobile Money Number</span>
                                <span>:</span>
                                <span className="value">
                                  {details?.mobile_money_number}
                                </span>
                              </div> */}

                              <div>
                                <span className="key">Type Of Business</span>
                                <span>:</span>
                                <span className="value text-capitalize">
                                  {details?.typeOfbusiness}
                                </span>
                              </div>
                              <div>
                                <span className="key">Status</span>
                                <span>:</span>
                                <span className="value">
                                  {details?.active_status == 0 ? (
                                    <span className="badge badge-info">
                                      Active
                                    </span>
                                  ) : (
                                    <span className="badge badge-danger">
                                      Deactive
                                    </span>
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-xxl-3">
                            <h4 className="mb-3 text-capitalize">
                              Vendor Information &nbsp;
                              <a
                                onClick={() => {
                                  navigate("/editmydetails");
                                }}
                                class="btn btn-sm btn--primary edit-btn"
                              >
                                Edit <i class="fa fa-pencil"></i>
                              </a>
                            </h4>
                            <div className="pair-list">
                              <div>
                                <span className="key">Name</span>
                                <span>:</span>
                                <span className="value text-capitalize">
                                  {details?.firstName} {details?.lastName}
                                </span>
                              </div>
                              <div>
                                <span className="key">Date of Birth</span>
                                <span>:</span>
                                <span className="value">{details?.dob}</span>
                              </div>
                              {/* <div>
                                <span className="key">Residencey City</span>
                                <span>:</span>
                                <span className="value text-capitalize">
                                  {details?.residenceyCity}
                                </span>
                              </div> */}
                              <div>
                                <span className="key">Address</span>
                                <span>:</span>
                                <span className="value text-capitalize">
                                  City {details?.city}, Country{" "}
                                  {details?.country} {details?.shop_address}
                                </span>
                              </div>
                              <div>
                                <span className="key">Email</span>
                                <span>:</span>
                                <span className="value text-capitalize">
                                  {details?.email}
                                </span>
                              </div>

                              <div>
                                <span className="key">Phone</span>
                                <span>:</span>
                                <span className="value">
                                  {details?.mobile_number}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-xxl-4">
                            <div className="bg-light p-3 border border-primary-light rounded">
                              <h4 className="mb-3 text-capitalize">
                                Bank information &nbsp;
                                <a
                                  onClick={() => {
                                    navigate("/editmybankinfo");
                                  }}
                                  class="btn btn-sm btn--primary edit-btn"
                                >
                                  Edit <i class="fa fa-pencil"></i>
                                </a>
                              </h4>
                              <div className="d-flex gap-5">
                                <div className="pair-list">
                                  <div>
                                    <span className="key text-nowrap">
                                      Branch
                                    </span>
                                    <span className="px-2">:</span>
                                    <span className="value text-capitalize">
                                      {details?.bank_name !== "Undefined"
                                        ? details?.bank_name
                                        : "Na"}
                                    </span>
                                  </div>
                                  <div>
                                    <span className="key text-nowrap">
                                      Swift Code
                                    </span>
                                    <span className="px-2">:</span>
                                    <span className="value ">
                                      {details?.swift_code !== "Undefined"
                                        ? details?.swift_code
                                        : "Na"}
                                    </span>
                                  </div>
                                </div>
                                <div className="pair-list ">
                                  <div>
                                    <span className="key text-nowrap">
                                      Account Name
                                    </span>
                                    <span className="px-2">:</span>
                                    <span className="value text-capitalize">
                                      {details?.bankAccount_name !== "Undefined"
                                        ? details?.bankAccount_name
                                        : "Na"}
                                    </span>
                                  </div>
                                  <div>
                                    <span className="key text-nowrap">
                                      A/C No
                                    </span>
                                    <span className="px-2">:</span>
                                    <span className="value">
                                      {details?.acc_number !== "Undefined"
                                        ? details?.acc_number
                                        : "Na"}
                                    </span>
                                  </div>
                                  <div>
                                    <span className="key text-nowrap">
                                      Mobile Number
                                    </span>
                                    <span className="px-2">:</span>
                                    <span className="value">
                                      {details?.mobile_money_number}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row g-2" id="order_stats">
                    <div className="col-lg-12">
                      <div className="row g-2">
                        {details?.vender_profile ? (
                          <div className="col-md-2">
                            <div className="card card-body h-100 justify-content-center">
                              <div className="d-flex gap-2 justify-content-between align-items-center">
                                <div className="d-flex flex-column align-items-start">
                                  <h6 className="mb-1 text-capitalize ">
                                    Vendor Profile
                                  </h6>

                                  <div>
                                    <img
                                      width={200}
                                      height={130}
                                      className="mb-2"
                                      src={
                                        `${process.env.REACT_APP_API_KEY}uploads/` +
                                        details?.vender_profile
                                      }
                                      alt
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}

                        {details?.shop_logo ? (
                          <div className="col-md-2">
                            <div className="card card-body h-100 justify-content-center">
                              <div className="d-flex gap-2 justify-content-between align-items-center">
                                <div className="d-flex flex-column align-items-start">
                                  <h6 className="mb-1 text-capitalize ">
                                    Store Logo
                                  </h6>

                                  <div>
                                    <img
                                      width={200}
                                      height={130}
                                      className="mb-2"
                                      src={
                                        `${process.env.REACT_APP_API_KEY}uploads/` +
                                        details?.shop_logo
                                      }
                                      alt
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}

                        {details?.upload_frontId ? (
                          <div className="col-md-2">
                            <div className="card card-body h-100 justify-content-center">
                              <div className="d-flex gap-2 justify-content-between align-items-center">
                                <div className="d-flex flex-column align-items-start">
                                  <h6 className="mb-1 text-capitalize ">
                                    FrontId Image
                                  </h6>

                                  <div>
                                    <img
                                      width={200}
                                      height={130}
                                      className="mb-2"
                                      src={
                                        `${process.env.REACT_APP_API_KEY}uploads/` +
                                        details?.upload_frontId
                                      }
                                      alt
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {details?.upload_backsideId ? (
                          <div className="col-md-2">
                            <div className="card card-body h-100 justify-content-center">
                              <div className="d-flex gap-2 justify-content-between align-items-center">
                                <div className="d-flex flex-column align-items-start">
                                  <h6 className="mb-1 text-capitalize ">
                                    BackId Image
                                  </h6>

                                  <div>
                                    <img
                                      width={200}
                                      height={130}
                                      className="mb-2"
                                      src={
                                        `${process.env.REACT_APP_API_KEY}uploads/` +
                                        details?.upload_backsideId
                                      }
                                      alt
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}

                        {details?.shop_licence ? (
                          <div className="col-md-2">
                            <div className="card card-body h-100 justify-content-center">
                              <div className="d-flex gap-2 justify-content-between align-items-center">
                                <div className="d-flex flex-column align-items-start">
                                  <h6 className="mb-1 text-capitalize ">
                                    Store Licence
                                  </h6>
                                  {/* <div className="text-capitalize mb-0">
                              Pending Withdraw
                            </div> */}

                                  <div>
                                    <img
                                      width={200}
                                      height={130}
                                      className="mb-2 "
                                      src={
                                        `${process.env.REACT_APP_API_KEY}uploads/` +
                                        details?.shop_licence
                                      }
                                      alt
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}

                        {/* <div className="col-md-2">
                          <div className="card card-body h-100 justify-content-center">
                            <div className="d-flex gap-2 justify-content-between align-items-center">
                              <div className="d-flex flex-column align-items-start">
                                <h6 className="mb-1 text-capitalize">
                                  Vendor Profile
                                </h6>
                               

                                <div>
                                  <img
                                    width={200}
                                    height={130}
                                    className="mb-2"
                                    src={
                                      `${process.env.REACT_APP_API_KEY}uploads/` +
                                      details?.vender_profile
                                    }
                                    alt
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <br />
                </>

                {/* <div className="card mb-3 mb-lg-5">
        <div className="card-header">
          <h5 className="mb-0 text-capitalize">Bank and mobile money</h5>
        </div>
        <form onSubmit={updatebankdetails}>
        <div className="card-body">

          <div className="row">
           
            <div className="col-sm-12">
              <div className="row">
               
                <div className="col-lg-4 col-md-4 mb-3">
                  <label htmlFor="name" className="title-color">Bank name <span className="text-danger">*</span></label>
                  <input type="text"  placeholder={details?.bank_name} className="form-control" onChange={((e)=>{setbank_name(e.target.value)})} />
                </div>
                <div className="col-lg-4 col-md-4 mb-3">
                  <label htmlFor="name" className="title-color">Bank account number <span className="text-danger">*</span></label>
                  <input type="text" placeholder={details?.acc_number} onChange={((e)=>{setacc_number(e.target.value)})}  className="form-control"  />
                </div>
                <div className="col-lg-4 col-md-4 mb-3">
                  <label htmlFor="name" className="title-color">Bank account name <span className="text-danger">*</span></label>
                  <input type="text" placeholder={details?.bank_name} onChange={((e)=>{setbankAccount_name(e.target.value)})} className="form-control"  />
                </div>
                <div className="col-lg-4 col-md-4 mb-3">
                  <label htmlFor="name" className="title-color">SWIFT Code <span className="text-danger">*</span></label>
                  <input type="text" placeholder={details?.swift_code} onChange={((e)=>{setswift_code(e.target.value)})} className="form-control"  />
                </div>
                <div className="col-lg-4 col-md-4 mb-3">
                  <label htmlFor="name" className="title-color">Mobile money number<span className="text-danger">*</span></label>
                  <input type="text" placeholder={details?.mobile_money_number} onChange={((e)=>{setmobile_money_number(e.target.value)})} className="form-control"  />
                </div>
                
              </div>
            </div>
          </div>
         
          <div className="d-none" id="select-img">
            <input type="file" name="image" id="custom-file-upload" className="custom-file-input image-input" data-image-id="viewer" accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*" />
          </div>
          <div className="d-flex justify-content-end">
            <button type="submit" className="btn btn--primary call-demo">Save changes</button>
          </div>
        </div>
        </form>
      </div> */}

                <div id="passwordDiv" className="card mb-3 mb-lg-5">
                  <div className="card-header">
                    <h4 className="card-title">Change your password</h4>
                  </div>
                  <div className="card-body">
                    <form onSubmit={changepassword}>
                      <input type="hidden" />
                      <div className="row form-group">
                        <label
                          htmlFor="newPassword"
                          className="col-sm-3 col-form-label input-label"
                        >
                          {" "}
                          Enter current password
                        </label>
                        <div className="col-sm-9">
                          <input
                            required
                            value={currentpassword}
                            onChange={(e) => {
                              setcurrentpassword(e.target.value);
                            }}
                            type="password"
                            className="js-pwstrength form-control"
                            name="password"
                            placeholder="Enter new password"
                            aria-label="Enter new password"
                          />
                          <p className="form-text mb-2" />
                          <div id="passwordStrengthProgress" />
                        </div>
                      </div>

                      <div className="row form-group">
                        <label
                          htmlFor="newPassword"
                          className="col-sm-3 col-form-label input-label"
                        >
                          {" "}
                          New password
                        </label>
                        <div className="col-sm-9">
                          <input
                            required
                            value={password}
                            onChange={(e) => {
                              setpassword(e.target.value);
                            }}
                            type="password"
                            className="js-pwstrength form-control"
                            name="password"
                            placeholder="Enter new password"
                            aria-label="Enter new password"
                          />
                          <p className="form-text mb-2" />
                          <div id="passwordStrengthProgress" />
                        </div>
                      </div>
                      <div className="row form-group">
                        <label
                          htmlFor="confirmNewPasswordLabel"
                          className="col-sm-3 col-form-label input-label"
                        >
                          {" "}
                          Confirm password{" "}
                        </label>
                        <div className="col-sm-9">
                          <div className="mb-3">
                            <input
                              required
                              value={confirmpassword}
                              onChange={(e) => {
                                setconfirmpassword(e.target.value);
                              }}
                              type="password"
                              className="form-control"
                              name="confirm_password"
                              placeholder="Confirm your new password"
                              aria-label="Confirm your new password"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row form-group">
                        <Link
                          to="/"
                          htmlFor="confirmNewPasswordLabel"
                          className="col-sm-3 col-form-label input-label"
                        >
                          {" "}
                          Forgot password ?
                        </Link>
                      </div>
                      <div className="d-flex justify-content-end">
                        <button type="submit" className="btn btn--primary">
                          Done
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div id="stickyBlockEndPoint" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
