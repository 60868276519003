import React, { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import Header from "../Header";
import swal from "sweetalert";
import Sidebarr from "../Sidebar";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import toast, { Toaster } from "react-hot-toast";

const Bannerlist = () => {
  let navigate = useNavigate();
  const [bannerlist, setbannerlist] = useState();
  const [count, setcount] = useState();
  let banneredit = () => {
    navigate(`/editbannerlist`);
  };

  let token = secureLocalStorage.getItem("vendortoken");
  

  let deletebanner = (item) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this banner file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let deletebannerimage = () => {
          let bannerdata = {
            bannerId: item,
          };

          let options = {
            headers: {
              token: token,
            },
          };

          axios
            .post(
              `${process.env.REACT_APP_API_KEY}vender/api/delete_banner_api`,
              bannerdata,
              options
            )
            .then((res) => {
              getbannerlist();
            })
            .catch((error) => {});
        };
        deletebannerimage();
        swal("Poof! Your banner file has been deleted!", {
          icon: "success",
        });
      } else {
        swal("Your banner is safe!");
      }
    });
  };

  let add_banner = () => {
    navigate(`/add_banner`);
  };

  useEffect(() => {
    getbannerlist();
  }, [0]);
  let getbannerlist = () => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}vender/api/get_banner_api`)
      .then((res) => {
        setcount(res?.data?.data?.length)
        setbannerlist(res.data.data);
      })
      .catch((error) => {});
  };

  const activedeactive = (item) => {
    const Data = {
      bannerId: item,
    };

    let options = {
      hraders: {
        token: token,
      },
    };

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/activeDeactive_banner`,
        Data,
        options
      )
      .then((res) => {
        toast.success(res.data.message);
        getbannerlist();
      })
      .catch((error) => {});
  };
  return (
    <div className="container">
      <Toaster />
      {/* <Header /> */}
      <div
        className="container row"
        style={{ paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px" }}
      >
        <div className="col-lg-2 col-md-3" style={{ paddingLeft: "0px" }}>
          {/* <Sidebarr /> */}
        </div>

        <div
          className="col-lg-10 col-md-9"
          style={{ paddingLeft: "30px", marginTop: "60px" }}
        >
          <div className=" mt-3">
            <div className="">
              <div className="d-flex justify-content-between align-items-center gap-3 mb-3">
                <h2 className="h1 mb-1 text-capitalize d-flex align-items-center gap-2">
                  <img
                    width={20}
                    src="https://6valley.6amtech.com/public/assets/back-end/img/banner.png"
                    alt
                  />
                  Banner Setup
                </h2>
              </div>
              <div
                className="row pb-4 d--none"
                id="main-banner"
                style={{ textAlign: "left" }}
              >
                <div className="col-md-12">
                  <div className="card">
                    {/* <div className="card-header">
                      <h5 className="mb-0 text-capitalize">Banner form</h5>
                    </div> */}
                    <div className="card-body">
                      <form
                        action="https://6valley.6amtech.com/admin/banner/add-new"
                        method="post"
                        encType="multipart/form-data"
                        className="banner_form"
                      >
                        <input
                          type="hidden"
                          name="_token"
                          defaultValue="M5Ms1Z4GEx6hqRPEuULXw4EVAXN9PGreUwLBXESa"
                        />{" "}
                        <div className="row g-3">
                          <div className="col-md-6">
                            <input type="hidden" id="id" name="id" />
                            <div className="form-group">
                              <label
                                htmlFor="name"
                                className="title-color text-capitalize"
                              >
                                Banner type
                              </label>
                              <select
                                className="js-example-responsive form-control w-100"
                                name="banner_type"
                                required
                                id="banner_type_select"
                              >
                                <option value="Main Banner">Main Banner</option>
                                <option value="Popup Banner">
                                  Popup Banner
                                </option>
                                <option value="Footer Banner">
                                  Footer Banner
                                </option>
                                <option value="Main Section Banner">
                                  Main Section Banner
                                </option>
                              </select>
                            </div>
                            <div className="form-group mb-3">
                              <label
                                htmlFor="name"
                                className="title-color text-capitalize"
                              >
                                Banner URL
                              </label>
                              <input
                                type="url"
                                name="url"
                                className="form-control"
                                id="url"
                                required
                                placeholder="Enter url"
                              />
                            </div>
                            <div className="form-group">
                              <label
                                htmlFor="resource_id"
                                className="title-color text-capitalize"
                              >
                                Resource type
                              </label>
                              <select
                                onchange="display_data(this.value)"
                                className="js-example-responsive form-control w-100"
                                name="resource_type"
                                required
                              >
                                <option value="product">Product</option>
                                <option value="category">Category</option>
                                <option value="shop">Shop</option>
                                <option value="brand">Brand</option>
                              </select>
                            </div>
                            <div
                              className="form-group mb-0"
                              id="resource-product"
                            >
                              <label
                                htmlFor="product_id"
                                className="title-color text-capitalize"
                              >
                                Product
                              </label>
                              <select
                                className="js-example-responsive form-control w-100"
                                name="product_id"
                              >
                                <option value={1}>
                                  Women's long-sleeve lightweight french terry
                                  fleece quarter-zip top
                                </option>
                                <option value={3}>
                                  Ladies Winter Long Sleeve Sweater
                                </option>
                                <option value={4}>
                                  Crossbody Shoulder Bag Soft Leather Bag Female
                                  Fashion
                                </option>
                                <option value={5}>
                                  Exclusive &amp; Fashionable Suit For Men
                                </option>
                                <option value={6}>
                                  Real Diamond Ring Multi Stone Solid 18K Gold
                                  Certified WR2133
                                </option>
                                <option value={7}>
                                  Progress lighting P4009-10 5-light chandelier,
                                  polished brass
                                </option>
                                <option value={8}>
                                  Girls Beautiful White &amp; Purple Sneakers
                                </option>
                                <option value={9}>
                                  LM Washable and Light-Weight Men's Shoe
                                </option>
                                <option value={12}>
                                  Red Flash Melting Matte Waterproof Lip Stick -
                                  P05
                                </option>
                                <option value={13}>
                                  Family Size Trolley Case Long Lasting and 8
                                  Wheel Waterproof bag
                                </option>
                                <option value={15}>
                                  Latest Cool headphone with Bluetooth version
                                  5.0
                                </option>
                                <option value={16}>
                                  East M-242 Refrigerator - 242 Liter - Black
                                  color
                                </option>
                                <option value={17}>
                                  Simple Mobile Carrier-Locked Galaxy A50 4G LTE
                                  Prepaid Smartphone - Blac
                                </option>
                                <option value={18}>
                                  Timex marlin stainless steel hand-wound
                                  movement
                                </option>
                                <option value={21}>
                                  Fastest Electric Spice Grinder and Blender
                                </option>
                                <option value={22}>
                                  Enos 658 Hair Dryer for Women-Multicolor
                                </option>
                                <option value={23}>
                                  Fashionable bag for women
                                </option>
                                <option value={27}>
                                  New Fashionable Box Balance Heel shoes for
                                  Women
                                </option>
                                <option value={29}>
                                  Hot Selling Sneakers, Sneakers Men Casual
                                  Shoes Men Fashion Sneakers Fly knit Li
                                </option>
                                <option value={32}>
                                  New Design Trendy Casual Sneakers for Men
                                  Lightweight &amp; Comfortable For Party We
                                </option>
                                <option value={36}>
                                  Quartz wrist watch waterproof watch for Men
                                  and Women
                                </option>
                                <option value={38}>
                                  Fashionable Box Balance Heel shoes for Women
                                </option>
                                <option value={49}>women's shoes</option>
                                <option value={50}>
                                  Super Portable Electric Iron - Multicolor -007
                                </option>
                                <option value={51}>Ladies Bag</option>
                                <option value={53}>
                                  Klash Flash Melting Matte Waterproof Lip Stick
                                  - P09
                                </option>
                                <option value={56}>
                                  Girls Beautiful White &amp; Purple Sneakers
                                </option>
                                <option value={57}>
                                  Smile Plastic stacking ring for kids
                                </option>
                                <option value={58}>
                                  Power Magnetic Sport cycle K3.8
                                </option>
                                <option value={59}>Football - Size 3</option>
                                <option value={60}>
                                  Smart Heart Adult Dog Food Chicken &amp; Egg
                                  Flavor 2Kg
                                </option>
                                <option value={61}>
                                  Wooden Dog Toy for kids
                                </option>
                                <option value={62}>
                                  New Long Sleeve Formal Shirt for Men
                                </option>
                                <option value={63}>
                                  Chicken and Egg Puppy Dog Food, 400g
                                </option>
                                <option value={64}>
                                  Wallet For Men PU Horizontal Wallet Business
                                  Wallets High Quality PU Leather Bag
                                </option>
                                <option value={65}>
                                  Drinking Glass Bottle 420 ml with cover -
                                  Water Bottle
                                </option>
                                <option value={66}>
                                  Ava Professional Hair Straightener - Black
                                  color
                                </option>
                                <option value={67}>
                                  Beautiful long lasting Golden Wing Football -
                                  Size 2
                                </option>
                                <option value={68}>
                                  Honix Badminton Racket With String - Black
                                </option>
                                <option value={69}>
                                  15 IN 1 MAKEUP COMBO SET WITH FULL MAKEUP
                                  COVERAGE set
                                </option>
                                <option value={70}>
                                  6" Combination Pliers electric plus
                                </option>
                                <option value={71}>
                                  Multi color mobile back cover
                                </option>
                                <option value={73}>
                                  Hockey Stick Fiber Material
                                </option>
                                <option value={74}>
                                  Table Tennis Bat - Double Fish - 3A-C - 2 Pcs
                                </option>
                                <option value={75}>
                                  Voltage Tester 100-500V 3X140mm Neon Bulb
                                  38114
                                </option>
                                <option value={76}>
                                  Yuzu Fast Charger 27W QC3.0 Quick Charge
                                  Adapter USB Type-B
                                </option>
                                <option value={77}>TWS Ear Buds</option>
                                <option value={78}>
                                  Mophony Helio30 RAM 6 GB/ROM 128 GB Mobile
                                  Phone Smartphone
                                </option>
                                <option value={79}>
                                  Stone Crystal Unique Design Necklace Woman
                                  Exquisite Wild Short Clavicle Chain
                                </option>
                                <option value={80}>
                                  Diamond Quartz Watch Steel Band Watch Luminous
                                  Pointer Wrist Watches for Men
                                </option>
                                <option value={81}>
                                  Silicone Strap Analogue Sports Watch
                                  Rectangular Dial New Model 2023 Men Watches
                                </option>
                              </select>
                            </div>
                            <div
                              className="form-group mb-0 d--none"
                              id="resource-category"
                            >
                              <label
                                htmlFor="name"
                                className="title-color text-capitalize"
                              >
                                Category
                              </label>
                              <select
                                className="js-example-responsive form-control w-100"
                                name="category_id"
                              >
                                <option value={39}>Bags &amp; Shoes</option>
                                <option value={34}>
                                  Home, Pet &amp; Appliances
                                </option>
                                <option value={28}>Phones &amp; Telecom</option>
                                <option value={27}>
                                  Computer, Office &amp; Security
                                </option>
                                <option value={26}>Mobile Accessories</option>
                                <option value={12}>
                                  Toys , Kids &amp; Babies
                                </option>
                                <option value={25}>
                                  Beauty, Health &amp; Hair
                                </option>
                                <option value={24}>
                                  Jewelry &amp; Watches
                                </option>
                                <option value={16}>ebook</option>
                                <option value={15}>Women's fashion</option>
                                <option value={11}>
                                  Home Improvement &amp; Tools
                                </option>
                                <option value={14}>
                                  Outdoor Fun &amp; Sports
                                </option>
                                <option value={13}>Men's fashion</option>
                              </select>
                            </div>
                            <div
                              className="form-group mb-0 d--none"
                              id="resource-shop"
                            >
                              <label htmlFor="shop_id" className="title-color">
                                Shop
                              </label>
                              <select
                                className="w-100 js-example-responsive form-control"
                                name="shop_id"
                              >
                                <option value={1}>Deluxe Online</option>
                                <option value={2}>Mart Morning</option>
                                <option value={3}>Wellness Fair</option>
                                <option value={4}>Bicycle Shop</option>
                                <option value={5}>KR Fashion</option>
                                <option value={6}>Country Fair</option>
                                <option value={7}>Tech Shop</option>
                                <option value={8}>Royal Crown</option>
                                <option value={9}>Super Store Online</option>
                                <option value={10}>Digital House</option>
                                <option value={12}>Perry Dotson</option>
                              </select>
                            </div>
                            <div
                              className="form-group mb-0 d--none"
                              id="resource-brand"
                            >
                              <label
                                htmlFor="brand_id"
                                className="title-color text-capitalize"
                              >
                                Brand
                              </label>
                              <select
                                className="js-example-responsive form-control w-100"
                                name="brand_id"
                              >
                                <option value={1}>i Bird</option>
                                <option value={4}>Agron</option>
                                <option value={5}>Triangle</option>
                                <option value={6}>Estro</option>
                                <option value={7}>Ohoenix</option>
                                <option value={8}>Waltro</option>
                                <option value={9}>JK</option>
                                <option value={10}>Crave</option>
                                <option value={11}>Framerce</option>
                                <option value={12}>Fashion</option>
                                <option value={13}>S.Cube</option>
                                <option value={14}>Estha dot</option>
                                <option value={17}>Digital Product</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6 d-flex flex-column justify-content-center">
                            <div>
                              <center className="mx-auto">
                                <div className="uploadDnD">
                                  <div
                                    className="form-group inputDnD input_image"
                                    data-title="Drag and drop file or Browse file"
                                  >
                                    <input
                                      type="file"
                                      name="image"
                                      className="form-control-file text--primary font-weight-bold"
                                      onchange="readUrl(this)"
                                      accept=".jpg, .png, .jpeg, .gif, .bmp, .webp |image/*"
                                    />
                                  </div>
                                </div>
                              </center>
                              <label
                                htmlFor="name"
                                className="title-color text-capitalize"
                              >
                                Banner image
                              </label>
                              <span className="title-color" id="theme_ratio">
                                ( Ratio 4:1 )
                              </span>
                              <p>
                                Banner Image ratio is not same for all sections
                                in website. Please review the ratio before
                                upload
                              </p>
                            </div>
                          </div>
                          <div className="col-12 d-flex justify-content-end flex-wrap gap-10">
                            <button
                              className="btn btn-secondary cancel px-4"
                              type="reset"
                            >
                              Reset
                            </button>
                            <button
                              id="add"
                              type="submit"
                              className="btn btn--primary px-4"
                            >
                              Save
                            </button>
                            <button
                              id="update"
                              className="btn btn--primary d--none text-white"
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row" id="banner-table">
                <div className="col-md-12">
                  <div className="card">
                    <div className="px-3 py-4">
                      <div className="row align-items-center">
                        <div className="col-md-4 col-lg-6 mb-2 mb-md-0">
                          <h5 className="mb-0 text-capitalize d-flex gap-2">
                            Banner Data
                            <span className="badge badge-soft-dark radius-50 fz-12">
                              {count}
                            </span>
                          </h5>
                        </div>
                        <div className="col-md-8 col-lg-5">
                        <div className="row gy-2 gx-2 align-items-center text-left">
                          <div className="col-sm-12 col-md-9"></div>
                          <div className="col-sm-12 col-md-3">
                            <div id="banner-btn">
                              <button
                                onClick={add_banner}
                                id="main-banner-add"
                                className="btn btn--primary text-nowrap text-capitalize"
                              >
                                Add banner
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table
                        id="columnSearchDatatable"
                        style={{ textAlign: "left" }}
                        className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100"
                      >
                        <thead className="thead-light thead-50 text-capitalize">
                          <tr>
                            <th className="pl-xl-5">SL</th>
                            <th>Image</th>
                            <th>Banner title</th>
                            <th>Published</th>
                            <th className="text-center">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {bannerlist?.map((bannerdata, index) => {
                            return (
                              <tr id="data-37">
                                <td className="pl-xl-5">{index + 1}</td>
                                <td>
                                  <img
                                    className="rounded"
                                    width={64}
                                    src={
                                      `${process.env.REACT_APP_API_KEY}uploads/` +
                                      bannerdata?.image
                                    }
                                  />
                                </td>
                                <td>{bannerdata?.title}</td>

                                {bannerdata?.active_status != 0 ? (
                                  <td>
                                    <form className="banner_status_form">
                                      <input type="hidden" />
                                      <input type="hidden" name="id" />
                                      <label className="switcher">
                                        <input
                                          type="checkbox"
                                          className="switcher_input"
                                          name="status"
                                          onChange={() =>
                                            activedeactive(bannerdata?._id)
                                          }
                                        />
                                        <span className="switcher_control" />
                                      </label>
                                    </form>
                                  </td>
                                ) : (
                                  <td>
                                    <form className="banner_status_form">
                                      <input type="hidden" />
                                      <input type="hidden" name="id" />
                                      <label className="switcher">
                                        <input
                                          id="coupon_status9"
                                          name="status"
                                          defaultValue={1}
                                          defaultChecked
                                          type="checkbox"
                                          className="switcher_input"
                                          onChange={() =>
                                            activedeactive(bannerdata?._id)
                                          }
                                        />
                                        <span className="switcher_control" />
                                      </label>
                                    </form>
                                  </td>
                                )}

                                <td>
                                  <div className="d-flex gap-10 justify-content-center">
                                    <span
                                      className="btn btn-outline--primary btn-sm cursor-pointer edit"
                                      onClick={() => {
                                        banneredit(
                                          secureLocalStorage.setItem(
                                            "banner_id",
                                            bannerdata?._id
                                          )
                                        );
                                      }}
                                      title="Edit"
                                    >
                                      <i
                                        className="fa fa-pencil-square-o"
                                        aria-hidden="true"
                                      />
                                    </span>
                                    <a
                                      onClick={() => {
                                        deletebanner(bannerdata?._id);
                                      }}
                                      className="btn btn-outline-danger btn-sm cursor-pointer delete"
                                      title="Delete"
                                      id={37}
                                    >
                                      <i
                                        className="fa fa-trash-o"
                                        aria-hidden="true"
                                      />
                                    </a>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div className="table-responsive mt-4">
                      <div className="px-4 d-flex justify-content-lg-end"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bannerlist;
