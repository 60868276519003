import React, { useEffect, useState } from "react";
import Header from "../Header";

import "../sidebar.css";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Sidebarr from "../Sidebar";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import "./Productdetails.css";
import QRCode from "react-qr-code";
import swal from "sweetalert";
import ReactPlayer from "react-player";
const Productdetails = () => {
  const [productdata, setproductdata] = useState();

  const [isOpen, setIsOpen] = useState(true);
  let productidd = secureLocalStorage.getItem("productid");

  const Navigate = useNavigate();
  let token = secureLocalStorage.getItem("vendortoken");
  let venderIdd = secureLocalStorage.getItem("vendorid");
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    Products_details();
  }, [0]);

  const Products_details = () => {
    const options = {
      headers: {
        token: token,
      },
    };
    const data = {
      productId: productidd,
      venderId: venderIdd,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/productDetails`,
        data,
        options
      )
      .then((res) => {
        console.log("res", res);
        setproductdata(res.data.data);
      })
      .catch((error) => {});
  };

  let deleteproduct = (item) => {
    swal({
      title: `Delete ${productdata[0]?.product_name}?`,
      //text: "Once deleted, you will not be able to recover this Product!",
      icon: "warning",
      buttons: ["No", "Yes"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let deletebannerimage = () => {
          let bannerdata = {
            venderId: venderIdd,
            productId: item,
          };

          let options = {
            headers: {
              token: token,
            },
          };
          axios
            .post(
              `${process.env.REACT_APP_API_KEY}vender/api/deleteProduct`,
              bannerdata,
              options
            )
            .then((res) => {
              setTimeout(() => {
                Navigate("/products");
              }, 5000);
            })
            .catch((error) => {});
        };
        deletebannerimage();
        swal(`Delete ${productdata[0]?.product_name}`, {
          icon: "success",
        });
      } else {
        swal("Your Product is safe!");
      }
    });
  };
  return (
    <div className="container">
      {/* <Header /> */}
      <div
        className="container row"
        style={{ paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px" }}
      >
        <div className="col-lg-2 col-md-3" style={{ paddingLeft: "0px" }}>
          {/* <Sidebarr /> */}
        </div>

        <div
          className="col-lg-10 col-md-9"
          style={{ paddingLeft: "30px", marginTop: "60px" }}
        >
          <div className="row mt-20">
            <div className="col-md-12">
              <div className="card">
                <div className="px-3 py-4">
                  <div className="row align-items-center">
                    <div className="col-lg-4"></div>
                    <div className="col-lg-12 mt-3 mt-lg-0 d-flex flex-wrap gap-3 justify-content-lg-end">
                      <div className="content container-fluid text-start">
                        <div className="d-flex align-items-center justify-content-between flex-wrap gap-10 mb-3">
                          <div className>
                            <h2 className="h1 mb-0 text-capitalize d-flex align-items-center gap-2">
                              <img
                                width={20}
                                onerror="this.src='https://6valley.6amtech.com/public/assets/back-end/img/160x160/img2.jpg'"
                                src="https://6valley.6amtech.com/public/assets/back-end/img/products.png"
                                alt=""
                              />
                              Product details
                            </h2>
                          </div>
                        </div>

                        {productdata?.map((data) => {
                          return (
                            <div className="card">
                              <div className="card-body">
                                <div className="d-flex flex-wrap flex-lg-nowrap gap-3 justify-content-between">
                                  <div className="media flex-wrap flex-sm-nowrap gap-3">
                                    <a
                                      className="aspect-1 float-left overflow-hidden"
                                      data-lightbox="mygallery"
                                    >
                                      {data?.image1 ? (
                                        <img
                                          className="avatar avatar-170 rounded-0"
                                          src={
                                            `${process.env.REACT_APP_API_KEY}uploads/` +
                                            data?.image1
                                          }
                                          alt="Image Description"
                                        />
                                      ) : (
                                        <img
                                          className="avatar avatar-170 rounded-0"
                                          src="https://6valley.6amtech.com/storage/app/public/product/thumbnail/2023-06-13-64883db39dcbb.png"
                                          alt="Image Description"
                                        />
                                      )}
                                    </a>
                                    <div className="d-block">
                                      <div className="d-flex flex-wrap flex-sm-nowrap align-items-start gap-2 mb-2 min-h-50">
                                        <a
                                          className="aspectt aspect-1 float-left overflow-hidden"
                                          data-lightbox="mygallery"
                                        >
                                          {data?.image1 ? (
                                            <img
                                              width={100}
                                              height={100}
                                              src={
                                                `${process.env.REACT_APP_API_KEY}uploads/` +
                                                data?.image1
                                              }
                                              alt="Image Description"
                                            />
                                          ) : null}
                                        </a>
                                        <a
                                          className="aspectt aspect-1 float-left overflow-hidden"
                                          data-lightbox="mygallery"
                                        >
                                          {data?.image2 ? (
                                            <img
                                              width={100}
                                              height={100}
                                              src={
                                                `${process.env.REACT_APP_API_KEY}uploads/` +
                                                data?.image2
                                              }
                                              alt="Image Description"
                                            />
                                          ) : null}
                                        </a>
                                        <a
                                          className="aspectt aspect-1 float-left overflow-hidden"
                                          data-lightbox="mygallery"
                                        >
                                          {data?.image3 ? (
                                            <img
                                              width={100}
                                              height={100}
                                              src={
                                                `${process.env.REACT_APP_API_KEY}uploads/` +
                                                data?.image3
                                              }
                                              alt="Image Description"
                                            />
                                          ) : null}
                                        </a>
                                        <a
                                          className="aspectt aspect-1 float-left overflow-hidden"
                                          data-lightbox="mygallery"
                                        >
                                          {data?.image4 ? (
                                            <img
                                              width={100}
                                              height={100}
                                              src={
                                                `${process.env.REACT_APP_API_KEY}uploads/` +
                                                data?.image4
                                              }
                                              alt="Image Description"
                                            />
                                          ) : null}
                                        </a>
                                        <a
                                          className="aspectt aspect-1 float-left overflow-hidden"
                                          data-lightbox="mygallery"
                                        >
                                          {data?.image5 ? (
                                            <img
                                              width={100}
                                              height={100}
                                              src={
                                                `${process.env.REACT_APP_API_KEY}uploads/` +
                                                data?.image5
                                              }
                                              alt="Image Description"
                                            />
                                          ) : null}
                                        </a>
                                      </div>
                                      <div className="d-block">
                                        <div className="d-flex">
                                          <h5 className="mb-2 pb-1 text-gulf-blue text-capitalize">
                                            {data?.product_name &&
                                            data?.product_name.length > 100
                                              ? data?.product_name?.slice(
                                                  0,
                                                  100
                                                ) + "..."
                                              : data?.product_name}
                                          </h5>
                                        </div>
                                        <div className="d-flex">
                                          <h5 className="mb-2 pb-1 text-gulf-blue text-capitalize">
                                            {data?.frnz_product_name &&
                                            data?.product_name.length > 100
                                              ? data?.frnz_product_name?.slice(
                                                  0,
                                                  100
                                                ) + "..."
                                              : data?.frnz_product_name}
                                          </h5>
                                        </div>

                                        <div className="d-flex gap-3 flex-wrap mb-3 lh-1">
                                          <a href="#" className="text-dark">
                                            0 Orders
                                          </a>
                                          <span className="border-left" />
                                          <div className="review-hover position-relative cursor-pointer d-flex gap-2 align-items-center">
                                            <i
                                              className="fa fa-star"
                                              aria-hidden="true"
                                            />
                                            <span>0</span>
                                            <div className="review-details-popup">
                                              <h6 className="mb-2">Rating</h6>
                                              <div className>
                                                <ul className="list-unstyled list-unstyled-py-2 mb-0">
                                                  <li className="d-flex align-items-center font-size-sm">
                                                    <span className="mr-3">
                                                      5 Star
                                                    </span>
                                                    <div className="progress flex-grow-1">
                                                      <div
                                                        className="progress-bar"
                                                        role="progressbar"
                                                        style={{ width: "0%" }}
                                                        aria-valuenow={0}
                                                        aria-valuemin={0}
                                                        aria-valuemax={100}
                                                      />
                                                    </div>
                                                    <span className="ml-3">
                                                      0
                                                    </span>
                                                  </li>
                                                  <li className="d-flex align-items-center font-size-sm">
                                                    <span className="mr-3">
                                                      4 Star
                                                    </span>
                                                    <div className="progress flex-grow-1">
                                                      <div
                                                        className="progress-bar"
                                                        role="progressbar"
                                                        style={{ width: "0%" }}
                                                        aria-valuenow={0}
                                                        aria-valuemin={0}
                                                        aria-valuemax={100}
                                                      />
                                                    </div>
                                                    <span className="ml-3">
                                                      0
                                                    </span>
                                                  </li>
                                                  <li className="d-flex align-items-center font-size-sm">
                                                    <span className="mr-3">
                                                      3 Star
                                                    </span>
                                                    <div className="progress flex-grow-1">
                                                      <div
                                                        className="progress-bar"
                                                        role="progressbar"
                                                        style={{ width: "0%" }}
                                                        aria-valuenow={0}
                                                        aria-valuemin={0}
                                                        aria-valuemax={100}
                                                      />
                                                    </div>
                                                    <span className="ml-3">
                                                      0
                                                    </span>
                                                  </li>
                                                  <li className="d-flex align-items-center font-size-sm">
                                                    <span className="mr-3">
                                                      2 Star
                                                    </span>
                                                    <div className="progress flex-grow-1">
                                                      <div
                                                        className="progress-bar"
                                                        role="progressbar"
                                                        style={{ width: "0%" }}
                                                        aria-valuenow={0}
                                                        aria-valuemin={0}
                                                        aria-valuemax={100}
                                                      />
                                                    </div>
                                                    <span className="ml-3">
                                                      0
                                                    </span>
                                                  </li>
                                                  <li className="d-flex align-items-center font-size-sm">
                                                    <span className="mr-3">
                                                      1 Star
                                                    </span>
                                                    <div className="progress flex-grow-1">
                                                      <div
                                                        className="progress-bar"
                                                        role="progressbar"
                                                        style={{ width: "0%" }}
                                                        aria-valuenow={0}
                                                        aria-valuemin={0}
                                                        aria-valuemax={100}
                                                      />
                                                    </div>
                                                    <span className="ml-3">
                                                      0
                                                    </span>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                          <span className="border-left" />
                                          <a
                                            href="javascript:"
                                            className="text-dark"
                                          >
                                            {data?.rating} Ratings
                                          </a>
                                          <span className="border-left" />
                                          <a
                                            href="javascript:"
                                            className="text-dark"
                                          >
                                            0 Reviews
                                          </a>
                                          <div className="review-hover position-relative cursor-pointer d-flex gap-2 align-items-center">
                                            <i
                                              className="fa fa-ellipsis-h"
                                              aria-hidden="true"
                                            />

                                            <div
                                              className="review-details-popup"
                                              style={{ width: "100px" }}
                                            >
                                              <h6 className="mb-2"></h6>
                                              <div className>
                                                <ul className="list-unstyled list-unstyled-py-2 mb-0">
                                                  <li
                                                    onClick={() => {
                                                      secureLocalStorage.setItem(
                                                        "productid",
                                                        data?._id
                                                      );
                                                      Navigate("/editproduct");
                                                    }}
                                                    className="d-flex align-items-center font-size-sm"
                                                  >
                                                    <span className="mr-3">
                                                      Edit
                                                    </span>

                                                    <span className="ml-4">
                                                      <i
                                                        class="fa fa-pencil"
                                                        aria-hidden="true"
                                                      ></i>
                                                    </span>
                                                  </li>
                                                  <li className="d-flex align-items-center font-size-sm">
                                                    <span className="mr-3">
                                                      Share
                                                    </span>

                                                    <span className="ml-2">
                                                      <i
                                                        class="fa fa-share"
                                                        aria-hidden="true"
                                                      ></i>
                                                    </span>
                                                  </li>
                                                  <li
                                                    onClick={() => {
                                                      deleteproduct(data?._id);
                                                    }}
                                                    className="d-flex align-items-start font-size-sm"
                                                  >
                                                    <span className="mr-3">
                                                      Delete
                                                    </span>

                                                    <span className="ml-2">
                                                      <i
                                                        class="fa fa-trash"
                                                        aria-hidden="true"
                                                      ></i>
                                                    </span>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <hr />
                                <div className="d-flex gap-3 flex-wrap">
                                  <div className="border p-3 w-380">
                                    <div className="d-flex flex-column mb-1">
                                      <h6 className="text-primary fs-18">
                                        Description(EN) :
                                      </h6>
                                      <h6 className="font-weight-normal fs-18 text-capitalize">
                                        {data?.description}
                                      </h6>
                                    </div>

                                    <div className="d-flex flex-column">
                                      <h6 className="text-primary fs-18">
                                        Product Details(EN) :
                                      </h6>
                                      <h6 className="font-weight-normal text-capitalize">
                                        {data?.product_details}
                                      </h6>
                                    </div>
                                    {data?.frnz_product_details ? (
                                      <div className="d-flex flex-column">
                                        <h6 className="text-primary fs-18">
                                          Product Details(FR) :
                                        </h6>
                                        <h6 className="font-weight-normal text-capitalize">
                                          {data?.frnz_product_details}
                                        </h6>
                                      </div>
                                    ) : null}
                                  </div>
                                  <div className="border p-3 w-170">
                                    <div className="d-flex flex-column mb-1">
                                      <h6 className="text-primary fs-18">
                                        QR Code
                                      </h6>
                                      {data?.status != 0 ? (
                                        <div>
                                          {" "}
                                          <span className="key">
                                            <label className="__badge badge badge-soft-success">
                                              Approved
                                            </label>
                                          </span>
                                          <Link
                                            onClick={() => {
                                              secureLocalStorage.setItem(
                                                "productid",
                                                data?._id
                                              );
                                            }}
                                            to="/productqrcode"
                                          >
                                            {" "}
                                            <QRCode
                                              size={50}
                                              bgColor="white"
                                              fgColor="black"
                                              value={data?.product_code}
                                            />
                                          </Link>
                                        </div>
                                      ) : (
                                        <label className="__badge badge badge-soft-danger">
                                          Pending
                                        </label>
                                      )}
                                    </div>
                                  </div>
                                  <div className="row gy-3 flex-grow-1">
                                    <div className="col-sm-12 col-xl-4 col-lg-4">
                                      <h4 className="mb-3">
                                        General Information
                                      </h4>
                                      <div className="pair-list">
                                        <div>
                                          <span className="key">Brand</span>
                                          <span>:</span>
                                          {data?.brand_name ? (
                                            <span className="value text-capitalize">
                                              {data?.brand_name}
                                            </span>
                                          ) : (
                                            <span className="value">NA</span>
                                          )}
                                        </div>
                                        <div>
                                          <span className="key">Category</span>
                                          <span>:</span>
                                          <span className="value text-capitalize">
                                            {
                                              data?.categoryId
                                                ?.category_englishName
                                            }
                                          </span>
                                        </div>

                                        <div>
                                          <span className="key">
                                            Subcategory
                                          </span>
                                          <span>:</span>
                                          {data?.subcategoryId
                                            ?.english_subcategory_name ? (
                                            <span className="value text-capitalize">
                                              {
                                                data?.subcategoryId
                                                  ?.english_subcategory_name
                                              }
                                            </span>
                                          ) : (
                                            <span className="value">NA</span>
                                          )}
                                        </div>
                                        <div>
                                          <span className="key">
                                            Product Type
                                          </span>
                                          <span>:</span>
                                          <span className="value text-capitalize">
                                            {data?.productType ? data?.productType : "NA"}
                                          </span>
                                        </div>
                                        <div>
                                          <span className="key">
                                            Product Code
                                          </span>
                                          <span>:</span>
                                          <span className="value">
                                            {data?.product_code}
                                          </span>
                                        </div>
                                        <div>
                                          <span className="key">
                                            Current Stock
                                          </span>
                                          <span>:</span>
                                          {data?.stock ? (
                                            <span className="value">
                                              {data?.stock}
                                            </span>
                                          ) : (
                                            <span className="value">NA</span>
                                          )}
                                        </div>
                                        <div>
                                          <span className="key">
                                            Min Order Quantity
                                          </span>
                                          <span>:</span>
                                          {data?.minimum_order ? (
                                            <span className="value">
                                              {data?.minimum_order}
                                            </span>
                                          ) : (
                                            <span className="value">NA</span>
                                          )}
                                        </div>
                                        <div>
                                          <span className="key">SKU</span>
                                          <span>:</span>
                                          <span className="value">
                                            {data?.product_code}
                                          </span>
                                        </div>
                                        <div>
                                          <span className="key">Warranty</span>
                                          <span>:</span>
                                          {data?.warranty ? (
                                            <span className="value">
                                              {data?.warranty}
                                            </span>
                                          ) : (
                                            <span className="value">NA</span>
                                          )}
                                        </div>

                                        {/* <div
                                            
                                          >
                                            <span className="key">Qr Code</span>
                                            <span>:</span>
                                             {data?.status != 0 ? (
                                              
         
            <Link
            onClick={() => {
              secureLocalStorage.setItem("productid", data?.productId);
            }}
            to='/productqrcode'
            
          > <QRCode size={50} bgColor='white' fgColor='black' value={data?.product_code}/></Link>
       
        ) : (
         
            <label className="badge badge-soft-danger">Pending</label>
          
        )}
                                          </div> */}
                                      </div>
                                    </div>
                                    <div className="col-sm-12 col-xl-4 col-lg-4">
                                      <h4 className="mb-3">
                                        Price Information
                                      </h4>
                                      <div className="pair-list">
                                        <div>
                                          <span className="key">
                                            Unit Price
                                          </span>
                                          <span>:</span>
                                          <span className="value">
                                            {data?.unit_price}CFA
                                          </span>
                                        </div>
                                        <div>
                                          <span className="key">
                                            Sale Price
                                          </span>
                                          <span>:</span>
                                          <span className="value">
                                            {data?.sale_price}CFA
                                          </span>
                                        </div>
                                        <div>
                                          <span className="key">Tax</span>
                                          <span>:</span>
                                          <span className="value">
                                            {data?.Tax[0]?.tax_value} (
                                            {data?.Tax[0]?.tax_name})
                                          </span>
                                        </div>

                                        <div>
                                          <span className="key">Discount</span>
                                          <span>:</span>
                                          <span className="value">
                                            {data?.discount[0]?.discount_value}{" "}
                                            {data?.discount[0]?.discount_type}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-sm-12 col-xl-4 col-lg-4">
                                      <h4 className="mb-3">
                                        Available Variations
                                      </h4>
                                      {data?.product_variation?.map((data) => {
                                        return (
                                          <div>
                                            <span className="key text-capitalize">
                                              {data?.attribute_name}
                                            </span>
                                            <span> :</span>{" "}
                                            <span className="value text-capitalize">
                                              {data?.attribute_values?.join(
                                                ","
                                              )}
                                            </span>
                                          </div>
                                        );
                                      })}

                                      {/* <div>
                                          <span className="key">Sleeve</span>
                                          <span>:</span>
                                          <span className="value">
                                            {data?.sleeve?.map((sleeve) => {
                                              return (
                                                <>{Array?.(sleeve).join(",")}</>
                                              );
                                            })}
                                          </span>
                                        </div> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Productdetails;
