import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../Header";
import Sidebarr from "../Sidebar";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import toast, { Toaster } from "react-hot-toast";

const Deletaccount = () => {
  let navigate = useNavigate();

  const [selectedReason, setSelectedReason] = useState(null);
  const [customDescription, setCustomDescription] = useState("");

  const handleCheckboxChange = (reason) => {
    setSelectedReason((prevSelectedReason) =>
      prevSelectedReason === reason ? null : reason
    );
  };

  let token = secureLocalStorage.getItem("vendortoken");
  let venderid = secureLocalStorage.getItem("vendorid");

  let deletebanner = () => {
    if (!selectedReason) {
      toast.error("Please select a reason before proceeding.");
      return;
    }

    let vendordata = {
        venderId: venderid,
        reason: selectedReason,
        text: customDescription,
    };

    let options = {
      headers: {
        token: token,
      },
    };

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/deleteVendor`,
        vendordata,
        options
      )
      .then((res) => {
        toast.success(
          `Your account has been deleted. We hope to see you soon!`
        );
        setTimeout(() => {
          navigate("/home");
        }, 3000);
      })
      .catch((error) => {

      });
  };

  return (
    <div className="container">
      <Toaster />
      {/* <Header /> */}
      <div
        className="container row"
        style={{
          paddingLeft: "0px",
          paddingRight: "0px",
          marginLeft: "0px",
        }}
      >
        <div className="col-lg-2 col-md-3" style={{ paddingLeft: "0px" }}>
          {/* <Sidebarr /> */}
        </div>

        <div
          className="col-lg-10 col-md-9"
          style={{ paddingLeft: "30px", marginTop: "60px" }}
        >
          <div className=" mt-3">
            <div className="">
              <div className="d-flex justify-content-between align-items-center gap-3 mb-3">
                <h2 className="h1 mb-1 text-capitalize d-flex align-items-center gap-2">
                  <img
                    width={20}
                    src="https://6valley.6amtech.com/public/assets/back-end/img/banner.png"
                    alt
                  />
                  Account deletion
                </h2>
              </div>

              <div className="row" id="banner-table">
                <div className="col-md-12">
                  <div className="card">
                    <div className="px-3 py-4">
                      <div className="row align-items-center">
                        <div className="col-md-12 col-lg-12 mb-2 mb-md-0">
                          <h5 className="mb-2 text-capitalize d-flex gap-2 ml-6">
                            Please let us know the reasons you are deleting your
                            account
                            <span className="text-danger">*</span>
                          </h5>

                          <div className="justify-content-lg-right">
                            <ul style={{ listStyle: "none" }}>
                              <li>
                                <input
                                  onChange={() =>
                                    handleCheckboxChange("Privacy concerns")
                                  }
                                  type="checkbox"
                                  checked={
                                    selectedReason === "Privacy concerns"
                                  }
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    marginTop: "10px",
                                  }}
                                />{" "}
                                <span
                                  style={{
                                    marginLeft: "20px",
                                    fontSize: "19px",
                                  }}
                                >
                                  Privacy concerns
                                </span>
                              </li>

                              <li>
                                <input
                                  type="checkbox"
                                  onChange={() =>
                                    handleCheckboxChange(
                                      "Too many notifications"
                                    )
                                  }
                                  checked={
                                    selectedReason === "Too many notifications"
                                  }
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    marginTop: "10px",
                                  }}
                                />
                                <span
                                  style={{
                                    marginLeft: "20px",
                                    fontSize: "18px",
                                  }}
                                >
                                  Too many notifications
                                </span>
                              </li>

                              <li>
                                <input
                                  type="checkbox"
                                  onChange={() =>
                                    handleCheckboxChange(
                                      "Better prices elsewhere"
                                    )
                                  }
                                  checked={
                                    selectedReason === "Better prices elsewhere"
                                  }
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    marginTop: "10px",
                                  }}
                                />
                                <span
                                  style={{
                                    marginLeft: "20px",
                                    fontSize: "18px",
                                  }}
                                >
                                  Better prices elsewhere
                                </span>
                              </li>
                              <li>
                                <input
                                  type="checkbox"
                                  onChange={() =>
                                    handleCheckboxChange(
                                      "I do not use it quite often"
                                    )
                                  }
                                  checked={
                                    selectedReason ===
                                    "I do not use it quite often"
                                  }
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    marginTop: "10px",
                                  }}
                                />
                                <span
                                  style={{
                                    marginLeft: "20px",
                                    fontSize: "18px",
                                  }}
                                >
                                  I do not use it quite often
                                </span>
                              </li>

                              {/* <li>
                                <input
                                  type="checkbox"
                                  onChange={() =>
                                    handleCheckboxChange(
                                      "Too many notifications"
                                    )
                                  }
                                  checked={
                                    selectedReason === "Too many notifications"
                                  }
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    marginTop: "10px",
                                  }}
                                />
                                <span
                                  style={{
                                    marginLeft: "20px",
                                    fontSize: "18px",
                                  }}
                                >
                                  Too many notifications
                                </span>
                              </li> */}

                              <li>
                                <input
                                  type="checkbox"
                                  onChange={() =>
                                    handleCheckboxChange(
                                      "I found a better platform"
                                    )
                                  }
                                  checked={
                                    selectedReason ===
                                    "I found a better platform"
                                  }
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    marginTop: "10px",
                                  }}
                                />
                                <span
                                  style={{
                                    marginLeft: "20px",
                                    fontSize: "18px",
                                  }}
                                >
                                  I found a better platform
                                </span>
                              </li>

                              <li>
                                <input
                                  type="checkbox"
                                  onChange={() =>
                                    handleCheckboxChange("Personal reasons")
                                  }
                                  checked={
                                    selectedReason === "Personal reasons"
                                  }
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    marginTop: "10px",
                                  }}
                                />
                                <span
                                  style={{
                                    marginLeft: "20px",
                                    fontSize: "18px",
                                  }}
                                >
                                  Personal reasons
                                </span>
                              </li>

                              <li>
                                <input
                                  type="checkbox"
                                  onChange={() => handleCheckboxChange("Other")}
                                  checked={selectedReason === "Other"}
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    marginTop: "10px",
                                  }}
                                />
                                <span
                                  style={{
                                    marginLeft: "20px",
                                    fontSize: "18px",
                                  }}
                                >
                                  Other
                                </span>
                              </li>

                              <input required
                                value={customDescription}
                                onChange={(e) =>
                                  setCustomDescription(e.target.value)
                                }
                                style={{ marginTop: "10px" }}
                                placeholder="Share Descriptions"
                                type="textarea"
                                className="form-control"
                              />
                              <button
                                onClick={deletebanner}
                                className=" text-nowrap text-capitalize btn btn-danger mt-5 "
                              >
                                Proceed and delete
                              </button>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Deletaccount;

