import React from 'react'
import Header from '../Header'

import '../sidebar.css'
import { Link } from 'react-router-dom'
import Sidebarr from '../Sidebar'

const Filterproduct = () => {
  return (
    <div className='container'>

    {/* <Header/> */}
    <div className='container row' style={{ paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px" }}>
    <div className="col-lg-2 col-md-3" style={{ paddingLeft: "0px" }}>
    {/* <Sidebarr/> */}
    </div>

    <div className="col-lg-10 col-md-9" style={{ paddingLeft: "30px", marginTop: "60px" }}>
   <br/>
   <br/>
    <div className="content container-fluid">
        <div className="mb-4">
          <h2 className="h1 mb-0 text-capitalize d-flex align-items-center gap-2">
            <img width={20} src="https://6valley.6amtech.com/public/assets/back-end/img/products.png" alt="" />
            Products
            <span className="badge badge-soft-dark radius-50 fz-14 ml-1">1</span>
          </h2>
        </div>
        <div className="card">
          <div className="card-body">
            <form action="https://6valley.6amtech.com/seller/product/list" method="GET">
              <input type="hidden" defaultValue name="status" />
              <div className="row gx-2">
                <div className="col-12">
                  <h4 className="mb-3">Filter Products</h4>
                </div>
                <div className="col-sm-6 col-lg-4 col-xl-3">
                  <div className="form-group">
                    <label className="title-color" htmlFor="store">Brand</label>
                    <select name="brand_id" className="js-select2-custom form-control text-capitalize select2-hidden-accessible" data-select2-id={1} tabIndex={-1} aria-hidden="true">
                      <option value selected data-select2-id={3}>All Brands</option>
                      <option value={1} selected data-select2-id={4}>i Bird</option>
                      <option value={4} data-select2-id={5}>Agron</option>
                      <option value={5} data-select2-id={6}>Triangle</option>
                      <option value={6} data-select2-id={7}>Estro</option>
                      <option value={7} data-select2-id={8}>Ohoenix</option>
                      <option value={8} data-select2-id={9}>Waltro</option>
                      <option value={9} data-select2-id={10}>JK</option>
                      <option value={12} data-select2-id={11}>Fashion</option>
                      <option value={13} data-select2-id={12}>S.Cube</option>
                      <option value={14} data-select2-id={13}>Estha dot</option>
                      <option value={17} data-select2-id={14}>Digital Product</option>
                    </select><span className="select2 select2-container select2-container--default" dir="ltr" data-select2-id={2} style={{width: '100%'}}><span className="selection"><span className="select2-selection custom-select" role="combobox" aria-haspopup="true" aria-expanded="false" tabIndex={0} aria-disabled="false" aria-labelledby="select2-brand_id-50-container"><span className="select2-selection__rendered" id="select2-brand_id-50-container" role="textbox" aria-readonly="true" title="i Bird"><span>i Bird</span></span><span className="select2-selection__arrow" role="presentation"><b role="presentation" /></span></span></span><span className="dropdown-wrapper" aria-hidden="true" /></span>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4 col-xl-3">
                  <div className="form-group">
                    <label htmlFor="name" className="title-color">Category</label>
                    <select className="js-select2-custom form-control select2-hidden-accessible" name="category_id" onchange="getRequest('https://6valley.6amtech.com/seller/product/get-categories?parent_id='+this.value,'sub-category-select','select')" data-select2-id={15} tabIndex={-1} aria-hidden="true">
                      <option value selected disabled data-select2-id={17}>Select category</option>
                      <option value={11} selected data-select2-id={18}>
                        Home Improvement &amp; Tools
                      </option>
                      <option value={12} data-select2-id={19}>
                        Toys , Kids &amp; Babies
                      </option>
                      <option value={13} data-select2-id={20}>
                        Men's fashion
                      </option>
                      <option value={14} data-select2-id={21}>
                        Outdoor Fun &amp; Sports
                      </option>
                      <option value={15} data-select2-id={22}>
                        Women's fashion
                      </option>
                      <option value={16} data-select2-id={23}>
                        ebook
                      </option>
                      <option value={24} data-select2-id={24}>
                        Jewelry &amp; Watches
                      </option>
                      <option value={25} data-select2-id={25}>
                        Beauty, Health &amp; Hair
                      </option>
                      <option value={26} data-select2-id={26}>
                        Mobile Accessories
                      </option>
                      <option value={27} data-select2-id={27}>
                        Computer, Office &amp; Security
                      </option>
                      <option value={28} data-select2-id={28}>
                        Phones &amp; Telecom
                      </option>
                      <option value={34} data-select2-id={29}>
                        Home, Pet &amp; Appliances
                      </option>
                      <option value={39} data-select2-id={30}>
                        Bags &amp; Shoes
                      </option>
                    </select><span className="select2 select2-container select2-container--default" dir="ltr" data-select2-id={16} style={{width: '100%'}}><span className="selection"><span className="select2-selection custom-select" role="combobox" aria-haspopup="true" aria-expanded="false" tabIndex={0} aria-disabled="false" aria-labelledby="select2-category_id-n0-container"><span className="select2-selection__rendered" id="select2-category_id-n0-container" role="textbox" aria-readonly="true" title="
Home Improvement & Tools
"><span>
                              Home Improvement &amp; Tools
                            </span></span><span className="select2-selection__arrow" role="presentation"><b role="presentation" /></span></span></span><span className="dropdown-wrapper" aria-hidden="true" /></span>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4 col-xl-3">
                  <div className="form-group">
                    <label htmlFor="name" className="title-color">Sub Category</label>
                    <select className="js-select2-custom form-control select2-hidden-accessible" name="sub_category_id" id="sub-category-select" onchange="getRequest('https://6valley.6amtech.com/seller/product/get-categories?parent_id='+this.value,'sub-sub-category-select','select')" data-select2-id="sub-category-select" tabIndex={-1} aria-hidden="true">
                      <option value selected disabled data-select2-id={32}>Select Sub Category</option>
                    </select><span className="select2 select2-container select2-container--default" dir="ltr" data-select2-id={31} style={{width: '100%'}}><span className="selection"><span className="select2-selection custom-select" role="combobox" aria-haspopup="true" aria-expanded="false" tabIndex={0} aria-disabled="false" aria-labelledby="select2-sub-category-select-container"><span className="select2-selection__rendered" id="select2-sub-category-select-container" role="textbox" aria-readonly="true" title="Select Sub Category"><span>Select Sub Category</span></span><span className="select2-selection__arrow" role="presentation"><b role="presentation" /></span></span></span><span className="dropdown-wrapper" aria-hidden="true" /></span>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4 col-xl-3">
                  <div className="form-group">
                    <label htmlFor="name" className="title-color">Sub Sub Category</label>
                    <select className="js-select2-custom form-control select2-hidden-accessible" name="sub_sub_category_id" id="sub-sub-category-select" data-select2-id="sub-sub-category-select" tabIndex={-1} aria-hidden="true">
                      <option value selected disabled data-select2-id={34}>Select Sub Sub Category</option>
                    </select><span className="select2 select2-container select2-container--default" dir="ltr" data-select2-id={33} style={{width: '100%'}}><span className="selection"><span className="select2-selection custom-select" role="combobox" aria-haspopup="true" aria-expanded="false" tabIndex={0} aria-disabled="false" aria-labelledby="select2-sub-sub-category-select-container"><span className="select2-selection__rendered" id="select2-sub-sub-category-select-container" role="textbox" aria-readonly="true" title="Select Sub Sub Category"><span>Select Sub Sub Category</span></span><span className="select2-selection__arrow" role="presentation"><b role="presentation" /></span></span></span><span className="dropdown-wrapper" aria-hidden="true" /></span>
                  </div>
                </div>
                <div className="col-12">
                  <div className="d-flex gap-3 justify-content-end">
                    <a href="https://6valley.6amtech.com/seller/product/list" className="btn btn-secondary px-5">
                      Reset
                    </a>
                    <Link to='#'  className="btn btn--primary px-5" >
                      Show data
                    </Link>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="row mt-20">
          <div className="col-md-12">
            <div className="card">
              <div className="px-3 py-4">
                <div className="row align-items-center">
                  <div className="col-lg-4">
                    <form >
                      <div className="input-group input-group-merge input-group-custom">
                        <div className="input-group-prepend">
                          <div className="input-group-text">
                          <i class="fa fa-search" aria-hidden="true"></i>
                          </div>
                        </div>
                        <input id="datatableSearch_" type="search" name="search" className="form-control" placeholder="Search by Product Name" aria-label="Search orders" defaultValue required />
                        <button type="submit" className="btn btn--primary">Search</button>
                      </div>
                    </form>
                  </div>
                  {/* <div className="col-lg-8 mt-3 mt-lg-0 d-flex flex-wrap gap-3 justify-content-lg-end">
                    <div>
                      <button type="button" className="btn btn-outline--primary" data-toggle="dropdown">
                        <i className="tio-download-to" />
                        Export
                        <i className="tio-chevron-down" />
                      </button>
                      <ul className="dropdown-menu dropdown-menu-right">
                        <li>
                          <a className="dropdown-item" href="https://6valley.6amtech.com/seller/product/bulk-export?brand_id=1&category_id=11">
                            <img width={14} src="https://6valley.6amtech.com/public/assets/back-end/img/excel.png" alt="" />
                            Excel
                          </a>
                        </li>
                      </ul>
                    </div>
                    <a href="https://6valley.6amtech.com/seller/product/stock-limit-list/in_house" className="btn btn-info">
                      <i className="tio-add-circle" />
                      <span className="text">Limited Stocks</span>
                    </a>
                    <a href="https://6valley.6amtech.com/seller/product/add-new" className="btn btn--primary">
                      <i className="tio-add" />
                      <span className="text">Add new product</span>
                    </a>
                  </div> */}
                </div>
              </div>
              <div className="table-responsive">
                <table id="datatable" style={{textAlign: 'left'}} className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100">
                  <thead className="thead-light thead-50 text-capitalize">
                    <tr>
                      <th>SL</th>
                      <th>Product Name</th>
                      <th>Product Type</th>
                      <th>Purchase price</th>
                      <th>Selling price</th>
                      <th>Verify status</th>
                      <th>Active Status</th>
                      <th className="text-center __w-5px">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>
                        <Link to='/productdetails' className="media align-items-center gap-2 w-max-content">
                          <img src="https://6valley.6amtech.com/public/assets/back-end/img/brand-logo.png" onerror="this.src='https://6valley.6amtech.com/public/assets/back-end/img/brand-logo.png'" className="avatar border" alt="" />
                          <span className="media-body title-color hover-c1">
                            test seller product 2
                          </span>
                        </Link>
                      </td>
                      <td>Physical</td>
                      <td>
                        $400.00
                      </td>
                      <td>
                        $600.00
                      </td>
                      <td>
                        <label className="badge badge-soft-success">Approved</label>
                      </td>
                      <td>
                        <form action="https://6valley.6amtech.com/seller/product/status-update" method="post" id="product_status10_form" className="product_status_form">
                          <input type="hidden" name="_token" defaultValue="YFPrIbFYlUsgcs6Ht4EEyCysWGBq8lmpLaBUW0q6" /> <input type="hidden" name="id" defaultValue={10} />
                          <label className="switcher mx-auto">
                            <input type="checkbox" className="switcher_input" id="product_status10" name="status" defaultValue={1} onclick="toogleStatusModal(event,'product_status10',
                                                'product-status-on.png','product-status-off.png',
                                                'Want to Turn ON test seller product 2 Status',
                                                'Want to Turn OFF test seller product 2 Status',
                                                `<p>If enabled this product will be available on the website and customer app</p>`,
                                                `<p>If disabled this product will be hidden from the website and customer app</p>`)" />
                            <span className="switcher_control" />
                          </label>
                        </form>
                      </td>
                      <td>
                        <div className="d-flex gap-10">
                          <Link className="btn btn-outline-info btn-sm square-btn" title="Barcode" to="/barcode">
                          <i class="fa fa-barcode" aria-hidden="true"></i>
                          </Link>
                          <Link className="btn btn-outline-info btn-sm square-btn" title="View" to="/productdetails">
                          <i className="fa fa-eye" aria-hidden="true" />
                          </Link>
                          <Link className="btn btn-outline-primary btn-sm square-btn" title="Edit" to="/productedit">
                          <i class="fa fa-pencil" aria-hidden="true"></i>
                          </Link>
                          <a className="btn btn-outline-danger btn-sm square-btn" href="javascript:" title="Delete" onclick="form_alert('product-10','Want to delete this item ?')">
                          <i className="fa fa-trash-o" aria-hidden="true" />
                          </a>
                        </div>
                        <form action="https://6valley.6amtech.com/seller/product/delete/10" method="post" id="product-10">
                          <input type="hidden" name="_token" defaultValue="YFPrIbFYlUsgcs6Ht4EEyCysWGBq8lmpLaBUW0q6" /> <input type="hidden" name="_method" defaultValue="delete" /> </form>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="table-responsive mt-4">
                <div className="px-4 d-flex justify-content-lg-end">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    </div>



    </div>
  )
}

export default Filterproduct



