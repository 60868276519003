import React, { useEffect, useState } from 'react'
import Header from '../Header'

import '../sidebar.css'
import { Link, useNavigate } from 'react-router-dom'
import Sidebarr from '../Sidebar'
import axios from 'axios'
import secureLocalStorage from 'react-secure-storage'
import toast, { Toaster } from 'react-hot-toast'

const Promotionsupdate = () => {
  



  const [cupon_type, setcupon_type] = useState();
  const [cupon_title, setcupon_title] = useState();
  const [customer_type, setcustomer_type] = useState();
  const [expire_date, setexpire_date] = useState();
  const [start_date, setstart_date] = useState();
  const [discountPercantage, setdiscountPercantage] = useState();
  const [discountAmount, setdiscountAmount] = useState();
  const [sameUser_discountLimit, setsameUser_discountLimit] = useState();
  const [minimum_purchase, setminimum_purchase] = useState();
  const [cupon_code, setcupon_code] = useState();

  const [Detils, setDetils] = useState([]);
  
    const Navigate = useNavigate();

  let promotion_idd = secureLocalStorage.getItem("promotion_id");
  console.log("promotion_idd",promotion_idd)
  let venderIdd = secureLocalStorage.getItem("vendorid");
  let token = secureLocalStorage.getItem("adminidtoken");
  useEffect(() => {
    coupanDetails();
  }, [0]);
  const coupanDetails = () => {
    const Data = {
      promotionId: promotion_idd,
    };

    let options = {
      hraders: {
        token: token,
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/promotinDetails`,
        Data,
        options
      )
      .then((res) => {

        setDetils(res.data.data);
      })
      .catch((error) => {});
  };
   

  const Updatepromotions = (e) => {
    e.preventDefault();

    const formData = {
      venderId: venderIdd,
      promotionId:promotion_idd,
      cupon_type: cupon_type ,
      cupon_code: cupon_code ,
      cupon_title: cupon_title ,
      customer_type: customer_type ,
      expire_date: expire_date ,
      start_date: start_date ,
      discountPercantage: discountPercantage ,
      discountAmount: discountAmount ,
      sameUser_discountLimit: sameUser_discountLimit ,
      minimum_purchase: minimum_purchase ,
    };

 axios
      .post(`${process.env.REACT_APP_API_KEY}vender/api/promotinUpdate`,formData)
      .then((res) => {
        setTimeout(()=>{
          Navigate("/promotions")
        },3000)
        toast.success(res.data.message);
      
      })
      .catch((error) => {});
    
      
  };
  return (
    <div className='container'>
<Toaster/>
      {/* <Header /> */}
      <div className='container row' style={{ paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px" }}>
        <div className="col-lg-2 col-md-3" style={{ paddingLeft: "0px" }}>
          {/* <Sidebarr /> */}
        </div>

        <div className="col-lg-10 col-md-9" style={{ paddingLeft: "30px", marginTop: "60px" }}>
          <br />
          <br />

          <div className="mt-3">
            <div className="mb-3">
              <h2 className="h1 mb-0 text-capitalize">
                <img src="https://6valley.6amtech.com/public/assets/back-end/img/coupon_setup.png" className="mb-1 mr-1" alt="" />
                Coupon update
              </h2>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                  <form
                      onSubmit={Updatepromotions}
                    >
                      <input
                        type="hidden"
                        name="_token"
                        defaultValue="YFPrIbFYlUsgcs6Ht4EEyCysWGBq8lmpLaBUW0q6"
                      />
                      <div className="row">
                        <div className="col-md-6 col-lg-4 form-group">
                          <label
                            htmlFor="name"
                            className="title-color font-weight-medium d-flex"
                          >
                            Coupon type
                          </label>
                          <select onChange={((e)=>{
                            setcupon_type(e.target.value)
                          })}
                            className="form-control"
                            
                            name="coupon_type"
                            
                          >
                            <option disabled selected value="">
                              {Detils[0]?.cupon_type}
                            </option>
                            <option value="Discount on Purchase">
                              Discount on Purchase
                            </option>
                            <option value="Free Delivery">Free Delivery</option>
                          </select>
                        </div>
                        <div className="col-md-6 col-lg-4 form-group">
                          <label
                            htmlFor="name"
                            className="title-color font-weight-medium d-flex"
                          >
                            Coupon title
                          </label>
                          <input value={cupon_title}
                          onChange={((e)=>{
                            setcupon_title(e.target.value)
                          })}
                            type="text"
                            
                            className="form-control"
                           
                            
                            placeholder={Detils[0]?.cupon_title}
                            
                          />
                        </div>
                        <div className="col-md-6 col-lg-4 form-group">
                          <div className="d-flex justify-content-between">
                            <label
                              htmlFor="name"
                              className="title-color font-weight-medium text-capitalize"
                            >
                              Coupon code
                            </label>
                           
                          </div>
                          <input
                            type="text"
                           value={cupon_code}
                            onChange={((e)=>{
                              setcupon_code(e.target.value)
                            })}
                            className="form-control"
                            
                            placeholder={Detils[0]?.cupon_code}
                            
                          />
                        </div>
                        <div className="col-md-6 col-lg-4 form-group coupon_type">
                          
                          
                          <label
                            htmlFor="name"
                            className="title-color font-weight-medium d-flex"
                          >
                            Customer
                          </label>
                          <select onChange={((e)=>{
                            setcustomer_type(e.target.value)
                          })}
                            className="form-control"
                            
                            name="coupon_type"
                            
                          >
                            <option disabled selected value="">
                            {Detils[0]?.customer_type}
                            </option>
                            <option value="Discount on Purchase">
                              Discount on Purchase
                            </option>
                            <option value="Free Delivery">Free Delivery</option>
                          </select>
                        </div>
                        <div className="col-md-6 col-lg-4 form-group">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="title-color font-weight-medium d-flex"
                          >
                            Limit for same user
                          </label>
                          <input
                            type="number"
                            
                            min={0}
                            value={sameUser_discountLimit} onChange={((e)=>{
                              setsameUser_discountLimit(e.target.value)
                            })}
                            className="form-control"
                            placeholder= {Detils[0]?.sameUser_discountLimit}
                          />
                        </div>
                        <div className="col-md-6 col-lg-4 form-group free_delivery">
                          <label
                            htmlFor="name"
                            className="title-color font-weight-medium d-flex"
                          >
                            Discount Percentage
                          </label>
                          <input  type="text"
                            placeholder={Detils[0]?.discountPercantage}
                            className="form-control w-100"
                            name="discount_type"
                            value={discountPercantage} onChange={((e)=>{
                              setdiscountPercantage(e.target.value)
                            })}
                          >
                            
                          </input>
                        </div>
                        <div className="col-md-6 col-lg-4 form-group free_delivery">
                          <label
                            htmlFor="name"
                            className="title-color font-weight-medium d-flex"
                          >
                            Discount Amount
                            <span
                              
                              style={{ display: "none" }}
                            >
                              {" "}
                              (%)
                            </span>
                          </label>
                          <input
                            type="number"
                            min={1}
                            max={1000000}
                            name="discount"
                           
                            className="form-control"
                           value={discountAmount} onChange={((e)=>{
                            setdiscountAmount(e.target.value)
                           })}
                            placeholder={Detils[0]?.discountAmount}
                        />
                        </div>
                        <div className="col-md-6 col-lg-4 form-group">
                          <label
                            htmlFor="name"
                            className="title-color font-weight-medium d-flex"
                          >
                            Minimum purchase ($)
                          </label>
                          <input 
                            type="number"
                            min={1}
                            max={1000000}
                            name="min_purchase"
                          
                            className="form-control"
                            
                            value={minimum_purchase} onChange={((e)=>{
                              setminimum_purchase(e.target.value)
                             })}
                            placeholder={Detils[0]?.minimum_purchase}
                          />
                        </div>
                       
                        <div className="col-md-6 col-lg-4 form-group">
                          <label
                            htmlFor="name"
                            className="title-color font-weight-medium d-flex"
                          >
                            Start date
                          </label>
                          <input
                            
                            type="date"
                            name="start_date"
                            value={start_date}
                            onChange={((e)=>{
                              setstart_date(e.target.value)
                             })}
                            className="form-control"
                            placeholder={Detils[0]?.start_date}
                            
                            min="2023-12-23"
                          />
                        </div>
                        <div className="col-md-6 col-lg-4 form-group">
                          <label
                            htmlFor="name"
                            className="title-color font-weight-medium d-flex"
                          >
                            Expire date
                          </label>
                          <input
                          value={expire_date}
                            type="date"
                            name="expire_date"
                           onChange={((e)=>{
                            setexpire_date(e.target.value)
                           })}
                            className="form-control"
                            placeholder={Detils[0]?.expire_date}
                            
                            min="2023-12-23"
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-end flex-wrap gap-10">
                        {/* <button type="reset" className="btn btn-secondary px-4">
                          Reset
                        </button> */}
                        <button type="submit" className="btn btn--primary px-4">
                          Update
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>



    </div>
  )
}

export default Promotionsupdate



