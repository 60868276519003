import React, { useEffect, useState } from "react";
import Header from "../Header";

import "../sidebar.css";
import { Link } from "react-router-dom";
import Sidebarr from "../Sidebar";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";

const Products_list = () => {
  const [productlist, setproductlist] = useState();

  let token = secureLocalStorage.getItem("vendortoken");
  let venderIdd = secureLocalStorage.getItem("vendorid");

  useEffect(() => {
    getproductlist();
  }, [0]);
  let getproductlist = () => {
    let data = {
      venderId: venderIdd,
    };

    const options = {
      headers: {
        token: token,
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/productList`,
        data,
        options
      )
      .then((res) => {
        setproductlist(res.data.data);
      })
      .catch((error) => {});
  };

  return (
    <div className="container">
      {/* <Header/> */}
      <div
        className="container row"
        style={{ paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px" }}
      >
        <div className="col-lg-2 col-md-3" style={{ paddingLeft: "0px" }}>
          {/* <Sidebarr/> */}
        </div>

        <div
          className="col-lg-10 col-md-9"
          style={{ paddingLeft: "30px", marginTop: "60px" }}
        >
          <div div className="mb-4">
            <h2 className="h1 mb-0 text-capitalize d-flex align-items-center gap-2">
              <img
                width={20}
                src="https://6valley.6amtech.com/public/assets/back-end/img/products.png"
                alt
              />
              Productsa
              <span className="badge badge-soft-dark radius-50 fz-14 ml-1">
                11
              </span>
            </h2>
          </div>
          <div className="card">
            <div className="card-body">
              <form
                action="https://6valley.6amtech.com/seller/product/list"
                method="GET"
              >
                <input type="hidden" defaultValue name="status" />
                <div className="row gx-2">
                  <div className="col-12">
                    <h4 className="mb-3">Filter Products</h4>
                  </div>
                  <div className="col-sm-6 col-lg-4 col-xl-3">
                    <div className="form-group">
                      <label className="title-color" htmlFor="store">
                        Brand
                      </label>
                      <select
                        name="brand_id"
                        className="js-select2-custom form-control text-capitalize"
                      >
                        <option value selected>
                          All Brands
                        </option>
                        <option value={1}>i Bird</option>
                        <option value={4}>Agron</option>
                        <option value={5}>Triangle</option>
                        <option value={6}>Estro</option>
                        <option value={7}>Ohoenix</option>
                        <option value={8}>Waltro</option>
                        <option value={9}>JK</option>
                        <option value={12}>Fashion</option>
                        <option value={13}>S.Cube</option>
                        <option value={14}>Estha dot</option>
                        <option value={17}>Digital Product</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-4 col-xl-3">
                    <div className="form-group">
                      <label htmlFor="name" className="title-color">
                        Category
                      </label>
                      <select
                        className="js-select2-custom form-control"
                        name="category_id"
                        onchange="getRequest('https://6valley.6amtech.com/seller/product/get-categories?parent_id='+this.value,'sub-category-select','select')"
                      >
                        <option value selected disabled>
                          Select category
                        </option>
                        <option value={11}>Home Improvement &amp; Tools</option>
                        <option value={12}>Toys , Kids &amp; Babies</option>
                        <option value={13}>Men's fashion</option>
                        <option value={14}>Outdoor Fun &amp; Sports</option>
                        <option value={15}>Women's fashion</option>
                        <option value={16}>ebook</option>
                        <option value={24}>Jewelry &amp; Watches</option>
                        <option value={25}>Beauty, Health &amp; Hair</option>
                        <option value={26}>Mobile Accessories</option>
                        <option value={27}>
                          Computer, Office &amp; Security
                        </option>
                        <option value={28}>Phones &amp; Telecom</option>
                        <option value={34}>Home, Pet &amp; Appliances</option>
                        <option value={39}>Bags &amp; Shoes</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-4 col-xl-3">
                    <div className="form-group">
                      <label htmlFor="name" className="title-color">
                        Sub Category
                      </label>
                      <select
                        className="js-select2-custom form-control"
                        name="sub_category_id"
                        id="sub-category-select"
                        onchange="getRequest('https://6valley.6amtech.com/seller/product/get-categories?parent_id='+this.value,'sub-sub-category-select','select')"
                      >
                        <option value selected disabled>
                          Select Sub Category
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-4 col-xl-3">
                    <div className="form-group">
                      <label htmlFor="name" className="title-color">
                        Sub Sub Category
                      </label>
                      <select
                        className="js-select2-custom form-control"
                        name="sub_sub_category_id"
                        id="sub-sub-category-select"
                      >
                        <option value selected disabled>
                          Select Sub Sub Category
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="d-flex gap-3 justify-content-end">
                      <a
                        href="https://6valley.6amtech.com/seller/product/list"
                        className="btn btn-secondary px-5"
                      >
                        Reset
                      </a>
                      <button
                        type="submit"
                        className="btn btn--primary px-5"
                        onclick="formUrlChange(this)"
                        data-action="https://6valley.6amtech.com/seller/product/list"
                      >
                        Show data
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="row mt-20">
            <div className="col-md-12">
              <div className="card">
                <div className="px-3 py-4">
                  <div className="row align-items-center">
                    <div className="col-lg-4">
                      <form
                        action="https://6valley.6amtech.com/seller/product/list"
                        method="GET"
                      >
                        <div className="input-group input-group-merge input-group-custom">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="tio-search" />
                            </div>
                          </div>
                          <input
                            id="datatableSearch_"
                            type="search"
                            name="search"
                            className="form-control"
                            placeholder="Search by Product Name"
                            aria-label="Search orders"
                            defaultValue
                            required
                          />
                          <button type="submit" className="btn btn--primary">
                            Search
                          </button>
                        </div>
                      </form>
                    </div>
                    <div className="col-lg-8 mt-3 mt-lg-0 d-flex flex-wrap gap-3 justify-content-lg-end">
                      <div>
                        <button
                          type="button"
                          className="btn btn-outline--primary"
                          data-toggle="dropdown"
                        >
                          <i className="tio-download-to" />
                          Export
                          <i className="tio-chevron-down" />
                        </button>
                        <ul className="dropdown-menu dropdown-menu-right">
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://6valley.6amtech.com/seller/product/bulk-export"
                            >
                              <img
                                width={14}
                                src="https://6valley.6amtech.com/public/assets/back-end/img/excel.png"
                                alt
                              />
                              Excel
                            </a>
                          </li>
                        </ul>
                      </div>
                      <a
                        href="https://6valley.6amtech.com/seller/product/stock-limit-list/in_house"
                        className="btn btn-info"
                      >
                        <i className="tio-add-circle" />
                        <span className="text">Limited Stocks</span>
                      </a>
                      <Link to="/addnewproducts" className="btn btn--primary">
                        <i className="tio-add" />
                        <span className="text">Add new product</span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <table
                    id="datatable"
                    style={{ textAlign: "left" }}
                    className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100"
                  >
                    <thead className="thead-light thead-50 text-capitalize">
                      <tr>
                        <th>SL</th>
                        <th>Product Name</th>
                        <th>Product Type</th>
                        <th>Purchase price</th>
                        <th>Selling price</th>
                        <th>Verify status</th>
                        <th>Active Status</th>
                        <th className="text-center __w-5px">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">1</th>
                        <td>
                          <a
                            href="https://6valley.6amtech.com/seller/product/view/54"
                            className="media align-items-center gap-2 w-max-content"
                          >
                            <img
                              src="https://6valley.6amtech.com/storage/app/public/product/thumbnail/2023-06-13-64883db39dcbb.png"
                              onerror="this.src='https://6valley.6amtech.com/public/assets/back-end/img/brand-logo.png'"
                              className="avatar border"
                              alt
                            />
                            <span className="media-body title-color hover-c1">
                              Secret Covered in Sand Comic b...
                            </span>
                          </a>
                        </td>
                        <td>Digital</td>
                        <td>$450.00</td>
                        <td>$500.00</td>
                        <td>
                          <label className="badge badge-soft-success">
                            Approved
                          </label>
                        </td>
                        <td>
                          <form
                            action="https://6valley.6amtech.com/seller/product/status-update"
                            method="post"
                            id="product_status54_form"
                            className="product_status_form"
                          >
                            <input
                              type="hidden"
                              name="_token"
                              defaultValue="ogOCpmdAJJ38pWdY4o8txAvCPH58PO03n5rkZpRx"
                            />{" "}
                            <input type="hidden" name="id" defaultValue={54} />
                            <label className="switcher mx-auto">
                              <input
                                type="checkbox"
                                className="switcher_input"
                                id="product_status54"
                                name="status"
                                defaultValue={1}
                                defaultChecked
                                onclick="toogleStatusModal(event,'product_status54',
                                              'product-status-on.png','product-status-off.png',
                                              'Want to Turn ON Secret Covered in Sand Comic book pdf Status',
                                              'Want to Turn OFF Secret Covered in Sand Comic book pdf Status',
                                              `<p>If enabled this product will be available on the website and customer app</p>`,
                                              `<p>If disabled this product will be hidden from the website and customer app</p>`)"
                              />
                              <span className="switcher_control" />
                            </label>
                          </form>
                        </td>
                        <td>
                          <div className="d-flex gap-10">
                            <a
                              className="btn btn-outline-info btn-sm square-btn"
                              title="Barcode"
                              href="https://6valley.6amtech.com/seller/product/barcode/54"
                            >
                              <i className="tio-barcode" />
                            </a>
                            <a
                              className="btn btn-outline-info btn-sm square-btn"
                              title="View"
                              href="https://6valley.6amtech.com/seller/product/view/54"
                            >
                              <i className="tio-invisible" />
                            </a>
                            <a
                              className="btn btn-outline-primary btn-sm square-btn"
                              title="Edit"
                              href="https://6valley.6amtech.com/seller/product/edit/54"
                            >
                              <i className="tio-edit" />
                            </a>
                            <a
                              className="btn btn-outline-danger btn-sm square-btn"
                              href="javascript:"
                              title="Delete"
                              onclick="form_alert('product-54','Want to delete this item ?')"
                            >
                              <i className="tio-delete" />
                            </a>
                          </div>
                          <form
                            action="https://6valley.6amtech.com/seller/product/delete/54"
                            method="post"
                            id="product-54"
                          >
                            <input
                              type="hidden"
                              name="_token"
                              defaultValue="ogOCpmdAJJ38pWdY4o8txAvCPH58PO03n5rkZpRx"
                            />{" "}
                            <input
                              type="hidden"
                              name="_method"
                              defaultValue="delete"
                            />{" "}
                          </form>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">2</th>
                        <td>
                          <a
                            href="https://6valley.6amtech.com/seller/product/view/53"
                            className="media align-items-center gap-2 w-max-content"
                          >
                            <img
                              src="https://6valley.6amtech.com/storage/app/public/product/thumbnail/2023-06-13-64884db79d7af.png"
                              onerror="this.src='https://6valley.6amtech.com/public/assets/back-end/img/brand-logo.png'"
                              className="avatar border"
                              alt
                            />
                            <span className="media-body title-color hover-c1">
                              Klash Flash Melting Matte Wate...
                            </span>
                          </a>
                        </td>
                        <td>Physical</td>
                        <td>$500.00</td>
                        <td>$990.00</td>
                        <td>
                          <label className="badge badge-soft-success">
                            Approved
                          </label>
                        </td>
                        <td>
                          <form
                            action="https://6valley.6amtech.com/seller/product/status-update"
                            method="post"
                            id="product_status53_form"
                            className="product_status_form"
                          >
                            <input
                              type="hidden"
                              name="_token"
                              defaultValue="ogOCpmdAJJ38pWdY4o8txAvCPH58PO03n5rkZpRx"
                            />{" "}
                            <input type="hidden" name="id" defaultValue={53} />
                            <label className="switcher mx-auto">
                              <input
                                type="checkbox"
                                className="switcher_input"
                                id="product_status53"
                                name="status"
                                defaultValue={1}
                                defaultChecked
                                onclick="toogleStatusModal(event,'product_status53',
                                              'product-status-on.png','product-status-off.png',
                                              'Want to Turn ON Klash Flash Melting Matte Waterproof Lip Stick - P09 Status',
                                              'Want to Turn OFF Klash Flash Melting Matte Waterproof Lip Stick - P09 Status',
                                              `<p>If enabled this product will be available on the website and customer app</p>`,
                                              `<p>If disabled this product will be hidden from the website and customer app</p>`)"
                              />
                              <span className="switcher_control" />
                            </label>
                          </form>
                        </td>
                        <td>
                          <div className="d-flex gap-10">
                            <a
                              className="btn btn-outline-info btn-sm square-btn"
                              title="Barcode"
                              href="https://6valley.6amtech.com/seller/product/barcode/53"
                            >
                              <i className="tio-barcode" />
                            </a>
                            <a
                              className="btn btn-outline-info btn-sm square-btn"
                              title="View"
                              href="https://6valley.6amtech.com/seller/product/view/53"
                            >
                              <i className="tio-invisible" />
                            </a>
                            <a
                              className="btn btn-outline-primary btn-sm square-btn"
                              title="Edit"
                              href="https://6valley.6amtech.com/seller/product/edit/53"
                            >
                              <i className="tio-edit" />
                            </a>
                            <a
                              className="btn btn-outline-danger btn-sm square-btn"
                              href="javascript:"
                              title="Delete"
                              onclick="form_alert('product-53','Want to delete this item ?')"
                            >
                              <i className="tio-delete" />
                            </a>
                          </div>
                          <form
                            action="https://6valley.6amtech.com/seller/product/delete/53"
                            method="post"
                            id="product-53"
                          >
                            <input
                              type="hidden"
                              name="_token"
                              defaultValue="ogOCpmdAJJ38pWdY4o8txAvCPH58PO03n5rkZpRx"
                            />{" "}
                            <input
                              type="hidden"
                              name="_method"
                              defaultValue="delete"
                            />{" "}
                          </form>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">3</th>
                        <td>
                          <a
                            href="https://6valley.6amtech.com/seller/product/view/52"
                            className="media align-items-center gap-2 w-max-content"
                          >
                            <img
                              src="https://6valley.6amtech.com/storage/app/public/product/thumbnail/2023-06-13-648842cf52943.png"
                              onerror="this.src='https://6valley.6amtech.com/public/assets/back-end/img/brand-logo.png'"
                              className="avatar border"
                              alt
                            />
                            <span className="media-body title-color hover-c1">
                              Cooking Recipe Book
                            </span>
                          </a>
                        </td>
                        <td>Digital</td>
                        <td>$350.00</td>
                        <td>$400.00</td>
                        <td>
                          <label className="badge badge-soft-success">
                            Approved
                          </label>
                        </td>
                        <td>
                          <form
                            action="https://6valley.6amtech.com/seller/product/status-update"
                            method="post"
                            id="product_status52_form"
                            className="product_status_form"
                          >
                            <input
                              type="hidden"
                              name="_token"
                              defaultValue="ogOCpmdAJJ38pWdY4o8txAvCPH58PO03n5rkZpRx"
                            />{" "}
                            <input type="hidden" name="id" defaultValue={52} />
                            <label className="switcher mx-auto">
                              <input
                                type="checkbox"
                                className="switcher_input"
                                id="product_status52"
                                name="status"
                                defaultValue={1}
                                defaultChecked
                                onclick="toogleStatusModal(event,'product_status52',
                                              'product-status-on.png','product-status-off.png',
                                              'Want to Turn ON Cooking Recipe Book Status',
                                              'Want to Turn OFF Cooking Recipe Book Status',
                                              `<p>If enabled this product will be available on the website and customer app</p>`,
                                              `<p>If disabled this product will be hidden from the website and customer app</p>`)"
                              />
                              <span className="switcher_control" />
                            </label>
                          </form>
                        </td>
                        <td>
                          <div className="d-flex gap-10">
                            <a
                              className="btn btn-outline-info btn-sm square-btn"
                              title="Barcode"
                              href="https://6valley.6amtech.com/seller/product/barcode/52"
                            >
                              <i className="tio-barcode" />
                            </a>
                            <a
                              className="btn btn-outline-info btn-sm square-btn"
                              title="View"
                              href="https://6valley.6amtech.com/seller/product/view/52"
                            >
                              <i className="tio-invisible" />
                            </a>
                            <a
                              className="btn btn-outline-primary btn-sm square-btn"
                              title="Edit"
                              href="https://6valley.6amtech.com/seller/product/edit/52"
                            >
                              <i className="tio-edit" />
                            </a>
                            <a
                              className="btn btn-outline-danger btn-sm square-btn"
                              href="javascript:"
                              title="Delete"
                              onclick="form_alert('product-52','Want to delete this item ?')"
                            >
                              <i className="tio-delete" />
                            </a>
                          </div>
                          <form
                            action="https://6valley.6amtech.com/seller/product/delete/52"
                            method="post"
                            id="product-52"
                          >
                            <input
                              type="hidden"
                              name="_token"
                              defaultValue="ogOCpmdAJJ38pWdY4o8txAvCPH58PO03n5rkZpRx"
                            />{" "}
                            <input
                              type="hidden"
                              name="_method"
                              defaultValue="delete"
                            />{" "}
                          </form>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">4</th>
                        <td>
                          <a
                            href="https://6valley.6amtech.com/seller/product/view/51"
                            className="media align-items-center gap-2 w-max-content"
                          >
                            <img
                              src="https://6valley.6amtech.com/storage/app/public/product/thumbnail/2022-10-12-6346833cd4973.png"
                              onerror="this.src='https://6valley.6amtech.com/public/assets/back-end/img/brand-logo.png'"
                              className="avatar border"
                              alt
                            />
                            <span className="media-body title-color hover-c1">
                              Ladies Bag
                            </span>
                          </a>
                        </td>
                        <td>Physical</td>
                        <td>$430.00</td>
                        <td>$670.00</td>
                        <td>
                          <label className="badge badge-soft-success">
                            Approved
                          </label>
                        </td>
                        <td>
                          <form
                            action="https://6valley.6amtech.com/seller/product/status-update"
                            method="post"
                            id="product_status51_form"
                            className="product_status_form"
                          >
                            <input
                              type="hidden"
                              name="_token"
                              defaultValue="ogOCpmdAJJ38pWdY4o8txAvCPH58PO03n5rkZpRx"
                            />{" "}
                            <input type="hidden" name="id" defaultValue={51} />
                            <label className="switcher mx-auto">
                              <input
                                type="checkbox"
                                className="switcher_input"
                                id="product_status51"
                                name="status"
                                defaultValue={1}
                                defaultChecked
                                onclick="toogleStatusModal(event,'product_status51',
                                              'product-status-on.png','product-status-off.png',
                                              'Want to Turn ON Ladies Bag Status',
                                              'Want to Turn OFF Ladies Bag Status',
                                              `<p>If enabled this product will be available on the website and customer app</p>`,
                                              `<p>If disabled this product will be hidden from the website and customer app</p>`)"
                              />
                              <span className="switcher_control" />
                            </label>
                          </form>
                        </td>
                        <td>
                          <div className="d-flex gap-10">
                            <a
                              className="btn btn-outline-info btn-sm square-btn"
                              title="Barcode"
                              href="https://6valley.6amtech.com/seller/product/barcode/51"
                            >
                              <i className="tio-barcode" />
                            </a>
                            <a
                              className="btn btn-outline-info btn-sm square-btn"
                              title="View"
                              href="https://6valley.6amtech.com/seller/product/view/51"
                            >
                              <i className="tio-invisible" />
                            </a>
                            <a
                              className="btn btn-outline-primary btn-sm square-btn"
                              title="Edit"
                              href="https://6valley.6amtech.com/seller/product/edit/51"
                            >
                              <i className="tio-edit" />
                            </a>
                            <a
                              className="btn btn-outline-danger btn-sm square-btn"
                              href="javascript:"
                              title="Delete"
                              onclick="form_alert('product-51','Want to delete this item ?')"
                            >
                              <i className="tio-delete" />
                            </a>
                          </div>
                          <form
                            action="https://6valley.6amtech.com/seller/product/delete/51"
                            method="post"
                            id="product-51"
                          >
                            <input
                              type="hidden"
                              name="_token"
                              defaultValue="ogOCpmdAJJ38pWdY4o8txAvCPH58PO03n5rkZpRx"
                            />{" "}
                            <input
                              type="hidden"
                              name="_method"
                              defaultValue="delete"
                            />{" "}
                          </form>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">5</th>
                        <td>
                          <a
                            href="https://6valley.6amtech.com/seller/product/view/50"
                            className="media align-items-center gap-2 w-max-content"
                          >
                            <img
                              src="https://6valley.6amtech.com/storage/app/public/product/thumbnail/2023-06-13-648847880d064.png"
                              onerror="this.src='https://6valley.6amtech.com/public/assets/back-end/img/brand-logo.png'"
                              className="avatar border"
                              alt
                            />
                            <span className="media-body title-color hover-c1">
                              Super Portable Electric Iron -...
                            </span>
                          </a>
                        </td>
                        <td>Physical</td>
                        <td>$3,500.00</td>
                        <td>$5,000.00</td>
                        <td>
                          <label className="badge badge-soft-success">
                            Approved
                          </label>
                        </td>
                        <td>
                          <form
                            action="https://6valley.6amtech.com/seller/product/status-update"
                            method="post"
                            id="product_status50_form"
                            className="product_status_form"
                          >
                            <input
                              type="hidden"
                              name="_token"
                              defaultValue="ogOCpmdAJJ38pWdY4o8txAvCPH58PO03n5rkZpRx"
                            />{" "}
                            <input type="hidden" name="id" defaultValue={50} />
                            <label className="switcher mx-auto">
                              <input
                                type="checkbox"
                                className="switcher_input"
                                id="product_status50"
                                name="status"
                                defaultValue={1}
                                defaultChecked
                                onclick="toogleStatusModal(event,'product_status50',
                                              'product-status-on.png','product-status-off.png',
                                              'Want to Turn ON Super Portable Electric Iron - Multicolor -007 Status',
                                              'Want to Turn OFF Super Portable Electric Iron - Multicolor -007 Status',
                                              `<p>If enabled this product will be available on the website and customer app</p>`,
                                              `<p>If disabled this product will be hidden from the website and customer app</p>`)"
                              />
                              <span className="switcher_control" />
                            </label>
                          </form>
                        </td>
                        <td>
                          <div className="d-flex gap-10">
                            <a
                              className="btn btn-outline-info btn-sm square-btn"
                              title="Barcode"
                              href="https://6valley.6amtech.com/seller/product/barcode/50"
                            >
                              <i className="tio-barcode" />
                            </a>
                            <a
                              className="btn btn-outline-info btn-sm square-btn"
                              title="View"
                              href="https://6valley.6amtech.com/seller/product/view/50"
                            >
                              <i className="tio-invisible" />
                            </a>
                            <a
                              className="btn btn-outline-primary btn-sm square-btn"
                              title="Edit"
                              href="https://6valley.6amtech.com/seller/product/edit/50"
                            >
                              <i className="tio-edit" />
                            </a>
                            <a
                              className="btn btn-outline-danger btn-sm square-btn"
                              href="javascript:"
                              title="Delete"
                              onclick="form_alert('product-50','Want to delete this item ?')"
                            >
                              <i className="tio-delete" />
                            </a>
                          </div>
                          <form
                            action="https://6valley.6amtech.com/seller/product/delete/50"
                            method="post"
                            id="product-50"
                          >
                            <input
                              type="hidden"
                              name="_token"
                              defaultValue="ogOCpmdAJJ38pWdY4o8txAvCPH58PO03n5rkZpRx"
                            />{" "}
                            <input
                              type="hidden"
                              name="_method"
                              defaultValue="delete"
                            />{" "}
                          </form>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">6</th>
                        <td>
                          <a
                            href="https://6valley.6amtech.com/seller/product/view/49"
                            className="media align-items-center gap-2 w-max-content"
                          >
                            <img
                              src="https://6valley.6amtech.com/storage/app/public/product/thumbnail/2022-10-12-6346670e3427e.png"
                              onerror="this.src='https://6valley.6amtech.com/public/assets/back-end/img/brand-logo.png'"
                              className="avatar border"
                              alt
                            />
                            <span className="media-body title-color hover-c1">
                              women's shoes
                            </span>
                          </a>
                        </td>
                        <td>Physical</td>
                        <td>$4,000.00</td>
                        <td>$5,000.00</td>
                        <td>
                          <label className="badge badge-soft-success">
                            Approved
                          </label>
                        </td>
                        <td>
                          <form
                            action="https://6valley.6amtech.com/seller/product/status-update"
                            method="post"
                            id="product_status49_form"
                            className="product_status_form"
                          >
                            <input
                              type="hidden"
                              name="_token"
                              defaultValue="ogOCpmdAJJ38pWdY4o8txAvCPH58PO03n5rkZpRx"
                            />{" "}
                            <input type="hidden" name="id" defaultValue={49} />
                            <label className="switcher mx-auto">
                              <input
                                type="checkbox"
                                className="switcher_input"
                                id="product_status49"
                                name="status"
                                defaultValue={1}
                                defaultChecked
                                onclick="toogleStatusModal(event,'product_status49',
                                              'product-status-on.png','product-status-off.png',
                                              'Want to Turn ON women`s shoes Status',
                                              'Want to Turn OFF women`s shoes Status',
                                              `<p>If enabled this product will be available on the website and customer app</p>`,
                                              `<p>If disabled this product will be hidden from the website and customer app</p>`)"
                              />
                              <span className="switcher_control" />
                            </label>
                          </form>
                        </td>
                        <td>
                          <div className="d-flex gap-10">
                            <a
                              className="btn btn-outline-info btn-sm square-btn"
                              title="Barcode"
                              href="https://6valley.6amtech.com/seller/product/barcode/49"
                            >
                              <i className="tio-barcode" />
                            </a>
                            <a
                              className="btn btn-outline-info btn-sm square-btn"
                              title="View"
                              href="https://6valley.6amtech.com/seller/product/view/49"
                            >
                              <i className="tio-invisible" />
                            </a>
                            <a
                              className="btn btn-outline-primary btn-sm square-btn"
                              title="Edit"
                              href="https://6valley.6amtech.com/seller/product/edit/49"
                            >
                              <i className="tio-edit" />
                            </a>
                            <a
                              className="btn btn-outline-danger btn-sm square-btn"
                              href="javascript:"
                              title="Delete"
                              onclick="form_alert('product-49','Want to delete this item ?')"
                            >
                              <i className="tio-delete" />
                            </a>
                          </div>
                          <form
                            action="https://6valley.6amtech.com/seller/product/delete/49"
                            method="post"
                            id="product-49"
                          >
                            <input
                              type="hidden"
                              name="_token"
                              defaultValue="ogOCpmdAJJ38pWdY4o8txAvCPH58PO03n5rkZpRx"
                            />{" "}
                            <input
                              type="hidden"
                              name="_method"
                              defaultValue="delete"
                            />{" "}
                          </form>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">7</th>
                        <td>
                          <a
                            href="https://6valley.6amtech.com/seller/product/view/48"
                            className="media align-items-center gap-2 w-max-content"
                          >
                            <img
                              src="https://6valley.6amtech.com/storage/app/public/product/thumbnail/2023-06-13-6488421c1e361.png"
                              onerror="this.src='https://6valley.6amtech.com/public/assets/back-end/img/brand-logo.png'"
                              className="avatar border"
                              alt
                            />
                            <span className="media-body title-color hover-c1">
                              Secret Covered in Sand Comic b...
                            </span>
                          </a>
                        </td>
                        <td>Digital</td>
                        <td>$400.00</td>
                        <td>$600.00</td>
                        <td>
                          <label className="badge badge-soft-success">
                            Approved
                          </label>
                        </td>
                        <td>
                          <form
                            action="https://6valley.6amtech.com/seller/product/status-update"
                            method="post"
                            id="product_status48_form"
                            className="product_status_form"
                          >
                            <input
                              type="hidden"
                              name="_token"
                              defaultValue="ogOCpmdAJJ38pWdY4o8txAvCPH58PO03n5rkZpRx"
                            />{" "}
                            <input type="hidden" name="id" defaultValue={48} />
                            <label className="switcher mx-auto">
                              <input
                                type="checkbox"
                                className="switcher_input"
                                id="product_status48"
                                name="status"
                                defaultValue={1}
                                defaultChecked
                                onclick="toogleStatusModal(event,'product_status48',
                                              'product-status-on.png','product-status-off.png',
                                              'Want to Turn ON Secret Covered in Sand Comic book pdf Status',
                                              'Want to Turn OFF Secret Covered in Sand Comic book pdf Status',
                                              `<p>If enabled this product will be available on the website and customer app</p>`,
                                              `<p>If disabled this product will be hidden from the website and customer app</p>`)"
                              />
                              <span className="switcher_control" />
                            </label>
                          </form>
                        </td>
                        <td>
                          <div className="d-flex gap-10">
                            <a
                              className="btn btn-outline-info btn-sm square-btn"
                              title="Barcode"
                              href="https://6valley.6amtech.com/seller/product/barcode/48"
                            >
                              <i className="tio-barcode" />
                            </a>
                            <a
                              className="btn btn-outline-info btn-sm square-btn"
                              title="View"
                              href="https://6valley.6amtech.com/seller/product/view/48"
                            >
                              <i className="tio-invisible" />
                            </a>
                            <a
                              className="btn btn-outline-primary btn-sm square-btn"
                              title="Edit"
                              href="https://6valley.6amtech.com/seller/product/edit/48"
                            >
                              <i className="tio-edit" />
                            </a>
                            <a
                              className="btn btn-outline-danger btn-sm square-btn"
                              href="javascript:"
                              title="Delete"
                              onclick="form_alert('product-48','Want to delete this item ?')"
                            >
                              <i className="tio-delete" />
                            </a>
                          </div>
                          <form
                            action="https://6valley.6amtech.com/seller/product/delete/48"
                            method="post"
                            id="product-48"
                          >
                            <input
                              type="hidden"
                              name="_token"
                              defaultValue="ogOCpmdAJJ38pWdY4o8txAvCPH58PO03n5rkZpRx"
                            />{" "}
                            <input
                              type="hidden"
                              name="_method"
                              defaultValue="delete"
                            />{" "}
                          </form>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">8</th>
                        <td>
                          <a
                            href="https://6valley.6amtech.com/seller/product/view/47"
                            className="media align-items-center gap-2 w-max-content"
                          >
                            <img
                              src="https://6valley.6amtech.com/storage/app/public/product/thumbnail/2023-06-13-6488413bebea3.png"
                              onerror="this.src='https://6valley.6amtech.com/public/assets/back-end/img/brand-logo.png'"
                              className="avatar border"
                              alt
                            />
                            <span className="media-body title-color hover-c1">
                              Premium Business presentation...
                            </span>
                          </a>
                        </td>
                        <td>Digital</td>
                        <td>$5,000.00</td>
                        <td>$6,000.00</td>
                        <td>
                          <label className="badge badge-soft-success">
                            Approved
                          </label>
                        </td>
                        <td>
                          <form
                            action="https://6valley.6amtech.com/seller/product/status-update"
                            method="post"
                            id="product_status47_form"
                            className="product_status_form"
                          >
                            <input
                              type="hidden"
                              name="_token"
                              defaultValue="ogOCpmdAJJ38pWdY4o8txAvCPH58PO03n5rkZpRx"
                            />{" "}
                            <input type="hidden" name="id" defaultValue={47} />
                            <label className="switcher mx-auto">
                              <input
                                type="checkbox"
                                className="switcher_input"
                                id="product_status47"
                                name="status"
                                defaultValue={1}
                                defaultChecked
                                onclick="toogleStatusModal(event,'product_status47',
                                              'product-status-on.png','product-status-off.png',
                                              'Want to Turn ON Premium Business presentation file Status',
                                              'Want to Turn OFF Premium Business presentation file Status',
                                              `<p>If enabled this product will be available on the website and customer app</p>`,
                                              `<p>If disabled this product will be hidden from the website and customer app</p>`)"
                              />
                              <span className="switcher_control" />
                            </label>
                          </form>
                        </td>
                        <td>
                          <div className="d-flex gap-10">
                            <a
                              className="btn btn-outline-info btn-sm square-btn"
                              title="Barcode"
                              href="https://6valley.6amtech.com/seller/product/barcode/47"
                            >
                              <i className="tio-barcode" />
                            </a>
                            <a
                              className="btn btn-outline-info btn-sm square-btn"
                              title="View"
                              href="https://6valley.6amtech.com/seller/product/view/47"
                            >
                              <i className="tio-invisible" />
                            </a>
                            <a
                              className="btn btn-outline-primary btn-sm square-btn"
                              title="Edit"
                              href="https://6valley.6amtech.com/seller/product/edit/47"
                            >
                              <i className="tio-edit" />
                            </a>
                            <a
                              className="btn btn-outline-danger btn-sm square-btn"
                              href="javascript:"
                              title="Delete"
                              onclick="form_alert('product-47','Want to delete this item ?')"
                            >
                              <i className="tio-delete" />
                            </a>
                          </div>
                          <form
                            action="https://6valley.6amtech.com/seller/product/delete/47"
                            method="post"
                            id="product-47"
                          >
                            <input
                              type="hidden"
                              name="_token"
                              defaultValue="ogOCpmdAJJ38pWdY4o8txAvCPH58PO03n5rkZpRx"
                            />{" "}
                            <input
                              type="hidden"
                              name="_method"
                              defaultValue="delete"
                            />{" "}
                          </form>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">9</th>
                        <td>
                          <a
                            href="https://6valley.6amtech.com/seller/product/view/46"
                            className="media align-items-center gap-2 w-max-content"
                          >
                            <img
                              src="https://6valley.6amtech.com/storage/app/public/product/thumbnail/2023-06-13-648841a2ece12.png"
                              onerror="this.src='https://6valley.6amtech.com/public/assets/back-end/img/brand-logo.png'"
                              className="avatar border"
                              alt
                            />
                            <span className="media-body title-color hover-c1">
                              Secret Covered in Sand Comic b...
                            </span>
                          </a>
                        </td>
                        <td>Digital</td>
                        <td>$4,000.00</td>
                        <td>$5,000.00</td>
                        <td>
                          <label className="badge badge-soft-success">
                            Approved
                          </label>
                        </td>
                        <td>
                          <form
                            action="https://6valley.6amtech.com/seller/product/status-update"
                            method="post"
                            id="product_status46_form"
                            className="product_status_form"
                          >
                            <input
                              type="hidden"
                              name="_token"
                              defaultValue="ogOCpmdAJJ38pWdY4o8txAvCPH58PO03n5rkZpRx"
                            />{" "}
                            <input type="hidden" name="id" defaultValue={46} />
                            <label className="switcher mx-auto">
                              <input
                                type="checkbox"
                                className="switcher_input"
                                id="product_status46"
                                name="status"
                                defaultValue={1}
                                defaultChecked
                                onclick="toogleStatusModal(event,'product_status46',
                                              'product-status-on.png','product-status-off.png',
                                              'Want to Turn ON Secret Covered in Sand Comic book pdf version 1 Status',
                                              'Want to Turn OFF Secret Covered in Sand Comic book pdf version 1 Status',
                                              `<p>If enabled this product will be available on the website and customer app</p>`,
                                              `<p>If disabled this product will be hidden from the website and customer app</p>`)"
                              />
                              <span className="switcher_control" />
                            </label>
                          </form>
                        </td>
                        <td>
                          <div className="d-flex gap-10">
                            <a
                              className="btn btn-outline-info btn-sm square-btn"
                              title="Barcode"
                              href="https://6valley.6amtech.com/seller/product/barcode/46"
                            >
                              <i className="tio-barcode" />
                            </a>
                            <a
                              className="btn btn-outline-info btn-sm square-btn"
                              title="View"
                              href="https://6valley.6amtech.com/seller/product/view/46"
                            >
                              <i className="tio-invisible" />
                            </a>
                            <a
                              className="btn btn-outline-primary btn-sm square-btn"
                              title="Edit"
                              href="https://6valley.6amtech.com/seller/product/edit/46"
                            >
                              <i className="tio-edit" />
                            </a>
                            <a
                              className="btn btn-outline-danger btn-sm square-btn"
                              href="javascript:"
                              title="Delete"
                              onclick="form_alert('product-46','Want to delete this item ?')"
                            >
                              <i className="tio-delete" />
                            </a>
                          </div>
                          <form
                            action="https://6valley.6amtech.com/seller/product/delete/46"
                            method="post"
                            id="product-46"
                          >
                            <input
                              type="hidden"
                              name="_token"
                              defaultValue="ogOCpmdAJJ38pWdY4o8txAvCPH58PO03n5rkZpRx"
                            />{" "}
                            <input
                              type="hidden"
                              name="_method"
                              defaultValue="delete"
                            />{" "}
                          </form>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">10</th>
                        <td>
                          <a
                            href="https://6valley.6amtech.com/seller/product/view/10"
                            className="media align-items-center gap-2 w-max-content"
                          >
                            <img
                              src="https://6valley.6amtech.com/storage/app/public/product/thumbnail/2022-03-17-62331d0904557.png"
                              onerror="this.src='https://6valley.6amtech.com/public/assets/back-end/img/brand-logo.png'"
                              className="avatar border"
                              alt
                            />
                            <span className="media-body title-color hover-c1">
                              test seller product 2
                            </span>
                          </a>
                        </td>
                        <td>Physical</td>
                        <td>$400.00</td>
                        <td>$600.00</td>
                        <td>
                          <label className="badge badge-soft-success">
                            Approved
                          </label>
                        </td>
                        <td>
                          <form
                            action="https://6valley.6amtech.com/seller/product/status-update"
                            method="post"
                            id="product_status10_form"
                            className="product_status_form"
                          >
                            <input
                              type="hidden"
                              name="_token"
                              defaultValue="ogOCpmdAJJ38pWdY4o8txAvCPH58PO03n5rkZpRx"
                            />{" "}
                            <input type="hidden" name="id" defaultValue={10} />
                            <label className="switcher mx-auto">
                              <input
                                type="checkbox"
                                className="switcher_input"
                                id="product_status10"
                                name="status"
                                defaultValue={1}
                                onclick="toogleStatusModal(event,'product_status10',
                                              'product-status-on.png','product-status-off.png',
                                              'Want to Turn ON test seller product 2 Status',
                                              'Want to Turn OFF test seller product 2 Status',
                                              `<p>If enabled this product will be available on the website and customer app</p>`,
                                              `<p>If disabled this product will be hidden from the website and customer app</p>`)"
                              />
                              <span className="switcher_control" />
                            </label>
                          </form>
                        </td>
                        <td>
                          <div className="d-flex gap-10">
                            <a
                              className="btn btn-outline-info btn-sm square-btn"
                              title="Barcode"
                              href="https://6valley.6amtech.com/seller/product/barcode/10"
                            >
                              <i className="tio-barcode" />
                            </a>
                            <a
                              className="btn btn-outline-info btn-sm square-btn"
                              title="View"
                              href="https://6valley.6amtech.com/seller/product/view/10"
                            >
                              <i className="tio-invisible" />
                            </a>
                            <a
                              className="btn btn-outline-primary btn-sm square-btn"
                              title="Edit"
                              href="https://6valley.6amtech.com/seller/product/edit/10"
                            >
                              <i className="tio-edit" />
                            </a>
                            <a
                              className="btn btn-outline-danger btn-sm square-btn"
                              href="javascript:"
                              title="Delete"
                              onclick="form_alert('product-10','Want to delete this item ?')"
                            >
                              <i className="tio-delete" />
                            </a>
                          </div>
                          <form
                            action="https://6valley.6amtech.com/seller/product/delete/10"
                            method="post"
                            id="product-10"
                          >
                            <input
                              type="hidden"
                              name="_token"
                              defaultValue="ogOCpmdAJJ38pWdY4o8txAvCPH58PO03n5rkZpRx"
                            />{" "}
                            <input
                              type="hidden"
                              name="_method"
                              defaultValue="delete"
                            />{" "}
                          </form>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">11</th>
                        <td>
                          <a
                            href="https://6valley.6amtech.com/seller/product/view/9"
                            className="media align-items-center gap-2 w-max-content"
                          >
                            <img
                              src="https://6valley.6amtech.com/storage/app/public/product/thumbnail/2023-06-13-64882fd48c1c9.png"
                              onerror="this.src='https://6valley.6amtech.com/public/assets/back-end/img/brand-logo.png'"
                              className="avatar border"
                              alt
                            />
                            <span className="media-body title-color hover-c1">
                              LM Washable and Light-Weight M...
                            </span>
                          </a>
                        </td>
                        <td>Physical</td>
                        <td>$400.00</td>
                        <td>$500.00</td>
                        <td>
                          <label className="badge badge-soft-success">
                            Approved
                          </label>
                        </td>
                        <td>
                          <form
                            action="https://6valley.6amtech.com/seller/product/status-update"
                            method="post"
                            id="product_status9_form"
                            className="product_status_form"
                          >
                            <input
                              type="hidden"
                              name="_token"
                              defaultValue="ogOCpmdAJJ38pWdY4o8txAvCPH58PO03n5rkZpRx"
                            />{" "}
                            <input type="hidden" name="id" defaultValue={9} />
                            <label className="switcher mx-auto">
                              <input
                                type="checkbox"
                                className="switcher_input"
                                id="product_status9"
                                name="status"
                                defaultValue={1}
                                defaultChecked
                                onclick="toogleStatusModal(event,'product_status9',
                                              'product-status-on.png','product-status-off.png',
                                              'Want to Turn ON LM Washable and Light-Weight Men`s Shoe Status',
                                              'Want to Turn OFF LM Washable and Light-Weight Men`s Shoe Status',
                                              `<p>If enabled this product will be available on the website and customer app</p>`,
                                              `<p>If disabled this product will be hidden from the website and customer app</p>`)"
                              />
                              <span className="switcher_control" />
                            </label>
                          </form>
                        </td>
                        <td>
                          <div className="d-flex gap-10">
                            <a
                              className="btn btn-outline-info btn-sm square-btn"
                              title="Barcode"
                              href="https://6valley.6amtech.com/seller/product/barcode/9"
                            >
                              <i className="tio-barcode" />
                            </a>
                            <a
                              className="btn btn-outline-info btn-sm square-btn"
                              title="View"
                              href="https://6valley.6amtech.com/seller/product/view/9"
                            >
                              <i className="tio-invisible" />
                            </a>
                            <a
                              className="btn btn-outline-primary btn-sm square-btn"
                              title="Edit"
                              href="https://6valley.6amtech.com/seller/product/edit/9"
                            >
                              <i className="tio-edit" />
                            </a>
                            <a
                              className="btn btn-outline-danger btn-sm square-btn"
                              href="javascript:"
                              title="Delete"
                              onclick="form_alert('product-9','Want to delete this item ?')"
                            >
                              <i className="tio-delete" />
                            </a>
                          </div>
                          <form
                            action="https://6valley.6amtech.com/seller/product/delete/9"
                            method="post"
                            id="product-9"
                          >
                            <input
                              type="hidden"
                              name="_token"
                              defaultValue="ogOCpmdAJJ38pWdY4o8txAvCPH58PO03n5rkZpRx"
                            />{" "}
                            <input
                              type="hidden"
                              name="_method"
                              defaultValue="delete"
                            />{" "}
                          </form>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="table-responsive mt-4">
                  <div className="px-4 d-flex justify-content-lg-end"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products_list;
