import React from 'react'
import Header from '../Header'

import '../sidebar.css'
import { Link } from 'react-router-dom'
import Sidebarr from '../Sidebar'

const Chatwithadmin = () => {
  return (
    <div className='container'>

    {/* <Header/> */}
    <div className='container row' style={{ paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px" }}>
    <div className="col-lg-2 col-md-3" style={{ paddingLeft: "0px" }}>
    {/* <Sidebarr/> */}
    </div>

    <div className="col-lg-10 col-md-9" style={{ paddingLeft: "30px", marginTop: "60px" }}>
    <br/>
<br/>
    <div   div className="mb-4">
      <h2 className="h1 mb-0 text-capitalize d-flex align-items-center gap-2">
        <img width={20} src="https://6valley.6amtech.com/public/assets/back-end/img/products.png" alt />
        Chat with admin
        <span className="badge badge-soft-dark radius-50 fz-14 ml-1">11</span>
      </h2>
    </div>
  
    <div className="row mt-3">
      <div className="col-md-12">
        
          
          <section className="col-xl-12 col-lg-12 mt-4 mt-lg-0">
        <div className="card card-body card-chat justify-content-between Chat">
          <div className="inbox_msg_header d-flex flex-wrap gap-3 justify-content-between align-items-center border px-3 py-2 rounded mb-4">
            <div className="media align-items-center gap-3">
              <div className="avatar avatar-sm avatar-circle">
                <img className="avatar-img" id="profile_image" src="https://6valley.6amtech.com/storage/app/public/profile/2022-10-12-63464cd299fc3.png" onerror="this.src='https://6valley.6amtech.com/public/assets/front-end/img/image-place-holder.png'" alt="Image Description" />
                <span className="avatar-status avatar-sm-status avatar-status-success" />
              </div>
              <div className="media-body">
                <h5 className="profile-name mb-1" id="profile_name">Admin</h5>
                <span className="fz-12" id="profile_phone">0000000000</span>
              </div>
            </div>
          </div>
          <div className="messaging">
            <div className="inbox_msg">
              <div className="mesgs">
                <div className="msg_history d-flex flex-column-reverse pr-2" id="show_msg">
                  <div className="incoming_msg">
                    <div className="received_msg">
                      <div className="received_withd_msg">
                        <div className="d-flex justify-content-start">
                          <p className="bg-chat rounded px-3 py-2 mb-1">
                            ok, thanks
                          </p>
                        </div>
                        <span className="time_date fz-12 pt-2 d-flex justify-content-start"> 02:38 AM | Jan 10 </span>
                      </div>
                    </div>
                  </div>
                  <div className="outgoing_msg">
                    <div className="sent_msg p-2">
                      <div className="d-flex justify-content-end">
                        <p className="bg-c1 text-white rounded px-3 py-2 mb-1">
                          How can I help you??
                        </p>
                      </div>
                      <span className="time_date fz-12 pt-2 d-flex justify-content-end"> 08:53 AM | Oct 12 </span>
                    </div>
                  </div>
                  <div className="incoming_msg">
                    <div className="received_msg">
                      <div className="received_withd_msg">
                        <div className="d-flex justify-content-start">
                          <p className="bg-chat rounded px-3 py-2 mb-1">
                            There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.
                          </p>
                        </div>
                        <span className="time_date fz-12 pt-2 d-flex justify-content-start"> 08:53 AM | Oct 12 </span>
                      </div>
                    </div>
                  </div>
                  <div className="outgoing_msg">
                    <div className="sent_msg p-2">
                      <div className="d-flex justify-content-end">
                        <p className="bg-c1 text-white rounded px-3 py-2 mb-1">
                          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum
                        </p>
                      </div>
                      <span className="time_date fz-12 pt-2 d-flex justify-content-end"> 08:53 AM | Oct 12 </span>
                    </div>
                  </div>
                  <div className="incoming_msg">
                    <div className="received_msg">
                      <div className="received_withd_msg">
                        <div className="d-flex justify-content-start">
                          <p className="bg-chat rounded px-3 py-2 mb-1">
                            It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
                          </p>
                        </div>
                        <span className="time_date fz-12 pt-2 d-flex justify-content-start"> 08:52 AM | Oct 12 </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="type_msg">
                  <div className="input_msg_write">
                    <form className="mt-4" id="myForm" encType="multipart/form-data">
                      <input type="hidden" name="_token" defaultValue="YFPrIbFYlUsgcs6Ht4EEyCysWGBq8lmpLaBUW0q6" /> <input type="hidden" id="hidden_value" defaultValue={9} name="user_id" />
                      <div className="position-relative">
                        <label className="py-0 px-3 d-flex align-items-center m-0 cursor-pointer position-absolute top-3">
                          <svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} viewBox="0 0 22 22" fill="none">
                            <path d="M18.1029 1.83203H3.89453C2.75786 1.83203 1.83203 2.75786 1.83203 3.89453V18.1029C1.83203 19.2395 2.75786 20.1654 3.89453 20.1654H18.1029C19.2395 20.1654 20.1654 19.2395 20.1654 18.1029V3.89453C20.1654 2.75786 19.2395 1.83203 18.1029 1.83203ZM3.89453 3.20703H18.1029C18.4814 3.20703 18.7904 3.51595 18.7904 3.89453V12.7642L15.2539 9.2277C15.1255 9.09936 14.9514 9.02603 14.768 9.02603H14.7653C14.5819 9.02603 14.405 9.09936 14.2776 9.23136L10.3204 13.25L8.65845 11.5945C8.53011 11.4662 8.35595 11.3929 8.17261 11.3929C7.9957 11.3654 7.81053 11.4662 7.6822 11.6009L3.20703 16.1705V3.89453C3.20703 3.51595 3.51595 3.20703 3.89453 3.20703ZM3.21253 18.1304L8.17903 13.0575L13.9375 18.7904H3.89453C3.52603 18.7904 3.22811 18.4952 3.21253 18.1304ZM18.1029 18.7904H15.8845L11.2948 14.2189L14.7708 10.6898L18.7904 14.7084V18.1029C18.7904 18.4814 18.4814 18.7904 18.1029 18.7904Z" fill="#1455AC" />
                            <path d="M8.12834 9.03012C8.909 9.03012 9.54184 8.39728 9.54184 7.61662C9.54184 6.83597 8.909 6.20312 8.12834 6.20312C7.34769 6.20312 6.71484 6.83597 6.71484 7.61662C6.71484 8.39728 7.34769 9.03012 8.12834 9.03012Z" fill="#1455AC" />
                          </svg>
                          <input type="file" id="msgfilesValue" className="h-100 position-absolute w-100 " hidden multiple accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*" />
                        </label>
                        <textarea className="form-control pl-8" id="msgInputValue" name="message" type="text" placeholder="Send a message" aria-label="Search" defaultValue={""} />
                      </div>
                      <div className="mt-3 d-flex justify-content-between">
                        <div className>
                          <div className="d-flex gap-3 flex-wrap filearray" />
                          <div id="selected-files-container" />
                        </div>
                        <div>
                          <button className="aSend btn btn--primary" type="submit" id="msgSendBtn">Send Reply</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
        
      </div>
    </div>
    </div>
  
    </div>



    </div>
  )
}

export default Chatwithadmin



