import React, { useEffect, useState } from "react";
import Header from "../Header";
import "../sidebar.css";
import { Link } from "react-router-dom";
import Sidebarr from "../Sidebar";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import toast, { Toaster } from "react-hot-toast";
import swal from "sweetalert";
import Pagination from "react-js-pagination";
import QRCode from "react-qr-code";
const Staffrole = () => {
  const [productlist, setproductlist] = useState([]);
  const [count, setcount] = useState();
  const [ProductListfilter, setProductListfilter] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const itemsPerPage = 10;
  let token = secureLocalStorage.getItem("vendortoken");
  let shopid = secureLocalStorage.getItem("shopidofvendor");

  useEffect(() => {
    getproductlist();
  }, [0]);
  let getproductlist = () => {
    const data = {
      shopId: shopid,
      status:"0"
    };
    const options = {
      headers: {
        token: token,
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}vender/api/filterOrder_list`,
        data,
        options
      )
      .then((res) => {

        setcount(res?.data?.data?.length);
        setproductlist(res?.data?.data);
      })
      .catch((error) => {});
  };
  useEffect(() => {
    setProductListfilter(productlist);
  }, [productlist]);
  const handleFilter = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    const result = productlist.filter((item) =>
      item.products[0]?.product_name?.toLowerCase().includes(searchTerm)
    );
    setProductListfilter(result);
    setActivePage(1);
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const [CategoryList, setCategoryList] = useState();
  const [serviceName, setServiceName] = useState("");
  let serviceTypeId = secureLocalStorage.getItem("serviceTypeeee");

  useEffect(() => {
    if (CategoryList) {
      const matchedCategory = CategoryList?.find(
        (category) => category?._id === serviceTypeId
      );
      if (matchedCategory) {
        setServiceName(matchedCategory?.maincategory_englishName);
      }
    }
  }, [CategoryList, serviceTypeId]);

  const renderProductData = (data, index) => {
    const adjustedIndex = (activePage - 1) * itemsPerPage + index + 1;
    return (
        <tr>
        <td>{adjustedIndex}</td>
        <td class="text-capitalize">
<div class="media align-items-center gap-10">
<img class="rounded-circle avatar avatar-lg" alt="" src="https://6valley.6amtech.com/storage/app/public/admin/2022-10-12-634695f03aebb.png"/>
<div class="media-body">
Demo Employee
</div>
</div>
</td>
<td>
d**********@gmail.com
</td>
<td>0**********</td>

<td>product manager</td>
        
        <td >
        <form action="https://6valley.6amtech.com/admin/employee/status" method="post" id="employee-id-2-form" className="employee_id_form">
        <input type="hidden" name="_token" defaultValue="8baIIpI2lEoDxK6XQguMChlqlEV1eBJ0rk7Q8wPn" autoComplete="off" /> <input type="hidden" name="id" defaultValue={2} />
        <label className="switcher">
          <input type="checkbox" className="switcher_input toggle-switch-message" defaultValue={1} id="employee-id-2" name="status" defaultChecked data-modal-id="toggle-status-modal" data-toggle-id="employee-id-2" data-on-image="employee-on.png" data-off-image="employee-off.png" data-on-title="Want to Turn ON Employee Status?" data-off-title="Want to Turn OFF Employee Status?" data-on-message="<p>If enabled this employee can log in to the system and perform his role</p>" data-off-message="<p>If disabled this employee can not log in to the system and perform his role</p>" />`)"&gt;
          <span className="switcher_control" />
        </label>
      </form>
        </td>
        <td class="text-center">
<div class="d-flex gap-10 justify-content-center">
<a href="https://6valley.6amtech.com/admin/employee/update/2" class="btn btn-outline--primary btn-sm square-btn" title="Edit">
<i class="fa fa-pencil-square-o" aria-hidden="true"></i>
</a>
<a class="btn btn-outline-info btn-sm square-btn" title="View" href="https://6valley.6amtech.com/admin/employee/view/2">
<i class="fa fa-eye" aria-hidden="true"></i>
</a>
</div>
</td>
       
      </tr>
    );
  };


  return (
    <div className="container">
      <Toaster />
      {/* <Header /> */}
      <div
        className="container row"
        style={{ paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px" }}
      >
        <div className="col-lg-2 col-md-3" style={{ paddingLeft: "0px" }}>
          {/* <Sidebarr /> */}
        </div>

        <div
          className="col-lg-10 col-md-9"
          style={{ paddingLeft: "30px", marginTop: "60px" }}
        >
          <div div className="mt-3 mb-4">
            <h2 className="h1 mb-0 text-capitalize d-flex align-items-center gap-2">
              <img
                width={20}
                src="https://6valley.6amtech.com/public/assets/back-end/img/employee.png"
                alt
              />
              Employee List
              <span className="badge badge-soft-dark radius-50 fz-14 ml-1">
                {count}
              </span>
            </h2>
          </div>
          
          <div className="row mt-20 mb-3">
            <div className="col-md-12">
              <div className="card">
                <div className="px-3 py-4 light-bg">
                  <div className="row g-2 align-items-center flex-grow-1">
                    <div className="col-md-4">
                      <h5 className="text-capitalize d-flex gap-1">
                      Employee 
                        <span className="badge badge-soft-dark radius-50 fz-12">
                          {count}
                        </span>
                      </h5>
                    </div>
                    <div className="col-md-8 d-flex gap-3 flex-wrap flex-sm-nowrap justify-content-md-end">
                      <form>
                        <div className="input-group input-group-merge input-group-custom">
                          <input
                            id="datatableSearch_"
                            onChange={handleFilter}
                            type="search"
                            name="search"
                            className="form-control"
                            placeholder="Search by name email"
                            aria-label="Search orders"
                            required
                          />
                          <button type="submit" className="btn btn--primary">
                            Search
                          </button>
                        </div>
                      </form>
                      <div className="dropdown">
                        <ul className="dropdown-menu dropdown-menu-right">
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://6valley.6amtech.com/seller/orders/export-order-data/all?date_type=this_year"
                            >
                              <img
                                width={14}
                                src="https://6valley.6amtech.com/public/assets/back-end/img/excel.png"
                                alt
                              />
                              Excel
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  {ProductListfilter?.length > 0 ? (
                    <table
                      style={{ textAlign: "left" }}
                      className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100"
                    >
                      <thead className="thead-light thead-50 text-capitalize">
                      <tr>
<th>SL</th>
<th>Name</th>
<th>Email</th>
<th>Phone</th>
<th>Role</th>
<th>Status</th>
<th class="text-center">Action</th>
</tr>
                      </thead>
                      <tbody>
                        {ProductListfilter.slice(
                          (activePage - 1) * itemsPerPage,
                          activePage * itemsPerPage
                        ).map((data, index) => renderProductData(data, index))}
                      </tbody>
                    </table>
                  ) : (
                    <div class="text-center p-4">
                    <img class="mb-3 w-160" src="https://6valley.6amtech.com/public/assets/back-end/img/empty-state-icon/default.png" alt="Image Description"/>
                    <p class="mb-0 order-stats__subtitle">No order found</p>
                    </div>
                  )}
                  <div className="d-flex justify-content-center mt-4">
                    {ProductListfilter.length > itemsPerPage && (
                      <Pagination
                        activePage={activePage}
                        itemsCountPerPage={itemsPerPage}
                        totalItemsCount={ProductListfilter.length}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                      />
                    )}
                  </div>
                </div>

                <div className="table-responsive mt-4">
                  <div className="px-4 d-flex justify-content-lg-end"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Staffrole;





